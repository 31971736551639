import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { SANS_SERIF, WEIGHT } from '../../style/type'

import Button from '../common/Button'
import { CalendarWithDays, FormDownArrow } from '../common/Icons'
import DatePicker from 'react-datepicker'
import ReactHtmlParser from 'react-html-parser'
import Section, { SliceSection } from '../common/Section'
import Text, { setFontSize, sizes } from '../common/Text'
import { Formik } from 'formik'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { useAppContext } from '../../context/AppContext'
import { rem } from 'polished'
import * as yup from 'yup'
import { addDays, format, isAfter } from 'date-fns'
import { datePickerStyle } from '../common/DatePicker'

import '../../style/dateStyle.css'

const guestsArr = Array.from(Array(5).keys())

const URL = 'https://be.synxis.com'

// https://be.synxis.com/?&hotel=78996&configcode=LNY18&adult=5&arrive=09/02/2019&depart=09/02/2019&promo=5RNP7F&?
// https://be.synxis.com/?adult=5&arrive=2019-09-04&chain=22494&child=0&configcode=LNY18&currency=USD&depart=2019-09-05&hotel=78996&level=hotel&locale=en-US&rooms=1

// https://book.rguest.com/onecart/wbe/room/1791/The-Landing-Hotel/2022-09-20/2022-09-30/Best%20Available%20Rate/2

const DatePickerField = ({ name, value, onChange, minDate }) => {
  return (
    <DatePicker
      placeholderText="MM / DD / YYYY"
      selected={(value && new Date(value)) || null}
      minDate={minDate}
      onChange={val => {
        onChange(name, val)
      }}
    />
  )
}

const HotelBookingModule = ({ primary: data, items }) => {
  const { focusStyleOn, isXLarge } = useAppContext()

  const arrivalLabel = get(data, 'arrival_label')
  const departureLabel = get(data, 'departure_label')
  const ratesLabel = get(data, 'rates_label')
  const guestsLabel = get(data, 'guests_label')
  const formLabel = get(data, 'form_label')
  const infoText = get(data, 'info_text.html')
  const synxisID = get(data, 'hotel_id')
  const agilysisID = get(data, 'agilysis_hotel_id')
  const resStartDate = get(data, 'res_start_date')

  const src = get(data, 'hotel_image.url')
  const image = {
    src,
    alt: get(data, 'hotel_image.alt'),
  }

  const Wrapper = isXLarge ? Fragment : ColSmall

  return (
    <Section bgColor="#EAEEF4" noPaddingBottom noPaddingTop>
      <Container>
        {(synxisID || agilysisID) && (
          <Content>
            {!isXLarge && src && <Image {...image} />}
            <Formik
              initialValues={{
                arrive: '',
                depart: '',
                adult: 1,
                rates: agilysisID ? 'Best%20Available%20Rate' : '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                // Format dates
                let arriveDate
                if (values.arrive === '') {
                  if (resStartDate) {
                    arriveDate = new Date(resStartDate)
                  } else {
                    arriveDate = new Date()
                  }
                } else {
                  arriveDate = new Date(values.arrive)
                }

                const arrive = synxisID
                  ? format(arriveDate, 'MM/dd/yyyy')
                  : format(arriveDate, 'yyyy-MM-dd')

                // Ensure departure is after arrival
                const isAfterArrival = isAfter(
                  new Date(values.depart),
                  new Date(arrive),
                )

                const departDate =
                  values.depart !== '' && isAfterArrival
                    ? new Date(values.depart)
                    : addDays(arriveDate, 1)

                const depart = synxisID
                  ? format(departDate, 'MM/dd/yyyy')
                  : format(departDate, 'yyyy-MM-dd')
                const params = {
                  ...values,
                  arrive,
                  depart,
                  // From cms
                  hotel: synxisID || agilysisID || '',
                  promo: values.rates,
                }

                // Query string
                if (synxisID) {
                  const options = Object.keys(params)
                    .reduce((acc, key) => {
                      if (!!params[key]) acc += `${key}=${params[key]}&`
                      return acc
                    }, '')
                    .slice(0, -1)

                  // Open window
                  if (typeof window !== 'undefined') {
                    window.open(`${URL}?${options}`, '_blank')
                  }
                } else if (agilysisID) {
                  // Open window
                  if (typeof window !== 'undefined') {
                    window.open(
                      `https://book.rguest.com/onecart/wbe/room/${params.hotel}/${params.arrive}/${params.depart}/${params.promo}/${params.adult}`,
                      '_blank',
                    )
                  }
                }

                setSubmitting(false)
              }}
              validationSchema={yup.object().shape({
                arrive: yup.string(),
                depart: yup.string(),
                rates: yup.string(),
                adult: yup.number().required('Required'),
              })}
            >
              {formProps => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                } = formProps

                return (
                  <Form
                    showFocus={focusStyleOn}
                    onSubmit={handleSubmit}
                    // hasError={errors.guests && touched.guests}
                  >
                    {isXLarge && src && <Image {...image} />}
                    {isXLarge && (
                      <>
                        <Wrapper>
                          <Col>
                            {arrivalLabel && (
                              <Text
                                htmlFor="arrive"
                                as="label"
                                uppercase
                                size="xxs"
                                bold
                                color={errors.arrive && touched.arrive && 'red'}
                                nowrap
                                spacing={0.5}
                              >
                                {arrivalLabel}
                              </Text>
                            )}
                            <DatePickerField
                              className="date-picker"
                              name="arrive"
                              value={values.arrive}
                              onChange={setFieldValue}
                              minDate={
                                resStartDate
                                  ? new Date(resStartDate)
                                  : new Date()
                              }
                            />
                            <CalendarWithDays />
                          </Col>

                          <Col>
                            {departureLabel && (
                              <Text
                                htmlFor="depart"
                                as="label"
                                uppercase
                                size="xxs"
                                bold
                                color={errors.depart && touched.depart && 'red'}
                                nowrap
                                spacing={0.5}
                              >
                                {departureLabel}
                              </Text>
                            )}
                            <DatePickerField
                              name="depart"
                              value={values.depart}
                              onChange={setFieldValue}
                              minDate={
                                resStartDate
                                  ? addDays(new Date(resStartDate), 1)
                                  : values.arrive
                                  ? addDays(new Date(values.arrive), 1)
                                  : addDays(new Date(), 1)
                              }
                            />
                            <CalendarWithDays />
                          </Col>
                        </Wrapper>

                        <Wrapper>
                          {ratesLabel && (
                            <Col maxWidth={200}>
                              {ratesLabel && (
                                <Text
                                  htmlFor="rates"
                                  as="label"
                                  uppercase
                                  size="xxs"
                                  bold
                                  color={errors.rates && touched.rates && 'red'}
                                  nowrap
                                  spacing={0.5}
                                >
                                  {ratesLabel}
                                </Text>
                              )}
                              <select
                                name="rates"
                                value={values.rates}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="">Select</option>
                                {items &&
                                  items.map(
                                    ({ rate_option, rate_value }, i) => (
                                      <option key={`s${i}`} value={rate_value}>
                                        {rate_option}
                                      </option>
                                    ),
                                  )}
                              </select>
                              <FormDownArrow
                                width={14}
                                height={9}
                                className="down-arrow"
                              />
                            </Col>
                          )}
                          <Col maxWidth={60}>
                            {guestsLabel && (
                              <Text
                                htmlFor="adult"
                                as="label"
                                uppercase
                                size="xxs"
                                bold
                                color={errors.adult && touched.adult && 'red'}
                                nowrap
                                spacing={0.5}
                              >
                                {guestsLabel}
                              </Text>
                            )}
                            <select
                              name="adult"
                              value={values.adult}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {guestsArr &&
                                guestsArr.map(item => (
                                  <option key={item} value={item + 1}>
                                    {item + 1}
                                  </option>
                                ))}
                            </select>
                            <FormDownArrow
                              width={14}
                              height={9}
                              className="down-arrow"
                            />
                          </Col>
                        </Wrapper>
                      </>
                    )}

                    <ButtonGroup>
                      {formLabel && (
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          style={{ minWidth: rem(178) }}
                        >
                          {formLabel}
                        </Button>
                      )}
                      {infoText && (
                        <Text size="xs" xl="xxs" xxl="xs" center>
                          {ReactHtmlParser(infoText)}
                        </Text>
                      )}
                    </ButtonGroup>
                  </Form>
                )
              }}
            </Formik>
          </Content>
        )}
      </Container>
    </Section>
  )
}

HotelBookingModule.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
}

export default HotelBookingModule

const Form = styled.form`
  ${({ showFocus, theme }) => css`
    ${datePickerStyle};

    ${theme.mixin.fadeIn()};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    position: relative;
    width: 100%;

    ${theme.media.xl`
      justify-content: space-between;
      flex-direction: row;
    `};

    label {
      margin-bottom: ${theme.space(1)};
      z-index: 0;
      line-height: ${rem(14)};
    }

    input,
    select {
      font-family: ${SANS_SERIF};
      ${setFontSize(sizes.md)};
      font-weight: ${WEIGHT.BOOK};
      color: ${theme.color.neutralBlue};
      background-color: transparent;
      padding-bottom: ${theme.space(0.5)};
      cursor: pointer;

      &:focus {
        outline: none;

        ${showFocus &&
          css`
            outline: 2px solid ${theme.color.accentLightBlue};
            box-shadow: 0 0 5px ${theme.color.accentLightBlue};
          `};
      }
    }

    input {
      display: flex;
      align-items: center;
      width: 100%;
    }

    select {
      appearance: none;
      border: none;
    }
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: ${rem(208)};
    max-width: ${theme.layout.maxWidth};

    ${theme.media.xl`
      height: ${rem(160)};
     `};

    > div {
      width: 100%;
    }

    .react-datepicker {
      transform: translateY(-10px) !important;
    }

    z-index: 2;
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
  `};
`

const Image = styled.img`
  ${({ theme }) => css`
    width: auto;
    height: ${rem(60)};

    ${theme.media.xl`
      margin-bottom: ${theme.space(3)};

      height: ${rem(55)};
      margin-bottom: 0;
    `};

    ${theme.media.xxl`
      height: ${rem(60)};
    `};
  `};
`

const Col = styled.div`
  ${({ theme, maxWidth }) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: ${rem(160)};

    ${theme.media.xl`
        max-width: ${rem(maxWidth ? maxWidth : 204)};
      `};

    border-bottom: 2px solid ${theme.color.secondaryLightBlue};
    margin: 0 ${theme.space(1)};

    &:last-child {
      margin-top: ${theme.space(2)};

      ${theme.media.xl`
        margin-top: 0;
      `};
    }

    svg {
      position: absolute;
      bottom: ${rem(7)};
      right: 0;
      pointer-events: none;
    }

    .down-arrow {
      transform: translateY(-1px);
    }
  `};
`

const ColSmall = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: ${rem(200)};
  `};
`

const ButtonGroup = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding-top: ${theme.space(2)};

    button {
      margin: 0 auto ${theme.space(1)};

      ${theme.media.xl`
        margin: ${theme.space(2)} auto ${theme.space(1)};
      `};
    }

    a {
      white-space: nowrap;
    }

    ${theme.media.md`
      width: auto;
    `};

    ${theme.media.xl`
      padding-top: 0;
    `};
  `};
`
