import React from 'react'
import PropTypes from 'prop-types'

import Flickity from '../common/Flickity'
import { SliceSection } from '../common/Section'
import ReactHtmlParser from 'react-html-parser'
import Text from '../common/Text'
import { Twitter } from '../common/Icons'

import { useAppContext } from '../../context/AppContext'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import { format } from 'date-fns'
import { formatTweet } from '../../utils/formatText'

const TwitterModule = ({ slice_type, primary: data, twitter, ...props }) => {
  const { isMedium, isLarge, isXXLarge, focusStyleOn } = useAppContext()
  const title = get(data, 'twitter_title.text')
  const body = get(data, 'twitter_body.html')
  const bgColor = get(data, 'background_color') || 'softBlue'
  const Wrapper = isMedium ? Grid : Flickity
  if (twitter.length > 4) twitter.length = 4

  let dateFormat = 'h:mma MMMM d, yyyy'
  if ((isMedium || isLarge) && !isXXLarge) dateFormat = 'h:mma MMM. d, yyyy'

  const options = isMedium ? {} : { options: { accessibility: focusStyleOn } }

  return (
    <SliceSection bgColor={bgColor} fullWidthSmall {...props}>
      <Container>
        <TextGroup>
          {title && <Text.h2 center>{title}</Text.h2>}
          {body && (
            <Text html center>
              {ReactHtmlParser(body)}
            </Text>
          )}
        </TextGroup>
        <Wrapper key={focusStyleOn} type={slice_type} {...options}>
          {twitter &&
            twitter.map(({ node: item }, i) => {
              const text = get(item, 'text')
              const date = get(item, 'source_created_at')
              const url = get(item, 'url')
              return (
                <TweetItem key={item.id}>
                  <div>
                    <Top href={url} target="_blank" rel="noopener noreferrer">
                      <Text size="xxs" xl="xs" spacing={0}>
                        {format(new Date(date), dateFormat)}
                      </Text>
                      <Twitter className="icon" fill="#fff" />
                    </Top>
                    <Text
                      className="tweet"
                      book
                      left
                      size="xs"
                      lineHeightMultiplier={20 / 13}
                      spacing={0}
                    >
                      {formatTweet(text)}
                    </Text>
                  </div>
                </TweetItem>
              )
            })}
        </Wrapper>
      </Container>
    </SliceSection>
  )
}

TwitterModule.propTypes = {
  twitter: PropTypes.array,
  primary: PropTypes.object,
  slice_type: PropTypes.string,
}

export default TwitterModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;

    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidth};

    > div {
      width: 100%;
    }
  `};
`

const TextGroup = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.space(2)};
  `};
`

const Top = styled.a`
  ${({ theme }) => css`
    ${theme.mixin.opacityHover};
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: ${theme.space(3)};

    ${Text} {
      opacity: 0.5;
      margin-right: ${theme.space(1)};
    }

    svg {
      width: ${rem(21)};
      height: ${rem(17)};
    }
  `};
`

const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(${rem(120)}, 1fr);
    grid-gap: ${theme.space(1)} ${theme.space(1)};

    width: 100%;

    ${theme.media.md`
      grid-template-columns: repeat(2, minmax(${rem(160)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};

    ${theme.media.xl`
      grid-template-columns: repeat(auto-fit, minmax(${rem(160)}, 1fr));
    `};
  `};
`

const TweetItem = styled.div`
  ${({ type, theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;

    padding: 0 ${theme.space(1)};

    ${theme.media.md`
        padding: 0;
      `};

    > div {
      position: relative;
      height: 100%;
      min-height: ${rem(320)};

      background-color: #fff;
      border-radius: ${theme.layout.borderRadius};

      overflow: hidden;

      padding: ${theme.space(3)};

      ${theme.media.lg`
         min-height: none;
      `};
    }

    .tweet a {
      text-decoration: underline;
    }

    ${theme.media.md`
      &:hover {
        img {
          opacity: 0.8;
        }
      }
    `};
  `};
`
