import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { CARD_STYLES } from ' ../../../lib/constants'

import AlphaGradient, { AlphaGradientCorner } from '../common/AlphaGradient'
import AspectRatioBox from '../common/AspectRatioBox'
import Button from '../common/Button'
import { StyledButton } from '../common/Button'
import FeaturedGridItem from '../components/grid-module/FeaturedGridItem'
import Dropdown from '../common/Dropdown'
import Flickity from '../common/Flickity'
import { Link } from 'gatsby'
import { PrevNextArrow } from '../common/Icons'
import SectionWithoutVerticalPadding from '../common/SectionWithoutVerticalPadding'
import Section from '../common/Section'
import ReactHtmlParser from 'react-html-parser'
import Page from '../container/Page'
import Text from '../common/Text'
import { GridItemTag } from '../components/grid-module/GridItemCommon'
import { motion } from 'framer-motion'
import SplitGallery from '../common/SplitGallery'

import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import { get, flatten, uniq, cloneDeep, take } from 'lodash'
import styled, { css } from 'styled-components'
import { rem, transparentize } from 'polished'
import { useAppContext } from '../../context/AppContext'
import { format, getMinutes, compareAsc } from 'date-fns'
import { color } from '../../style/theme'
import linkResolver from '../../utils/linkResolver'

const BLACKLIST_TAGS = ['featured', 'promo']

const padTop = ({ theme }) => css`
  padding-top: ${theme.space(4.5)};

  ${theme.media.lg`
    padding-top: ${theme.space(6)};
  `};

  ${theme.media.xl`
    padding-top: ${theme.space(7)};
  `};
`

const padCards = ({ theme }) => css`
  padding-top: ${theme.space(2)};

  ${theme.media.xl`
    padding-top: ${theme.space(4)};
  `};

  ${theme.layout.verticalPaddingBottom};
`

const SlideText = ({
  dates,
  index,
  event,
  event_button_label,
  card_title,
  featured_label,
}) => {
  const date = get(dates, '[0].date')
  const link = {
    href: linkResolver({ event }, 'event'),
    target: get(event, 'target'),
  }
  const title = get(card_title, 'text')

  return (
    <motion.div
      key={index}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        type: 'tween',
        duration: 0.4,
        ease: 'easeOut',
      }}
    >
      {featured_label && (
        <Text
          center
          color="secondaryLightBlue"
          size="xs"
          medium
          lineHeight={rem(23)}
          css={{ marginBottom: rem(7) }}
        >
          {featured_label}
        </Text>
      )}
      {title && (
        <Text.h3 center white>
          {title}
        </Text.h3>
      )}
      {date && (
        <Text.h5 center white>
          {format(
            new Date(date),
            `iii',' MMM dd '@' ${getMinutes(new Date(date)) ? 'p' : 'ha'}`,
          )}
        </Text.h5>
      )}
      <Button {...link}>
        {event_button_label ? event_button_label : 'View Details'}
      </Button>
    </motion.div>
  )
}

SlideText.propTypes = {
  dates: PropTypes.array,
  event: PropTypes.object,
  event_button_label: PropTypes.string,
  index: PropTypes.number,
  card_title: PropTypes.string,
  featured_label: PropTypes.string,
}

const useFindRecurringEvents = eventsRaw => {
  const recurring = useMemo(() => {
    const newEvents = eventsRaw.reduce((acc, item) => {
      const dates = get(item, 'grid_content.document.data.dates') || []
      for (let i = 0; i < dates.length; i++) {
        const newItem = cloneDeep(item)
        newItem.grid_content.document.data.dates = [dates[i]]

        acc.push(newItem)
      }

      return acc
    }, [])
    newEvents.sort((a, b) => {
      const aDate = get(a, 'grid_content.document.data.dates[0].date')
      const bDate = get(b, 'grid_content.document.data.dates[0].date')

      return compareAsc(new Date(aDate), new Date(bDate))
    })

    return newEvents
  }, [eventsRaw])

  return recurring
}

const EventVenueDetailPage = ({ pageContext: { staticData } }) => {
  const { focusStyleOn, isLarge } = useAppContext()
  const [activeFilter, setActiveFilter] = useState('all')
  const pageData = usePreviewData(staticData)
  const { sections } = useParseSiteData(pageData)
  const { data } = pageData
  const eventsRaw = get(pageData, 'venueEvents', [])
  const [showNumEvents, setShowEvent] = useState(12)

  const handleShowMore = e => {
    e.preventDefault()
    setShowEvent(prevState => prevState + 12)
  }

  const events = useFindRecurringEvents(eventsRaw)

  const filteredEvents = useMemo(() => {
    if (activeFilter === 'all') {
      return events
    } else {
      return events.filter(item => {
        const t = get(item, 'grid_content.document.tags')
        return t && t.includes(activeFilter)
      })
    }
  }, [activeFilter, events])

  const title = get(data, 'header_title')
  const logoImage = {
    src: get(data, 'logo_image.url'),
    alt: get(data, 'logo_image.alt') || '',
  }

  const defaultFeaturedImage = {
    src: get(data, 'default_featured_image.url'),
    alt: get(data, 'default_featured_image.alt') || '',
  }

  const hoursLabel = get(data, 'hours_label')
  const hours = get(data, 'hours.html')

  const allVenuesLink = linkResolver(data, 'all_venues_link')
  const allVenuesLabel = get(data, 'all_venues_label') || 'All Venues'

  const boxOfficeLabel = get(data, 'box_office_label') || 'Box Office'
  const boxOfficeButtonLabel = get(data, 'box_office_button_label') || 'Tickets'
  const boxOfficeLink = {
    href: linkResolver(data, 'box_office_link'),
    target: get(data, 'box_office_link.target'),
  }

  const images = get(data, 'images').filter(item => get(item, 'image.url'))

  let featured = get(data, 'featured')
  const hasFeatures = featured && !!featured.length
  if (hasFeatures) {
    featured = featured.map(item => ({
      ...item,
      ...get(item, 'event.document.data'),
    }))
  }
  const upcomingEventsTitle = get(data, 'upcoming_events_title.text')
  const upcomingEventsSubTitle = get(data, 'upcoming_events_sub_title.text')

  let filters = uniq(
    flatten(events.map(item => get(item, 'grid_content.document.tags'))),
  )
  if (!!filters.length) {
    filters = filters.map(item => ({
      label: item,
      value: item,
    }))
  }

  const descriptionTitle = get(data, 'description_title.text')
  const description = get(data, 'description.html')

  const foodMenuLabel = get(data, 'food_menu_label.text')
  const menus = get(data, 'menu_links')

  const allVenues = get(pageData, 'venuePagination')
  const { nextVenue, previousVenue } = useMemo(() => {
    const thisVenueIndex = allVenues.findIndex(
      ({ uid }) => uid === pageData.uid,
    )
    let nextVenue = {}
    let previousVenue = {}
    if (thisVenueIndex > 0 && thisVenueIndex < allVenues.length - 1) {
      nextVenue = allVenues[thisVenueIndex + 1]
      previousVenue = allVenues[thisVenueIndex - 1]
    } else if (thisVenueIndex === 0) {
      nextVenue = allVenues[thisVenueIndex + 1]
      previousVenue = allVenues[allVenues.length - 1]
    } else if (thisVenueIndex === allVenues.length - 1) {
      nextVenue = allVenues[0]
      previousVenue = allVenues[thisVenueIndex - 1]
    }
    return { nextVenue, previousVenue }
  }, [allVenues, pageData.uid])

  return (
    <>
      <Page {...sections}>
        <Section noPaddingBottom noPaddingTop>
          <TopBg />
          <Content>
            <VenueHeader>
              <TextContainer>
                <AlphaGradientCorner />

                <div>
                  {logoImage && logoImage.src && (
                    <Logo>
                      <Circle>
                        <img alt="" {...logoImage} />
                      </Circle>
                    </Logo>
                  )}
                  {title && (
                    <Text.h1 center={!isLarge} white lineHeightMultiplier={1.2}>
                      {title.text}
                    </Text.h1>
                  )}
                </div>
                <div>
                  <InfoItem>
                    {hoursLabel && <Text.h5 white>{hoursLabel}</Text.h5>}
                    {hours && (
                      <Text color="secondaryLightBlue" size="xs" medium>
                        {ReactHtmlParser(hours)}
                      </Text>
                    )}
                  </InfoItem>
                  <InfoItem>
                    {boxOfficeLink && boxOfficeLink.href && (
                      <Text.h5 white>{boxOfficeLabel}</Text.h5>
                    )}
                    {boxOfficeLink && boxOfficeLink.href && (
                      <StyledButton {...boxOfficeLink} fullWidth>
                        {boxOfficeButtonLabel}
                      </StyledButton>
                    )}
                  </InfoItem>
                </div>
              </TextContainer>
              {hasFeatures ? (
                <SplitGallery
                  hasFeatures={hasFeatures}
                  images={images}
                  features={featured}
                />
              ) : (
                <DefaultImageContainer>
                  <img
                    src={defaultFeaturedImage.src}
                    alt={defaultFeaturedImage.alt}
                  />
                  <AlphaGradient deg={90} height={100} />
                  <AlphaGradient />
                </DefaultImageContainer>
              )}
            </VenueHeader>
          </Content>
        </Section>

        {events && !!events.length && (
          <>
            <SectionWithoutVerticalPadding css={padTop}>
              <Content>
                <UpcomingHeader>
                  <div>
                    {upcomingEventsTitle && (
                      <Text.h3 center={!isLarge}>{upcomingEventsTitle}</Text.h3>
                    )}
                    {upcomingEventsSubTitle && (
                      <Text.h5 center={!isLarge}>
                        {upcomingEventsSubTitle}
                      </Text.h5>
                    )}
                  </div>
                  <Dropdown
                    defaultItemLabel="All"
                    defaultValue="all"
                    defaultLabel="Filter by Type"
                    items={filters}
                    value={activeFilter}
                    onChange={setActiveFilter}
                    minWidth={330}
                    style={{ height: rem(42) }}
                  />
                </UpcomingHeader>
              </Content>
            </SectionWithoutVerticalPadding>

            <SectionWithoutVerticalPadding css={padCards} fullWidthSmall>
              <Grid columns={4}>
                {take(filteredEvents, showNumEvents).map((item, i) => {
                  const tags = get(item, 'grid_content.document.tags', [])
                    .filter(tag => !BLACKLIST_TAGS.includes(tag.toLowerCase()))
                    .map(tag => (
                      <GridItemTag
                        fill
                        onClick={() => setActiveFilter(tag)}
                        label={tag}
                      />
                    ))
                  return (
                    <FeaturedGridItem
                      key={`gi${i}`}
                      item={item}
                      tags={tags}
                      style={CARD_STYLES.EVENT_VENUE_CARD}
                    />
                  )
                })}
              </Grid>
            </SectionWithoutVerticalPadding>
            {showNumEvents < filteredEvents.length && (
              <ShowMoreContainer>
                <ButtonContainer>
                  <Button underline onClick={handleShowMore}>
                    Show more
                  </Button>
                </ButtonContainer>
              </ShowMoreContainer>
            )}
          </>
        )}

        <Section bgColor="softBlue">
          <Content>
            <Description>
              <div>
                {descriptionTitle && <Text.h4>{descriptionTitle}</Text.h4>}
                {description && (
                  <Text html htmlMargin wysiwyg>
                    {ReactHtmlParser(description)}
                  </Text>
                )}
                {foodMenuLabel && <Text.h4>{foodMenuLabel}</Text.h4>}
                <Menus>
                  {menus &&
                    !!menus.length &&
                    menus.map((item, i) => {
                      const link = {
                        href: linkResolver(item, 'link'),
                      }
                      const label = get(item, 'menu_label')
                      return (
                        link.href && (
                          <Button outline key={title + i} {...link}>
                            {label}
                          </Button>
                        )
                      )
                    })}
                </Menus>
              </div>
              {images && images.length > 1 && (
                <AspectRatioBox width={690} height={429} border>
                  <Flickity
                    className="flickity"
                    key={`img-${focusStyleOn}`}
                    minHeight={330}
                    options={{
                      contain: true,
                      accessibility: focusStyleOn,
                      prevNextButtons: images.length > 1,
                    }}
                    type="eventsVenueGallery"
                  >
                    {images.map(({ image }, i) => {
                      const src = get(image, 'url')
                      const alt = get(image, 'alt') || ''
                      return (
                        <Slide key={src + i}>
                          <AspectRatioBox width={690} height={429}>
                            <img src={src} alt={alt} />
                          </AspectRatioBox>
                        </Slide>
                      )
                    })}
                  </Flickity>
                </AspectRatioBox>
              )}
              {images &&
                images.length === 1 &&
                images.map(({ image }, i) => {
                  const src = get(image, 'url')
                  const alt = get(image, 'alt') || ''
                  return (
                    <Slide key={src + i}>
                      <AspectRatioBox width={690} height={429} border>
                        <img src={src} alt={alt} />
                      </AspectRatioBox>
                    </Slide>
                  )
                })}
            </Description>
          </Content>
        </Section>

        {allVenues.length > 1 && (
          <SectionWithoutVerticalPadding>
            <Content>
              <NextSection>
                {isLarge && previousVenue && previousVenue.href ? (
                  <PrevLink to={previousVenue.href}>
                    <Row>
                      <PrevNextArrow
                        fill={color.yellow}
                        left
                        width={24}
                        height={24}
                      />
                      <Text.h5>
                        {get(previousVenue, 'label.text') || 'Previous Venue'}
                      </Text.h5>
                    </Row>
                  </PrevLink>
                ) : (
                  <>{isLarge && <Spacer />}</>
                )}
                {isLarge && !!!allVenuesLink && <div />}
                {isLarge && !!allVenuesLink && (
                  <Button
                    className={'AllVenueButton'}
                    outline
                    href={allVenuesLink}
                  >
                    {allVenuesLabel}
                  </Button>
                )}
                {nextVenue && nextVenue.href ? (
                  <NextLink to={nextVenue.href}>
                    {!isLarge && <Text.h6 center>Next venue</Text.h6>}
                    <Row>
                      <Text.h5>
                        {get(nextVenue, 'label.text') || 'Next Venue'}
                      </Text.h5>
                      <PrevNextArrow
                        fill={color.yellow}
                        width={24}
                        height={24}
                      />
                    </Row>
                  </NextLink>
                ) : (
                  <>{isLarge && <Spacer />}</>
                )}
              </NextSection>
            </Content>
          </SectionWithoutVerticalPadding>
        )}
      </Page>
    </>
  )
}

EventVenueDetailPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default EventVenueDetailPage

const DefaultImageContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    display: none;
    ${theme.media.lg`
    display: block;
    padding-right: 0;
    margin-bottom: 0;
    grid-column: 7 / 13;

  `};

    ${theme.media.xl`
    grid-column: 6 / 13;
  `};

    img {
      object-fit: cover;
      object-position: top left;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  `,
)

const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(${rem(120)}, 1fr);
    grid-gap: ${theme.space(1)} ${theme.space(1)};

    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.md`
      grid-template-columns: repeat(2, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(1.5)} ${theme.space(1.5)};
    `};

    ${theme.media.xl`
      grid-template-columns: repeat(3, minmax(${rem(120)}, 1fr));
    `};

    ${theme.media.xxl`
      grid-template-columns: repeat(4, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};
  `};
`

const Spacer = styled.div`
  flex: 1;
  max-width: 25%;
`

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`

const TopBg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 33%;
    background-color: ${theme.color.softBlue};

    ${theme.media.lg`
      height: 70%;
    `};
  `};
`

const Circle = styled.div`
  ${({ theme }) => css`
    background-color: #fff;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    max-width: ${rem(95)};
    max-height: ${rem(95)};

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  `};
`

const Logo = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: ${rem(80)};
    height: ${rem(80)};

    background-color: rgba(255, 255, 255, 0.2);
    padding: ${theme.space(0.5)};
    border-radius: 50%;
    box-shadow: 0 3px 20px 0 #1d355b;

    margin: 0 auto;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      max-height: ${rem(95)};
    }

    ${theme.media.lg`
      margin: 0;
      width: ${rem(100)};
      height: ${rem(100)};
    `};
  `};
`

const VenueHeader = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};



      background-color: ${theme.color.blue};
      border-radius: ${theme.layout.borderRadius};
      overflow: hidden;
    `};

    ${theme.media.xxl`
      height: ${rem(503)};
    `};
  `};
`

const TextContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: ${theme.color.blue};
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    padding: ${theme.space(3)};
    margin-bottom: ${theme.space(1)};

    > svg {
      height: auto;

      ${theme.media.lg`
        max-width: 50vw;
      `};
    }

    h1 {
      margin-top: ${theme.space(2)};
    }

    > div:first-child {
      flex: 1;
      height: 100%;
    }

    > div:last-child {
      margin-top: ${theme.space(4)};
    }

    > div {
      z-index: 1;
    }

    ${theme.media.lg`
      padding: ${theme.space(5)} ${theme.space(4)} ${theme.space(5.5)};
      padding-right: 0;
      margin-bottom: 0;
      grid-column: 1 / 7;
    `};

    ${theme.media.xl`
      grid-column: 1 / 6;
    `};
  `};
`

const InfoItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.space(1.5)} 0;
    border-top: 1px solid ${transparentize(0.8, theme.color.secondaryLightBlue)};

    &:last-child {
      padding-bottom: 0;
    }

    h5 {
      width: 40%;
      margin-right: ${theme.space(2)};
      max-width: ${rem(150)};
    }

    a {
      cursor: pointer;
      /* transition: 0.3s color; */

      &:hover {
        color: #fff;
      }
    }
  `};
`

const UpcomingHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
      margin-bottom: ${theme.space(2)};

      ${theme.media.lg`
        margin-bottom: 0;
       `};
    }

    ${theme.media.lg`
      flex-direction: row;
      justify-content: space-between;
    `};
  `};
`

const ShowMoreContainer = styled.div`
  ${() => css`
    display: flex;
    justify-content: center;
    width: 100%;
  `}
`

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${rem(-20)};
    margin-bottom: ${rem(20)};

    ${theme.media.lg`
      margin-top: ${rem(-40)};
      margin-bottom: ${rem(40)};
    `}
  `}
`

const Description = styled.div`
  ${({ theme }) => css`
    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};
      align-items: center;
    `};

    > div {
      &:first-child {
        grid-column: 1/6;

        h4,
        p {
          margin-bottom: ${theme.space(2)};
        }
      }

      &:last-child {
        grid-column: 7/13;
      }
    }
  `};
`

const Slide = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`

const Menus = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.space(4)};

    ${theme.media.lg`
      margin-bottom: 0;
     `};

    > a,
    > button {
      &:not(:last-child):not(:only-child) {
        margin-right: ${theme.space(2)};
      }
    }
  `};
`

const NextSection = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: ${theme.space(1)} 0;
    height: ${rem(130)};

    button {
      margin: 0 ${theme.space(1)};
      flex-shrink: 0;
      order: 2;
    }

    ${theme.media.lg`
      height: ${rem(100)};
      display: flex;
      flex-direction: row;
      justify-content: space-between;

       button {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
        }
      `};
  `};
`
const sharedLink = ({ theme }) => css`
  cursor: pointer;

  svg * {
    transition: 0.3s fill;
  }

  &:hover {
    svg * {
      fill: ${theme.color.blue};
    }
  }

  ${theme.media.lg`
    max-width: 35%;
  `};
`

const PrevLink = styled(Link)`
  ${({ theme }) => css`
    ${sharedLink};
    display: none;

    svg {
      margin-right: ${theme.space(1)};
    }

    ${theme.media.lg`
        display: flex
      `};
  `};
`

const NextLink = styled(Link)`
  ${({ theme }) => css`
    ${sharedLink};
    order: 1;

    svg {
      margin-left: ${theme.space(1)};
    }

    h6 {
      margin: 0 0 ${theme.space(1)};
      opacity: 0.5;

      ${theme.media.lg`
        margin: ${theme.space(3)} 0 ${theme.space(1)};
       `};
    }

    ${theme.media.lg`
      order: 3;
      margin-bottom: 0;

      h5 {
        text-align: right;
      }
    `};
  `};
`

const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
  `};
`
