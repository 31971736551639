import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  FEATURED_HERO_SLIDER_MODULE,
  IMAGE_GALLERY_MODULE,
  INSTAGRAM_MODULE,
  TEXT_IMAGE_MODULE,
  TICKER_MODULE,
  TWITTER_MODULE,
} from '../../constants/sliceTypes'

import Loader from 'react-loader-spinner'
import styled, { css } from 'styled-components'
import { rem, transparentize } from 'polished'
import { isNull } from 'lodash'
import { color } from '../../style/theme'

class Flickity extends Component {
  static propTypes = {
    children: PropTypes.node,
    flickityRef: PropTypes.func,
    index: PropTypes.number,
    minHeight: PropTypes.number,
    onChange: PropTypes.func,
    onInit: PropTypes.func,
    onRemove: PropTypes.func,
    options: PropTypes.object,
    type: PropTypes.string,
    className: PropTypes.string,
    showFocus: PropTypes.bool,
    updateOnIndexChage: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this._options = {
      contain: true,
      accessibility: true,
      imagesLoaded: true,
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      ...props.options,
    }
  }

  _Flickity
  _interval = null
  _touchStartCoords
  _touchingCarousel = false

  state = { init: false }

  componentDidMount() {
    this._loadFlickity()
  }

  componentDidUpdate(prevProps) {
    const { index, updateOnIndexChage } = this.props
    if (updateOnIndexChage && index !== this._flkty.selectedIndex) {
      this._flkty.select(index, true, true)
    }
  }

  componentWillUnmount() {
    this._reset()
    this._removeTouchListeners()

    if (this.props.onRemove) this.props.onRemove()

    if (this._flkty) {
      window.removeEventListener('resize', this.resize)
      this._flkty.destroy()
      this._flkty = null
    }
  }

  _loadFlickity = () => {
    const { onInit, type } = this.props

    if (!this._Flickity && typeof window !== 'undefined') {
      this._Flickity = require('flickity')
    }

    if (this._Flickity && typeof window !== 'undefined') {
      const { flickityRef } = this.props
      this._flkty = new this._Flickity(this._el, this._options)
      this._flkty.on('change', this._onChange)
      if (type && type === 'diningGallery') {
        this._flkty.focus()
      }
      if (flickityRef) flickityRef(this._flkty)
      window.addEventListener('resize', this.resize, false)
      this.resize()
      this._addTouchListeners()

      // Show on init
      this.setState({ init: true })
      if (onInit) onInit(this)

      this._interval = setInterval(this.checkHeight, 10)
    }
  }

  // iOS bug
  // https://github.com/metafizzy/flickity/issues/959#issuecomment-536464536
  // https://gist.github.com/bakura10/b0647ef412eb7757fa6f0d2c74c1f145
  _addTouchListeners = () => {
    document.body.addEventListener('touchstart', this._onTouchStart, false)
    document.body.addEventListener('touchmove', this._onTouchMove, false)
  }

  _removeTouchListeners = () => {
    if (window === 'undefined') return
    document.body.removeEventListener('touchstart', this._onTouchStart)
    document.body.removeEventListener('touchmove', this._onTouchMove)
  }

  _onTouchStart = e => {
    if (e.target.closest('.flickity-slider')) {
      this._touchingCarousel = true
    } else {
      this._touchingCarousel = false
      return
    }

    this._touchStartCoords = {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY,
    }
  }

  _onTouchMove = e => {
    if (!(this._touchingCarousel && e.cancelable)) return

    const moveVector = {
      x: e.touches[0].pageX - this._touchStartCoords.x,
      y: e.touches[0].pageY - this._touchStartCoords.y,
    }

    if (Math.abs(moveVector.x) > 7) e.preventDefault()
  }

  _onChange = () => {
    const { onChange } = this.props
    if (onChange) onChange(this._flkty.selectedIndex)
  }

  checkHeight = () => {
    if (this._el) {
      const el = this._el.querySelector('.flickity-viewport')
      if (el) {
        const { height } = el.getBoundingClientRect()
        if (height === 0) {
          this.resize()
        } else {
          this._reset()
        }
      }
    }
  }

  _reset = () => {
    if (!isNull(this._interval)) {
      clearInterval(this._interval)
      this._interval = null
    }
  }

  /*
    Flickity API methods
  */

  get flickity() {
    return this._flkty
  }

  next = () => {
    if (this._flkty) this._flkty.next()
  }

  prev = () => {
    if (this._flkty) this._flkty.previous()
  }

  resize = () => {
    if (this._flkty) this._flkty.resize()
  }

  select = value => {
    if (this._flkty) this._flkty.selectCell(value)
  }

  stopAutoplay = () => {
    if (this._flkty) this._flkty.stopPlayer()
  }

  resumeAutoplay = () => {
    if (this._flkty) this._flkty.playPlayer()
  }

  render() {
    const { minHeight, showFocus, type, className } = this.props
    const { init } = this.state

    return (
      <>
        <Container
          className={`flickity ${className}`}
          ref={i => (this._el = i)}
          minHeight={minHeight}
          type={type}
          showFocus={showFocus}
          init={init}
        >
          {this.props.children}
        </Container>
        {!init && (
          <LoaderContainer init>
            <StyledLoader
              type="TailSpin"
              color={color.blue}
              height={80}
              width={80}
            />
          </LoaderContainer>
        )}
      </>
    )
  }
}

export default Flickity

const LoaderContainer = styled.div`
  ${({ theme }) => css`
    ${theme.mixin.fadeIn(3)}
  `};
`

const Container = styled.div`
  ${({ init, type, theme }) => css`
    position: relative;
    ${(type && sliceTypes[type]) || imageGallery};

    > div {
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
    }

    ${!init &&
      css`
        height: 100%;

        > * {
          opacity: 0;

          &:not(:first-child) {
            position: absolute !important;
            top: 0;
            left: 0;
          }
        }
      `};

    &:focus {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: ${theme.layout.border} solid ${theme.color.accentLightBlue};
        box-shadow: inset 0 0 5px ${theme.color.accentLightBlue};
        background-color: transparent;
        pointer-events: none;
      }
    }

    outline: none;

    ol {
      list-style: none;
    }
  `};
`

const StyledLoader = styled(Loader)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const pageDots = ({ theme }) => css`
  .flickity {
    &-page-dots {
      display: flex;
      justify-content: center;

      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);

      ${theme.media.md`
          transform: translate(-50%, -${rem(40)});
        `};

      .dot {
        width: ${rem(7)};
        height: ${rem(7)};

        border-radius: 100%;
        opacity: 1;
        background: transparent;
        border: 1px solid ${theme.color.secondaryLightBlue};
        margin: 0 ${rem(7)};
        cursor: pointer;
        transition: background-color 0.4s ease-in, border 0.4s ease-in;

        ${theme.media.lg`
          width: ${rem(9)};
          height: ${rem(9)};
        `};

        &:only-child {
          display: none;
        }

        ${theme.media.lg`
          margin: 0 ${theme.space(1.5)};
         `};

        &:hover {
          background: ${theme.color.yellow};
          border: 1px solid ${theme.color.yellow};
          transition: background-color 0.3s, border 0.3s;
        }
      }

      .dot.is-selected {
        background: ${theme.color.secondaryLightBlue};
        border: none;
        cursor: auto;
      }
    }
  }
`

const blueDots = ({ theme }) => css`
  .flickity {
    &-page-dots {
      .dot {
        border: 1px solid ${theme.color.secondaryBlue};

        ${theme.media.md`
           border: 1px solid ${theme.color.secondaryLightBlue};
         `};

        &:hover {
          background: ${theme.color.yellow};
          border: 1px solid ${theme.color.yellow};

          ${theme.media.md`
            background:  ${theme.color.yellow};
          `};
        }
      }

      .dot.is-selected {
        background: ${theme.color.secondaryBlue};
        border: 1px solid ${theme.color.secondaryBlue};

        ${theme.media.md`
           background: ${theme.color.secondaryLightBlue};
           border: 1px solid ${theme.color.secondaryLightBlue};
         `};
      }
    }
  }
`

const prevNext = ({ showFocus, theme }) => css`
  .flickity {
    &-button {
      display: none;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background-color: ${theme.color.yellow};
      transition: background-color 0.3s;
      cursor: pointer;

      &:disabled {
        display: none;
      }

      &:hover {
        background-color: ${theme.color.blue};
        transition: background-color 0.3s;
      }

      ${showFocus &&
        css`
          &:focus {
            border: 1px solid ${theme.color.accentLightBlue};
            box-shadow: inset 0 0 5px ${theme.color.accentLightBlue};
          }
        `};

      &.next {
        left: auto;
        right: 0;
      }

      &-icon {
        fill: white;
      }

      ${theme.media.md`
        display: block;
      `};
    }

    &-prev-next-button {
      width: ${rem(40)};
      height: ${rem(40)};
      padding: ${rem(10)};
      border-radius: 100%;
    }
  }
`

const flickityInit = ({ theme }) => css`
  .flickity {
    width: 100%;
    height: 100%;

    &-slider {
      > * {
        width: 100%;
      }
    }

    &-viewport {
      overflow: hidden;
      border-radius: ${theme.layout.borderRadius};
      margin-bottom: ${theme.space(2)};

      ${theme.media.md`
        margin-bottom: 0;
      `};
    }
  }
`

const imageGallery = ({ minHeight, theme }) => css`
  ${flickityInit};
  ${pageDots};
  ${blueDots};
  ${prevNext};

  max-height: ${rem(573)};
`

const heroSlider = ({ theme }) => css`
  ${flickityInit};
  ${pageDots};
  ${blueDots};
  ${prevNext};

  .flickity {
    &-viewport {
      border-radius: 0;
    }
    &-button {
      display: none;

      ${theme.media.lg`
        display: block;
      `};

      &.previous {
        left: 0;
        right: auto;

        @media (min-width: 1450px) {
          right: 0;
        }

        ${theme.media.xxl`
           left: 0;
        `};

        @media (min-width: 1450px) {
          left: ${rem(25)};
        }
      }

      &.next {
        left: auto;
        right: 0;
        border: 1px solid gren;

        ${theme.media.xxl`
          right: 0;
        `};

        @media (min-width: 1450px) {
          right: ${rem(25)};
        }
      }
    }
  }
`

const textModule = ({ theme }) => css`
  ${flickityInit};
  ${pageDots};
  ${prevNext};

  .flickity {
    &-viewport {
      height: calc(100% - 1px);
    }

    &-page-dots {
      transform: translate(-50%, -${rem(60)});

      ${theme.media.md`
        transform: translate(-50%, -${rem(40)});
      `};

      ${theme.media.lg`
        .dot {
          border: 1px solid ${theme.color.secondaryLightBlue};

          &:hover {
            background: ${theme.color.yellow};
            border: 1px solid ${theme.color.yellow};
          }
        }

        .dot.is-selected {
          background: ${theme.color.secondaryLightBlue};
        }
      `};
    }

    &-button {
      display: none;
      left: ${theme.space(2)};
      background: #fff;

      ${theme.media.lg`
        display: block;
      `};

      &.next {
        right: ${theme.space(2)};
      }

      &-icon {
        fill: ${theme.color.blue};
      }
    }

    &-prev-next-button {
      width: ${rem(32)};
      height: ${rem(32)};
      padding: ${rem(8)};
    }
  }
`

const socialModules = ({ theme }) => css`
  ${flickityInit};
  ${pageDots};

  .flickity {
    &-button {
      display: none;
    }

    &-page-dots {
      .dot {
        border: 1px solid ${theme.color.secondaryBlue};

        &:hover {
          background: ${theme.color.blue};
        }
      }

      .dot.is-selected {
        background: ${theme.color.secondaryBlue};
      }
    }
  }
`

const homeGallery = ({ theme }) => css`
  ${flickityInit};
  ${prevNext};

  .flickity-viewport {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const tickerModule = ({ theme }) => css`
  .flickity {
    width: 100%;
    height: 100%;
  }
`

const diningGallery = ({ theme }) => css`
  ${flickityInit};
  ${prevNext};
  ${pageDots};
  width: 100%;

  .flickity {
    width: 100%;
    height: auto;

    &-viewport {
      width: 100%;
    }

    &-button {
      left: ${theme.space(4)};
      transform: translate(0, -50%);
      background-color: #fff;

      &:hover {
        background-color: ${theme.color.yellow};
      }

      &.next {
        left: auto;
        right: ${theme.space(4)};
      }

      &-icon {
        fill: ${theme.color.blue};
      }

      ${theme.media.md`
        display: none;
      `};

      ${theme.media.lg`
        display: block;
      `};
    }

    &-page-dots {
      ${theme.media.md`
          transform: translate(-50%, ${rem(20)});
        `};

      ${theme.media.lg`
        display: none;
      `};
    }
  }
`

const post = ({ theme }) => css`
  ${diningGallery};

  .flickity {
    &-page-dots {
      .dot {
        border: 1px solid ${theme.color.secondaryBlue};

        ${theme.media.lg`
           border: 1px solid ${theme.color.secondaryLightBlue};
         `};

        &:hover {
          background: ${theme.color.yellow};
          border: 1px solid ${theme.color.yellow};

          ${theme.media.lg`
            background:  ${theme.color.yellow};
          `};
        }
      }

      .dot.is-selected {
        background: ${theme.color.secondaryBlue};
        border: 1px solid ${theme.color.secondaryBlue};

        ${theme.media.lg`
           background: ${theme.color.secondaryLightBlue};
           border: 1px solid ${theme.color.secondaryLightBlue};
         `};
      }
    }
  }
`

const FADE_COLOR = '#fff'

const relatedEvents = ({ theme }) => css`
  ${prevNext};
  ${pageDots};
  ${blueDots};

  .flickity {
    &-viewport {
      width: 100%;
      overflow: hidden;
      z-index: 1;

      &:after,
      &:before {
        position: absolute;
        top: 0;
        width: ${rem(70)};
        height: 100%;
        z-index: 2;
        pointer-events: none;

        ${theme.media.md`
          content: '';
        `};
      }

      &:after {
        right: 0;
        background: linear-gradient(
          90deg,
          ${transparentize(1, FADE_COLOR)} 0%,
          ${transparentize(0.5, FADE_COLOR)} 35%,
          ${transparentize(0, FADE_COLOR)} 100%
        );
        transition: 0.3s opacity ease-out;
      }

      &:before {
        left: 0;
        background: linear-gradient(
          -90deg,
          ${transparentize(1, FADE_COLOR)} 0%,
          ${transparentize(0.5, FADE_COLOR)} 35%,
          ${transparentize(0, FADE_COLOR)} 100%
        );
        transition: 0.3s opacity ease-out;
      }
    }

    &-page-dots {
      ${theme.media.md`
        display: none;
      `};
    }

    &-prev-next-button {
      width: ${rem(24)};
      height: ${rem(24)};
      padding: ${rem(6)};
      border-radius: 100%;
      z-index: 3;
      transition: 0.3s background ease-out;

      svg {
        transform: translateY(-1px);
      }
    }
  }
`

const eventsVenue = ({ minHeight, theme }) => css`
  ${prevNext};
  ${pageDots};

  ${minHeight &&
    css`
      > div {
        min-height: ${rem(minHeight)};

        ${theme.media.md`
            min-height: ${rem(minHeight)};
          `};
      }
    `};

  .flickity {
    &-viewport {
      border-radius: ${theme.layout.borderRadius};
      overflow: hidden;
    }

    &-slider {
      height: 100%;
    }

    &-page-dots {
      transform: translate(-50%, -${rem(30)});

      ${theme.media.md`
          transform: translate(-50%, -${rem(30)});
        `};

      ${theme.media.lg`
        display: none;
      `};
    }

    &-button {
      left: ${theme.space(2)};
      background: #fff;

      &:hover {
        background-color: ${theme.color.yellow};
      }

      &.next {
        right: ${theme.space(2)};
      }

      &-icon {
        fill: ${theme.color.blue};
      }
    }

    &-prev-next-button {
      top: 80%;
      display: none;
      padding: ${rem(12)};

      ${theme.media.lg`
        //display: block;
      `};
    }
  }
`

const pokerRoom = ({ theme }) => css`
  ${eventsVenue};

  .flickity {
    &-button {
      &.previous {
        ${theme.media.xxl`
          left: ${theme.space(8)} !important;
        `};
      }

      &.next {
        ${theme.media.xxl`
          right: ${theme.space(8)} !important;
        `};
      }
    }
  }
`

const eventsVenueGallery = ({ minHeight, theme }) => css`
  ${prevNext};
  ${pageDots};

  .flickity {
    &-viewport {
      border-radius: ${theme.layout.borderRadius};
      overflow: hidden;
    }

    &-slider {
      height: 100%;
    }

    &-page-dots {
      transform: translate(-50%, -${rem(30)});

      ${theme.media.md`
          transform: translate(-50%, -${rem(30)});
        `};
    }

    &-button {
      left: ${theme.space(2)};
      background: #fff;

      &:hover {
        background-color: ${theme.color.yellow};
      }

      &.next {
        right: ${theme.space(2)};
      }

      &-icon {
        fill: ${theme.color.blue};
      }
    }
  }
`

const sliceTypes = {
  diningGallery,
  [FEATURED_HERO_SLIDER_MODULE]: heroSlider,
  [IMAGE_GALLERY_MODULE]: imageGallery,
  [TEXT_IMAGE_MODULE]: textModule,
  [INSTAGRAM_MODULE]: socialModules,
  [TICKER_MODULE]: tickerModule,
  [TWITTER_MODULE]: socialModules,
  eventsVenue,
  eventsVenueGallery,
  homeGallery,
  relatedEvents,
  pokerRoom,
  post,
}
