import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import Page from '../container/Page'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import styled, { css } from 'styled-components'
import useParseSiteData from '../../hooks/useParseSiteData'
import Section from '../common/Section'
import Text from '../common/Text'
import Button from '../common/Button'
import { rem } from 'polished'
import { get } from 'lodash'

import { ERROR_PATHS } from '../../../lib/constants'
import linkResolver from '../../utils/linkResolver'
import wait from '../../utils/wait'
import { navigate } from 'gatsby'
import Loader from 'react-loader-spinner'
import theme from '../../style/theme'

const redirectPage = async path => {
  await wait(5000)
  navigate(path, { replace: true })
}

const NotFoundPage = ({
  location,
  pageContext: { staticData: data },
  path,
}) => {
  const { sections } = useParseSiteData(data)
  const [redirectPath, setRedirectPath] = useState(false)
  const showButtons = get(data, 'showButtons', true)
  const label = get(data, 'label') || 404
  const header =
    get(data, 'headerText') || 'The page you’re looking for can’t be found.'
  const body =
    get(data, 'bodyText') ||
    'The link you have followed is out-of-date or does not exist. Use the navigation, search above or contact us if you continue to have trouble.'

  useEffect(() => {
    if (path === ERROR_PATHS.ERROR_PATH_404_EVENT)
      setRedirectPath(
        linkResolver(data, 'sharedGlobalProperties.events_redirect'),
      )
    else if (path === ERROR_PATHS.ERROR_PATH_404_PROMO)
      setRedirectPath(
        linkResolver(data, 'sharedGlobalProperties.promos_redirect'),
      )
    if (redirectPath) redirectPage(redirectPath)
  }, [data, path, redirectPath])

  const propertyPath = get(data, 'siteMetadata.pathPrefix')
  let contactURL = '/'

  switch (propertyPath) {
    case '/desplaines':
      contactURL = '/contact-us'
      break
    case '/philadelphia':
      contactURL = '/contact'
      break
    case '/pittsburgh':
      contactURL = '/contact'
      break
    case '/schenectady':
      contactURL = '/contact-us'
      break
    default:
      contactURL = '/'
  }

  const homeLink = {
    href: '/',
  }

  const contactLink = {
    href: contactURL,
  }

  return (
    <Page {...sections}>
      <Section
        bgColor="softBlue"
        noPaddingTop
        css={{ minHeight: 'calc(100vh - 530px)' }}
      >
        <Container>
          <Text.h6 center>{label}</Text.h6>
          <Text.h1 uppercase center spacing={0.5}>
            {header}
          </Text.h1>
          <Text.p center>
            {' '}
            {redirectPath && (
              <StyledLoader
                type="Oval"
                color={theme.color.blue}
                height={15}
                width={15}
              />
            )}
            {body}
          </Text.p>

          {showButtons && (
            <ButtonContainer>
              <Button {...homeLink}>Go Home</Button>
              <Button {...contactLink}>Contact Us</Button>
            </ButtonContainer>
          )}
        </Container>
      </Section>
    </Page>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  path: PropTypes.string,
}

export default NotFoundPage

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space(4)};
    display: flex;
    justify-content: center;
    > *:last-child:not(:first-child) {
      margin-left: ${theme.space(2)};
    }
  `}
`

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;

    max-width: ${theme.layout.maxWidthArticle};
    margin: ${theme.space(3)} ${theme.space(4)} ${theme.space(5)};

    ${theme.media.lg`
      margin: ${theme.space(11)} 0 ${theme.space(18)};
    `};

    h6 {
      margin-bottom: ${rem(26)};
    }

    p {
      margin-top: ${rem(24)};
    }

    ${Text.h4} {
      margin: ${theme.space(2.5)} 0;
    }
  `}
`

const StyledLoader = styled(Loader)`
  display: inline-block;
  position: relative;
  top: ${rem(2)};
  margin-right: ${theme.space(0.5)};
`
