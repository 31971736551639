import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import AspectRatioBox from './AspectRatioBox'
import ReactPlayer from 'react-player'

import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
//import { conforms } from 'lodash'

const FullBleedPlayer = ({ image, url, ytProps, className, visible }) => {
  const video = useRef()

  return (
    <Container
      whileHover="hover"
      className={`${className} ${
        video.current && !video.current.player.isPlaying ? 'click-through' : ''
      }`}
    >
      <AspectRatioBox width={16} height={9}>
        <Player
          ref={video}
          url={url}
          width="100%"
          height="100%"
          playing={visible}
          loop
          config={{
            youtube: ytProps,
            vimeo: {
              playerOptions: {
                controls: 0,
                playsinline: true,
                autoplay: false,
                muted: true,
                loop: true,
              },
            },
          }}
        />
      </AspectRatioBox>
    </Container>
  )
}

FullBleedPlayer.propTypes = {
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  url: PropTypes.string,
  ytProps: PropTypes.object,
  className: PropTypes.string,
  visible: PropTypes.bool,
}

FullBleedPlayer.defaultProps = {
  ytProps: {
    embedOptions: {
      host: 'https://www.youtube-nocookie.com',
    },
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      controls: 0,
      modestbranding: 1,
      rel: 0,
      playsinline: 1,
    },
  },
}

export default FullBleedPlayer

const Container = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 66.667vw;
    z-index: -1;
    overflow: hidden;

    ${theme.media.lg`
      position: absolute;
      height: 100%;
    `};

    &.click-through {
      z-index: unset;
    }

    .react-aspect-ratio-placeholder {
      height: 66.667vw;

      ${theme.media.lg`
        height: 100%;
      `};
    }
  `};
`

const Player = styled(ReactPlayer)`
  z-index: 1;
  background-color: #000;

  .react-player {
    &__shadow {
      background: transparent;
    }

    &__play-icon {
      display: none;
    }
  }

  iframe {
    ${({ theme }) => css`
      display: block;
      width: 120% !important;
      height: 120% !important;
      position: relative;
      top: -10%;
      left: -10%;

      ${theme.media.lg`
      width: 100% !important;
      height: 100% !important;
      top: auto;
      left: auto;
    `};
    `};
  }
`
