import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ErrorText from './ErrorText'
import InputContainer from './InputContainer'
import Label from './Label'
import Text from '../../common/Text'

import styled, { css } from 'styled-components'

const padZero = num => String(num).padStart(2, '0')

const placeholderColor = { color: '#9ca1b2' }

const TimeInput = ({
  columns,
  error,
  label,
  name,
  handleChange,
  handleBlur,
  touch,
}) => {
  const [hour, setHour] = useState('')
  const [minute, setMinute] = useState('')
  const [amPm, setAmPm] = useState('')
  const hasError = touch && error

  const gridColumn = columns && columns !== 1 ? { gridColumn: '1 / 3' } : {}

  const hoursList = Array.from(Array(12).keys())
  const minutesList = Array.from(Array(60).keys())

  const onHoursChange = e => setHour(e.target.value)
  const onMinsChange = e => setMinute(e.target.value)
  const onAmPmChange = e => setAmPm(e.target.value)

  useEffect(() => {
    const val = `${hour || '01'}:${minute || '00'} ${amPm || 'am'}`
    if (hour && amPm) handleChange(name, val)
  }, [hour, minute, amPm, handleChange, name])

  return (
    <InputContainer
      error={hasError}
      css={gridColumn}
      valid={hour && minute && amPm}
    >
      {label && <Label htmlFor={name}>{label}</Label>}
      <Row>
        <div>
          <select
            name={name}
            defaultValue=""
            onChange={onHoursChange}
            onBlur={handleBlur}
            style={hour === '' ? placeholderColor : {}}
          >
            <option key="hh" value="" disabled>
              HH
            </option>
            {hoursList.map(item => {
              const val = padZero(item + 1)
              return (
                <option key={item} value={val}>
                  {val}
                </option>
              )
            })}
          </select>

          <Text center>:</Text>

          <select
            name={name}
            defaultValue=""
            onChange={onMinsChange}
            onBlur={handleBlur}
            style={minute === '' ? placeholderColor : {}}
          >
            <option key="mm" value="" disabled selected>
              MM
            </option>
            {minutesList.map(item => {
              const val = padZero(item)
              return (
                <option key={item} value={val}>
                  {val}
                </option>
              )
            })}
          </select>
        </div>

        <select
          name={name}
          defaultValue=""
          onChange={onAmPmChange}
          onBlur={handleBlur}
          style={amPm === '' ? placeholderColor : {}}
        >
          <option value="" disabled>
            AM or PM
          </option>
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </select>
      </Row>
      <ErrorText show={hasError}>{error}</ErrorText>
    </InputContainer>
  )
}

TimeInput.propTypes = {
  columns: PropTypes.number,
  error: PropTypes.string,
  handleBlur: PropTypes.func,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  touch: PropTypes.bool,
}

export default TimeInput

const Row = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 ${theme.space(3)};

    > div {
      display: grid;
      grid-template-columns: 1fr ${theme.space(3)} 1fr;

      select {
        background: none;

        text-align: center;
        text-align-last: center;
      }
    }
  `};
`
