import React from 'react'
import PropTypes from 'prop-types'

import Page from '../container/Page'

import componentRenderer from '../componentRenderer'
import { get } from 'lodash'
import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'

const RestaurantLandingPage = ({ location, pageContext: { staticData } }) => {
  const data = usePreviewData(staticData)
  const { components, sections, social } = useParseSiteData(data)

  return (
    <Page noPaddingTop {...sections}>
      {!!components && componentRenderer(components, { social })}
    </Page>
  )
}

RestaurantLandingPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default RestaurantLandingPage
