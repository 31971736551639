import React from 'react'
import PropTypes from 'prop-types'

import Button from '../common/Button'
import ReactHtmlParser from 'react-html-parser'
import Section, { SliceSection } from '../common/Section'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import linkResolver from '../../utils/linkResolver'
import theme from '../../style/theme'
import { rem } from 'polished'

const SignUpModule = ({ primary, slice_type }) => {
  const bgColor = get(primary, 'sign_up_background_color')
  const description = get(primary, 'sign_up_description.text')
  const label = get(primary, 'sign_up_label') || 'Sign Up Now'
  const link = {
    href: linkResolver(primary, 'sign_up_link'),
    target: get(primary, 'sign_up_link.target'),
  }

  return (
    <SliceSection
      fullWidth
      bgColor={bgColor}
      paddingProps={{ top: 0, bottom: 0 }}
    >
      <Container>
        <Text.h5 center color={theme.color.blue}>
          {ReactHtmlParser(description)}
        </Text.h5>
        <Button outline {...link}>
          {label}
        </Button>
      </Container>
    </SliceSection>
  )
}

SignUpModule.propTypes = {
  primary: PropTypes.object,
  slice_type: PropTypes.string,
}

export default SignUpModule

const Container = styled.div`
  ${({ theme, backgroundColor }) => css`
    position: relative;
    width: 100%;
    max-width: ${theme.layout.maxWidth};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: ${theme.space(2)} ${theme.space(2)};

    h5 {
      margin-bottom: ${theme.space(1)};
    }

    ${theme.media.xl`
      flex-direction: row;
      padding: ${theme.space(1.5)} ${theme.space(10)};

       h5 {
         text-align: left;
         margin-bottom: 0;
         margin-right: ${theme.space(6)};
       }
    `}
  `};
`
