import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  UPLOAD_MODULE,
  formTypeByModuleType,
} from '../../../constants/formTypes'
import { GATSBY_FORM_URL, PRISMIC_REPO_NAME } from '../../../../lib/constants'
import keyCodes from '../../../constants/keyCodes'

import { Star } from '../../common/Icons'
import Button from '../../common/Button'
import { Formik, Form } from 'formik'
import Loader from 'react-loader-spinner'
import Text from '../../common/Text'

import formRenderer from './formRenderer'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get, keyBy } from 'lodash'
import { color } from '../../../style/theme'
// import Recaptcha from 'react-recaptcha'
import Reaptcha from 'reaptcha'
import useSSR from 'use-ssr'
const ERROR_MESSAGE = 'There was problem handling the request.'

const ModularForm = ({
  id,
  initialValues,
  items,
  onSuccess,
  requiredLabel,
  robotLabel,
  submitLabel,
  validationSchema,
  getformEndpoint,
  siteKey,
}) => {
  const { isBrowser } = useSSR()
  const submitRef = useRef(null)
  const [errorMessage, setErrorMessage] = useState(false)
  const [verificationKey, setVerificationKey] = useState(false)
  const [captchaDelay, setCaptchaDelay] = useState(false)
  const itemKeys = keyBy(items, 'id')
  const onKeyDown = useCallback(e => {
    const key = e.which || e.keyCode
    const el = get(submitRef, 'current').querySelector('button')
    if (key === keyCodes.ENTER && el) el.click()
  }, [])
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', onKeyDown, false)
      return () => {
        window.removeEventListener('keydown', onKeyDown)
      }
    }
  }, [onKeyDown])

  const updateCaptchaDelay = () => {
    setCaptchaDelay(true)
  }

  setTimeout(updateCaptchaDelay, 500)

  const captchaCallback = verificationKeyVal => {
    setVerificationKey(verificationKeyVal)
  }

  const submitForm = useCallback(
    (values, { setSubmitting }) => {
      setErrorMessage(null)

      let uploadFile

      // Tranform values to data to submit
      const fields = Object.keys(values)
        .map((key, i) => {
          const sliceType = get(itemKeys, `${key}.slice_type`)
          // Get that file
          if (sliceType === UPLOAD_MODULE) {
            const file = get(values, key)
            if (file) uploadFile = file
          }

          return {
            type: get(formTypeByModuleType, sliceType),
            name: get(itemKeys, `${key}.primary.label`) || `${sliceType}_${i}`,
            value: values[key],
          }
        })
        .filter(item => item.value !== '')
        .filter(item => !!item.type)
      if (!!fields.length && verificationKey) {
        const formData = new FormData()
        if (getformEndpoint) {
          for (let i = 0; i < fields.length; i++) {
            formData.append(fields[i].name, fields[i].value)
          }
          formData.append('documentId', id)
          formData.append('domain', PRISMIC_REPO_NAME)
          // formData.append('recaptchaValidationKey', verificationKey)
        } else {
          formData.append('documentId', id)
          formData.append('domain', PRISMIC_REPO_NAME)
          formData.append('recaptchaValidationKey', verificationKey)
          formData.append('fields', JSON.stringify(fields))
        }

        if (uploadFile && !!uploadFile.length) {
          uploadFile.forEach(file =>
            formData.append('file', get(file, 'file'), get(file, 'file.name')),
          )
        }

        const request = async () => {
          try {
            const endpoint = getformEndpoint || GATSBY_FORM_URL

            const response = await fetch(endpoint, {
              method: 'post',
              body: formData,
            })

            setSubmitting(false)
            if (response.ok) {
              if (onSuccess) onSuccess()
            } else {
              if (response.status === 400) {
                const json = await response.json()
                const message = get(json, 'message')
                setErrorMessage(message)
              } else if (response.status >= 500) {
                setErrorMessage(ERROR_MESSAGE)
              }
            }
          } catch (err) {
            console.error('submit request error', err)
            setSubmitting(false)
            setErrorMessage(ERROR_MESSAGE)
          }
        }

        request()
      } else {
        console.warn('error: nothing to submit')
        setSubmitting(false)
      }
    },
    [verificationKey, itemKeys, id, onSuccess],
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {({
        errors,
        isSubmitting,
        setFieldValue,
        touched,
        values,
        handleBlur,
      }) => {
        return (
          <StyledForm disable={isSubmitting ? 'false' : undefined}>
            {formRenderer(items, {
              errors,
              values,
              setFieldValue,
              touched,
              handleBlur,
            })}

            <SubmitContainer>
              <Hr />

              {requiredLabel && (
                <RequiredText size="xs">
                  <Star /> {requiredLabel}
                </RequiredText>
              )}
              <Group ref={submitRef}>
                <Robot>
                  {isBrowser && siteKey && captchaDelay && (
                    <Reaptcha sitekey={siteKey} onVerify={captchaCallback} />
                  )}
                </Robot>
                <Button
                  type="submit"
                  disabled={isSubmitting || !verificationKey}
                >
                  {submitLabel}
                </Button>
              </Group>
            </SubmitContainer>

            {errorMessage && (
              <ErrorText color="red" center medium size="sm">
                {errorMessage}
              </ErrorText>
            )}
            {isSubmitting && (
              <StyledLoader
                type="TailSpin"
                color={color.blue}
                height={80}
                width={80}
              />
            )}
          </StyledForm>
        )
      }}
    </Formik>
  )
}

ModularForm.propTypes = {
  id: PropTypes.string,
  initialValues: PropTypes.object,
  items: PropTypes.array,
  onSuccess: PropTypes.func,
  requiredLabel: PropTypes.string,
  robotLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  validationSchema: PropTypes.object,
  getformEndpoint: PropTypes.string,
  siteKey: PropTypes.string,
}

export default ModularForm

const StyledForm = styled(Form)`
  ${({ disable, theme }) => css`
    ${theme.mixin.fadeIn()};

    position: relative;
    width: 100%;

    transition: opacity 0.5s ease-out;

    ${theme.media.lg`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: ${theme.space(4)} ${theme.space(4)};
    `};

    ${disable &&
      css`
        > div:not(:last-child) {
          opacity: 0.5;
          pointer-events: none;
        }
      `};
  `};
`

const SubmitContainer = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column: span 2;
    align-items: center;

    padding-top: ${theme.space(3)};

    ${theme.media.lg`
     flex-direction: row;
    `};
  `};
`

const Hr = styled.hr`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    border: none;
    padding: 0;
    height: 1px;
    background: ${theme.color.rule};
  `};
`

const Group = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    button {
      width: 100%;
      min-width: ${rem(210)};
    }

    ${theme.media.lg`
     width: auto;
     flex-direction: row;

      button {
        width: auto;
        margin-left: ${theme.space(2)};
        min-width: ${rem(170)};
      }
    `};
  `};
`

const RequiredText = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(1)};

    ${theme.media.lg`
      margin-bottom: 0;
    `};
  `};
`

const Robot = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.space(1)};

    ${theme.media.lg`
      margin-bottom: 0;
    `};
  `};
`

const ErrorText = styled(Text)`
  grid-column: span 2;
`

const StyledLoader = styled(Loader)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
