import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Button from '../common/Button'
import CalendarDate from '../components/events/CalendarDate'
import { AddToCalendar, Rules, Share } from '../common/Icons'
import SectionWithoutVerticalPadding from '../common/SectionWithoutVerticalPadding'
import Section from '../common/Section'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import RelatedEvents from '../components/events/RelatedEvents'
import Page from '../container/Page'
import Text from '../common/Text'
import useSSR from 'use-ssr'

import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import { get, first } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'
import linkResolver from '../../utils/linkResolver'
import componentRenderer from '../componentRenderer'
import { isDate, isAfter, isToday } from 'date-fns'
import { ICON_ATLAS, iconCss } from '../common/IconAtlas'
import transformDates, {
  parseDayBasedData,
} from '../../../lib/transformDisplayDates'
import {
  ALL_ICON_TYPES,
  EVENT_DETAIL_PAGE,
  GATSBY_SITE_URL,
} from '../../../lib/constants'
import Info from '../../assets/icons/yellowinfo.inline.svg'

import ResponsivePlayer from '../common/ResponsivePlayer'
import useCalendarLinks from '../../hooks/useCalendarLinks'
import { renderLink, transformLinks } from '../../utils/textTransforms'

const transformCategories = isLarge => (node, index) => {
  if (node.type === 'tag' && node.name === 'a') {
    return renderLink(node.attribs, node, index)
  }
  if (node.type === 'tag' && node.name === 'li') {
    return (
      <Text as="li" key={`tag_${index}`} center={!isLarge}>
        {node.children && node.children.map(({ data }) => data)}
      </Text>
    )
  }
}

const transformBullet = (node, index) => {
  if (node.type === 'tag' && node.name === 'a') {
    return renderLink(node.attribs, node, index)
  }
  if (node.type === 'tag' && node.name === 'li') {
    return (
      <DescriptionListItem>
        <Info />
        <Text.h5 as="div">
          {node.children && node.children.map(({ data }) => data)}
        </Text.h5>
      </DescriptionListItem>
    )
  }
  if (node.type === 'tag' && node.name === 'ul') {
    return (
      <>
        <ul>{convertNodeToElement(node, index, transformBullet)}</ul>
        <Hr marginBottom />
      </>
    )
  }
}

const DescriptionListItem = styled.li`
  ${({ theme }) => css`
    && {
      display: flex;
      align-items: center;
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      margin-bottom: ${theme.space(2)};
      svg {
        margin-right: ${theme.space(0.75)};
        flex-shrink: 0;
      }
    }
  `}
`

const ymalPaddingTop = ({ theme }) => css`
  padding-top: ${theme.space(4)};
  padding-bottom: ${theme.space(5)};

  ${theme.media.lg`
    padding-top: ${theme.space(6)};
    padding-bottom: ${theme.space(7)};
  `};
`

const EventAndPromoDetailPage = ({ pageContext: { staticData, ...props } }) => {
  const today = new Date()
  const { isBrowser } = useSSR()

  const { isLarge, isXLarge, isMedium } = useAppContext()
  const pageData = usePreviewData(staticData)
  const { components, sections, social } = useParseSiteData(pageData)
  const { data } = pageData

  const title = get(data, 'header_title')
  const path = get(data, 'path')
  const cardBody = get(data, 'card_body.text')
  const subTitle = get(data, 'sub_title.text')
  const image = {
    src: get(data, 'event_image.url'),
    alt: get(data, 'event_image.alt') || '',
  }

  let pageType = null
  if (typeof staticData === 'string') {
    const staticDataToObject = JSON.parse(staticData)
    pageType = get(staticDataToObject, 'type')
  } else {
    pageType = get(staticData, 'type')
  }

  // console.log(pageType)
  const timeLabel = get(data, 'time_label') || 'Time'
  const time = get(data, 'time.text')
  const locationLabel = get(data, 'location_label') || 'Location'
  const location = get(data, 'location.document.data.card_title.text')
  const customLocation = get(data, 'custom_location')

  // const displayDates = {
  //   month: get(data, 'display_date_header'),
  //   between: get(data, 'display_date_between_label'),
  //   day: get(data, 'display_date_label'),
  // }

  const ticketsLabel = get(data, 'tickets_label') || 'Buy Tickets'
  const ticketsLink = {
    href: linkResolver(data, 'tickets_link'),
    target: get(data, 'tickets_link.target') || '_self',
  }
  const price = get(data, 'price')
  const infoText = get(data, 'info_text')

  const description = get(data, 'description.html')
  const descriptionText = get(data, 'description.text')
  const disclaimer = get(data, 'disclaimer.html')
  const media = get(data, 'media')
  const mediaCaption = get(data, 'media_caption.text')
  const rushRewardsOnly = get(data, 'rush_rewards_only') === 'Yes'
  const datesRaw = get(data, 'dates', [])
  // console.log('datesRaw', datesRaw)
  // Side
  const dates = get(data, 'dates', [])
    .filter(({ date }) => isDate(new Date(date)))
    .map(({ date }) => new Date(date))
  const date = first(dates)
  // console.log('dates', dates)
  // console.log('data', data);
  // console.log('dates', dates);

  const displayDates = date && transformDates(data)

  const onlyOnDays = parseDayBasedData(data).onlyOnDays
  const recurring = dates.length > 1 && parseDayBasedData(data).isOnCertainDays

  const nextDateInFuture = dates.find(
    date => isAfter(date, today) || isToday(date),
  )
  // const nextDateInFuture = true;
  const displayCalLinks = useMemo(() => {
    if (pageType === EVENT_DETAIL_PAGE && nextDateInFuture) {
      return true
    } else if (dates.length === 1) {
      return true
    } else if (dates.length > 1 && nextDateInFuture) {
      return true
    } else {
      return false
    }
  }, [dates.length, nextDateInFuture, pageType])

  const endDate = dates.length > 1 ? dates[dates.length - 1] : null

  const calendarLinks = useCalendarLinks(
    !!(displayCalLinks && nextDateInFuture),
    time,
    date,
    location,
    title.text,
    cardBody,
    !isMedium,
    GATSBY_SITE_URL + path,
    endDate,
    dates,
    recurring,
    onlyOnDays,
  )
  // console.log('calendarLinks', calendarLinks)
  const setTimesLabel = get(data, 'set_times_label')
  const setTimes = get(data, 'set_times.html')

  const categories = get(data, 'categories.html')

  const relatedWebsiteLabel = get(data, 'related_website_label')
  const relatedLinks = get(data, 'related_links.html')

  const rulesLabel = get(data, 'rules_label') || 'View Complete Rules'
  const rulesLink = {
    href: linkResolver(data, 'rules_link'),
    target: get(data, 'rules_link.target') || '_self',
  }

  const relatedEventsLabel = get(data, 'related_events_label')
  const relatedEvents = get(data, 'related_events').filter(item =>
    get(item, 'event.document'),
  )
  const allLabel =
    get(data, 'all_button_label') ||
    (pageType === EVENT_DETAIL_PAGE ? 'ALl Events' : 'All Promos')
  const allLink = linkResolver(data, 'all_button_link')

  const badge = get(data, 'badge')
  const Badge = get(ICON_ATLAS, badge)
  const RushRewards = get(ICON_ATLAS, ALL_ICON_TYPES.ICON_RUSHREWARDS)

  const padTop = ({ theme }) => css`
    ${theme.media.lg`
      padding-top: ${theme.space(7)} !important;
    `};
  `

  return (
    <>
      <Page {...sections}>
        <Section
          bgColor={isXLarge ? '#fff' : 'softBlue'}
          noPaddingBottom
          noPaddingTop
          css={padTop}
        >
          <Content>
            <TopGrid>
              <BgColor />
              <TopTextGroup>
                {!isLarge && displayDates && <CalendarDate {...displayDates} />}
                <div>
                  {title && <Text.h1 center={!isLarge}>{title.text}</Text.h1>}
                  {subTitle && (
                    <Text.h4 center={!isLarge} style={{ marginTop: rem(10) }}>
                      {subTitle}
                    </Text.h4>
                  )}
                </div>

                <InfoRow>
                  {isLarge && displayDates && (
                    <CalendarDate {...displayDates} />
                  )}
                  {time && (
                    <InfoItem>
                      {timeLabel && (
                        <Text.h6 center={!isLarge}>{timeLabel}</Text.h6>
                      )}
                      <Text center={!isLarge}>{time}</Text>
                    </InfoItem>
                  )}
                  {location && (
                    <InfoItem>
                      {locationLabel && (
                        <Text.h6 center={!isLarge}>{locationLabel}</Text.h6>
                      )}
                      {location && <Text center={!isLarge}>{location}</Text>}
                    </InfoItem>
                  )}
                  {customLocation && (
                    <InfoItem>
                      {locationLabel && (
                        <Text.h6 center={!isLarge}>{locationLabel}</Text.h6>
                      )}
                      {customLocation && (
                        <Text center={!isLarge}>{customLocation}</Text>
                      )}
                    </InfoItem>
                  )}
                </InfoRow>

                {((ticketsLink && ticketsLink.href) || infoText) && (
                  <BuyRow>
                    {ticketsLink && ticketsLink.href && (
                      <Buy>
                        <ButtonContainer>
                          <Button {...ticketsLink}>{ticketsLabel}</Button>
                        </ButtonContainer>

                        {price && (
                          <Price>
                            <Text.h4 center>{price}</Text.h4>
                          </Price>
                        )}
                      </Buy>
                    )}
                    {infoText && (
                      <Text
                        book
                        size={isLarge ? 'xs' : 'xxs'}
                        lineHeightMultiplier={20 / 13}
                        color="neutralBlue"
                        center={!isLarge}
                      >
                        {infoText}
                      </Text>
                    )}
                  </BuyRow>
                )}
              </TopTextGroup>
              {image && image.src && <TopImage alt="" {...image} />}
            </TopGrid>
          </Content>
        </Section>

        <Section as="div" noPaddingTop noPaddingBottom>
          <Content>
            <BottomGrid>
              <Description>
                {description && (
                  <Text html>
                    {ReactHtmlParser(description, {
                      transform: transformBullet,
                    })}
                  </Text>
                )}
                {disclaimer && (
                  <Text size="xs">{ReactHtmlParser(disclaimer)}</Text>
                )}
                {media && media.embed_url && (
                  <ResponsivePlayer
                    url={media.embed_url}
                    image={media.thumbnail_url}
                  />
                )}
                {mediaCaption && (
                  <Text.h5 center size="xs">
                    {mediaCaption}
                  </Text.h5>
                )}
              </Description>
              <Side>
                {(Badge || rushRewardsOnly) && (
                  <InfoItemSide>
                    <Promos>
                      {badge && Badge && (
                        <PromoItem>
                          <Badge />
                          <Text.h5 uppercase lineHeightMultiplier={1.5}>
                            {badge}
                          </Text.h5>
                        </PromoItem>
                      )}
                      {rushRewardsOnly && (
                        <PromoItem>
                          <RushRewards />
                          <Text.h5 lineHeightMultiplier={1.4}>
                            RUSH REWARDS MEMBERS
                          </Text.h5>
                        </PromoItem>
                      )}
                    </Promos>
                    <Hr />
                  </InfoItemSide>
                )}

                <InfoItemSide>
                  {setTimesLabel && (
                    <Text.h6 center={!isLarge}>{setTimesLabel}</Text.h6>
                  )}
                  {setTimes && (
                    <Text center={!isLarge} xs="sm" lg="md">
                      {ReactHtmlParser(setTimes)}
                    </Text>
                  )}
                </InfoItemSide>

                {categories && (
                  <InfoItemSide>
                    <Text.h6 center={!isLarge}>CATEGORIES</Text.h6>
                    {ReactHtmlParser(categories, {
                      transform: transformCategories(isLarge),
                    })}
                  </InfoItemSide>
                )}
                {relatedLinks && (
                  <InfoItemSide>
                    {relatedWebsiteLabel && (
                      <Text.h6 center={!isLarge}>{relatedWebsiteLabel}</Text.h6>
                    )}
                    <Text center={!isLarge} size="sm">
                      {ReactHtmlParser(relatedLinks)}
                    </Text>

                    <Hr />
                  </InfoItemSide>
                )}
                <ActionItems>
                  {calendarLinks.map(({ label, ...link }, i) => (
                    <TextButtonBlueLink key={label + i} {...link} as="a">
                      <AddToCalendar />
                      <Text medium size="xs" nowrap uppercase>
                        {label}
                      </Text>
                    </TextButtonBlueLink>
                  ))}
                  <TextButton>
                    <Share />
                    {isBrowser && (
                      <div className="addthis_inline_share_toolbox"></div>
                    )}
                  </TextButton>
                  {rulesLink && rulesLink.href && (
                    <TextButtonBlueLink {...rulesLink} as="a">
                      <Rules />
                      <Text medium size="xs">
                        {rulesLabel}
                      </Text>
                    </TextButtonBlueLink>
                  )}
                </ActionItems>
              </Side>
            </BottomGrid>
          </Content>
        </Section>

        {relatedEvents && !!relatedEvents.length && (
          <Section noPaddingBottom noPaddingTop as="div">
            <Content>
              <RelatedEventsContainer>
                {relatedEventsLabel && (
                  <Text.h5 center>{relatedEventsLabel}</Text.h5>
                )}
                <RelatedEvents items={relatedEvents} />
              </RelatedEventsContainer>
            </Content>
          </Section>
        )}

        {components && components.body && !!components.body.length && (
          <SectionWithoutVerticalPadding
            bgColor="softBlue"
            as="div"
            css={ymalPaddingTop}
          >
            <Content>
              {componentRenderer(components, {
                social,
                noPaddingBottom: true,
                noPaddingTop: true,
                forceBackground: 'softBlue',
              })}
            </Content>
          </SectionWithoutVerticalPadding>
        )}

        {allLink && (
          <Section noPaddingBottom noPaddingTop as="div">
            <Content>
              <AllEvents>
                <Button href={allLink} outline>
                  {allLabel}
                </Button>
              </AllEvents>
            </Content>
          </Section>
        )}
      </Page>
    </>
  )
}

EventAndPromoDetailPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default EventAndPromoDetailPage

const sharedPadding = ({ theme }) => css`
  padding: ${theme.space(4)} 0;

  ${theme.media.lg`
        padding: ${theme.space(6)} 0;
    `};
`

const grid12Left = ({ theme }) => css`
  grid-column: 1 / 8;

  ${theme.media.lg`
        padding-right: ${theme.space(2)};
    `};

  ${theme.media.xl`
        padding-right: 0;
        grid-column: 2 / 8;
      `};

  ${theme.media.xxl`
        grid-column: 2 / 7;
    `};
`

const grid12Right = ({ theme }) => css`
  grid-column: 9 / 13;

  ${theme.media.lg`
        grid-column: 8 / 13;
      `};

  ${theme.media.xl`
        grid-column: 8 / 12;
      `};

  ${theme.media.xxl`
        grid-column: 8 / 12;
      `};
`

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`

const TopGrid = styled.div`
  ${({ theme }) => css`
    ${sharedPadding};
    display: flex;
    flex-direction: column;
    padding-top: 0;

    ${theme.media.lg`
      display: grid;
      align-items: center;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};
      padding-top: 0;
    `};
  `};
`

const TopTextGroup = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    order: 2;
    z-index: 2;

    ${theme.media.lg`
        ${grid12Left};
        display: block;
        order: 1;
    `};
  `};
`

const TopImage = styled.img`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(2)};
    order: 1;
    border-radius: ${theme.layout.borderRadius};
    z-index: 2;
    position: relative;

    ${theme.media.lg`
        ${grid12Right};
        margin-bottom: 0;
    `};
  `};
`

const BgColor = styled.div`
  ${({ theme }) => css`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.color.softBlue};

    ${theme.media.lg`
        position: absolute;
        z-index: 1;
    `};
  `};
`

const InfoRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.space(2)};

    ${theme.media.lg`
        justify-content: flex-start;
        margin-top: ${theme.space(4)};
    `};
  `};
`

const BuyRow = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space(2)};

    ${theme.media.lg`
        display: flex;
        justify-content: center;
        align-items: center;

        justify-content: flex-start;
        margin-top: ${theme.space(4)};
    `};
  `};
`

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 100%;

    ${theme.media.md`
        width: auto;
     `};

    > button,
    a {
      width: 100%;
      min-width: ${rem(180)};
      max-width: 100%;
    }
  `};
`

const Buy = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${theme.space(1)};

    ${theme.media.lg`
        margin-bottom: 0;
     `};
  `};
`

const InfoItem = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.space(2.5)};

    &:not(:last-child):not(:only-child) {
      border-right: 1px solid ${theme.color.secondaryLightBlue};
    }

    h6 {
      margin-bottom: ${theme.space(0.5)};
    }
  `};
`

const Price = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    height: ${rem(41)};
    min-width: ${rem(110)};

    background-color: #fff;
    border-radius: ${theme.layout.borderRadius};
    margin-left: ${theme.space(1)};
    padding: 0 ${theme.space(1)};

    ${theme.media.lg`
        margin: 0 ${theme.space(1)};
        min-width: ${rem(120)};
     `};
  `};
`

const BottomGrid = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    ${theme.media.lg`
      display: grid;
      align-items: flex-start;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};
    `};
  `};
`

const Description = styled.div`
  ${({ theme }) => css`
    ${grid12Left};
    ${sharedPadding};
    position: relative;
    width: 100%;
    order: 2;

    ul,
    h4 {
      margin-bottom: ${theme.space(3)};
    }

    ${theme.media.lg`
      order: 1;
    `};

    a {
      ${theme.mixin.blueLink};
      text-transform: none;
    }

    iframe {
      width: 100%;
    }

    > *:not(:last-child) {
      margin-bottom: ${theme.space(2)};
    }
  `};
`

const Side = styled.aside`
  ${({ theme }) => css`
    ${grid12Right};

    height: 100%;
    padding: 0;
    margin-top: ${theme.space(4)};
    margin-bottom: -${theme.space(2)};

    order: 1;

    ${theme.media.lg`
      ${sharedPadding};
      margin-top: 0;
      margin-bottom: 0;
      border: 2;
      border-left: 1px solid ${theme.color.softBlue};
    `};
  `};
`

const InfoItemSide = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: ${theme.space(3)};

    > div {
      a {
        ${theme.mixin.blueLink};
      }
    }

    ${theme.media.lg`
        display: block;
        margin: ${theme.space(3)} 0 ${theme.space(3)} ${theme.space(6)};
     `};

    &:first-child {
      margin-top: 0;
    }

    li {
      list-style: none;
    }

    h6 {
      margin-bottom: ${theme.space(0.5)};
    }
  `};
`

const Hr = styled.hr`
  ${({ theme, marginBottom }) => css`
    border: none;
    padding: 0;
    width: 100%;
    height: 1px;
    background: ${theme.color.softBlue};
    margin-top: ${theme.space(4)};
    margin-bottom: 0;

    ${marginBottom &&
      css`
        margin-bottom: ${theme.space(3)};
      `}

    ${theme.media.lg`
        height: 1px;
        background: ${theme.color.softBlue};
        margin: ${theme.space(3)} 0;
     `};
  `};
`

const TextButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    position: relative;
    padding: 0;

    svg {
      margin-right: ${theme.space(0.75)};
      flex-shrink: 0;
    }
  `};
`

const TextButtonBlueLink = styled(TextButton)`
  ${({ theme }) => css`
    > div {
      position: relative;
      ${theme.mixin.blueLink}
    }
  `}
`

const PromoItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-end;

    ${iconCss(theme.color.accentBlue)};
    svg {
      width: ${rem(20)};
      height: ${rem(20)};
      margin-right: ${theme.space(0.75)};
    }
    ${theme.media.lg`
        justify-content: flex-start;
    `};
  `};
`

const Promos = styled.div`
  ${({ theme }) => css`
    > div:not(:last-child):not(:only-child) {
      margin-bottom: ${theme.space(1.5)};
    }
  `};
`

const ActionItems = styled(InfoItemSide)`
  ${({ theme }) => css`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;

    > * {
      margin-bottom: ${theme.space(2)};

      &:not(:last-child):not(:only-child) {
        margin-right: ${theme.space()};
      }
    }
  `};
`

const AllEvents = styled.div`
  ${({ theme }) => css`
    margin: ${theme.space(3)} 0;

    > button,
    > a {
      margin: 0 auto;
    }

    ${theme.media.lg`
      > button, > a {
        max-width: ${rem(150)};
      }
    `}
  `};
`

const RelatedEventsContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.space(3)} 0;
    border-top: 1px solid ${theme.color.softBlue};
    border-bottom: 1px solid ${theme.color.softBlue};
  `};
`

const DescList = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.space(2)};
    padding-bottom: ${theme.space(1)};
    margin-bottom: ${theme.space(4)} !important;
    border-bottom: 1px solid ${theme.color.softBlue};

    h5 {
      display: flex;
      margin-bottom: ${theme.space(2)};
    }

    img {
      height: ${rem(20)};
      width: auto;
      object-fit: scale-down;
      margin-right: ${theme.space(1)};
    }
  `};
`
