import React from 'react'
import PropTypes from 'prop-types'

import AlphaGradient from '../common/AlphaGradient'
import AspectRatioBox from '../common/AspectRatioBox'
import Button from '../common/Button'
import FlickityContainer from '../common/Flickity'
import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get, first } from 'lodash'
import { useAppContext } from '../../context/AppContext'
import linkResolver from '../../utils/linkResolver'

// Aspect Ratio
const aspectRatio = {
  width: 230,
  height: 143,
}

const TextImageModule = ({ primary: data, items, slice_type, ...props }) => {
  const { focusStyleOn } = useAppContext()
  const imageAlignLeft = get(data, 'align_image') === 'left'
  const title = get(data, 'title_left_right.text')
  const body = get(data, 'body_left_right.html')
  const label = get(data, 'button_label_left_right') || 'Learn More'
  const secondaryLabel =
    get(data, 'secondary_button_label') || 'Additional Info'
  const buttonStyle = get(data, 'button_style')
  const firstImage = get(first(items), 'images.url')
  const singleImage = get(data, 'single_image.url')

  const bgColor = get(data, 'background_color') || 'transparent'
  const link = {
    href: linkResolver(data, 'button_link_left_right'),
    target: get(data, 'button_link_left_right.target'),
  }

  const secondaryLink = {
    href: linkResolver(data, 'secondary_button_link'),
    target: get(data, 'secondary_button_link.target'),
  }

  const useOutline = {}
  if (buttonStyle === 'Secondary') useOutline.outline = true

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container imageAlignLeft={imageAlignLeft}>
        <TextContainer imageAlignLeft={imageAlignLeft}>
          <TextGroup imageAlignLeft={imageAlignLeft}>
            {title && <Text.h2>{title}</Text.h2>}
            {body && <Text html>{ReactHtmlParser(body)}</Text>}
            <ButtonContainer>
              {link.href && (
                <Button {...link} {...useOutline}>
                  {label}
                </Button>
              )}
              {secondaryLink.href && (
                <Button {...secondaryLink} {...useOutline}>
                  {secondaryLabel}
                </Button>
              )}
            </ButtonContainer>
          </TextGroup>
        </TextContainer>
        <ImageContainer imageAlignLeft={imageAlignLeft}>
          {items && items.length > 1 && !singleImage && (
            <AspectRatioBox {...aspectRatio}>
              <FlickityContainer
                key={focusStyleOn}
                options={{ setGallerySize: false, accessibility: focusStyleOn }}
                type={slice_type}
              >
                {items.map((item, i) => {
                  const image = get(item, 'images.url')
                  return (
                    <AspectRatioBox {...aspectRatio} key={`img${i}`}>
                      {image && <Image src={image} alt="" />}
                      <AlphaGradient />
                    </AspectRatioBox>
                  )
                })}
              </FlickityContainer>
            </AspectRatioBox>
          )}

          {((items && items.length === 1) || !!singleImage) && (
            <SingleImage src={singleImage || firstImage} alt="" />
          )}
        </ImageContainer>
      </Container>
    </SliceSection>
  )
}

TextImageModule.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
  slice_type: PropTypes.string,
}

export default TextImageModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(3)};
    `};

    min-height: ${rem(140)};
    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`

const TextContainer = styled.div`
  ${({ theme, imageAlignLeft }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    order: 1;

    grid-column: 1 / 7;

    ${imageAlignLeft &&
      css`
        grid-column: 7 / 13;
      `};

    padding: 0 ${theme.space(3)};

    h2 {
      margin-bottom: ${rem(17)};

      ${theme.media.lg`
        margin-top: ${rem(-11)};
      `};
    }

    ${theme.media.lg`
      height: 100%;
      order: 0;
      padding-left: 0;
      padding-right: ${theme.space(3)};


      ${imageAlignLeft &&
        css`
          order: 1;
        `};
    `};

    ${theme.media.xl`
        padding-right: 0;
        transform: translateX(${rem(-40)});
        grid-column: 2 / 7;

         ${imageAlignLeft &&
           css`
             transform: translateX(${rem(40)});
             grid-column: 7 / 12;
           `};
      `};
  `};
`

const ImageContainer = styled.div`
  ${({ imageAlignLeft, theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    order: 0;

    margin-bottom: ${theme.space(1.5)};

    grid-column: 7 / 13;

    ${imageAlignLeft &&
      css`
        grid-column: 1 / 7;
      `};

    ${theme.media.lg`
      margin-bottom: 0;
    `};

    > * {
      width: 100%;
    }

    .flickity {
      height: 100%;

      &-button {
        &:hover {
          background-color: ${theme.color.yellow};
        }
      }
    }
  `}
`

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space(3)};

    > * {
      display: inline-flex;
      width: 100%;
      margin: 0 auto;

      ${theme.media.lg`
        width: auto;
        margin: 0;
      `};
    }

    > *:nth-child(2) {
      margin-top: ${theme.space(1.5)};

      ${theme.media.xl`
         margin-left: ${theme.space(1.5)};
         margin-top:0;
      `};
    }
  `}
`

const TextGroup = styled.div`
  ${({ imageAlignLeft, theme }) => css`
    width: 100%;

    ${theme.media.lg`
      max-width: ${rem(545)};
    `};
  `};
`

const Image = styled.img`
  width: 100%;
  height: auto;
  min-height: ${rem(100)};
  max-height: ${rem(440)};
  object-fit: conver;

  ${({ round, theme }) =>
    round &&
    css`
      border-radius: ${theme.layout.borderRadius};
    `};
`

const SingleImage = styled(Image)`
  object-fit: contain;
`
