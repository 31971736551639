import React from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text from '../common/Text'

import { format } from 'date-fns'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import { useAppContext } from '../../context/AppContext'

function getNumberWithOrdinal(n) {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

const PostResultsModule = ({ date, primary: data, items, ...props }) => {
  const { isLarge } = useAppContext()
  const body = get(data, 'results_body.html')
  const bgColor = get(data, 'background_color') || 'softBlue'
  const chopLabel = get(data, 'chop_label') || '9 Way-Chop'

  const labels = [
    get(data, 'place_label') || 'Place',
    get(data, 'name_label') || 'Name',
    get(data, 'player_location_label') || 'Player Location',
    get(data, 'prize_label') || 'Prize',
    chopLabel,
  ]

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container>
        <Hr />

        {body && (
          <StyledText size="sm" lg="md" htmlMargin center={isLarge} medium>
            {date && (
              <DateText uppercase size="xs" bold>
                {format(new Date(date), "MMM dd',' yyyy")}
              </DateText>
            )}

            {ReactHtmlParser(body)}
          </StyledText>
        )}
        {isLarge ? (
          <TableContainer>
            <Table>
              <Head>
                <Row>
                  {labels.map((label, i) => (
                    <HeaderCell
                      center={i === 0 || i === labels.length - 1}
                      key={`label${i}`}
                    >
                      {label}
                    </HeaderCell>
                  ))}
                </Row>
              </Head>
              <Body>
                {items.map((item, i) => {
                  const name = get(item, 'name')
                  const loc = get(item, 'location')
                  const prize = get(item, 'prize')
                  const chop = get(item, 'chop')
                  return (
                    <Row key={`item${i}`}>
                      <Cell center as="div">
                        {i + 1}
                      </Cell>
                      <Cell as="div">{name}</Cell>
                      <Cell as="div">{loc}</Cell>
                      <Cell as="div">{prize}</Cell>
                      <Cell center as="div">
                        {chop === 'yes' && '*'}
                      </Cell>
                    </Row>
                  )
                })}
              </Body>
            </Table>
          </TableContainer>
        ) : (
          <Items>
            {items.map((item, i) => {
              const name = get(item, 'name')
              const loc = get(item, 'location')
              const prize = get(item, 'prize')
              const chop = get(item, 'chop')
              return (
                <Item key={`sm${i}`}>
                  <div>
                    <Text.h3>{prize}</Text.h3>
                    <Text.h5>{name}</Text.h5>
                    <Text size="sm" medium>
                      {loc}
                    </Text>
                  </div>
                  <div>
                    <Text.h6 center size="xxs" color="accentBlue" right>
                      {`${getNumberWithOrdinal(i + 1)} Place`}
                    </Text.h6>

                    {chop === 'yes' && (
                      <Text center as="div" right size="xxs" uppercase>
                        {chopLabel}
                      </Text>
                    )}
                  </div>
                </Item>
              )
            })}
          </Items>
        )}
      </Container>
    </SliceSection>
  )
}

PostResultsModule.propTypes = {
  date: PropTypes.string,
  primary: PropTypes.object,
  items: PropTypes.array,
}

export default PostResultsModule

const Items = styled.div`
  ${({ theme }) => css`
    margin: ${theme.space(4)} 0 0;
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    background-color: #fff;

    padding: ${theme.space(1.5)} ${theme.space(2)};

    border: 1px solid ${theme.color.secondaryLightBlue};
    border-radius: ${theme.layout.borderRadius};

    &:not(:last-child) {
      margin-bottom: ${theme.space(1)};
    }

    h3 {
      margin-bottom: ${theme.space(1.5)};
    }
  `};
`

const Hr = styled.hr`
  ${({ theme }) => css`
    border: none;
    padding: 0;
    margin-left: ${theme.space(3)};
    width: calc(100% - ${theme.space(6)});
    height: 1px;
    background: ${theme.color.secondaryLightBlue};
    margin-bottom: ${theme.space(4)};

    ${theme.media.lg`
      margin-left: 0;
      width: 100%;
    `};
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;

    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidthArticle};

    > div {
      width: 100%;
      max-width: ${theme.layout.maxWidthPost};
    }

    > img {
      border-radius: ${theme.layout.borderRadius};
      margin-bottom: ${theme.space(4)};

      ${theme.media.lg`
        margin-bottom: ${theme.space(6)};
       `};
    }

    ${theme.media.lg`
        flex-direction: row;
      `};
  `};
`

const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin: 0 auto;
    padding: 0 ${theme.space(3)};

    ${theme.media.lg`
      padding: 0;
    `};

    blockquote {
      max-width: none;

      ${theme.media.md`
        float: none;
        width: 100%;
      `};

      border-left: none;
      padding: 0;
      margin: ${theme.space(3)} 0;

      ${theme.media.lg`
        padding: 0 10%;
      `};
    }

    cite {
      color: ${theme.color.secondaryBlue};
      margin-top: -${theme.space(1)};
      margin-bottom: ${theme.space(3)};

      ${theme.media.lg`
        padding: 0 10%;
      `};
    }
  `};
`

const DateText = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(3)};

    ${theme.media.lg`
        display: none;
      `};
  `};
`

const TableContainer = styled.div`
  ${({ theme }) => css`
    overflow-x: auto;

    margin: ${theme.space(4)} auto 0;

    max-width: ${theme.layout.maxWidthArticle} !important;

    border: 1px solid ${theme.color.secondaryLightBlue};
    border-radius: ${theme.layout.borderRadius};
    background-color: #fff;
  `};
`
const Table = styled.div`
  ${({ theme }) => css`
    display: table;
    text-align: left;
    border-collapse: collapse;

    margin: 0 auto;
    width: 100%;
  `};
`

const Head = styled.div`
  ${({ theme }) => css`
    display: table-header-group;
  `};
`

const Row = styled.div`
  ${({ theme }) => css`
    display: table-row;

    &:last-child {
      border-bottom: none !important;
    }
  `};
`

const Body = styled.div`
  ${({ theme }) => css`
    display: table-row-group;
  `};
`

const shared = ({ theme }) => css`
  display: table-cell;

  border-top: 1px solid ${theme.color.secondaryLightBlue};
  padding: ${theme.space(1.5)} ${theme.space(1)};
`

const HeaderCell = styled(Text.h6)`
  ${({ theme }) => css`
    ${shared};
    border-top: none;
  `};
`

const Cell = styled(Text.h5)`
  ${({ theme }) => css`
    ${shared};
  `};
`
