import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import img from '../../assets/gradient.png'

const CornerGradient = styled.svg`
  ${({ width, height, image }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${width}%;
    height: ${height}%;

    background: url(${image ? image : img});
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 100%;
    background-position: bottom left;
    pointer-events: none;
  `};
`

CornerGradient.propTypes = {
  image: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

CornerGradient.defaultProps = {
  width: 100,
  height: 100,
}
export default CornerGradient
