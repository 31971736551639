import React from 'react'
import PropTypes from 'prop-types'
import {
  CHECKBOX_MODULE,
  DATE_MODULE,
  DOB_MODULE,
  DISCLAIMER_MODULE,
  MESSAGE_MODULE,
  SIGNATURE_MODULE,
  STATE_MODULE,
  TEXT_MODULE,
  TIME_MODULE,
  UPLOAD_MODULE,
} from '../../../constants/formTypes'

import ErrorBoundary from '../../common/ErrorBoundary'
import CheckBoxInput from './CheckBoxInput'
import DateModule from './DateInput'
import Disclaimer from './Disclaimer'
import MessageInput from './MessageInput'
import SignatureInput from './SignatureInput'
import StateInput from './StateInput'
import Text from '../../common/Text'
import TimeInput from './TimeInput'
import TextInput from './TextInput'
import UploadInput from './UploadInput'

import { get } from 'lodash'

import styled from 'styled-components'

const slices = {
  [CHECKBOX_MODULE]: CheckBoxInput,
  [DATE_MODULE]: DateModule,
  [DOB_MODULE]: DateModule,
  [DISCLAIMER_MODULE]: Disclaimer,
  [MESSAGE_MODULE]: MessageInput,
  [SIGNATURE_MODULE]: SignatureInput,
  [STATE_MODULE]: StateInput,
  [TEXT_MODULE]: TextInput,
  [TIME_MODULE]: TimeInput,
  [UPLOAD_MODULE]: UploadInput,
}

const NotFound = ({ children, slice_type }) => (
  <Container>
    <Text size="xs" center color="red" lineHeight={2}>
      <b>{slice_type}</b> not found.
      {children}
    </Text>
  </Container>
)

NotFound.propTypes = {
  children: PropTypes.node,
  slice_type: PropTypes.string,
}

const formRenderer = (
  data,
  { filter, filterIn, setFieldValue, values, errors, touched, handleBlur },
) => {
  // Filter out by slice_type
  if (filter && !!filter.length) {
    data = data.filter(item => {
      const includes = filter.includes(item.slice_type)
      return filterIn ? includes : !includes
    })
  }

  return (
    <>
      {data &&
        !!data.length &&
        data.map((item, i) => {
          const type = get(item, '__typename')

          if (item && item.slice_type) {
            const { id, primary, ...rest } = item
            const Slice = get(slices, item.slice_type) || NotFound
            const touch = get(touched, id)
            const error = get(errors, id)
            const sliceProps = {
              ...primary,
              ...rest,
              touch,
              error,
              index: i,
              name: id,
              value: get(values, id),
              handleChange: setFieldValue,
              handleBlur,
            }

            return (
              <ErrorBoundary key={id + i} label={item.slice_type}>
                <Slice {...sliceProps} />
              </ErrorBoundary>
            )
          } else if (type) {
            return (
              <ErrorBoundary key={`type${i}`} label={item.type}>
                <NotFound slice_type={type}>
                  <div>(Add query to schema.)</div>
                </NotFound>
              </ErrorBoundary>
            )
          }
          return null
        })}
    </>
  )
}

export default formRenderer

const Container = styled.div`
  display: flex;
  justify-content: center;

  > div {
    padding: 1rem 2rem;
    margin: 1rem;
    background: #fff;
  }
`
