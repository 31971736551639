import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import AlphaGradient, { AlphaGradientCorner } from '../../common/AlphaGradient'
import ReactHtmlParser from 'react-html-parser'
import Button from '../../common/Button'
import { get } from 'lodash'
import getPath from '../../../../lib/getPath'
import { CARD_STYLES } from '../../../../lib/constants'
import { rem } from 'polished'
import { motion } from 'framer-motion'
import {
  ButtonContainer,
  ImagePositioner,
  TextBody,
  TextContainer,
  WideImagePositioner,
  transform,
  TextTitle,
} from './GridItemCommon'
import linkResolver from '../../../utils/linkResolver'
// Aspect Ratio

const WideGridItem = ({ item, style, tags }) => {
  const data = get(item, 'grid_content.document.data')

  const image = get(data, 'image')
  const title = get(data, 'card_title.html')
  const body = get(data, 'card_body.html')
  const label = get(data, 'button_label')

  const showButton = ![CARD_STYLES.LANDING_CARD].includes(style)
  const showBody = ![CARD_STYLES.IMAGE_HEADLINE_CARD].includes(style)
  const showImage = ![CARD_STYLES.HEADLINE_COPY_CARD].includes(style)

  //temp

  const buttonLink = {
    href: linkResolver(data, 'button_link') || getPath({ data }),
    target: get(data, 'button_link.target'),
  }

  const buttonContent = (
    <ButtonContainer>
      {buttonLink.href && showButton && (
        <Button {...buttonLink}>{label || 'Read More'}</Button>
      )}
    </ButtonContainer>
  )

  const imageContent = (
    <Mask>
      <WideImagePositioner image={image}>
        <AlphaGradient vertical />
      </WideImagePositioner>
    </Mask>
  )
  //Transition Stuff

  return (
    <Item>
      <Content styleType={style}>
        {showImage && imageContent}
        <AlphaGradientCorner transparent />

        <TextContainer>
          {title && (
            <TextTitle medium html>
              {ReactHtmlParser(title, { transform })}
            </TextTitle>
          )}
          {body && showBody && (
            <TextBody
              medium
              size="xs"
              lineHeightMultiplier={19 / 13}
              css={{ maxWidth: rem(265) }}
            >
              {ReactHtmlParser(body)}
            </TextBody>
          )}
        </TextContainer>
        {buttonContent}
      </Content>
    </Item>
  )
}

WideGridItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.node),
}

export default WideGridItem

const Mask = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    width: 66%;
    height: 100%;
    right: 0;

    > div {
      width: 100%;
      height: 100%;
      transition: transform 0.3s, opacity 0.3s;
    }

    overflow: hidden;
  `}
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: ${theme.layout.borderRadius};

    ${WideImagePositioner} {
      transition: 0.3s transform, opacity: 0.3s;
    }

    &:hover {
      ${WideImagePositioner} {
        // transform: scale(1.03);
        // opacity: 0.75;
      }
    }
  `};
`

const Content = styled(motion.div)`
  ${({ theme, styleType }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    background-color: ${theme.color.blue};

    ${TextContainer} {
      background-color: ${theme.color.blue};
      padding: 0 0 0 ${theme.space(3)};
    }

    ${ImagePositioner} {
      position: absolute;
      top: 0;
      left: 0;
    }

    h4 {
      color: #fff;
      letter-spacing: 1.58px;

      ${theme.media.lg`
        margin-top: ${theme.space(1)};
      `};
    }

    && {
      p {
        color: ${theme.color.secondaryLightBlue};
        font-size: ${rem(14)};
        line-height: ${rem(20)};

        ${theme.media.lg`
          font-size: ${rem(13)};
          line-height: ${rem(19)};
        `};
      }
    }

    ${ButtonContainer} {
      position: relative;
      padding: 0 0 0 ${theme.space(3)};
      margin: ${theme.space(0.5)} 0 ${theme.space(2.5)};

      ${theme.media.lg`
        min-width: ${rem(250)};
      `};
    }
    .img-spacer {
      display: block;
    }

    ${theme.media.xl`
        background-color: ${theme.color.blue};
      flex-direction: column;
      justify-content: space-between;
      height: ${rem(264)};



       ${AlphaGradient} {
          height: 100%;
          width: 100%;
          position: absolute;
       }

      ${TextContainer} {
       background-color: transparent;
       position: relative;
       padding-top: ${theme.space(2.5)};
       max-width: ${rem(380)};
      }

      ${ButtonContainer} {
        max-width: ${rem(220)};
        > * {
          width: 100%;
        }

      }
    `};
  `};
`
