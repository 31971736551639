import React from 'react'
import PropTypes from 'prop-types'
import usePreviewData from '../../hooks/usePreviewData'

import DiningDetailPage from './DiningDetailPage'
import DiningMenuPage from './DiningMenuPage'
import EventAndPromoDetailPage from './EventAndPromoDetailPage'
import EventVenueDetailPage from './EventVenueDetailPage'
import FormPage from './FormPage'
import HomePage from './HomePage'
import PostDetailPage from './PostDetailPage'
import RestaurantLandingPage from './RestaurantLandingPage'
import LandingPage from './LandingPage'
import ListingPage from './ListingPage'
import PokerRoomLandingPage from './PokerRoomLandingPage'
import PostListingPage from './PostListingPage'
import EventCalendarPage from './EventCalendarPage'
import Page from '../container/Page'

const {
  DINING_DETAIL_PAGE,
  DINING_MENU_PAGE,
  EVENT_DETAIL_PAGE,
  EVENT_VENUE_DETAIL_PAGE,
  FORM_PAGE,
  HOME_PAGE,
  LANDING_PAGE,
  LISTING_PAGE,
  RESTAURANT_LANDING_PAGE,
  PROMO_DETAIL_PAGE,
  POST_DETAIL_PAGE,
  POST_LISTING_PAGE,
  POKER_ROOM_LANDING_PAGE,
  EVENT_CALENDAR_PAGE,
  GENERAL_TEMPLATE,
} = require('../../../lib/constants')

const IMPORTED_TEMPLATES = {
  [DINING_DETAIL_PAGE]: DiningDetailPage,
  [DINING_MENU_PAGE]: DiningMenuPage,
  [EVENT_DETAIL_PAGE]: EventAndPromoDetailPage,
  [EVENT_VENUE_DETAIL_PAGE]: EventVenueDetailPage,
  [FORM_PAGE]: FormPage,
  [HOME_PAGE]: HomePage,
  [LANDING_PAGE]: LandingPage,
  [LISTING_PAGE]: ListingPage,
  [RESTAURANT_LANDING_PAGE]: RestaurantLandingPage,
  [PROMO_DETAIL_PAGE]: EventAndPromoDetailPage,
  [POST_DETAIL_PAGE]: PostDetailPage,
  [POKER_ROOM_LANDING_PAGE]: PokerRoomLandingPage,
  [POST_LISTING_PAGE]: PostListingPage,
  [EVENT_CALENDAR_PAGE]: EventCalendarPage,
  [GENERAL_TEMPLATE]: LandingPage,
}

const UnpublishedPreviewPage = ({
  pageContext: { staticData: staticDataJson },
  ...props
}) => {
  const staticData = JSON.parse(staticDataJson)

  const staticDataPreMerge = {
    ...staticData,
    ...(window.__PRISMIC_PREVIEW_DATA__ ? window.__PRISMIC_PREVIEW_DATA__ : {}),
  }
  const data = usePreviewData(staticDataPreMerge)
  const Component = IMPORTED_TEMPLATES[data.type]
  return <Component pageContext={{ staticData: data }} {...props} />
}

UnpublishedPreviewPage.propTypes = {
  pageContext: PropTypes.object,
}

export default UnpublishedPreviewPage
