import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  CARD_STYLES,
  GRID_MODULE_MOBILE_BEHAVIORS,
  GRID_GALLERY,
  GRID_STACK_SHOW_MORE,
  GRID_STACK_CONDENSED,
} from ' ../../../lib/constants'

import FeaturedGridItem from '../components/grid-module/FeaturedGridItem'
import Flickity from '../common/Flickity'
import WideGridItem from '../components/grid-module/WideGridItem'
import ImageHeadlineCopyGridItem from '../components/grid-module/ImageHeadlineCopyGridItem'
import LandingGridItem from '../components/grid-module/LandingGridItem'
import UpModuleGridItem from '../components/grid-module/UpModuleGridItem'
import StripGridItem from '../components/grid-module/StripGridItem'
import { DEFAULT_MAX_FEATURED_CARDS } from '../../constants/site'
import { SliceSection } from '../common/Section'
import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'
import PostGridItem from '../components/grid-module/PostGridItem'
import CondensedGridItem from '../components/grid-module/CondensedGridItem'

import Text from '../common/Text'

const AUTO_FIT = 'auto-fit'

const selectCardStyle = (style, isXLarge, isLarge, isMedium, isCondensed) => {
  if (!isMedium && isCondensed) return CondensedGridItem

  switch (style) {
    case CARD_STYLES.EVENT_VENUE_CARD:
    case CARD_STYLES.FEATURED_CARD:
      return FeaturedGridItem
    case CARD_STYLES.WIDE_CARD:
      if (isXLarge) return WideGridItem
      else return FeaturedGridItem
    case CARD_STYLES.IMAGE_HEADLINE_CARD:
    case CARD_STYLES.IMAGE_HEADLINE_COPY_CARD:
    case CARD_STYLES.HEADLINE_COPY_CARD:
      return ImageHeadlineCopyGridItem
    case CARD_STYLES.LANDING_CARD:
      return LandingGridItem
    case CARD_STYLES.GRADIENT_CARD:
      return FeaturedGridItem
    case CARD_STYLES.WHITE_CARD:
    case CARD_STYLES.CLEAR_CARD:
      return UpModuleGridItem
    case CARD_STYLES.STRIP_CARD:
      if (isLarge) return StripGridItem
      else return FeaturedGridItem
    case CARD_STYLES.POST_CARD:
      return PostGridItem
    default:
      return FeaturedGridItem
  }
}

const useCardItemStyle = (style, useCondensedCards) => {
  const { isMedium, isXLarge, isLarge } = useAppContext()

  const [columns, setColumns] = useState(AUTO_FIT)
  const [{ component }, setItem] = useState({
    component: selectCardStyle(
      style,
      isXLarge,
      isLarge,
      isMedium,
      useCondensedCards,
    ),
  })
  useEffect(() => {
    setColumns(1)
    if (isMedium) setColumns(2)
    if (isXLarge) setColumns(AUTO_FIT)
    switch (style) {
      case CARD_STYLES.WIDE_CARD:
        if (isXLarge) {
          setColumns(2)
        }
        break
      case CARD_STYLES.STRIP_CARD:
        if (isLarge) {
          setColumns(1)
        }
        break
    }
  }, [isLarge, isMedium, isXLarge, style])

  useEffect(() => {
    setItem({
      component: selectCardStyle(
        style,
        isXLarge,
        isLarge,
        isMedium,
        useCondensedCards,
      ),
    })
  }, [isXLarge, style, isLarge, isMedium, useCondensedCards])

  return { component, columns }
}

const GridModule = ({ bgColor, items, primary, fullWidth, ...props }) => {
  const { isMedium, focusStyleOn } = useAppContext()
  const [showMore, setShowMore] = useState(false)

  const cleanedItems = items.filter(
    ({ grid_content }) => grid_content && grid_content.document,
  )

  const backgroundColor = get(primary, 'background_color') || bgColor

  const style = get(primary, 'style', CARD_STYLES.FEATURED_CARD)
  const mobileBehavior =
    get(primary, 'mobile_behavior') || GRID_MODULE_MOBILE_BEHAVIORS[0]

  const useGallery = !isMedium && mobileBehavior === GRID_GALLERY
  const useShowMore = !isMedium && mobileBehavior === GRID_STACK_SHOW_MORE
  const useCondensedCards = !isMedium && mobileBehavior === GRID_STACK_CONDENSED

  const { component: Item, columns } = useCardItemStyle(
    style,
    useCondensedCards,
  )

  const content =
    (cleanedItems &&
      !!cleanedItems.length &&
      cleanedItems.map((item, i) => (
        <Item key={`${item.id}-${i}`} item={item} style={style} />
      ))) ||
    []

  const Wrap = useShowMore && !isMedium ? GridWrapper : React.Fragment

  const columnMax =
    columns === AUTO_FIT && cleanedItems.length > 4 ? 4 : columns

  return (
    <SliceSection
      bgColor={backgroundColor}
      as="div"
      fullWidth={isMedium ? fullWidth : true}
      {...props}
    >
      {!isMedium && useGallery ? (
        <FlickityContainer>
          <Flickity
            className="flickity"
            key={focusStyleOn}
            type="post"
            options={{
              accessibility: focusStyleOn,
            }}
          >
            {content}
          </Flickity>
        </FlickityContainer>
      ) : (
        <Wrap>
          <Grid
            showMore={useShowMore && !showMore}
            columns={columnMax}
            useCondensedCards={useCondensedCards}
          >
            {content}
          </Grid>
          {useShowMore && !showMore && (
            <ShowMore onClick={() => setShowMore(true)}>
              <Text medium size="xs" spacing="1px">
                Show More
              </Text>
            </ShowMore>
          )}
        </Wrap>
      )}
    </SliceSection>
  )
}

GridModule.propTypes = {
  bgColor: PropTypes.string,
  items: PropTypes.array,
  primary: PropTypes.object,
  fullWidth: PropTypes.bool,
}

GridModule.defaultProps = {
  bgColor: 'softBlue',
  fullWidth: false,
}

export default GridModule

const GridWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  `}
`

const ShowMore = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    display: inline-block;
    ${theme.mixin.blueLink};
    max-width: ${rem(100)};
    margin: ${theme.space(2)} 0 0;
  `}
`

const Grid = styled.div`
  ${({ columns, theme, showMore, useCondensedCards }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(${rem(useCondensedCards ? 100 : 120)}, 1fr);
    grid-gap: ${theme.space(1)} ${theme.space(1)};

    ${showMore &&
      css`
        > * {
          :not(:first-child) {
            display: none;
          }
        }
      `};

    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.md`
      grid-template-columns: repeat(${columns}, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};

    ${theme.media.xl`
      grid-gap: ${theme.space(1.5)} ${theme.space(1.5)};
    `};

    ${theme.media.xxl`
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};
  `};
`

const FlickityContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    margin-bottom: ${theme.space(2)};

    ${theme.media.lg`
      margin-bottom: 0;
    `};
  `};
`
