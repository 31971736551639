import React from 'react'
import PropTypes from 'prop-types'

import { SmallLinkArrow, Share } from '../common/Icons'
import Flickity from '../common/Flickity'
import LinkComponent from '../common/Link'
import Page from '../container/Page'
import PrevNextLinks from '../common/PrevNextLinks'
import PostGridItem from '../components/grid-module/PostGridItem'
import Section, { SliceSection } from '../common/Section'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import { format, compareDesc } from 'date-fns'
import componentRenderer from '../componentRenderer'
import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import { useAppContext } from '../../context/AppContext'
import linkResolver from '../../utils/linkResolver'

import avatar from '../../assets/avatar.svg'
import useSSR from 'use-ssr/dist/useSSR'

const PostDetailPage = ({ pageContext: { staticData }, location }) => {
  const { isBrowser } = useSSR()
  const { focusStyleOn, isLarge } = useAppContext()
  const pageData = usePreviewData(staticData)
  const { components, sections, social } = useParseSiteData(pageData)
  const data = get(pageData, 'data')
  const title = get(data, 'header_title')
  const date = get(data, 'date')
  const shareLabel = 'Share'
  const author = get(data, 'author_name')
  const authorTitle = get(data, 'author_title')
  const authorImage = get(data, 'author_image.url') || avatar
  const relatedPosts = get(pageData, 'relatedPosts') || []
  const cleanedRelatedPosts = relatedPosts
    .filter(
      ({
        grid_content: {
          document: { uid },
        },
      }) => uid !== pageData.uid,
    )
    .slice(0, 4)

  cleanedRelatedPosts.sort((a, b) => {
    return compareDesc(
      new Date(get(a, 'grid_content.document.data.date')),
      new Date(get(b, 'grid_content.document.data.date')),
    )
  })
  const relatedBlog = get(data, 'related_blog')

  const href = linkResolver({ relatedBlog }, 'relatedBlog')
  const page = get(location, 'state.page') || 0
  const backLink = { href, state: { page } }
  const tags = get(pageData, 'tags', ['']) //@NOTE: For layout purposes
  const pagination = get(pageData, 'pagination') || []
  const index = pagination.map(x => x.href).indexOf(data.path)
  const len = pagination.length
  const previous = pagination[(index + len - 1) % len]
  const next = pagination[(index + 1) % len]
  const paginationLinks = {
    prevTitle: 'Prev Post',
    allLabel: get(relatedBlog, 'document.data.intro_title.text') || 'All posts',
    nextTitle: 'Next Post',
    prevUrl: get(previous, 'href'),
    allLink: relatedBlog,
    nextUrl: get(next, 'href'),
  }
  const styledPosts =
    cleanedRelatedPosts &&
    !!cleanedRelatedPosts.length &&
    cleanedRelatedPosts.map((post, i) => (
      <PostGridItem key={`rl${i}`} item={post} />
    ))

  return (
    <Page {...sections}>
      <Section noPaddingBottom bgColor="softBlue">
        {isLarge && (
          <BackButton {...backLink}>
            <div>
              <SmallLinkArrow width={8} height={13} transform="scale(-1,1)" />
              <Text.h6>Back</Text.h6>
            </div>
          </BackButton>
        )}

        <Container>
          <Tags>
            {tags.map((label, i) => (
              <Tag
                white
                key={label + i}
                size="xxs"
                uppercase
                blank={!!!label.length}
              >
                {label}
              </Tag>
            ))}
          </Tags>

          {title.text && <Text.h1 center>{title.text}</Text.h1>}

          <Row>
            {isLarge && date && (
              <DateText center uppercase size="xs" medium>
                {format(new Date(date), "MMM dd',' yyyy")}
              </DateText>
            )}

            <Author>
              <AuthorImage>
                <img alt="author" src={authorImage} />
              </AuthorImage>
              {(author || authorTitle) && (
                <div>
                  {author && (
                    <Text center={!authorImage} size="xs" medium>
                      {author}
                    </Text>
                  )}
                  {authorTitle && (
                    <Text center={!authorImage} size="xs">
                      {authorTitle}
                    </Text>
                  )}
                </div>
              )}
            </Author>

            {isLarge && shareLabel && (
              <TextButton>
                <Share />
                {isBrowser && (
                  <div className="addthis_inline_share_toolbox"></div>
                )}
              </TextButton>
            )}
          </Row>
        </Container>
      </Section>
      {!!components &&
        componentRenderer(components, { social, extraProps: { date } })}

      {!!len && <PrevNextLinks bgColor="softBlue" {...paginationLinks} />}

      {cleanedRelatedPosts && !!cleanedRelatedPosts.length && (
        <SliceSection
          fullWidthMedium
          paddingProps={{
            top: 1,
            bottom: 1,
          }}
        >
          <Content>
            <RelatedTitle>YOU MAY ALSO LIKE...</RelatedTitle>
            {isLarge ? (
              <RelatedPosts>{styledPosts}</RelatedPosts>
            ) : (
              <Flickity
                type="post"
                key={focusStyleOn}
                options={{
                  accessibility: focusStyleOn,
                }}
              >
                {styledPosts}
              </Flickity>
            )}
          </Content>
        </SliceSection>
      )}
    </Page>
  )
}

PostDetailPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default PostDetailPage

const RelatedTitle = styled(Text.h3)`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(2)};

    ${theme.media.lg`
      margin-bottom: ${theme.space(3.4)};
    `};
  `};
`

const BackButton = styled(LinkComponent)`
  ${({ theme }) => css`
    ${theme.layout.horizontalPadding};

    display: flex;
    align-items: center;

    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, ${rem(59)});
    padding: 0 ${theme.space(4)};
    width: 100%;
    height: ${rem(22)};

    z-index: 3;

    @media (min-width: ${theme.layout.maxWidthPad}) {
      padding: 0;
      max-width: ${theme.layout.maxWidth};
    }

    > div {
      display: flex;
      align-items: center;

      svg {
        margin-right: ${theme.space(0.5)};
        transform: scaleX(-1);
        transition: transform 0.3s ease-out;
      }
    }

    &:hover {
      svg {
        transition: transform 0.3s ease-out;
        transform: scaleX(-1) translateX(${rem(5)});
      }
    }
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: ${theme.layout.maxWidth};

    .flickity {
      margin-bottom: ${theme.space(1)};

      ${theme.media.lg`
        margin-bottom: ${theme.space(6)};
      `};
    }

    .post-grid-item {
      ${theme.media.md`
        padding-left: ${theme.space(4.5)};
        padding-right: ${theme.space(4.5)};

        > div {
          border-radius: ${theme.layout.borderRadius};
          overflow: hidden;
        }
      `};

      ${theme.media.lg`
        padding-left: 0;
        padding-right: 0;

        > div {
          padding: ${theme.space(2.5)} ${theme.space(3)} ${theme.space(3)};
          border-radius: none;
          overflow: visible;
        }
      `};
    }
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;
    z-index: 2;

    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidthArticle};

    > div {
      width: 100%;
    }

    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};
      margin-bottom: ${theme.space(1)};
      margin-top: -${theme.space(2)};

       > * {
         grid-column: span 12;
       }

       > h1 {
         grid-column: 3/11;
       }

    `};
  `};
`

const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: ${theme.layout.maxWidthPost};

    margin: 0 auto;
    margin-top: ${theme.space(2)};

    ${theme.media.lg`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: ${theme.space(1)} ${theme.space(2)};
      justify-content: space-between;
      margin-top: ${theme.space(4)};
    `};
  `};
`

const DateText = styled(Text)`
  display: flex;
  align-items: center;
`

const TextButton = styled.button`
  ${({ theme }) => css`
    ${theme.mixin.opacityHover};

    display: inline-flex;

    position: relative;
    padding: 0;
    margin-left: ${theme.space(3)};

    svg {
      margin-right: ${theme.space(1)};
    }
    > svg {
      &:first-child {
        transform: translateY(5px);
      }
    }

    ${theme.media.lg`
      justify-content: flex-end;
      margin-left: 0;
    `};
  `};
`

const Author = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
  `};
`

const AuthorImage = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-right: ${theme.space(2)};
    flex-shrink: 0;

    border: ${theme.layout.border} solid ${theme.color.secondaryLightBlue};
    border-radius: 100%;

    img {
      position: relative;
      width: ${rem(50)};
      height: ${rem(50)};
      object-fit: cover;
      border-radius: 100%;
    }
  `};
`

const Tags = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin: 0 0 ${theme.space(2)};

    ${theme.media.lg`
      margin: 0 0 ${theme.space(4)};
     `};
  `};
`

const Tag = styled(Text.h6)`
  ${({ theme, blank }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background-color: ${!blank && theme.color.accentBlue};
    padding: 0 ${theme.space(1)};
    border-radius: ${rem(30)};
    margin: 0 ${theme.space(0.5)};
    height: ${rem(20)};
  `};
`

const RelatedPosts = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(${rem(120)}, 1fr);
    grid-gap: ${theme.space(1)} ${theme.space(1)};

    width: 100%;

    ${theme.media.sm`
      grid-template-columns: repeat(2, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};

    ${theme.media.xxl`
      grid-template-columns: repeat(4, minmax(${rem(120)}, 1fr));
    `};
  `};
`
