import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import AlphaGradient from '../../common/AlphaGradient'
import AspectRatioBox from '../../common/AspectRatioBox'
import Text from '../../common/Text'
import ReactHtmlParser from 'react-html-parser'
import Button from '../../common/Button'
import { get } from 'lodash'
import getPath from '../../../../lib/getPath'
import { CARD_STYLES } from '../../../../lib/constants'
import { rem } from 'polished'
import { motion } from 'framer-motion'
import truncate from '../../../utils/truncateText'

import {
  ButtonContainer,
  Image,
  ImagePositioner,
  TextBody,
  TextContainer,
} from './GridItemCommon'
import linkResolver from '../../../utils/linkResolver'

// Aspect Ratio
const WIDTH = 69
const HEIGHT = 43

const UpModuleGridItem = ({ item, style }) => {
  const data = get(item, 'grid_content.document.data')

  const image = get(data, 'image')
  const title = get(data, 'card_title.html')
  const body = get(data, 'card_body.text')
  const label = get(data, 'button_label')

  const showButton = ![CARD_STYLES.LANDING_CARD].includes(style)
  const showUnderlineLinks = [
    CARD_STYLES.WHITE_CARD,
    CARD_STYLES.CLEAR_CARD,
  ].includes(style)
  const showBody = ![CARD_STYLES.IMAGE_HEADLINE_CARD].includes(style)
  const showImage = ![CARD_STYLES.HEADLINE_COPY_CARD].includes(style)

  const width = WIDTH
  const height = HEIGHT

  const buttonLink = {
    href: linkResolver(data, 'button_link') || getPath({ data }),
    target: get(data, 'button_link.target'),
  }

  const buttonContent = (
    <ButtonContainer>
      {buttonLink.href && showButton && (
        <Button {...buttonLink} underline={showUnderlineLinks}>
          {label || (buttonLink.href !== '/' && 'Read More')}
        </Button>
      )}
    </ButtonContainer>
  )

  const imageContent = (
    <>
      <ImagePositioner>
        <AspectRatioBox absolute width={width} height={height}>
          {image && <Image src={image.url} alt={title || ''} />}
        </AspectRatioBox>
      </ImagePositioner>
      <AspectRatioBox
        className="img-spacer"
        absolute
        width={width}
        height={height}
      >
        <AlphaGradient />
      </AspectRatioBox>
    </>
  )

  return (
    <Item>
      <Content styleType={style}>
        {showImage && imageContent}
        <TextContainer>
          {title && <Text html>{ReactHtmlParser(title)}</Text>}
          {!title && <h4> </h4>}
          {body && showBody && <TextBody html>{truncate(body, 135)}</TextBody>}
        </TextContainer>
        {buttonContent}
      </Content>
    </Item>
  )
}

UpModuleGridItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.string,
}

export default UpModuleGridItem

const whiteCard = ({ theme }) => css`
  background-color: #fff;
  margin-left: ${rem(10)};
  margin-right: ${rem(10)};
  max-width: calc(100% - ${rem(20)});

  ${theme.media.md`
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  `};

  ${AlphaGradient} {
    display: none;
  }

  ${TextContainer} {
    margin-top: ${theme.space(2.5)};
    padding: 0 ${theme.space(2.5)};

    h4 {
      min-height: ${rem(28)};
    }
  }

  ${ButtonContainer} {
    justify-content: flex-start;
    padding: 0 ${theme.space(2.5)};
    margin: ${theme.space(0.5)} 0 ${theme.space(2.5)};
  }

  && {
    p {
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      ${theme.media.lg`
        font-size: ${rem(15)};
        line-height: ${rem(25)};

      `};
    }
  }
`

const clearCard = ({ theme }) => css`
  margin-bottom: ${theme.space(2)};
  margin-left: ${rem(10)};
  margin-right: ${rem(10)};
  max-width: calc(100% - ${rem(20)});

  ${theme.media.md`
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  `};

  ${AlphaGradient} {
    display: none;
  }

  ${TextContainer} {
    margin-top: ${theme.space(2.5)};
    padding: 0 ${theme.space(2)};

    ${theme.media.md`
      padding: 0;
    `};
  }

  ${ButtonContainer} {
    justify-content: flex-start;
    margin: ${theme.space(0.5)} 0 0;
    padding: 0 ${theme.space(2)};

    ${theme.media.md`
      padding: 0;
    `};
  }

  img {
    border-radius: ${theme.layout.borderRadius};
  }

  .aspect-img {
    ${theme.layout.borderRadius};
    overflow: hidden;
    border-bottom-left-radius: ${rem(5)};
    border-bottom-right-radius: ${rem(5)};
  }

  && {
    p {
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      ${theme.media.lg`
        font-size: ${rem(15)};
        line-height: ${rem(25)};

      `};
    }
  }
`

const cardStyles = {
  [CARD_STYLES.WHITE_CARD]: whiteCard,
  [CARD_STYLES.CLEAR_CARD]: clearCard,
}

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .aspect-img {
      background-color: ${theme.color.blue};
    }

    &:hover {
      ${theme.mixin.imgHover};
    }

    &:last-child {
      ${Content} {
        margin-bottom: 0;
      }
    }
  `};
`

const Content = styled(motion.div)`
  ${({ theme, styleType }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    ${ImagePositioner} {
      position: absolute;
      top: 0;
      left: 0;
    }

    ${cardStyles[styleType]};
  `};
`
