import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { StyledButton } from '../../common/Button'
import { get } from 'lodash'
import getPath from '../../../../lib/getPath'
import { rem } from 'polished'
import { motion } from 'framer-motion'
import { Image, TextContainer, Item } from './GridItemCommon'
import linkResolver from '../../../utils/linkResolver'
import Text from '../../common/Text'

const CondensedGridItem = ({ item, style, tags }) => {
  const data = get(item, 'grid_content.document.data')
  const image = get(data, 'image')
  const title = get(data, 'card_title.text')
  const body = get(data, 'card_body.text')
  const label = get(data, 'button_label')

  //temp
  const buttonLink = {
    href: linkResolver(data, 'button_link') || getPath({ data }),
    target: get(data, 'button_link.target'),
  }

  const imageContent = (
    <ImageContainer>
      {image && <Image src={image.url} alt={title || ''} />}
    </ImageContainer>
  )

  return (
    <CondensedItem>
      <Content styleType={style}>
        {imageContent}
        <TextContainer>
          <TextContent>
            {title && (
              <Text.h4
                size="xs"
                medium
                spacing={1}
                lineHeightMultiplier={18 / 13}
                uppercase
              >
                {title}
              </Text.h4>
            )}
            {body && (
              <Text as="p" book size="xxs">
                {body}
              </Text>
            )}
            {buttonLink.href && (
              <StyledButton {...buttonLink} underline>
                {label || (buttonLink.href !== '/' && 'Read more')}
              </StyledButton>
            )}
          </TextContent>
        </TextContainer>
      </Content>
    </CondensedItem>
  )
}

CondensedGridItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.node),
}

export default CondensedGridItem

const CondensedItem = styled(Item)`
  ${({ theme }) => css`
    max-height: ${rem(100)};
  `}
`

const TextContent = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.space(1.5)};
    width: 100%;
    h4 {
      margin-bottom: 0;
    }
    a {
      border-left-width: 0;
    }

    > ${Text} {
      &:not(first-child) {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: ${theme.space(0.2)} 0 0 0;
      }
    }

    > ${StyledButton} {
      margin-top: ${theme.space(0.8)};
    }
  `};
`

const ImageContainer = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: ${rem(100)};
    flex-shrink: 0;
  `};
`

const Content = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: ${rem(100)};

    ${TextContainer} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 100px);
    }
    img {
      border-radius: ${theme.layout.borderRadius};
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `};
`
