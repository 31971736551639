import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import Section from '../common/Section'
import IframeResizer from 'iframe-resizer-react'

import useSSR from 'use-ssr'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'

export function transform(node) {
  const { type, name } = node
  if (type === 'tag' && name === 'iframe') return node.attribs.src
}

const EmbedModule = ({ primary: data }) => {
  const { isBrowser } = useSSR()
  const iframeRef = useRef()
  const body = get(data, 'embed_body.html')
  const bgColor = get(data, 'background_color') || 'transparent'
  const src = get(ReactHtmlParser(body, { transform }), '[0].props.children[0]')

  const [messageData, setMessageData] = useState()

  const onResized = data => {
    console.log(data)
    setMessageData(data)
  }

  const onMessage = data => {
    setMessageData(data)
    console.log(data)
    iframeRef.current.sendMessage('Hello back from the parent page')
  }
  return body ? (
    <Section bgColor={bgColor}>
      <Container>
        {!!isBrowser && (
          <IframeResizer
            forwardRef={iframeRef}
            autoResize
            sizeHeight
            heightCalculationMethod="lowestElement"
            minHeight={640}
            src={src}
            onMessage={onMessage}
            onResized={onResized}
            style={{
              width: '100%',
              overflow: 'visible',
            }}
          />
        )}
      </Container>
    </Section>
  ) : null
}

EmbedModule.propTypes = {
  primary: PropTypes.object,
}

export default EmbedModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;

    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidth};

    margin: 0;

    div {
      width: 100%;
      -webkit-overflow-scrolling: touch;
      overflow: scroll;

      > * {
        -webkit-overflow-scrolling: touch;
        overflow: scroll;
      }
    }

    > div,
    iframe {
      width: 100%;
    }
  `};
`
