import React, { memo } from 'react'

import ReactHtmlParser from 'react-html-parser'
import Text, { setFontSize, sizes } from '../../common/Text'
import Link from '../../common/Link'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import linkResolver from '../../../utils/linkResolver'
import { rem } from 'polished'

import bgGradient from '../../../assets/home-right-bg.jpg'

const RightItem = props => {
  const label = get(props, 'label.html')
  const backgroundImage = get(props, 'right_image.url')
  const link = {
    href: linkResolver(props, 'link'),
    target: get(props, 'link.target'),
  }

  return (
    <StyledLink {...link}>
      <Image src={bgGradient} />
      <BgImage src={backgroundImage} />
      <Gradient />
      <Tri />
      <Content>
        <Border>
          {label && (
            <RightButton center html>
              {ReactHtmlParser(label)}
            </RightButton>
          )}
        </Border>
      </Content>
    </StyledLink>
  )
}

RightItem.propTypes = {}

export default memo(RightItem)

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    ${theme.mixin.afterFocus};
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;
    margin-bottom: ${theme.space(3)};

    &:last-child {
      ${theme.media.lg`
        margin-bottom: 0;
      `};
    }

    &:hover {
      ${BgImage} {
        opacity: 0.5;
        transform: scale(1.03);
      }

      ${Blue} {
        opacity: 0.4;
      }
    }
  `};
`

const RightButton = styled(Text)`
  ${({ theme }) => css`
    position: relative;

    height: 100%;
    padding: ${theme.space(1)};

    h3 {
      color: #fff;
      font-size: ${rem(24)};
      font-weight: bold;
      letter-spacing: 1.5px;
      line-height: ${rem(20)};
    }

    h6 {
      color: ${theme.color.yellow};
      font-size: ${rem(11)};
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: ${rem(20)};
    }
  `};
`

const Image = styled.img`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;
  `};
`

const BgImage = styled(Image)`
  z-index: 1;
  opacity: 0.3;
  transition: 0.3s ease-out;
`

const Content = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: ${theme.space(1)};
    width: 100%;
    height: 100%;
    z-index: 1;

    ${theme.media.xl`
        padding: ${theme.space(1.5)};
    `};

    ${theme.media.xxl`
        padding: ${theme.space(2)};
    `};
  `};
`

const Border = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border: 1px solid #fff;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `};
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 1;
  background-image: linear-gradient(
    270deg,
    rgba(52, 100, 148, 0.3) 0%,
    rgb(52, 100, 148) 100%
  );
  z-index: 1;
`

const Tri = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  clip-path: polygon(0px 0px, 100% 0px, 100% 100%);
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
`

const Blue = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: rgba(29, 53, 91, 0.25);
  background-image: linear-gradient(
    243deg,
    rgba(29, 53, 91, 0) 32%,
    #1d355b 68%
  );
`
