import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import IntroModule from '../slices/IntroModule'
import PostGridItem from '../components/grid-module/PostGridItem'
import FeaturedPostGridItem from '../components/grid-module/FeaturedPostGridItem'
import Pagination from '../common/Pagination'
import usePagination from '../../hooks/usePagination'
import Section from '../common/Section'
import Page from '../container/Page'

import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import { get, first } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import theme from '../../style/theme'

const FEATURED_TAG = 'featured'

let prevPage = 0

const promoteFeaturedAndTransform = (blogPosts = []) => {
  const featuredIndex = blogPosts.findIndex(({ tags = [] }) => {
    return tags.map(t => t.toLowerCase()).includes(FEATURED_TAG)
  })
  let featured
  if (featuredIndex > -1) {
    featured = first(blogPosts.splice(featuredIndex, 1))
  }

  return {
    posts: blogPosts,
    featured: featured && { grid_content: { document: featured } },
  }
}

const PostListingPage = ({ pageContext: { staticData }, location }) => {
  const pageData = usePreviewData(staticData)
  const { sections } = useParseSiteData(pageData)
  const { data, blogPosts = [] } = pageData

  const { posts, featured } = useMemo(
    () => promoteFeaturedAndTransform([...blogPosts]),
    [blogPosts],
  )

  const initialPage = get(location, 'state.page') || 0
  const { pages, ...paginationProps } = usePagination(posts, initialPage)
  const current = get(paginationProps, 'current') || 0
  const total = get(paginationProps, 'total')

  const introModule = {
    intro_image: get(data, 'intro_image'),
    intro_title: get(data, 'intro_title'),
    intro_body: get(data, 'intro_body'),
    background_image: get(data, 'background_image'),
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && prevPage !== current) {
      prevPage = current
      window.scrollTo(0, 0)
    }
  }, [current])

  return (
    <>
      <Page {...sections}>
        <TopBg />
        <IntroModule primary={introModule} paddingProps={{ top: 0 }} />
        <Spacer />
        <Section noPaddingTop>
          <Grid columns={4} hasFeatured={!!featured}>
            {featured && (
              <FeaturedPostGridItem
                key={'post_listing_item_featured'}
                item={featured}
              />
            )}
            {!!pages.length &&
              pages[current].map((item, i) => (
                <PostGridItem
                  key={`post_listing_item_${i}`}
                  item={item}
                  page={get(paginationProps, 'current') || 0}
                />
              ))}
          </Grid>
        </Section>
        {total > 1 && (
          <Section bgColor={theme.color.softBlue} noPaddingTop noPaddingBottom>
            <PaginationContainer>
              <Pagination {...paginationProps} iconColor={theme.color.yellow} />
            </PaginationContainer>
          </Section>
        )}
      </Page>
    </>
  )
}

PostListingPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default PostListingPage

const Spacer = styled.div`
  ${({ theme }) => css`
    height: ${theme.space(2)};

    ${theme.media.md`
      height: ${theme.space(5.5)};
    `};
  `};
`

const PaginationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    flex: 1;
    max-width: ${theme.layout.maxWidth};
    height: ${rem(100)};
  `}
`

const Grid = styled.div`
  ${({ columns, theme, hasFeatured }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${theme.space(2)} 0;

    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.md`
      grid-template-columns: repeat(${2}, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};

       ${hasFeatured &&
         css`
           > *:first-child {
             grid-column: span 2;
           }
         `};
    `};

    ${theme.media.xl`
      grid-template-columns: repeat(${3}, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};

    ${theme.media.xxl`
      grid-template-columns: repeat(${columns}, minmax(${rem(120)}, 1fr));
    `};
  `};
`

const TopBg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 33%;
    background-color: ${theme.color.softBlue};

    ${theme.media.lg`
      height: ${rem(580)};
    `};
  `};
`
