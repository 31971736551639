import React from 'react'
import PropTypes from 'prop-types'

import { WEIGHT } from '../../style/type'

import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text from '../common/Text'
import ResponsivePlayer from '../common/ResponsivePlayer'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'

const VideoModule = ({ primary: data, ...props }) => {
  const video = get(data, 'video_url.url')
  const caption = get(data, 'video_caption.text')
  const image = get(data, 'video_cover_image.url') || true
  const bgColor = get(data, 'background_color') || 'transparent'

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container>
        {video && <ResponsivePlayer url={video} image={image} />}
        {caption && <Text.h5 center>{ReactHtmlParser(caption)}</Text.h5>}
      </Container>
    </SliceSection>
  )
}

VideoModule.propTypes = {
  primary: PropTypes.object,
}

export default VideoModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    min-height: ${rem(140)};
    width: 100%;
    max-width: ${theme.layout.maxWidth};
    overflow: hidden;

    h5 {
      margin-top: ${theme.space(1)};
      font-size: 11px;
      letter-spacing: 0.5px;
      line-height: 14px;
      font-weight: ${WEIGHT.BOLD};

      ${theme.media.lg`
        margin-top: ${theme.space(3)};
        font-size: 14px;
        letter-spacing: 1.2px;
        line-height: 22px;
        font-weight: ${WEIGHT.MEDIUM};
      `};
    }
  `};
`
