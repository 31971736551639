import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  startOfDay,
  format,
  compareAsc,
  isWithinInterval,
  fromUnixTime,
} from 'date-fns'
import { get, map, size, orderBy } from 'lodash'
import Text from '../../common/Text'
import { SmallArrow } from '../../common/Icons'
import theme from '../../../style/theme'
import linkResolver from '../../../utils/linkResolver'
import LinkComponent from '../../common/Link'

const getDates = (events, dateStart, dateEnd) => {
  const dates = events.reduce((acc, item) => {
    const d = get(item, 'grid_content.document.data.dates', [])
    const datesThisWeek = d.reduce((acc, { date }, i) => {
      const isWithinDateRange = isWithinInterval(new Date(date), {
        start: dateStart,
        end: dateEnd,
      })
      if (isWithinDateRange) {
        acc.push({ date })
      }
      return acc
    }, [])
    if (datesThisWeek.length) {
      datesThisWeek.forEach(({ date }) => {
        const firstTime = startOfDay(new Date(date)).getTime() / 1000

        acc[firstTime] = acc[firstTime] || []
        acc[firstTime].push(item)
      })
    }
    return acc
  }, {})

  const dateKeys = Object.keys(dates)
  dateKeys.sort((a, b) => {
    return parseInt(a) - parseInt(b)
  })

  return dateKeys.reduce((acc, timestamp) => {
    acc[fromUnixTime(timestamp).toString()] = dates[timestamp]
    return acc
  }, {})
}

const CalendarMobileView = ({ dateStart, dateEnd, events }) => {
  const [showNoEvents, setShowNoEvents] = useState(true)
  const filteredDates = useMemo(() => getDates(events, dateStart, dateEnd), [
    dateEnd,
    dateStart,
    events,
  ])

  useEffect(() => {
    const numObject = size(filteredDates)
    numObject === 0 ? setShowNoEvents(true) : setShowNoEvents(false)
  }, [filteredDates])

  return (
    <ListContainer>
      {map(filteredDates, (items, key) => {
        const date = new Date(key)
        return <ListGroup key={date.getTime()} items={items} date={date} />
      })}
      {showNoEvents && (
        <EmptyCalendarMobileContainer>
          <EmptyList>
            <Label selectNone center>
              No Events Between Those Dates
            </Label>
          </EmptyList>
        </EmptyCalendarMobileContainer>
      )}
    </ListContainer>
  )
}

CalendarMobileView.propTypes = {
  dateStart: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),
  events: PropTypes.array,
}

CalendarMobileView.defaultProps = {
  events: [],
}

export default CalendarMobileView

const ListGroup = ({ items, date }) => {
  return (
    <ListGroupContainer>
      <Text.h4 center color="secondaryBlue">
        {format(date, "E',' MMM d")}
      </Text.h4>
      {items.map((item, i) => (
        <ListItem
          key={`${date.getTime()}-${i}`}
          item={get(item, 'grid_content')}
        />
      ))}
    </ListGroupContainer>
  )
}

ListGroup.propTypes = {
  date: PropTypes.instanceOf(Date),
  items: PropTypes.array,
}

const ListItem = ({ dates, item }) => {
  const title = get(item, 'document.data.card_title.text', '')
  const link = {
    href: linkResolver({ item }, 'item'),
  }
  return (
    <ListItemContainer>
      <LinkComponent {...link}>
        <Text size="xxs" bold center uppercase lineHeightMultiplier={14 / 11}>
          {title}
        </Text>
        <StyledSmallArrow
          fill={theme.color.secondaryLightBlue}
          width={20}
          height={20}
        />
      </LinkComponent>
    </ListItemContainer>
  )
}

ListItem.propTypes = {
  dates: PropTypes.array,
  item: PropTypes.object,
}

const ListGroupContainer = styled.div`
  ${({ theme }) => css`
    > ${Text.h4} {
      margin-top: ${theme.space(3)};
      margin-bottom: ${theme.space(1)};
    }

    ${theme.media.lg`
      padding: 0 ${theme.space(1.5)};
    `};
  `}
`

const ListItemContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    border-radius: ${theme.layout.borderRadius};
    background-color: white;
    padding: ${theme.space(1.5)} ${theme.space(4)};
    margin-bottom: ${theme.space(1)};
  `}
`

const ListContainer = styled.div`
  ${({ theme }) => css``}
`

const StyledSmallArrow = styled(SmallArrow)`
  ${({ theme }) => css`
    position: absolute;
    right: ${theme.space(1)};
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  `}
`
const EmptyCalendarMobileContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    background-color: white;
    border: 1px solid ${theme.color.secondaryLightBlue};
    border-radius: ${theme.layout.borderRadius};
  `}
`

const EmptyList = styled.div`
  ${({ theme }) => css`
    margin: ${theme.space(1)} ${theme.space(1)};
  `}
`

const Label = styled(Text.h6)``
