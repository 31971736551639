import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { get } from 'lodash'

const Check = props => (
  <Svg width={28} height={28} viewBox="0 0 16 12" {...props}>
    <path
      d="M5.5 9.477L14.328.648 15.5 1.82l-10 10L.852 7.172 2.023 6z"
      fillRule="nonzero"
    />
  </Svg>
)

const Svg = styled.svg`
  ${({ color, theme }) =>
    color &&
    css`
      path {
        fill: ${get(theme.color, color) || color};
      }
    `};
`

Check.propTypes = {
  color: PropTypes.string,
}

Check.defaultProps = {
  color: '#1D355B',
}

export default Check
