import { useState, useCallback, useEffect } from 'react'
import { localize } from 'date-fns/locale/en-US/index'
import {
  addWeeks,
  endOfWeek,
  format,
  getMonth,
  getYear,
  startOfWeek,
  subWeeks,
  endOfDay,
  startOfDay,
} from 'date-fns'

const dateFnsOpts = {
  weekStartsOn: 1,
}
const today = new Date()
export const initWeekState = {
  weekStart: startOfWeek(today, dateFnsOpts),
  weekEnd: endOfWeek(today, dateFnsOpts),
}

const useChangeWeekMonthYear = (startDate = today) => {
  const [weekStart, setWeekStart] = useState(
    startOfWeek(startDate, dateFnsOpts),
  )
  const [weekEnd, setWeekEnd] = useState(endOfWeek(weekStart, dateFnsOpts))
  const [text, setText] = useState()

  const onNextWeek = useCallback(() => {
    const nextWeek = addWeeks(weekStart, 1)
    setWeekStart(startOfDay(nextWeek))
    setWeekEnd(endOfDay(endOfWeek(nextWeek, dateFnsOpts)))
  }, [weekStart])

  const onPrevWeek = useCallback(() => {
    const nextWeek = subWeeks(weekStart, 1)
    setWeekStart(startOfDay(nextWeek))
    setWeekEnd(endOfDay(endOfWeek(nextWeek, dateFnsOpts)))
  }, [weekStart])

  useEffect(() => {
    const startMonth = getMonth(weekStart)
    const startYear = getYear(weekStart)
    const endMonth = getMonth(weekEnd)
    const endYear = getYear(weekEnd)

    const monthNotation = startYear !== endYear ? 'MMM' : 'MMMM'

    let text = `${format(weekStart, `${monthNotation} d`)}`

    text += startYear !== endYear ? format(weekStart, ' yyyy-') : '-'
    text +=
      startMonth === endMonth
        ? format(weekEnd, 'd yyyy')
        : format(weekEnd, `${monthNotation} d yyyy`)
    setText(text)
  }, [weekStart, weekEnd])
  return { weekStart, weekEnd, onNextWeek, onPrevWeek, text }
}

export default useChangeWeekMonthYear
