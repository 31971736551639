import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { SECTION_TITLE_MODULE } from '../../constants/sliceTypes'

import AlphaGradient from '../common/AlphaGradient'
import { SmallLinkArrow, Download, Share } from '../common/Icons'
import DiningModal from '../components/dining/DiningModal'
import Dropdown from '../common/Dropdown'
import LinkComponent from '../common/Link'
import MenuSection from '../components/dining/MenuSection'
import Section from '../common/Section'
import ReactHtmlParser from 'react-html-parser'
import Page from '../container/Page'
import Text from '../common/Text'
import ToolTip from '../common/ToolTip'
import PrevNextLinks from '../common/PrevNextLinks'

import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import { get, first, pick } from 'lodash'
import styled, { css } from 'styled-components'
import { rem, transparentize } from 'polished'
import { useAppContext } from '../../context/AppContext'
import { navigate } from 'gatsby'
import { AnimatePresence } from 'framer-motion'
import linkResolver from '../../utils/linkResolver'
import useSSR from 'use-ssr/dist/useSSR'

const DiningMenuPage = ({ pageContext: { staticData } }) => {
  const { isBrowser } = useSSR()
  const { isLarge, isXLarge } = useAppContext()
  const [hoverImage, setHoverImage] = useState(null)
  const pageData = usePreviewData(staticData)
  const { sections } = useParseSiteData(pageData)
  const { data } = pageData
  const diningDetail = get(data, 'dining_detail.document.data')
  const sectionItems = []
  let index = -1
  data.body.forEach(item => {
    if (item.slice_type === SECTION_TITLE_MODULE) {
      index++
      sectionItems.push({
        ...item.primary,
        items: [],
      })
    } else {
      const activeSection = sectionItems[index]
      const items = [...activeSection.items, pick(item, 'primary', 'items')]
      activeSection.items = items
    }
  })

  const topImage = {
    src: get(data, 'top_image.url'),
    alt: get(data, 'top_image.alt') || '',
  }
  const logoImage = {
    src: get(diningDetail, 'logo_image.url'),
    alt: get(diningDetail, 'logo_image.alt') || '',
  }
  const title = get(data, 'header_title.text')
  let hours = get(diningDetail, 'hours_body.html')
  if (hours) hours = hours.replace(/\•/g, '<span>•</span>')
  const hoursText = get(diningDetail, 'hours_body.text.length') !== 0
  const phone = get(diningDetail, 'phone.html')
  const hasPhoneNumber =
    get(diningDetail, 'phone.text', '').replace(/\D+/g, '').length >= 10
  const chooseMenuLabel = get(data, 'choose_menu_label')
  const downloadLabel = get(data, 'download_label') || 'Download Menu'
  const downloadLink = get(data, 'download_file.url')
  const disclaimer = get(data, 'disclaimer.html')

  let items = get(data, 'body[0].items')
  if (items && !!items.length) {
    items = items.map(item => get(item, 'section.document.data') || item)
  }

  const menus = get(data, 'menu_links', [])

  const useMenu = !!menus.length
  const filters = menus.map(item => ({
    label: get(item, 'menu_label'),
    value: get(item, 'link.document.uid'),
  }))

  const activeMenu = first(
    filters.filter(item => {
      const uid = get(item, 'value')
      return uid === pageData.uid
    }),
  )

  const activeId = get(activeMenu, 'value')

  const allLink = { url: get(data, 'dining_detail.document.url') }

  const paginationLinks = {
    allLabel: get(data, 'back_label') || 'Back to restaurant',
    allLink,
  }

  const getLink = link => ({
    href: linkResolver({ link }, 'link'),
  })

  const venueText = (
    <>
      {title && (
        <TitleContainer {...getLink(allLink)}>
          {isXLarge && (
            <BackButton>
              <SmallLinkArrow transform="scale(-1,1)" />
            </BackButton>
          )}
          <Text.h1 white={isXLarge} center>
            {title}
          </Text.h1>
        </TitleContainer>
      )}
    </>
  )

  const contacts = (
    <>
      {(hoursText || phone) && (
        <InfoText
          medium
          center
          size="sm"
          uppercase
          color={isXLarge ? 'secondaryLightBlue' : 'neutralBlue'}
        >
          {hoursText && ReactHtmlParser(hours)}
          {hasPhoneNumber && ReactHtmlParser(phone)}
        </InfoText>
      )}
    </>
  )

  const expandedImage = {
    src: get(hoverImage, 'primary.item_image.url'),
    alt: get(hoverImage, 'primary.item_image.alt'),
    title: get(hoverImage, 'primary.item_name.text'),
    description: get(hoverImage, 'primary.item_description.html'),
    price: get(hoverImage, 'primary.item_price'),
  }

  const ShareWrapper = useMenu ? ShareContainer : React.Fragment

  return (
    <>
      <Page {...sections}>
        {isLarge ? (
          <AnimatePresence>
            {hoverImage && (
              <ToolTip
                image={expandedImage}
                initial="hide"
                animate="show"
                exit="hide"
                variants={{
                  show: { opacity: 1 },
                  hide: { opacity: 0 },
                }}
                transition={{
                  duration: 0.4,
                  ease: [0.04, 0.62, 0.23, 0.98],
                }}
              />
            )}
          </AnimatePresence>
        ) : (
          <DiningModal
            showModal={!!get(expandedImage, 'src')}
            setModal={setHoverImage}
            {...expandedImage}
          />
        )}

        <Top>
          <TopBg />
          <TopContent>
            {topImage && topImage.src && <img alt="" {...topImage} />}
            {isXLarge && <AlphaGradient />}
            <TopText>
              <Hover>
                {logoImage && logoImage.src && (
                  <Logo {...getLink(allLink)}>
                    <Circle>
                      <img alt="" {...logoImage} />
                    </Circle>
                  </Logo>
                )}
                {isXLarge && venueText}
              </Hover>
              {isXLarge && contacts}
            </TopText>
          </TopContent>
        </Top>

        <Section
          bgColor={isXLarge ? '#fff' : 'softBlue'}
          noPaddingTop
          noPaddingBottom
        >
          <Content>
            {!isXLarge && venueText}
            {!isXLarge && contacts}

            <Options>
              {useMenu && filters && !!filters.length && (
                <SelectContainer>
                  {isLarge && chooseMenuLabel && (
                    <Text size="xs" uppercase medium color="secondaryBlue">
                      {chooseMenuLabel}
                    </Text>
                  )}
                  <Dropdown
                    defaultValue={activeId}
                    items={filters}
                    value={activeId}
                    onChange={v => {
                      const link = menus.reduce((acc, item) => {
                        const uid = get(item, 'link.document.uid')
                        if (uid === v) acc = linkResolver(item, 'link')
                        return acc
                      }, '')
                      if (v !== pageData.uid) navigate(link)
                    }}
                  />
                </SelectContainer>
              )}

              <ShareWrapper>
                <TextButton useMargin={useMenu}>
                  <Share style={{ transform: 'translateY(3px)' }} />
                  {isBrowser && (
                    <div className="addthis_inline_share_toolbox"></div>
                  )}
                </TextButton>

                {downloadLink && (
                  <TextButtonBlueLink
                    as="a"
                    href={downloadLink}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    useMargin={useMenu}
                  >
                    <Download />
                    <Text medium size="xs" uppercase>
                      {downloadLabel}
                    </Text>
                  </TextButtonBlueLink>
                )}
              </ShareWrapper>
            </Options>
          </Content>
        </Section>

        <Section
          noPaddingBottom
          noPaddingTop={!isLarge}
          css={{ marginTop: rem(isLarge ? 0 : 30) }}
        >
          <ContentMenu>
            {sectionItems &&
              !!sectionItems.length &&
              sectionItems.map((item, i) => {
                return (
                  <MenuSection
                    key={i}
                    index={i}
                    last={i === sectionItems.length - 1}
                    setHoverImage={setHoverImage}
                    {...item}
                  />
                )
              })}
          </ContentMenu>
        </Section>

        <Section noPaddingTop>
          {disclaimer && (
            <Center>
              <Hr />
              <Disclaimer
                size="xxs"
                lg="md"
                lineHeight={rem(18)}
                lineHeightLg={rem(25)}
                center
              >
                {ReactHtmlParser(disclaimer)}
              </Disclaimer>
            </Center>
          )}
        </Section>
        <PrevNextLinks
          hideBorder
          bgColor="softBlue"
          fullWidth
          {...paginationLinks}
        />
      </Page>
    </>
  )
}

DiningMenuPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default DiningMenuPage

const ShareContainer = styled.div(
  ({ theme }) => css`
    display: none;
    ${theme.media.lg`
    display: block;
  `}
  `,
)

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Hr = styled.hr`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    border: none;
    padding: 0;
    margin: 0 auto;
    height: 1px;
    background: ${theme.color.rule};
  `};
`

const TitleContainer = styled(LinkComponent)`
  ${({ theme }) => css`
    position: relative;

    h1 {
      margin-top: ${theme.space(2)};

      ${theme.media.xl`
        margin-top:  0;
      `};
    }
  `};
`

const BackButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: -${theme.space(3.5)};
    top: 50%;
    transform: translate(0, -45%);
    padding: ${theme.space(0.5)};

    svg {
      transform: scaleX(-1);
    }
  `};
`

const Disclaimer = styled(Text)`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidthArticle};

    padding-top: ${theme.space(4)};
    padding-bottom: ${theme.space(2)};

    ${theme.media.lg`
      padding-top: ${theme.space(6)};
    `};
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};
    padding-bottom: ${theme.space(3)};

    ${theme.media.lg`
      padding-bottom: 0;
    `};
  `};
`

const ContentMenu = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`

const Top = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
  `};
`

const TopBg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.color.softBlue};

    ${theme.media.xl`
      height: 70%;
    `};
  `};
`

const TopContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: calc(100% - ${rem(20)});
    max-width: ${theme.layout.maxWidth};
    max-height: ${rem(90)};
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    ${theme.media.md`
      width: calc(100% - ${rem(90)});
      max-height: ${rem(350)};
    `};

    margin: 0 auto;

    > img {
      width: 100%;
      height: auto;
      object-fit: cover;

      min-height: ${rem(90)};

      ${theme.media.xl`
        min-height: ${rem(280)};
      `}
    }
  `};
`

const TopText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: ${theme.space(1)};

    ${theme.media.xl`
        bottom: 0;
        height: auto;
        justify-content: flex-end;
        padding: ${theme.space(2)};
      `}
  `};
`

const Hover = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }

    ${theme.media.xl`
        justify-content: flex-end;
      `}
  `};
`

const InfoText = styled(Text)`
  ${({ theme }) => css`
    width: 100%;
    margin: ${theme.space(1.5)} 0 ${theme.space(2)};

    span {
      opacity: 0.5;
    }

    p {
      padding: ${theme.space(1)};

      &:not(:last-child) {
        border-bottom: 1px solid #e8ecf3;
      }
    }

    ${theme.media.xl`
      display: flex;
      justify-content: center;

      p {
        padding: 0;

        &:not(:last-child) {
          border-bottom: none;

          &:after {
            content: '|';
            margin: 0 ${theme.space(2)};
            opacity: 0.2;
          }
        }
      }
    `}
  `};
`

const Circle = styled.div`
  ${({ theme }) => css`
    background-color: #fff;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    max-width: ${rem(95)};
    max-height: ${rem(95)};

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  `};
`

const Logo = styled(LinkComponent)`
  ${({ theme }) => css`
    position: relative;

    height: 80px;
    width: 80px;

    background-color: rgba(255, 255, 255, 0.2);
    padding: ${theme.space(0.5)};
    border-radius: 50%;
    box-shadow: 0 3px 20px 0 #1d355b;

    ${theme.media.lg`
      height: 100px;
      width: 100px;
    `}

    ${theme.media.xl`
      margin: 0 auto ${theme.space(2)} auto;
    `};
  `};
`

const Options = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${theme.media.lg`
      height: ${rem(100)};
    `};

    ${theme.media.xl`
      border-bottom: 1px solid
        ${transparentize(0.2, theme.color.rule)};
    `};
  `};
`

const SelectContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;

    ${theme.media.lg`
      width: auto;

      > div {
        margin-right: ${theme.space(2)};
      }
    `};
  `};
`

const TextButton = styled.button`
  ${({ theme, useMargin }) => css`
    display: inline-flex;
    cursor: pointer;

    position: relative;
    padding: 0;
    margin-left: ${useMargin ? theme.space(3) : '0'};

    svg {
      margin-right: ${theme.space(1)};
    }

    > div {
      position: relative;
    }
  `};
`

const TextButtonBlueLink = styled(TextButton)`
  ${({ theme }) => css`
    > div {
      position: relative;
      ${theme.mixin.blueLink}
    }

    > svg {
      transform: translateY(3px);
    }
  `}
`
