import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import GridModule from './GridModule'

import { CARD_STYLES } from '../../../lib/constants'
import { get } from 'lodash'
import { compareDesc } from 'date-fns'

const DEFAULT_MAX_FEATURED_CARDS = 4

const BlogGridModule = ({
  primary: { heading, module_sub_heading, max_featured_cards, ...primaryProps },
  items,
  ...props
}) => {
  const cleanedItems = items
    ? items.filter(({ grid_content }) => grid_content && grid_content.document)
    : []

  const maxItems = useMemo(() => {
    cleanedItems.sort((a, b) => {
      const dateA = new Date(get(a, 'grid_content.document.data.date'))
      const dateB = new Date(get(b, 'grid_content.document.data.date'))

      return compareDesc(dateA, dateB)
    })
    return cleanedItems.slice(
      0,
      max_featured_cards || DEFAULT_MAX_FEATURED_CARDS,
    )
  }, [cleanedItems, max_featured_cards])

  const bgColor = get(primaryProps, 'background_color', 'transparent')

  return (
    <GridModule
      primary={{ ...primaryProps, style: CARD_STYLES.POST_CARD }}
      items={maxItems}
      bgColor={bgColor}
      {...props}
    />
  )
}

BlogGridModule.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.shape({
    background_color: PropTypes.string,
    heading: PropTypes.object,
    module_sub_heading: PropTypes.object,
    max_featured_cards: PropTypes.number,
  }),
}

export default BlogGridModule
