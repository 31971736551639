import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import Flickity from '../../common/Flickity'
import Text from '../../common/Text'

import { get, isNull } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { format } from 'date-fns'
import { useAppContext } from '../../../context/AppContext'
import getAsDate from '../../../utils/getAsDate'

const RelatedEvents = ({ items }) => {
  const containerRef = useRef()
  const [index, setIndex] = useState(0)
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)
  const { focusStyleOn } = useAppContext()

  const el = get(containerRef, 'current')
  if (el) {
    const prev = !isNull(el.querySelector('.previous:disabled'))
    const next = !isNull(el.querySelector('.next:disabled'))

    if (prevDisabled !== prev) setPrevDisabled(prev)
    if (nextDisabled !== next) setNextDisabled(next)
  }

  return (
    <Container
      ref={containerRef}
      index={index}
      prevDisabled={prevDisabled}
      nextDisabled={nextDisabled}
    >
      <Flickity
        className="flickity"
        key={focusStyleOn + items.length}
        minHeight={100}
        onChange={i => setIndex(i)}
        options={{
          accessibility: focusStyleOn,
          prevNextButtons: items.length > 1,
          wrapAround: false,
          groupCells: true,
        }}
        type="relatedEvents"
      >
        {items &&
          !!items.length &&
          items.map((item, i) => {
            const uid = get(item, 'event.document.uid')
            const data = get(item, 'event.document.data')
            const title = get(data, 'card_title.text')
            const image = {
              src: get(data, 'event_image.url'),
              alt: get(data, 'event_image.alt') || '',
            }
            const date = getAsDate(data, 'dates[0].date')

            return (
              <Item key={i}>
                {image && image.src && <img alt="" {...image} />}
                <div>
                  {title && <Text.h5>{title}</Text.h5>}
                  {date && (
                    <Text size="xs">{format(date, 'iii, MMMM dd')}</Text>
                  )}
                  <Text size="xxs" uppercase as="a" href={`${uid}`}>
                    View Details
                  </Text>
                </div>
              </Item>
            )
          })}
      </Flickity>
    </Container>
  )
}

RelatedEvents.propTypes = {
  items: PropTypes.array,
}

export default RelatedEvents

const Container = styled.div`
  ${({ prevDisabled, nextDisabled, theme }) => css`
    margin: ${theme.space(2)} auto ${theme.space(2)};
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    > div {
      max-height: ${rem(102)};
    }

    ${theme.media.lg`
        width: calc(100% - ${rem(90)});
         margin: ${theme.space(2)} auto 0;
    `};

    ${prevDisabled &&
      css`
        .previous {
          opacity: 0;
          pointer-events: none;
        }

        .flickity-viewport:before {
          opacity: 0;
          pointer-events: none;
        }
      `};

    ${nextDisabled &&
      css`
        .next {
          opacity: 0;
          pointer-events: none;
        }

        .flickity-viewport:after {
          opacity: 0;
          pointer-events: none;
        }
      `};
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    margin: 0 ${theme.space(1.5)} ${theme.space(2)};
    width: 100%;
    max-width: calc(100vw - ${theme.space(2)});

    border: 1px solid ${theme.color.secondaryLightBlue};
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    ${theme.media.md`
      width: 15%;
      min-width: ${rem(370)};
    `};

    ${theme.media.lg`
        margin-bottom: 0;
         width: 15%;
         min-width: ${rem(370)};
    `};

    > div {
      padding: ${theme.space(1)};
      > * {
        margin: ${theme.space(0.5)};
      }
    }

    a {
      ${theme.mixin.blueLink};
    }

    img {
      width: ${rem(160)};
      height: ${rem(100)};
      object-fit: cover;
    }
  `};
`
