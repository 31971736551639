import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

const AlphaGradient = styled.div`
  ${({
    padGradient,
    bottom,
    color,
    deg,
    height,
    middle,
    theme,
    top,
    vertical,
    width,
  }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${width}%;
    height: ${height}%;
    background: linear-gradient(
      ${vertical ? '90deg' : `${deg}deg`},
      ${transparentize(0, color || theme.color.blue)} ${top}%,
      ${transparentize(0, color || theme.color.blue)} ${top + padGradient}%,
      ${transparentize(0.5, color || theme.color.blue)}
        ${middle + padGradient / 2}%,
      ${transparentize(1, color || theme.color.blue)} ${bottom}%
    );
    pointer-events: none;
  `};
`

AlphaGradient.propTypes = {
  bottom: PropTypes.number,
  deg: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  middle: PropTypes.number,
  top: PropTypes.number,
  padGradient: PropTypes.number,
}

AlphaGradient.defaultProps = {
  bottom: 100,
  deg: 0,
  height: 100,
  middle: 50,
  top: 0,
  width: 100,
  padGradient: 0,
}

export const AlphaGradientCorner = ({ transparent, ...props }) => {
  const uid = parseInt(Math.random() * 10000)

  return (
    <Container
      viewBox="0 0 534 503"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <radialGradient
          cx="0%"
          cy="100%"
          fx="0%"
          fy="100%"
          r="84.404%"
          gradientTransform="matrix(0 -1 .94195 0 -.942 1)"
          id={`grad_${uid}`}
        >
          <stop stopColor="#3E6594" offset="0%" stopOpacity="1.0" />
          {transparent ? (
            <stop stopColor="#3E6594" offset="100%" stopOpacity="0.0" />
          ) : (
            <stop stopColor="#1D355B" offset="100%" />
          )}
        </radialGradient>
      </defs>
      <path
        d="M0 0h534v503H0z"
        fill={`url(#${`grad_${uid}`})`}
        fillRule="nonzero"
      />
    </Container>
  )
}
AlphaGradientCorner.propTypes = {
  transparent: PropTypes.bool,
}

const Container = styled.svg`
  ${({ opacity = 1 }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
    opacity: ${opacity};
  `};
`

export default AlphaGradient
