import { useEffect, useState } from 'react'
import { throttle } from 'lodash'

function useMousePosition() {
  let [mousePosition, setMousePosition] = useState({
    x: null,
    y: null,
  })

  function handleMouseMove(e) {
    setMousePosition({
      x: e.pageX,
      y: e.pageY,
    })
  }

  useEffect(() => {
    const handleMove = throttle(handleMouseMove, 10)
    window.addEventListener('mousemove', handleMove)
    return () => window.removeEventListener('resize', handleMove)
  }, [])

  return mousePosition
}

export default useMousePosition
