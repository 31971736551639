import React, { useState } from 'react'
import PropTypes from 'prop-types'

import AlphaGradient from '../common/AlphaGradient'
import ModularForm from '../components/form/ModularForm'
import Page from '../container/Page'
import ReactHtmlParser from 'react-html-parser'
import Text from '../common/Text'
import Section from '../common/Section'
import ThankYou from '../components/form/ThankYou'

import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import { rem, transparentize } from 'polished'
import validateForm from '../../utils/validateForm'
import linkResolver from '../../utils/linkResolver'
import WaveLogo from '../../assets/logo-mark.inline.svg'
import { transformLinks } from '../../utils/textTransforms'

const FormPage = ({ pageContext: { staticData } }) => {
  const pageData = usePreviewData(staticData)
  const { components, sections } = useParseSiteData(pageData)
  const data = get(pageData, 'data')
  const { isLarge } = useAppContext()
  const [success, setSucess] = useState(false)

  const topImage = {
    src: get(data, 'background_image.url'),
    alt: get(data, 'background_image.alt') || '',
  }

  const siteKey = get(pageData, 'sharedGlobalProperties.recaptcha_site_key')
  const title = get(data, 'form_title.text')
  const body = get(data, 'intro_body.html')
  const getformEndpoint = get(data, 'getform_endpoint')
  const submitLabel = get(data, 'submit_label') || 'Submit'
  const requiredLabel = get(data, 'required_label')
  const successMessage = get(data, 'success_message.html')
  const resetLabel = get(data, 'reset_form_label')
  const resetLink = {
    href: linkResolver(data, 'reset_link'),
    target: get(data, 'reset_link.target'),
  }

  const items = get(components, 'body') || []

  const initialValues = items
    .map(item => ({ id: [item.id] }))
    .reduce((obj, item) => {
      obj[item.id] = ''
      return obj
    }, {})

  const validationSchema = validateForm(items)

  const formProps = {
    id: get(pageData, 'prismicId'),
    items,
    initialValues,
    submitLabel,
    requiredLabel,
    onSuccess: () => setSucess(true),
    validationSchema,
    getformEndpoint,
    siteKey,
  }

  const heading = (
    <Text.h1 center white={isLarge}>
      {title}
    </Text.h1>
  )

  return (
    <Page {...sections}>
      {success && successMessage ? (
        <Section noPaddingTop bgColor="softBlue">
          <ThankYou label={resetLabel} image={topImage} link={resetLink}>
            {ReactHtmlParser(successMessage)}
          </ThankYou>
        </Section>
      ) : (
        <>
          <Top>
            <TopBg />
            <TopContent>
              {topImage && topImage.src && <img alt="" {...topImage} />}
              {isLarge && <AlphaGradient />}
              {isLarge && (
                <TopText>
                  <Logo>
                    <WaveLogo />
                  </Logo>

                  {heading}
                </TopText>
              )}
            </TopContent>
            {!isLarge && heading}
          </Top>
          <Section>
            <Content>
              {body && (
                <>
                  <StyledText html>
                    {ReactHtmlParser(body, { transform: transformLinks })}
                  </StyledText>
                  <Hr />
                </>
              )}

              {!!components && siteKey && <ModularForm {...formProps} />}
            </Content>
          </Section>
        </>
      )}
    </Page>
  )
}

FormPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default FormPage

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidthPost};
    padding-left: ${theme.space(3)};
    padding-right: ${theme.space(3)};

    ${theme.media.md`
      padding: 0;
      margin-top: ${theme.space(1)};
    `};

    ${theme.media.lg`
      margin-top: -${theme.space(2)};
      margin-bottom: -${theme.space(2)};
    `};
  `};
`

const Hr = styled.hr`
  ${({ theme }) => css`
    border: none;
    padding: 0;
    width: 100%;
    height: 1px;
    background: ${transparentize(0.5, theme.color.secondaryLightBlue)};
    margin: ${theme.space(3)} 0 ${theme.space(4)};

    ${theme.media.lg`
         margin: ${theme.space(6)} 0 ${theme.space(7)};
      `};
  `};
`

const Top = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    h1 {
      margin: ${theme.space(2.5)} 0 ${theme.space(4)};

      ${theme.media.lg`
        margin: ${theme.space(1)} 0 ${theme.space(3)};
      `};
    }
  `};
`

const TopBg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.color.softBlue};

    ${theme.media.lg`
      height: calc(100% - ${rem(50)});
    `};
  `};
`

const TopContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: calc(100% - ${rem(20)});
    max-height: ${rem(350)};
    max-width: ${theme.layout.maxWidth};
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    ${theme.media.md`
      width: calc(100% - ${rem(90)});
    `};

    margin: 0 auto;

    > img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: ${theme.layout.borderRadius};

      min-height: ${rem(90)};

      ${theme.media.lg`
        min-height: ${rem(280)};
      `}
    }
  `};
`

const Logo = styled.div`
  ${({ theme }) => css`
    position: relative;
    max-height: 100%;

    ${theme.media.xl`
      margin: ${theme.space(1.5)} auto;
      margin: 0 auto ${theme.space(1.5)} auto;
    `}

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      max-height: ${rem(95)};
    }
  `};
`

const TopText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: ${theme.space(1)};

    ${theme.media.lg`
        bottom: 0;
        height: auto;
        justify-content: flex-end;
        padding: ${theme.space(2)};
      `}
  `};
`

const StyledText = styled(Text)`
  ${({ theme }) => css`
    h3 {
      margin-bottom: ${theme.space(1.5)};
    }

    a {
      color: ${theme.color.secondaryBlue};
    }
  `};
`
