import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { WEIGHT } from '../../../style/type'

import Button from '../../common/Button'
import CornerGradient from '../../common/CornerGradient'
import Flickity from '../../common/Flickity'
import ReactHtmlParser from 'react-html-parser'
import Section from '../../common/Section'
import Text, { setFontSize, sizes } from '../../common/Text'
import Link from '../../common/Link'
import { PrevNextArrow } from '../../common/Icons'
import RightItem from './RightItem'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { rem, transparentize } from 'polished'
import { useLoadImage } from '../../../hooks/useLoadImage'
import { useAppContext } from '../../../context/AppContext'
import linkResolver from '../../../utils/linkResolver'
import { motion } from 'framer-motion'

const HomeSlideshow = ({ items, links, time, fadeBottom }) => {
  const [init, setInit] = useState(false)
  const [index, setIndex] = useState(0)
  const { isLarge, focusStyleOn } = useAppContext()
  const elRef = useRef(null)
  const flickityRef = useRef(null)
  const firstImage = get(items, '[0].hero_image.url')
  const { loaded } = useLoadImage(firstImage)

  const item = get(items, `[${index}]`)
  console.log('ITEM HOME', item)
  const body = get(item, 'hero_body.html')
  const buttonLabel = get(item, 'label') || 'Read More'
  const link = {
    href: linkResolver(item, 'link'),
    target: get(item, 'link.target'),
  }
  const autoPlay = (time || 3) * 1000

  const flickityEl = get(flickityRef, 'current')
  const handlePrev = () => flickityEl.prev()
  const handleNext = () => flickityEl.next()
  const handleEnter = () => flickityEl.stopAutoplay()
  const handleLeave = () => flickityEl.resumeAutoplay()

  const toggleAutoPlayHandlers = {
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave,
  }

  if (links && links.length > 3) links.length = 3

  const showPagination = items && !!items.length

  return (
    <Section noPaddingBottom bgColor="softBlue">
      <Bg fadeBottom={fadeBottom} />
      <Container ref={elRef}>
        <FlickityContainer>
          <Flickity
            ref={flickityRef}
            key={loaded && focusStyleOn}
            className="flickity"
            onChange={i => setIndex(i)}
            onInit={() => setInit(true)}
            type="homeGallery"
            options={{
              autoPlay,
              pauseAutoPlayOnHover: true,
              prevNextButtons: false,
              accessibility: focusStyleOn,
            }}
          >
            {items &&
              items.map((item, i) => {
                const image = {
                  src: get(item, 'hero_image.url'),
                  alt: get(item, 'hero_image.alt') || '',
                }

                const link = {
                  href: linkResolver(item, 'link'),
                  target: get(item, 'link.target'),
                }

                const Component = link.href ? ItemLink : Item

                return (
                  <Component key={`img${i}`} {...link}>
                    <Image alt="" {...image} />
                  </Component>
                )
              })}
          </Flickity>
          {showPagination && isLarge && (
            <PrevNext
              onClick={handlePrev}
              tabIndex={0}
              {...toggleAutoPlayHandlers}
            >
              <PrevNextArrow left fill="#fff" />
            </PrevNext>
          )}
          <TextContainer
            initial="hide"
            animate={init ? 'show' : 'hide'}
            variants={{
              show: { opacity: 1 },
              hide: { opacity: 0 },
            }}
            transition={{
              duration: 0.7,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
            {...toggleAutoPlayHandlers}
          >
            {!isLarge && <CornerGradient />}

            <TextBox
              white
              center
              uppercase
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <BgColor />
              {ReactHtmlParser(body)}
              {link.href && (
                <Button inline {...link}>
                  {buttonLabel}
                </Button>
              )}
              {showPagination && !isLarge && (
                <Pagination>
                  {items.map((item, i) => {
                    const handleSelect = () => flickityRef.current.select(i)
                    return (
                      <button key={`p${i}`} onClick={handleSelect}>
                        <Dot active={i === index} />
                      </button>
                    )
                  })}
                </Pagination>
              )}
            </TextBox>
          </TextContainer>
          {showPagination && isLarge && (
            <PrevNext
              onClick={handleNext}
              tabIndex={0}
              {...toggleAutoPlayHandlers}
            >
              <PrevNextArrow fill="#fff" />
            </PrevNext>
          )}
        </FlickityContainer>

        <Right showFocus={focusStyleOn}>
          {links &&
            links.map((item, i) => <RightItem key={`ri${i}`} {...item} />)}
        </Right>
      </Container>
    </Section>
  )
}

HomeSlideshow.propTypes = {
  fadeBottom: PropTypes.bool,
  items: PropTypes.array,
  links: PropTypes.array,
  time: PropTypes.number,
}

export default HomeSlideshow

const Bg = styled.div`
  ${({ theme, fadeBottom }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${rem(80)};
    background-color: #fff;

    ${fadeBottom &&
      css`
        height: ${rem(100)};
        background-color: ${theme.color.hotelBookingGrey};
      `};
  `};
`

const Pagination = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${rem(14)};
    display: flex;
    justify-content: center;

    button {
      padding-left: 7.5px;
      padding-right: 7.5px;
    }
  `};
`

const Dot = styled.div`
  ${({ active, theme }) => css`
    width: ${rem(7)};
    height: ${rem(7)};
    border-radius: 100%;
    opacity: 1;
    background: transparent;
    border: 1px solid ${theme.color.secondaryLightBlue};
    cursor: pointer;
    transition: background-color 0.4s ease-in, border 0.4s ease-in;

    ${theme.media.lg`
      width: ${rem(9)};
      height: ${rem(9)};
    `};

    &:hover {
      background: ${transparentize(0.5, theme.color.secondaryLightBlue)};
      transition: background-color 0.3s ease-out, border 0.3s ease-out;
    }

    ${active &&
      css`
        background: ${theme.color.secondaryLightBlue};
        border: none;
        cursor: auto;
      `};
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    ${theme.media.xl`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(3)};
    `};

    width: 100%;
    max-width: ${theme.layout.maxWidth};

    > div {
      &:first-child {
        width: 100%;
        grid-column: span 9;
      }

      &:last-child {
        grid-column: span 3;
      }
    }
  `};
`

const FlickityContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    max-height: ${rem(578)};

    > div:first-child {
      border-top-right-radius: ${theme.layout.borderRadius};
      border-top-left-radius: ${theme.layout.borderRadius};
      overflow: hidden;

      ${theme.media.lg`
      border-radius: ${theme.layout.borderRadius};

      .flickity-viewport{
        border-radius: ${theme.layout.borderRadius};
      }
    `};
    }

    img {
      max-height: ${rem(320)};

      ${theme.media.lg`
        max-height: 36.125rem;
      `};
    }

    button {
      z-index: 3;
    }

    > div:first-child {
      z-index: 1;
    }
  `};
`

const PrevNext = styled.button`
  ${({ theme }) => css`
    display: none;
    position: absolute;
    bottom: ${rem(92)};
    left: 0;
    cursor: pointer;

    &:last-child {
      left: auto;
      right: 0;
    }

    justify-content: center;
    align-items: center;
    width: 7vw;
    max-width: ${rem(160)};

    z-index: 2;

    ${theme.media.md`
      display: flex;
    `};

    ${theme.media.xl`
        width: 10vw;
    `};

    svg {
      width: ${rem(40)};
      height: ${rem(40)};

      * {
        transition: 0.3s fill;
      }
    }

    &:hover {
      * {
        fill ${theme.color.yellow};
      }
    }
  `};
`

const TextContainer = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: ${rem(-20)} 0 ${theme.space(2)};

    z-index: 2;

    ${theme.media.md`
      margin: 0 0 ${theme.space(2)};
    `};

    ${theme.media.lg`
      display: flex;
      background-color: transparent;

      position: absolute;
      bottom: ${theme.space(3)};
      left: 0;

      padding: 0 ${theme.space(2)};
      margin: 0;
    `};
  `};
`

const BgColor = styled.div`
  ${({ theme }) => css`
    ${theme.layout.bgGradient};

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;

    border-bottom-right-radius: ${theme.layout.borderRadius};
    border-bottom-left-radius: ${theme.layout.borderRadius};

    z-index: -1;

    ${theme.media.lg`
    border-radius: ${theme.layout.borderRadius};
      background-image: radial-gradient(
        -5% 126%,
        ${transparentize(0.15, '#3E6594')} 0%,
        ${transparentize(0.15, '#1D355B')} 100%
      );
    `};
  `};
`

const TextBox = styled(Text.motion)`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: ${rem(220)};

    border-bottom-right-radius: ${theme.layout.borderRadius};
    border-bottom-left-radius: ${theme.layout.borderRadius};

    padding: ${theme.space(2)};

    ${theme.media.lg`
      height: auto;
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
      border-radius: ${theme.layout.borderRadius};
      min-height: ${rem(170)};
      max-width: ${rem(580)};
    `};

    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      transform: translateY(-${rem(16)});

      ${theme.media.lg`
        transform: none;
      `};
    }

    h2,
    h3,
    h4 {
      font-weight: ${WEIGHT.BOLD};
    }

    h2 {
      ${setFontSize(sizes.xl, 1.4)};
      color: ${theme.color.yellow};
      letter-spacing: normal;

      ${theme.media.lg`
        font-size: ${rem(46)};
        line-height: ${rem(54)};
      `};
    }

    h3 {
      ${setFontSize(sizes.md, 1.5)};
      letter-spacing: normal;

      ${theme.media.lg`
        font-size: ${rem(32)};
        line-height: ${rem(42)};
        margin-top: -${rem(10)};
      `};
    }

    h4 {
      font-size: ${rem(18)};
      line-height: ${rem(25)};
      letter-spacing: ${rem(1)};

      ${theme.media.lg`
        font-size: ${rem(20)};
        letter-spacing: ${rem(1.5)};
        line-height: ${rem(30)};
      `};
    }

    h5 {
      ${setFontSize(sizes.xs, 1.4)};
      margin-top: ${theme.space(1)};
      line-height: ${rem(22)};
      font-weight: ${WEIGHT.MEDIUM};
      letter-spacing: 1px;

      ${theme.media.lg`
        ${setFontSize(sizes.sm, 1.4)};
        letter-spacing: 1.5px;
      `};
    }

    a,
    button {
      margin-top: ${theme.space(1.5)};
      transition: background-color 0.4s ease-in, color 0.4s ease-in;
      cursor: pointer;

      &:hover {
        transition: background-color 0.3s ease-out, color 0.3s ease-out;
      }
    }
  `};
`

const sharedItem = ({ theme }) => css`
  position: relative;

  ${Text} {
    margin-top: ${theme.space(2)};
  }
`

const Item = styled.div`
  ${sharedItem};
`

const ItemLink = styled(Link)`
  ${sharedItem};
`

const Right = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-gap: ${theme.space(1)};

    margin-top: -${theme.space(1)};

    ${theme.media.md`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: ${theme.space(3)} ${theme.space(1.5)};
      margin-top: ${theme.space(1.5)};
    `};

    ${theme.media.xl`
      display: flex;
      justify-content: space-between;
      flex-direction: column;
       margin-top: 0;
    `};
  `};
`

const Image = styled.img`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    max-height: ${rem(578)};
    object-fit: cover;
  `};
`
