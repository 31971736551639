import { format, formatISO, formatISO9075, endOfDay } from 'date-fns'

const isoFormat = "yyyyMMdd'T'HHmmss'Z'"

function getRandomKey() {
  let n = Math.floor(Math.random() * 999999999999).toString()
  return new Date().getTime().toString() + '_' + n
}

export function formatTime(date, options = {}) {
  return formatISO(date, options)
}

export function buildUrl(event, type, isMobile) {
  let calendarUrl = ''
  const dateFormat =
    event.byDays || event.allDay
      ? { format: 'basic', representation: 'date' }
      : { format: 'basic' }
  // allow mobile browsers to open the gmail data URI within native calendar app
  type = type === 'google' && isMobile ? 'default' : type
  switch (type) {
    case 'google':
      calendarUrl = 'https://calendar.google.com/calendar/render'
      calendarUrl += '?action=TEMPLATE'
      calendarUrl += '&dates=' + formatTime(event.startTime, dateFormat)
      if (event.byDays) {
        calendarUrl += '/' + formatTime(endOfDay(event.startTime), dateFormat)
      } else {
        calendarUrl += '/' + formatTime(event.endTime, dateFormat)
      }
      calendarUrl += '&location=' + encodeURIComponent(event.location)
      calendarUrl += '&text=' + encodeURIComponent(event.title)
      calendarUrl += '&details=' + encodeURIComponent(event.description)
      if (event.byDays) {
        calendarUrl +=
          '&recur=' +
          encodeURIComponent(
            `RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=${
              event.byDays
            };UNTIL=${formatTime(event.endTime, dateFormat)}`,
          )
      }

      break
    case 'outlook':
      calendarUrl = 'https://outlook.live.com/owa/?rru=addevent'
      calendarUrl += '&startdt=' + formatTime(event.startTime)
      calendarUrl += '&enddt=' + formatTime(event.endTime)
      calendarUrl += '&subject=' + encodeURIComponent(event.title)
      calendarUrl += '&location=' + encodeURIComponent(event.location)
      calendarUrl += '&body=' + encodeURIComponent(event.description)
      calendarUrl += '&allday=false'
      calendarUrl += '&uid=' + getRandomKey()
      calendarUrl += '&path=/calendar/view/Month'
      break

    default:
      calendarUrl = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'URL:' + event.url,
        'DTSTART:' +
          (event.byDays
            ? formatTime(event.startTime, {
                format: 'basic',
                representation: 'date',
              })
            : formatTime(event.startTime, dateFormat)),
        'DTEND:' +
          (event.byDays
            ? formatTime(endOfDay(event.startTime), {
                format: 'basic',
                representation: 'date',
              })
            : formatTime(event.endTime, dateFormat)),
        'SUMMARY:' + event.title,
        'DESCRIPTION:' + event.description,
        event.byDays
          ? `RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=${
              event.byDays
            };UNTIL=${formatTime(event.endTime, dateFormat)}`
          : '',
        'END:VEVENT',
        'END:VCALENDAR',
      ]

      if (event.location) {
        calendarUrl.push('LOCATION:' + event.location)
      }

      calendarUrl = calendarUrl.join('\n')

      calendarUrl = encodeURI('data:text/calendar;charset=utf8,' + calendarUrl)
  }

  return calendarUrl
}
