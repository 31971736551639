import React from 'react'
import { convertNodeToElement } from 'react-html-parser'
import LinkComponent from '../components/common/Link'

export function renderLink(attribs = {}, node, index) {
  console.log('renderLink', convertNodeToElement(node, index, transformLinks))
  return (
    <LinkComponent {...attribs}>
      {convertNodeToElement(node, index, transformLinks)}
    </LinkComponent>
  )
}

export function transformLinks(node, index) {
  console.log('transformLinks', node)
  const { type, name, attribs = {} } = node
  if (type === 'tag' && name === 'a') {
    return renderLink(attribs, node, index)
  }
}
