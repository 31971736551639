const { EVENT_DETAIL_PAGE } = require('./constants')
const { isBefore } = require('date-fns')
const { get, find } = require('lodash')
const { transformToGridItems } = require('./tranforms')

const sortEventDate = (a, b) => {
  const aDate = a.data.dates[0] ? a.data.dates[0].date : new Date()
  const bDate = b.data.dates[0] ? b.data.dates[0].date : new Date()

  return isBefore(new Date(aDate), new Date(bDate)) ? -1 : 1
}

const getEventDetailPages = allPages => {
  const eventDetailPages = allPages.filter(
    ({ type }) => type === EVENT_DETAIL_PAGE,
  )

  eventDetailPages.sort(sortEventDate)

  return eventDetailPages
}

const getEventDetailsByEventVenue = allPages => {
  const eventDetailPages = getEventDetailPages(allPages)

  const events = eventDetailPages.reduce((acc, post) => {
    const relatedVenueUid = get(post, 'data.location.document.uid')
    const venue = find(allPages, { uid: relatedVenueUid })
    if (venue) {
      if (!acc[venue.uid]) acc[venue.uid] = []
      acc[venue.uid] = [...acc[venue.uid], post]
    }

    return acc
  }, {})

  return events
}

const injectVenueWithEvents = allPages => {
  const events = getEventDetailsByEventVenue(allPages)

  Object.keys(events).forEach(key => {
    const venue = find(allPages, { uid: key })
    if (venue) {
      events[key].sort(sortEventDate)
      venue.venueEvents = events[key].map(transformToGridItems)
    }
  })

  return {
    ...allPages,
  }
}

module.exports = {
  injectVenueWithEvents,
  sortEventDate,
}
