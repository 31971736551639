import React, { useCallback, useMemo, useRef, useState, Fragment } from 'react'
import PropTypes from 'prop-types'

import { STICKY_Y_OFFSET } from '../../constants/settings'

import Button from '../common/Button'
import Dropdown from '../common/Dropdown'
import EventCalendarDayItem from '../components/events/EventCalendarDayItem'
import Section from '../common/Section'
import Sticky from 'react-stickynode'
import DatePicker from '../common/DatePicker'
import Text from '../common/Text'
import Page from '../container/Page'

import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import {
  startOfDay,
  format,
  isAfter,
  isToday,
  subWeeks,
  formatISO,
} from 'date-fns'
import { get, flatten, uniq, chunk } from 'lodash'
import styled, { css } from 'styled-components'
import useElementSize from '../../hooks/useElementSize'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'
import wait from '../../utils/wait'
import theme from '../../style/theme'

const CHUNKS = 15

const ALL_EVENTS = 'All Events'
export const FEATURED_TAGS = ['featured']

const EventCalendarPage = ({ pageContext: { staticData } }) => {
  const { isLarge } = useAppContext()
  const today = startOfDay(new Date())
  const [paginationLength, setPaginationLength] = useState(0)
  const [filtered, setFiltered] = useState(null)
  const [calendarStartDate, setCalendarStartDate] = useState(today)
  const elRef = useRef()
  const { height } = useElementSize(elRef, true, paginationLength)
  const data = usePreviewData(staticData)
  const { sections } = useParseSiteData(data)
  const offsetY = isLarge ? STICKY_Y_OFFSET : 0

  const title = get(data, 'data.header_title.text')
  const items = get(data, 'items', [])
  const [itemsSubset, setItemsSubset] = useState(items)

  const availableDates = useMemo(
    () =>
      items.reduce((acc, item) => {
        const dates = get(item, 'grid_content.document.data.dates', [])
        dates.forEach(({ date }) => {
          acc.push(new Date(date))
        })

        return acc
      }, []),
    [items],
  )

  const onDatePickerChange = useCallback(
    async date => {
      if (date instanceof Date) {
        setPaginationLength(0)
        setCalendarStartDate(date)
        await wait(200)
        goToDay(date)
      } else setCalendarStartDate(today)
    },
    [today],
  )

  const goToDay = async date => {
    const isBrowser = typeof document !== 'undefined'
    if (isBrowser) {
      const ISOStamp = new Date(formatISO(new Date(date)))
      const dateId = `date${startOfDay(ISOStamp).getTime()}`
      let el = document.getElementById(dateId)
      if (el) {
        if (el.previousSibling) el = el.previousSibling
        el.scrollIntoView(true, {
          behavior: 'smooth',
        })
      }
    }
  }

  const filterDays = e => {
    const val = e === ALL_EVENTS ? null : e
    setFiltered(val)
  }

  const filteredDays = items.filter(item => {
    const tags = get(item, 'grid_content.document.tags', [])
    return !!filtered ? tags.includes(filtered) : true
  })

  const days = useMemo(() => {
    return filteredDays.reduce((acc, item) => {
      const dates = get(item, 'grid_content.document.data.dates', [])
      dates.forEach(({ date }) => {
        const dateObj = new Date(date)
        const calendarStartISO = new Date(
          formatISO(new Date(calendarStartDate)),
        )
        const firstTime = startOfDay(new Date(date)).toString()
        if (
          isAfter(dateObj, subWeeks(calendarStartISO, 1)) ||
          isToday(dateObj, subWeeks(calendarStartISO, 1))
        ) {
          acc[firstTime] = acc[firstTime] || []
          acc[firstTime].push(item)
        }
      })
      return acc
    }, {})
  }, [filteredDays, calendarStartDate])

  // Sort days
  const dateList = Object.keys(days).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime(),
  )

  // Handle chunking
  let dayListChunks = chunk(dateList, CHUNKS)
  const chunksLength = get(dayListChunks, 'length')
  const handleShowMore = () => {
    const len = paginationLength + 1
    setPaginationLength(len)
  }

  if (chunksLength) {
    dayListChunks = dayListChunks.filter((chunk, i) => i <= paginationLength)
  }

  const flatDateList = chunksLength ? flatten(dayListChunks) : []

  const filterDates = flatDateList.map((value, i) => ({
    label: format(new Date(value), "E',' MMM d"),
    value: i,
  }))

  const filterTags = uniq(
    flatten(
      itemsSubset.map(item => get(item, 'grid_content.document.tags'), []),
    ),
  ).map(value => ({
    label: value,
    value,
  }))

  const Wrapper = isLarge ? Sticky : Fragment
  const wrapperProps = isLarge
    ? {
        innerZ: 4,
        top: 70,
        bottomBoundary: height,
      }
    : {}

  return (
    <Page {...sections}>
      <TopBg noPaddingTop noPaddingBottom>
        <div>
          <Text.h1 center lineHeightMultiplier={1.1}>
            {title}
          </Text.h1>
        </div>
      </TopBg>
      <Section noPaddingTop noPaddingBottom>
        <Content>
          <div ref={elRef}>
            <Wrapper {...wrapperProps}>
              <Filters>
                {filterDates && !!filterDates.length && (
                  <DatePicker
                    onChange={onDatePickerChange}
                    includeDates={availableDates}
                  />
                )}
                {filterTags && !!filterTags.length && (
                  <Dropdown
                    activeLabel={filtered}
                    defaultLabel={ALL_EVENTS}
                    defaultItemLabel={ALL_EVENTS}
                    items={filterTags}
                    onChange={filterDays}
                  />
                )}
              </Filters>
            </Wrapper>

            {!!chunksLength &&
              flatDateList.map((key, i) => {
                const dayItems = get(days, key)
                return (
                  <EventCalendarDayItem
                    key={`date${i}`}
                    index={i}
                    date={key}
                    items={dayItems}
                    height={height + offsetY}
                    filter={filtered}
                  />
                )
              })}
          </div>
          {paginationLength < chunksLength - 1 && (
            <Button underline onClick={handleShowMore}>
              Show more
            </Button>
          )}
        </Content>
      </Section>
    </Page>
  )
}

EventCalendarPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default EventCalendarPage

const TopBg = styled(Section)`
  ${({ theme }) => css`
    background-color: ${theme.color.softBlue};

    padding-top: ${theme.space(3)};
    padding-bottom: ${theme.space(4)};

    > div {
      ${theme.media.lg`
        padding-top: ${theme.space(1.5)};
        padding-bottom: ${theme.space(5)};
      `};

      ${theme.media.xl`
        padding-bottom: ${theme.space(8.5)};
      `};
    }
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    top: 0;
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.xl`
     padding-bottom: ${theme.space(5)};
    `};

    > ${Text.h1} {
      margin-top: ${theme.space(2)};
      margin-bottom: ${theme.space(5)};

      ${theme.media.xl`
        margin-bottom: ${theme.space(8)};
      `};
    }

    > div {
      width: 100%;
    }

    > button {
      margin-top: ${theme.space(1.5)};
      margin-bottom: ${theme.space(5)};

      ${theme.media.lg`
        margin-top: ${theme.space(3)};
        margin-bottom: ${theme.space(1)};
      `};
    }
  `};
`

const Filters = styled.div`
  ${({ theme }) => css`
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;

    padding: ${theme.space(2)} ${theme.space(1)} 0;

    ${theme.media.lg`
      border-bottom: 1px solid ${theme.color.rule};
      display: flex;
      justify-content: space-between;
      padding: ${theme.space(2)} 0 0;
    `};

    > div > button {
      width: 100%;
      height: ${rem(42)};

      ${theme.media.lg`
        width: ${rem(300)};
      `};
    }

    > div:first-child {
      margin-bottom: ${theme.space(2.5)};
    }
  `};
`
