import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import AlphaGradient, { AlphaGradientCorner } from '../../common/AlphaGradient'
import AspectRatioBox from '../../common/AspectRatioBox'
import Text from '../../common/Text'
import ReactHtmlParser from 'react-html-parser'
import { StyledButton } from '../../common/Button'
import { first, get } from 'lodash'
import getPath from '../../../../lib/getPath'
import { rem } from 'polished'
import { motion } from 'framer-motion'
import { format } from 'date-fns'

import {
  ButtonContainer,
  Image,
  ImagePositioner,
  Item,
  TextContainer,
  TextTitle,
  transform,
} from './GridItemCommon'
import { BLACKLIST_TAGS, PostTag, Top } from './PostGridItem'
import { useAppContext } from '../../../context/AppContext'
import linkResolver from '../../../utils/linkResolver'
// Aspect Ratio
const WIDTH = 69
const HEIGHT = 43

const FeaturedPostGridItem = ({ item, style }) => {
  const { isMedium } = useAppContext()
  const data = get(item, 'grid_content.document.data')
  const tag = first(
    get(item, 'grid_content.document.tags', []).filter(
      v => !BLACKLIST_TAGS.includes(v.toLowerCase()),
    ),
  )

  const image = get(data, 'image')
  const title = get(data, 'card_title.html')
  const label = get(data, 'button_label')

  const dateRaw = get(data, 'date')
  const date = dateRaw && format(new Date(dateRaw), "MMM d',' y").toUpperCase()

  const width = !isMedium ? 34 : WIDTH
  const height = !isMedium ? 44 : HEIGHT

  const buttonLink = {
    href: linkResolver(data, 'button_link') || getPath({ data }),
    target: get(data, 'button_link.target'),
  }

  const buttonContent = (
    <ButtonContainer>
      {buttonLink.href && (
        <StyledButton {...buttonLink}>{label || 'Read more'}</StyledButton>
      )}
    </ButtonContainer>
  )

  const imageContent = (
    <>
      <ImagePositioner>
        <AspectRatioBox absolute width={width} height={height}>
          {image && <Image src={image.url} alt={title || ''} />}
        </AspectRatioBox>
      </ImagePositioner>
      <AspectRatioBox
        className="img-spacer"
        absolute
        width={width}
        height={height}
      >
        <AlphaGradient height={isMedium ? 100 : 150} />
      </AspectRatioBox>
    </>
  )
  const textContent = (
    <TextContent>
      <Top>
        {date && (
          <Text size="xs" uppercase medium>
            {date}
          </Text>
        )}
        {tag && (
          <PostTag>
            <Text bold>{tag.toUpperCase()}</Text>
          </PostTag>
        )}
      </Top>
      <TextContainer>
        <Text.h5 white>FEATURED ARTICLE</Text.h5>
        {title && (
          <TextTitle html>{ReactHtmlParser(title, { transform })}</TextTitle>
        )}
      </TextContainer>
      {buttonContent}
    </TextContent>
  )

  return (
    <Item>
      <Content styleType={style}>
        {imageContent}
        <AlphaGradientCorner transparent opacity={0.5} />
        {textContent}
      </Content>
    </Item>
  )
}

FeaturedPostGridItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.string,
}

export default FeaturedPostGridItem

const TextContent = styled.div`
  ${({ theme, styleType }) => css`
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 ${theme.space(2.5)};

    ${theme.media.xl`
      padding: 0 ${theme.space(5)};


    `}

    ${TextContainer} {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      ${TextTitle} * {
        font-size: ${rem(22)};
        padding-top: ${theme.space(1.5)};

        ${theme.media.xl`
          font-size: ${rem(32)};

        `}
      }

      h5 {
        margin-bottom: 0;
      }

      ${Text} {
        text-align: left;
      }
    }
  `}
`

const Content = styled(motion.div)`
  ${({ theme, styleType }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: ${rem(370)};
    height: 100%;
    border-top: 3px solid ${theme.color.yellow};

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    background: ${theme.color.blue};

    ${ImagePositioner} {
      position: absolute;
      top: 0;
      left: 0;
    }

    && {
      h4 {
        color: #fff;
        margin-bottom: 0;
      }

      p {
        color: ${theme.color.secondaryLightBlue};
        font-size: ${rem(14)};
        line-height: ${rem(20)};
        ${theme.media.lg`
          font-size: ${rem(13)};
          line-height: ${rem(19)};

        `};
      }
    }

    ${Top} {
      padding-top: ${theme.space(2)};
      ${Text} {
        color: ${theme.color.secondaryLightBlue};
      }
    }

    ${ButtonContainer} {
      justify-content: flex-start;
      padding: 0;
      margin: ${theme.space(4)} 0 ${theme.space(2.5)};

      ${StyledButton} {
        max-width: ${rem(132)};
      }
    }

    .img-spacer {
      display: block;
    }
  `};
`
