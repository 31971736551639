import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text from '../common/Text'

import { format } from 'date-fns'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import decodeBodyHtml from '../../utils/decodeBodyHtml'
import { transformLinks } from '../../utils/textTransforms'

const PostWysiwygModule = ({ date, primary: data }) => {
  const image = {
    src: get(data, 'post_image.url'),
    alt: get(data, 'post_image.alt'),
  }
  const body = get(data, 'post_body.html')
  const imageCredit = get(data, 'image_credit')

  const decodedBody = useMemo(() => decodeBodyHtml(body), [body])

  return (
    <SliceSection bgColor="softBlue">
      <Container>
        {image && image.src && (
          <ImageContainer>
            <img alt="" {...image} />
            {imageCredit && (
              <Text
                size="xxs"
                lg="xs"
                lineHeight={rem(18)}
                lineHeightLg={rem(20)}
                right
                color="neutralBlue"
              >
                {imageCredit}
              </Text>
            )}
          </ImageContainer>
        )}

        {date && (
          <DateText uppercase size="xs" medium>
            {format(new Date(date), "MMM dd',' yyyy")}
          </DateText>
        )}

        {body && (
          <StyledText html htmlMargin>
            {ReactHtmlParser(decodedBody, { transform: transformLinks })}
          </StyledText>
        )}
      </Container>
    </SliceSection>
  )
}

PostWysiwygModule.propTypes = {
  date: PropTypes.string,
  primary: PropTypes.object,
}

export default PostWysiwygModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;

    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidthArticle};

    margin-bottom: ${theme.space(2)};

    ${theme.media.lg`
      margin-bottom: ${theme.space(4)};
    `};

    > div:not(:first-child) {
      width: 100%;
      max-width: ${theme.layout.maxWidthPost};
      padding: 0 ${theme.space(2)};

      ${theme.media.lg`
         padding: 0;
      `};
    }

    ${theme.media.lg`
        flex-direction: row;
      `};
  `};
`

const ImageContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.space(4)};

    ${theme.media.lg`
      margin-bottom: ${theme.space(6)};
    `};

    > img {
      border-radius: ${theme.layout.borderRadius};
    }

    > div {
      margin-top: ${theme.space(0.5)};
    }
  `};
`

const StyledText = styled(Text)`
  ${({ theme }) => css`
    margin: 0 auto;

    blockquote {
      max-width: none;

      ${theme.media.md`
        float: none;
        width: 100%;
      `};

      border-left: none;
      padding: 0;
      margin: ${theme.space(3)} 0;

      ${theme.media.lg`
        padding: 0 10%;
      `};
    }

    cite {
      color: ${theme.color.secondaryBlue};
      margin-top: -${theme.space(1)};
      margin-bottom: ${theme.space(3)};

      ${theme.media.lg`
        padding: 0 10%;
      `};
    }
  `};
`

const DateText = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${theme.space(3)};
    margin-left: ${rem(20)};

    ${theme.media.lg`
        display: none;
      `};
  `};
`
