const {
  DINING_DETAIL_PAGE,
  DINING_MENU_PAGE,
  EVENT_DETAIL_PAGE,
  EVENT_VENUE_DETAIL_PAGE,
  FORM_PAGE,
  HOME_PAGE,
  LANDING_PAGE,
  LISTING_PAGE,
  RESTAURANT_LANDING_PAGE,
  POKER_ROOM_LANDING_PAGE,
  POST_DETAIL_PAGE,
  POST_LISTING_PAGE,
  PROMO_DETAIL_PAGE,
  EVENT_CALENDAR_PAGE,
} = require('../../lib/constants')

const DAYS = [
  { label: 'SUN', index: 0 },
  { label: 'MON', index: 1 },
  { label: 'TUE', index: 2 },
  { label: 'WED', index: 3 },
  { label: 'THU', index: 4 },
  { label: 'FRI', index: 5 },
  { label: 'SAT', index: 6 },
]

const TEMPLATES = {
  [DINING_DETAIL_PAGE]: require.resolve(
    '../components/templates/DiningDetailPage',
  ),
  [DINING_MENU_PAGE]: require.resolve('../components/templates/DiningMenuPage'),
  [EVENT_CALENDAR_PAGE]: require.resolve(
    '../components/templates/EventCalendarPage',
  ),
  [EVENT_DETAIL_PAGE]: require.resolve(
    '../components/templates/EventAndPromoDetailPage',
  ),
  [EVENT_VENUE_DETAIL_PAGE]: require.resolve(
    '../components/templates/EventVenueDetailPage',
  ),
  [FORM_PAGE]: require.resolve('../components/templates/FormPage'),
  [HOME_PAGE]: require.resolve('../components/templates/HomePage'),
  [LANDING_PAGE]: require.resolve('../components/templates/LandingPage'),
  [LISTING_PAGE]: require.resolve('../components/templates/ListingPage'),
  [RESTAURANT_LANDING_PAGE]: require.resolve(
    '../components/templates/RestaurantLandingPage',
  ),
  [POST_DETAIL_PAGE]: require.resolve('../components/templates/PostDetailPage'),
  [POST_LISTING_PAGE]: require.resolve(
    '../components/templates/PostListingPage',
  ),
  [POKER_ROOM_LANDING_PAGE]: require.resolve(
    '../components/templates/PokerRoomLandingPage',
  ),
  [PROMO_DETAIL_PAGE]: require.resolve(
    '../components/templates/EventAndPromoDetailPage',
  ),
}

const DEFAULT_TEMPLATE = TEMPLATES[LANDING_PAGE]

const PREVIEW_TEMPLATE = require.resolve('../components/templates/PreviewPage')
const NOT_FOUND_TEMPLATE = require.resolve(
  '../components/templates/NotFoundPage',
)
const UNPUBLISHED_PREVIEW_TEMPLATE = require.resolve(
  '../components/templates/UnpublishedPreviewPage',
)

exports.DEFAULT_MAX_FEATURED_CARDS = 4

exports.TEMPLATES = TEMPLATES
exports.DEFAULT_TEMPLATE = DEFAULT_TEMPLATE
exports.PREVIEW_TEMPLATE = PREVIEW_TEMPLATE
exports.UNPUBLISHED_PREVIEW_TEMPLATE = UNPUBLISHED_PREVIEW_TEMPLATE
exports.DAYS = DAYS
exports.NOT_FOUND_TEMPLATE = NOT_FOUND_TEMPLATE
