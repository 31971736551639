const { POST_DETAIL_PAGE } = require('./constants')
const getPath = require('./getPath')
const { compareDesc, startOfDay } = require('date-fns')
const { get, find, reduce } = require('lodash')

const sort = (a, b) => {
  const aDate = get(a, 'data.date', a.first_publication_date)
  const bDate = get(b, 'data.date', b.first_publication_date)
  return compareDesc(new Date(aDate), new Date(bDate))
}

const getPostDetailPages = allPages => {
  const postDetailPages = allPages.filter(
    ({ type }) => type === POST_DETAIL_PAGE,
  )

  postDetailPages.sort(sort)

  return postDetailPages
}

const getPostDetailsByPostListing = allPages => {
  const postDetailPages = getPostDetailPages(allPages)

  const posts = postDetailPages.reduce((acc, post) => {
    const relatedPostListingUid = get(post, 'data.related_blog.document.uid')
    const postListing = find(allPages, { uid: relatedPostListingUid })
    if (postListing) {
      if (!acc[postListing.uid]) acc[postListing.uid] = []
      acc[postListing.uid] = [...acc[postListing.uid], post]
    }

    return acc
  }, {})

  return posts
}

const injectPostListingWithPostDetailPages = allPages => {
  const posts = getPostDetailsByPostListing(allPages)

  Object.keys(posts).forEach(key => {
    const postListing = find(allPages, { uid: key })
    postListing.blogPosts = []
    if (postListing && posts[key]) {
      posts[key].sort(sort)
      postListing.blogPosts = posts[key].map(mapToGridItem)
    }
  })

  return [...allPages]
}

const mapToGridItem = page => ({
  grid_content: {
    document: {
      uid: get(page, 'uid'),
      tags: get(page, 'tags'),
      data: {
        path: get(page, 'data.path'),
        card_body: get(page, 'data.card_body'),
        card_title: get(page, 'data.card_title'),
        image: get(page, 'data.image'),
        button_label: get(page, 'data.button_label'),
        button_link: get(page, 'data.button_link'),
        extra_links: get(page, 'data.extra_links'),
        badge: get(page, 'data.badge'),
        date: get(page, 'data.date'),
      },
    },
  },
})

const injectBlogGridModulesWithPostDetailPages = allPages => {
  const posts = getPostDetailsByPostListing(allPages)

  Object.keys(posts).forEach(key => {
    allPages.forEach(({ data: { body } }) => {
      if (body) {
        body.forEach(slice => {
          if (slice.slice_type === 'blog_grid_module') {
            const relatedBlogUid = get(
              slice,
              'primary.related_blog.document.uid',
            )
            if (relatedBlogUid && relatedBlogUid === key) {
              slice.items = posts[key].map(mapToGridItem)
            }
          }
        })
      }
    })
  })

  return [...allPages]
}

const injectPostDetailsWithPagination = allPages => {
  const posts = getPostDetailsByPostListing(allPages)

  const paginationByPostListing = reduce(
    posts,
    (acc, postList, key) => {
      const links = postList.map(node => ({
        href: getPath(node),
        uid: node.uid,
      }))
      acc[key] = links
      return acc
    },
    {},
  )

  allPages.forEach(page => {
    const relatedPostListingUid = get(page, 'data.related_blog.document.uid')
    if (
      relatedPostListingUid &&
      paginationByPostListing[relatedPostListingUid]
    ) {
      page.pagination = paginationByPostListing[relatedPostListingUid] || []
      page.pagination.sort(sort)
      page.relatedPosts = []
      if (posts[relatedPostListingUid] && posts[relatedPostListingUid].length) {
        page.relatedPosts = posts[relatedPostListingUid].map(mapToGridItem)
      }
    }
  })

  return [...allPages]
}

const pruneOldEntriesForPreview = (pages, nMostRecent = 20) => {
  const postDetailPages = getPostDetailPages(pages).slice(0, nMostRecent)

  const otherPages = pages.filter(({ type }) => {
    return type !== POST_DETAIL_PAGE
  })

  return [...otherPages, ...postDetailPages]
}

module.exports = {
  injectPostDetailsWithPagination,
  injectPostListingWithPostDetailPages,
  injectBlogGridModulesWithPostDetailPages,
  pruneOldEntriesForPreview,
}
