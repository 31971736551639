import React from 'react'
import PropTypes from 'prop-types'

import {
  INSTAGRAM_MODULE,
  TWITTER_MODULE,
  GRID_MODULE,
  TAG_GRID_MODULE,
  FEATURE_MODULE_2_UP,
  FEATURE_MODULE_3_UP,
  FEATURE_MODULE_4_UP,
  SECTION_TITLE_MODULE,
  BLOG_GRID_MODULE,
  SIGN_UP_BAR,
  TICKER_MODULE,
  FAQ_MODULE,
  INTRO_MODULE,
} from '../constants/sliceTypes'

import { HOME_PAGE } from '../../lib/constants'

import ErrorBoundary from './common/ErrorBoundary'
import Text from './common/Text'

import slices from '../components/slices/'
import { get, set } from 'lodash'

import styled from 'styled-components'

const GRID_MODULE_TYPES = [
  GRID_MODULE,
  TAG_GRID_MODULE,
  FEATURE_MODULE_2_UP,
  FEATURE_MODULE_3_UP,
  FEATURE_MODULE_4_UP,
  BLOG_GRID_MODULE,
  FAQ_MODULE,
]

const NotFound = ({ children, slice_type }) => (
  <Container>
    <Text size="xs" center color="red" lineHeight={2}>
      <b>{slice_type}</b> not found.
      {children}
    </Text>
  </Container>
)

NotFound.propTypes = {
  children: PropTypes.node,
  slice_type: PropTypes.string,
}

const isGrid = sliceType => GRID_MODULE_TYPES.includes(sliceType)

const isTitle = sliceType => sliceType === SECTION_TITLE_MODULE

const isDiffColor = (c1, c2) => c1 && c2 && c1 !== c2

const componentRenderer = (
  data,
  {
    filter,
    filterIn,
    social,
    extraProps,
    noPaddingTop,
    noPaddingBottom,
    pageType,
    forceBackground,
  },
) => {
  let body = get(data, 'body') || []

  // Filter out by slice_type
  if (filter && !!filter.length) {
    body = body.filter(item => {
      const includes = filter.includes(item.slice_type)
      return filterIn ? includes : !includes
    })
  }

  return (
    <>
      {body &&
        !!body.length &&
        body.map((slice, i) => {
          if (forceBackground && typeof forceBackground === 'string')
            set(slice, 'primary.background_color', forceBackground)

          const type = get(slice, '__typename')

          const prevSliceType = get(body, `[${i - 1}].slice_type`)
          const prevSliceColor = get(
            body,
            `[${i - 1}].primary.background_color`,
          )

          const sliceType = get(slice, 'slice_type')
          const sliceColor = get(slice, 'primary.background_color')

          const nextSliceType = get(body, `[${i + 1}].slice_type`)
          const nextSliceColor = get(
            body,
            `[${i + 1}].primary.background_color`,
          )

          if (sliceType) {
            const { id, ...rest } = slice
            const Slice = get(slices, sliceType) || NotFound

            let socialProps = {}
            if (sliceType === INSTAGRAM_MODULE) {
              socialProps = { instagram: get(social, 'instagram') }
            } else if (sliceType === TWITTER_MODULE) {
              socialProps = { twitter: get(social, 'twitter') }
            }

            const paddingProps = {
              top: 0.5,
              bottom: 0.5,
            }

            if (isTitle(sliceType) && isGrid(nextSliceType)) {
              paddingProps.bottom = 0.25
            }
            if (isGrid(sliceType) && isTitle(prevSliceType)) {
              paddingProps.top = 0.25
            }

            if (
              isDiffColor(prevSliceColor, sliceColor) ||
              prevSliceType === SIGN_UP_BAR
            ) {
              paddingProps.top = 1
            }
            if (
              isDiffColor(sliceColor, nextSliceColor) ||
              nextSliceType === SIGN_UP_BAR
            ) {
              paddingProps.bottom = 1
            }

            if (i === body.length - 1 && noPaddingBottom !== false) {
              paddingProps.bottom = 1
            }

            if (noPaddingTop && i === 0) {
              paddingProps.top = 0
            }

            if (noPaddingBottom && i === body.length - 1) {
              paddingProps.bottom = 0
            }

            if (sliceType === TICKER_MODULE && pageType === HOME_PAGE) {
              paddingProps.top = '20px'
            } else if (sliceType === TICKER_MODULE) {
              paddingProps.top = '20px'
              paddingProps.bottom = '20px'
            }

            if (sliceType === INTRO_MODULE && nextSliceType === TICKER_MODULE) {
              paddingProps.bottom = 0
            }

            return (
              <ErrorBoundary key={id} label={sliceType}>
                <Slice
                  index={i}
                  {...rest}
                  {...socialProps}
                  {...extraProps}
                  paddingProps={paddingProps}
                />
              </ErrorBoundary>
            )
          } else if (type) {
            return (
              <ErrorBoundary key={`type${i}`} label={slice.type}>
                <NotFound slice_type={sliceType}>
                  <div>(Add query to schema.)</div>
                </NotFound>
              </ErrorBoundary>
            )
          }
          return null
        })}
    </>
  )
}

export default componentRenderer

const Container = styled.div`
  display: flex;
  justify-content: center;

  > div {
    padding: 1rem 2rem;
    margin: 1rem;
    background: #fff;
  }
`
