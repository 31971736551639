import React from 'react'
import PropTypes from 'prop-types'

import Button from '../common/Button'
import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text from '../common/Text'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'
import linkResolver from '../../utils/linkResolver'

const StaggeredTextModule = ({ items, primary, ...props }) => {
  const { isLarge } = useAppContext()
  const column1 = items.filter((item, i) => i % 2 === 0)
  const column2 = items.filter((item, i) => i % 2 !== 0)

  const bgColor = get(primary, 'background_color') || 'transparent'

  const renderColumn = (item, i, key) => {
    const categories = get(item, 'category')
    const title = get(item, 'title_staggered.text')
    const body = get(item, 'body_staggered.html')
    const label = get(item, 'button_label_staggered')
    const link = {
      href: linkResolver(item, 'button_link_staggered'),
      target: get(item, 'button_link_staggered.target'),
    }
    const validLink = !!(link && link.href)

    return (
      <Item key={`${key}-${i}`}>
        <TextContainer>
          {categories &&
            categories.split(',').map((c, i) => (
              <Pill key={`t${i}`}>
                <Text.h6 white uppercase>
                  {c}
                </Text.h6>
              </Pill>
            ))}
          {title && <Text.h2 html>{title}</Text.h2>}
          {body && (
            <Text color="neutralBlue" html>
              {ReactHtmlParser(body)}
            </Text>
          )}
        </TextContainer>
        {validLink && (
          <ButtonContainer>
            <Button inline {...link}>
              {label || 'Read more'}
            </Button>
          </ButtonContainer>
        )}
      </Item>
    )
  }

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Grid>
        <Column>
          {column1 && column1.map((item, i) => renderColumn(item, i, 'one'))}
          {!isLarge &&
            column2 &&
            column2.map((item, i) => renderColumn(item, i, 'two'))}
        </Column>
        {isLarge && (
          <>
            <Spacer />
            <ColumnRight>
              {column2 &&
                column2.map((item, i) => renderColumn(item, i, 'two'))}
            </ColumnRight>
          </>
        )}
      </Grid>
    </SliceSection>
  )
}

StaggeredTextModule.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
}

export default StaggeredTextModule

const Grid = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${rem(1165)};

    ${theme.media.lg`
        padding: 0 ${theme.space(4)};
    `};

    ${theme.media.lg`
        display: flex;
    `};
  `};
`

const Column = styled.div`
  ${({ theme }) => css`
    width: 100%;

    ${theme.media.lg`
      width: 50%;
    `};
  `};
`

const ColumnRight = styled(Column)`
  padding-top: ${({ theme }) => theme.space(15)};
`

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-top: ${theme.space(2)};

    ${theme.media.lg`
      margin-top: ${theme.space(3)};
    `};
  `};
`

const TextContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    flex: 1;

    > ${Text} {
      margin-top: ${theme.space(1.5)};

      ${theme.media.lg`
        margin-top: ${rem(12)};
      `};
    }

    z-index: 2;
  `};
`

const Spacer = styled.div`
  ${({ theme }) => css`
    width: 10vw;
    height: 100%;
    flex-shrink: 0;

    ${theme.media.lg`
        width: 15vw;
    `};

    ${theme.media.xxl`
        width: 20%;
    `};
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;

    &:not(:first-child) {
      margin-top: ${theme.space(4)};

      ${theme.media.lg`
        margin-top: ${theme.space(15)};
    `};
    }
  `};
`
const Pill = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;

    padding: 0 ${theme.space(1)};
    height: ${rem(22)};

    background-color: ${theme.color.accentBlue};
    margin-bottom: ${rem(11)} !important;
    border-radius: 30px;
  `}
`
