import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Picture, Plus, PlusMinus } from '../../common/Icons'

import ReactHtmlParser from 'react-html-parser'
import Text from '../../common/Text'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem, transparentize } from 'polished'
import { useAppContext } from '../../../context/AppContext'
import { motion } from 'framer-motion'
import { Tag, TagGroup } from '../../common/Tags'

const DEFAULT_STATE = false

const MenuSection = ({
  index,
  items,
  last,
  section_title,
  section_sub_title,
  setHoverImage,
}) => {
  const { isLarge, focusStyleOn } = useAppContext()
  const [open, setOpen] = useState(DEFAULT_STATE)
  const toggle = () => setOpen(!open)
  const title = get(section_title, 'text')
  const subTitle = get(section_sub_title, 'text')

  useEffect(() => {
    if (isLarge && open !== DEFAULT_STATE) setOpen(DEFAULT_STATE)
  }, [isLarge, open])

  return (
    <Container tabIndex={-1}>
      <div tabIndex={-1}>
        <Title
          first={index === 0}
          showFocus={focusStyleOn}
          onClick={isLarge ? null : toggle}
          tabIndex={isLarge ? -1 : 0}
        >
          <div>
            {title && (
              <Text.h2 center={isLarge} onClick={toggle}>
                {title}
              </Text.h2>
            )}
            {!isLarge && subTitle && (
              <Text.h6 center={isLarge}>{subTitle}</Text.h6>
            )}
          </div>
          {!isLarge && (
            <Toggle open={open}>
              <PlusMinus key={open} width={16} height={16} open={open} />
            </Toggle>
          )}
        </Title>
        {isLarge && subTitle && (
          <StyledH5 center={isLarge}>{subTitle}</StyledH5>
        )}

        <Grid
          key={`content${isLarge}`}
          initial="collapsed"
          animate={isLarge || open ? 'open' : 'collapsed'}
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: {
              opacity: 0,
              height: 0,
              pointerEvents: isLarge ? 'auto' : 'none',
            },
          }}
          transition={{
            duration: 0.4,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
        >
          {items &&
            !!items.length &&
            items.map((item, i) => {
              const data = get(item, 'primary')
              const itemName = get(data, 'item_name.text')
              const desc = get(data, 'item_description.html')
              let price = get(data, 'item_price')
              if (price) price = price.replace(/\|/g, '<span>|</span>')

              let additions = get(item, 'items')
              if (additions && !!additions.length) {
                additions = additions.filter(item => get(item, 'addon_name'))
              }
              const hasAddons = additions && !!additions.length

              const image = {
                src: get(data, 'item_image.url'),
                alt: get(data, 'item_image.alt'),
              }
              let tags = get(data, 'tags')
              if (tags) tags = tags.split(',')

              return (
                <MenuItem key={`el${i}`}>
                  <MenuItemHeader marginBottom={!!(desc || hasAddons)}>
                    {itemName && (
                      <Text
                        className="menu-item"
                        color="secondaryBlue"
                        size={14}
                        uppercase
                      >
                        <b>{itemName}</b>
                        {image && image.src && (
                          <button
                            index={-1}
                            onClick={() => setHoverImage(item)}
                            onMouseEnter={() => {
                              if (isLarge) setHoverImage(item)
                            }}
                            onMouseLeave={() => {
                              if (isLarge) setHoverImage(null)
                            }}
                          >
                            <Picture />
                          </button>
                        )}
                      </Text>
                    )}
                    {price && (
                      <Text
                        className="price"
                        color="secondaryBlue"
                        size={14}
                        medium
                      >
                        {ReactHtmlParser(price)}
                      </Text>
                    )}
                  </MenuItemHeader>

                  {desc && (
                    <Text
                      size="xxs"
                      lg={13}
                      lineHeight={rem(18)}
                      lineHeightLg={rem(20)}
                    >
                      {ReactHtmlParser(desc)}
                    </Text>
                  )}

                  {hasAddons && (
                    <AddonItem>
                      {additions.map((addItem, i) => {
                        const addonName = get(addItem, 'addon_name')
                        const addonPrice = get(addItem, 'addon_price')
                        return addonName ? (
                          <Text
                            key={`ai${i}`}
                            size="xxs"
                            lg={13}
                            lineHeight={rem(18)}
                            lineHeightLg={rem(20)}
                          >
                            <AddonHeader>
                              <Plus />
                              {addonName && <div>{addonName}</div>}
                            </AddonHeader>

                            {addonPrice && (
                              <Text size={13} lineHeight={rem(20)}>
                                <b>{addonPrice}</b>
                              </Text>
                            )}
                          </Text>
                        ) : null
                      })}
                    </AddonItem>
                  )}

                  {tags && !!tags.length && (
                    <TagGroup css={{ marginTop: '10px' }}>
                      {tags.map((tag, i) => {
                        return (
                          <Tag key={tag + i} color="accentBlue">
                            {tag}
                          </Tag>
                        )
                      })}
                    </TagGroup>
                  )}
                </MenuItem>
              )
            })}
        </Grid>
        {!isLarge && !open && !last && <Rule />}
        {!isLarge && open && last && <Spacer />}
      </div>
    </Container>
  )
}

MenuSection.propTypes = {
  index: PropTypes.number,
  items: PropTypes.array,
  last: PropTypes.bool,
  section_title: PropTypes.object,
  section_sub_title: PropTypes.object,
  setHoverImage: PropTypes.func.isRequired,
}

export default MenuSection

const Spacer = styled.div`
  ${({ theme }) => css`
    height: ${theme.space(3)};
  `};
`

const StyledH5 = styled(Text.h5)`
  ${({ theme }) => css`
    margin-top: ${rem(3)};
  `};
`

const Rule = styled.hr`
  ${({ theme }) => css`
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 1px;
    background: ${theme.color.rule};
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    background-color: #fff;
    padding-top: 0;
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    outline: none;
    box-shadow: none;

    > div {
      outline: none;
      box-shadow: none;
    }

    > div > h6 {
      margin-top: ${theme.space(1)};
    }

    ${theme.media.lg`
      &:not(:first-child) {
        margin-top: ${theme.space(6.5)};
      }

      &:first-child {
        margin-top: -${theme.space(1.5)};
      }

      &:last-child {
        margin-bottom: ${theme.space(6)};
      }
   `};
  `};
`

const MenuItem = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space(2)};
    border: 2px solid ${transparentize(0.2, theme.color.rule)};
    border-radius: ${theme.layout.borderRadius};

    ${theme.media.lg`
      padding: ${theme.space(2)} ${theme.space(3)};
      height: 100%;
    `};

    &:not(:last-child) {
      margin-bottom: ${theme.space(1)};

      ${theme.media.lg`
        margin-bottom: 0;
      `};
    }
  `};
`

const MenuItemHeader = styled.div`
  ${({ marginBottom, theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${marginBottom ? theme.space(1) : 0};

    span {
      color: ${theme.color.ruleAlt};
    }

    button {
      display: inline;
      position: relative;
      cursor: pointer;
      transform: translateY(${rem(1)});
      pointer-events: all;

      &:after {
        content: '';
        position: absolute;
        top: -${theme.space(0.25)};
        left: 0;
        width: 100%;
        height: calc(100% + ${theme.space(0.5)});
        background-color: rgba(0, 0, 0, 0);
      }
    }

    > div {
      position: relative;

      &.menu-item {
        margin-right: ${theme.space(2)};
      }
    }
  `};
`

const Grid = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    ${theme.media.md`
      display: grid;
      grid-template-columns: repeat(2, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};

    ${theme.media.lg`
      margin-top: ${theme.space(3)};
      grid-template-columns: repeat(3, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};
  `};
`

const AddonItem = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space(1)};

    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: ${theme.space(0.25)} 0;

      ${theme.media.lg`
        padding: ${theme.space(0.5)} 0;
      `};

      > div {
        &:last-child {
          margin-left: ${theme.space(2)};
        }
      }
    }
  `};
`

const AddonHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    svg {
      transform: translateY(${rem(4)});
      align-self: flex-start;
      flex-shrink: 0;
      margin-right: ${theme.space(0.5)};
    }
  `};
`

const Title = styled.div`
  ${({ first, theme }) => css`
    display: flex;
    align-items: center;
    padding: ${rem(24)} 0;

    ${first &&
      css`
        padding-top: 0;
      `};

    &:focus {
      outline: none;
      box-shadow: none;
    }

    ${theme.mixin.afterFocus};

    position: relative;
    display: flex;
    justify-content: space-between;

    ${theme.media.lg`
      display: block;
      pointer-events: none;
      padding: 0;
   `};
  `};
`

const Toggle = styled.button`
  ${({ open, theme }) => css`
    ${theme.mixin.opacityHover};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${open ? theme.color.softBlue : 'transparent'};
    width: ${rem(32)};
    height: ${rem(32)};
    border-radius: ${rem(32)};
    cursor: pointer;

    svg {
      min-width: ${rem(16)};
      min-height: ${rem(16)};
    }
  `};
`
