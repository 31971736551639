import { get } from 'lodash'
import { addHours, addMinutes, startOfDay, endOfDay } from 'date-fns'
import { buildUrl } from '../utils/calendarLinks'

export default (
  addToCal,
  time,
  date,
  location,
  title,
  body,
  isMobile,
  url,
  endDate,
  dates,
  recurring,
  onlyOnDays,
) => {
  if (addToCal) {
    let scheduledDate = startOfDay(date)
    // console.log('scheduledDate', scheduledDate)
    // console.log('endDate', endDate)
    // console.log(dates)
    // console.log('time', time);
    if (time) {
      const timeArguments = /(\d{1,2}):?(\d{2})?(pm|am)/i.exec(time)
      // console.log('timeArguments', timeArguments);
      let hours = parseInt(get(timeArguments, '1', 0))
      // hours = 2
      // console.log('hours', hours);
      const minutes = parseInt(get(timeArguments, '2', 0))
      // console.log('minutes', minutes);
      let meridiem = get(timeArguments, '3', 'AM')
      // meridiem = 'PM'
      // console.log('meridiem', meridiem);
      if (meridiem.toLowerCase() === 'pm' && hours < 12) hours += 12
      // console.log('scheduledDate', scheduledDate);
      scheduledDate = addHours(scheduledDate, hours)
      // console.log('scheduledDate', scheduledDate);
      scheduledDate = addMinutes(scheduledDate, minutes)
      // console.log('scheduledDate', scheduledDate);
    }

    let endTime = addHours(scheduledDate, 1)
    if (endDate) endTime = endOfDay(endDate)

    let byDays = null
    let allDay = false
    if (dates) {
      if (dates.length > 1 && recurring) {
        byDays = onlyOnDays.map(day => {
          return day.slice(0, -1).toUpperCase()
        })
        byDays = byDays.join()
      } else if (dates.length === 1 && !time) {
        endTime = endOfDay(scheduledDate)
        allDay = true
      }
    }

    const event = {
      title: title,
      location,
      description: body,
      startTime: scheduledDate,
      endTime: endTime,
      byDays,
      allDay,
    }

    const googleLink = buildUrl(event, 'google', isMobile)
    const iCalLink = buildUrl(event, null, isMobile)
    // console.log(googleLink, iCalLink);
    return [
      {
        href: iCalLink,
        label: 'Add to Calendar',
      },
      {
        href: googleLink,
        label: 'Add to Google Calendar',
        target: '_blank',
      },
    ]
  } else return []
}
