import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import AlphaGradient from '../common/AlphaGradient'
import AspectRatioBox from '../common/AspectRatioBox'
import Flickity from '../common/Flickity'
import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text from '../common/Text'
import { motion } from 'framer-motion'
import { PrevNextArrow } from '../common/Icons'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import useElementSize from '../../hooks/useElementSize'
import { useLoadImage } from '../../hooks/useLoadImage'
import { useAppContext } from '../../context/AppContext'
import { color } from '../../style/theme'

const PADDING_H = 120

const ImageGalleryModule = ({ primary, items, slice_type, ...props }) => {
  const flickityRef = useRef()
  const [flickity, setFlickity] = useState(null)
  const [index, setIndex] = useState(0)
  const elRef = useRef(null)
  const firstImage = get(items, '[0].gallery_image.url')
  const { loaded } = useLoadImage(firstImage)
  const { width } = useElementSize(elRef, loaded)
  const { focusStyleOn, isMedium, isXLarge } = useAppContext()

  const offsetH = isMedium ? PADDING_H : 0
  const height = ((width - offsetH) * 19) / 39

  const caption = get(items, `[${index}]image_caption.text`)
  const bgColor = get(primary, 'background_color') || 'transparent'

  const onInit = el => setFlickity(el)

  const onPrev = () => {
    if (flickity) flickity.prev()
  }

  const onNext = () => {
    if (flickity) flickity.next()
  }

  const hasMultipleItems = isXLarge && items && items.length > 1

  const captions = items.filter(item => get(item, 'image_caption.text'))

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container ref={elRef}>
        {hasMultipleItems && (
          <Prev onClick={onPrev}>
            <PrevNextArrow left fill={color.yellow} width={40} height={40} />
          </Prev>
        )}
        <Flickity
          ref={flickityRef}
          className="flickity"
          minHeight={height}
          onChange={i => setIndex(i)}
          type={slice_type}
          key={focusStyleOn}
          onInit={onInit}
          onRemove={() => onInit(null)}
          options={{
            accessibility: focusStyleOn,
            prevNextButtons: false,
          }}
        >
          {items &&
            !!items.length &&
            items.map((item, i) => {
              const image = {
                src: get(item, 'gallery_image.url'),
                alt: get(item, 'gallery_image.alt') || '',
              }
              const text = get(item, 'image_caption.text')
              return (
                <Item key={`img${i}`}>
                  <AspectRatioBox width={39} height={19}>
                    <Image alt="" {...image} />
                    {!isMedium && text && text.length > 0 && <AlphaGradient />}
                    {!isMedium && (
                      <ImageText
                        medium
                        center
                        size="xxs"
                        color="secondaryLightBlue"
                      >
                        {ReactHtmlParser(text)}
                      </ImageText>
                    )}
                  </AspectRatioBox>
                </Item>
              )
            })}
        </Flickity>

        {hasMultipleItems && (
          <Next onClick={onNext}>
            <PrevNextArrow fill={color.yellow} width={40} height={40} />
          </Next>
        )}

        {isMedium && captions && !!captions.length && (
          <TextContainer>
            {caption && (
              <MotionText
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <Text center medium ellipsis>
                  {ReactHtmlParser(caption)}
                </Text>
              </MotionText>
            )}
          </TextContainer>
        )}
      </Container>
    </SliceSection>
  )
}

ImageGalleryModule.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
  slice_type: PropTypes.string,
}

export default ImageGalleryModule

const ImageText = styled(Text)`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    align-self: flex-end;
    padding: ${theme.space(3)};
    width: 100%;
  `};
`

const TextContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: ${theme.space(2)};
    margin-top: ${theme.space(3)};
    overflow: hidden;
    max-width: 100%;
  `};
`

const MotionText = styled(motion.div)`
  position: absolute;
  top: 0;
  width: 100%;
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    ${Text} {
      margin-top: ${theme.space(2)};
    }
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.xl`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    `};

    > div {
      width: 100%;
      grid-column: 2/12;
    }
  `};
`

const Image = styled.img`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `};
`

const Next = styled.button`
  ${({ theme }) => css`
    align-self: center;
    pointer-events: all;
    grid-column: -2;
    cursor: pointer;

    svg * {
      transition: 0.3s fill;
    }

    &:hover {
      svg * {
        fill: ${theme.color.blue};
      }
    }

    ${theme.media.xl`
      padding-right: ${theme.space(2)};
    `};
  `};
`

const Prev = styled(Next)`
  ${({ theme }) => css`
    grid-column: 1/2;
    padding-right: 0;

    ${theme.media.xl`
      padding-left: ${theme.space(2)};
    `};
  `};
`
