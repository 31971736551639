import React from 'react'
import PropTypes from 'prop-types'

import Check from '../../common/Check'
import ReactHtmlParser from 'react-html-parser'
import Text from '../../common/Text'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'

const RewardsTable = ({ items, ...data }) => {
  const title = get(data, 'matrix_title.text')
  const cards = [
    {
      image: get(data, 'column_1_image.url'),
      label: get(data, 'column_1_label'),
    },
    {
      image: get(data, 'column_2_image.url'),
      label: get(data, 'column_2_label'),
    },
    {
      image: get(data, 'column_3_image.url'),
      label: get(data, 'column_3_label'),
    },
  ]

  return (
    <Table>
      <thead>
        <tr>
          <th>{title && <Text.h2>{title}</Text.h2>}</th>
          {cards &&
            cards.map(({ label, image }, i) => {
              return (
                <CardTh key={`col${i}`}>
                  {image && <img src={image} alt={label || ''} />}
                  {label && <Text.h6 center>{label}</Text.h6>}
                </CardTh>
              )
            })}
        </tr>
      </thead>
      <tbody>
        {items &&
          items.map((item, i) => {
            const body = get(item, 'row_body.html')
            const ON = 'on'
            const col1 = get(item, 'column_1') === ON
            const col2 = get(item, 'column_2') === ON
            const col3 = get(item, 'column_3') === ON

            return (
              <tr key={`row${i}`}>
                <td>
                  <Cell>
                    <Text md="xs" xl="md" size="md">
                      {ReactHtmlParser(body)}
                    </Text>
                  </Cell>
                </td>
                <CheckTd>
                  <CellCheck>{col1 && <Check color="yellow" />}</CellCheck>
                </CheckTd>
                <CheckTd>
                  <CellCheck>{col2 && <Check color="accentBlue" />}</CellCheck>
                </CheckTd>
                <CheckTd>
                  <CellCheck>{col3 && <Check />}</CellCheck>
                </CheckTd>
              </tr>
            )
          })}
      </tbody>
    </Table>
  )
}

RewardsTable.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
}

export default RewardsTable

const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;

    border-collapse: separate;
    border-spacing: ${theme.space(0.5)};

    th {
      vertical-align: top;
      padding-bottom: ${theme.space(2)};
      
       h2 {
        padding-top: ${theme.space(1)};
       }
    }


    tbody {
      tr {
        position: relative;
        min-height: ${rem(52)};

        &:nth-child(odd) {
          background-color ${theme.color.softBlue};
        }
      }

    }

    td {
      position: relative;

      padding: 0;
      margin: 0;

      vertical-align: middle;
    }
  `};
`

const Cell = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
    min-height: ${rem(52)};

    padding: 0 ${theme.space(1)};
  `};
`

const CellCheck = styled(Cell)`
  ${({ theme }) => css`
    justify-content: center;

    width: 10vw;
    min-width: ${rem(90)};
    max-width: ${rem(90)};
    padding: 0;
    margin: 0 auto;
  `};
`

const CardTh = styled.th`
  ${({ theme }) => css`
    text-align: center;
    img {
      width: 100%;
      height: auto;
      object-fit: scale-down;
      max-width: ${rem(61)};
      margin: 0 auto ${theme.space(1)} auto;
    }
  `};
`

const CheckTd = styled.td`
  ${({ theme }) => css`
    text-align: center;
  `};
`
