import { useMemo } from 'react'
import {
  addDays,
  addMonths,
  getDay,
  getDaysInMonth,
  isSameDay,
  startOfMonth,
  subMonths,
  endOfDay,
} from 'date-fns'
import { get } from 'lodash'

const getDatesArray = (initDate, items) => {
  const days = []
  const daysInMonth = getDaysInMonth(initDate)
  const firstDayInMonth = startOfMonth(initDate)
  while (days.length < daysInMonth) {
    const date = addDays(firstDayInMonth, days.length)

    days.push({
      date,
      events: items.filter(({ grid_content: { document } }) => {
        const dates = get(document, 'data.dates', []).map(
          ({ date }) => new Date(date),
        )

        const datesToday = dates.filter(d => isSameDay(date, d))
        return !!datesToday.length
      }),
    })
  }
  return days
}

const useGetCalendarDatesList = (events, month, year) => {
  const totalSquares = 42
  const dateInMonth = new Date(`${year}/${month + 1}/15`)
  let monthStartsOnDayOfWeek = getDay(startOfMonth(dateInMonth))
  monthStartsOnDayOfWeek =
    monthStartsOnDayOfWeek >= 1 ? monthStartsOnDayOfWeek : 0
  const lastMonth = useMemo(
    () => getDatesArray(subMonths(dateInMonth, 1), events),
    [dateInMonth, events],
  )
  const thisMonth = useMemo(() => getDatesArray(dateInMonth, events), [
    dateInMonth,
    events,
  ])
  const nextMonth = useMemo(
    () => getDatesArray(addMonths(dateInMonth, 1), events),
    [dateInMonth, events],
  )

  const padLeft = lastMonth.slice(
    lastMonth.length - monthStartsOnDayOfWeek,
    lastMonth.length,
  )
  return [...padLeft, ...thisMonth, ...nextMonth].slice(0, totalSquares)
}

export default useGetCalendarDatesList
