import React from 'react'
import PropTypes from 'prop-types'

import { SANS_SERIF, WEIGHT } from '../../../style/type'

import { setFontSize, sizes } from '../../common/Text'

import styled, { css } from 'styled-components'
import { useAppContext } from '../../../context/AppContext'
import arrow from '../../../assets/select-arrow-blue.svg'

function InputContainer({ children, ...props }) {
  const { focusStyleOn } = useAppContext()
  return (
    <Container showFocus={focusStyleOn} {...props}>
      {children}
    </Container>
  )
}

InputContainer.propTypes = {
  children: PropTypes.node,
}

export default InputContainer

const Container = styled.div`
  ${({ error, valid, theme, showFocus, disableFocus }) => css`
    margin-bottom: ${theme.space(1)};
    z-index: 1;

    ${theme.media.lg`
       margin-bottom: 0;
    `};

    textarea,
    select {
      appearance: none;
      border: none;
      overflow: auto;
      outline: none;
      box-shadow: none;
      border-radius: 0;
    }

    select {
      background: url(${arrow}) calc(100% - ${theme.space(1)}) / 15px no-repeat;
    }

    input,
    select,
    textarea {
      appearance: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 2px solid
        ${theme.color[
          error ? 'red' : valid ? 'secondaryBlue' : 'secondaryLightBlue'
        ]};

      font-family: ${SANS_SERIF};
      ${setFontSize(sizes.md)};
      font-weight: 400;
      color: ${theme.color.neutralBlue};
      background-color: transparent;
      padding-bottom: ${theme.space(1)};

      transition: border 0.5s ease-out;

      &:focus {
        outline: none;

        ${!disableFocus &&
          css`
            border-bottom: 2px solid ${theme.color[error ? 'red' : 'yellow']};
          `};
      }
    }
  `};
`
