import React from 'react'
import PropTypes from 'prop-types'

import AspectRatioBox from '../common/AspectRatioBox'
import Section, { SliceSection } from '../common/Section'
import Text from '../common/Text'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'

// Aspect Ratio
const WIDTH = 21
const HEIGHT = 13

const LogoSoupModule = ({ items, primary, ...props }) => {
  const columns = get(primary, 'columns') || 6
  const title = get(primary, 'logo_title.text')
  const bgColor = get(primary, 'background_color') || 'softBlue'

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Center>
        {title && <Text.h4 center>{title}</Text.h4>}
        <Grid columns={columns}>
          {items &&
            items.map((item, i) => {
              const img = {
                src: get(item, 'logo_image.url'),
                alt: get(item, 'logo_image.alt') || '',
              }
              return (
                <Item key={`logo${i}`}>
                  <AspectRatioBox contain width={WIDTH} height={HEIGHT}>
                    {img && img.src && <Image {...img} />}
                  </AspectRatioBox>
                </Item>
              )
            })}
        </Grid>
      </Center>
    </SliceSection>
  )
}

LogoSoupModule.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
}

export default LogoSoupModule

const Center = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > h4 {
      margin: 0 0 ${theme.space(3)};
      padding: 0 ${theme.space(2)};
    }
  `};
`

const Grid = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-content: space-between;
    flex-wrap: wrap;

    width: calc(100% + ${rem(30)});
    max-height: calc(100% - ${rem(30)});
    max-width: ${theme.layout.maxWidth};
  `};
`

const Image = styled.img`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    object-fit: contain;
    border-radius: ${theme.layout.borderRadius};
    padding: ${rem(10)};

    ${theme.media.sm`
      padding: ${rem(15)};
     `};

    ${theme.media.lg`
      padding: ${rem(10)};
     `};

    ${theme.media.xl`
      padding: ${rem(15)};
     `};
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: calc(100% / 2 - ${rem(20)});
    max-width: calc(100% / 2 - ${rem(20)});

    margin: 0 ${rem(5)} ${rem(10)};

    background-color: #fff;
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    ${theme.media.md`
      width: calc(100% / 3 - ${rem(30)});
      max-width: calc(100% / 3 - ${rem(30)});
      margin: 0 ${rem(15)} ${rem(30)};
    `};

    ${theme.media.lg`
      width: calc(100% / 6 - ${rem(30)});
      max-width: calc(100% / 6 - ${rem(30)});
    `};
  `};
`
