import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Text from '../../common/Text'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { layout } from '../../../style/theme'
import { rem } from 'polished'

const CalendarDate = ({ top, bottom }) => {
  const topColumns = top && top.length
  const bottomColumns = bottom && bottom.length

  return topColumns && bottomColumns ? (
    <CalendarContainer>
      <Container
        columns={topColumns}
        middleLength={
          bottom.length === 3 && bottom[1] === 'UNTIL' ? '1fr' : '25px'
        }
      >
        {top &&
          !!top.length &&
          top.map((label, i) => {
            let borderRadius = '0'
            const borderTopRight = `0 ${layout.borderRadius} 0 0`
            const borderTopLeft = `${layout.borderRadius} 0 0`
            const borderTop = `${layout.borderRadius} ${layout.borderRadius} 0 0`

            if (top.length === 1) borderRadius = borderTop
            else if (i === 0) borderRadius = borderTopLeft
            else if (i === top.length - 1) borderRadius = borderTopRight
            return (
              <Fragment key={`h${i}`}>
                <Heading
                  center
                  white
                  nowrap
                  css={{ borderRadius }}
                  noPadding={!label.length}
                >
                  {label}
                </Heading>
              </Fragment>
            )
          })}
      </Container>
      <Container
        columns={bottomColumns}
        middleLength={
          bottom.length === 3 && bottom[1] === 'UNTIL' ? '1fr' : '25px'
        }
      >
        {bottom &&
          !!bottom.length &&
          bottom.map((label, i) => {
            let borderRadius = '0'
            const borderTopRight = `0 0 ${layout.borderRadius} 0`
            const borderTopLeft = `0 0 0 ${layout.borderRadius}`
            const borderTop = `0 0 ${layout.borderRadius} ${layout.borderRadius} `

            if (bottom.length === 1) borderRadius = borderTop
            else if (i === 0) borderRadius = borderTopLeft
            else if (i === bottom.length - 1) borderRadius = borderTopRight
            return (
              <Fragment key={`l${i}`}>
                {i === 1 && bottom.length === 3 ? (
                  <Between>
                    <Text
                      center
                      nowrap
                      color="#8E9AAD"
                      bold
                      uppercase
                      size="xs"
                    >
                      {label}
                    </Text>
                  </Between>
                ) : (
                  <Day center nowrap css={{ borderRadius }}>
                    {label}
                  </Day>
                )}
              </Fragment>
            )
          })}
      </Container>
    </CalendarContainer>
  ) : null
}

CalendarDate.propTypes = {
  top: PropTypes.array,
  bottom: PropTypes.array,
}

export default CalendarDate

const CalendarContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 auto ${rem(18)};
    //height: ${rem(34)};
    ${theme.media.lg`
      margin: 0 0 ${theme.space(1)};
    `}
  `}
`

const Heading = styled(Text.h6)`
  ${({ theme, noPadding }) => css`
    background: ${theme.color.secondaryBlue};
    height: ${rem(24)};
    ${noPadding &&
      css`
        && {
          padding: ${theme.space(0.5)} 0 !important;
        }
      `}
  `}
`

const Container = styled.div`
  ${({ columns, theme, middleLength = 25 }) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    ${columns === 3 &&
      css`
        grid-template-columns: 1fr ${middleLength} 1fr;
      `};
    align-items: center;
    border-radius: ${theme.layout.borderRadius};

    ${theme.media.lg`
      margin: 0;
    `};

    > ${Heading}, ${Day} {
      padding: ${theme.space(0.5)} ${theme.space(1)};
    }

    > h4 {
      padding: ${theme.space(0.5)} ${theme.space(2)};
    }
  `}
`

const HeadingSpacer = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.secondaryBlue};
    height: 100%;
    height: ${rem(24)};
  `}
`

const Day = styled(Text.h4)`
  background: #fff;
`

const Between = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    align-self: stretch;
  `}
`
