import React from 'react'
import PropTypes from 'prop-types'

import ErrorText from './ErrorText'
import InputContainer from './InputContainer'
import Label from './Label'

import { get } from 'lodash'
import { rem } from 'polished'
import { useAppContext } from '../../../context/AppContext'

const SignatureInput = ({
  columns,
  error,
  label,
  name,
  placeholder,
  handleChange,
  handleBlur,
  touch,
  validation,
  value,
  ...props
}) => {
  const { isMedium } = useAppContext()
  const hasError = touch && error
  const required = get(props, 'required') === 'yes'

  const inputProps = {
    name,
    placeholder,
    required,
  }

  const gridColumn = {
    gridColumn: '1 / 3',
    maxWidth: isMedium ? rem(450) : 'none',
  }

  return (
    <InputContainer
      error={hasError}
      css={gridColumn}
      valid={!hasError && touch && value !== ''}
    >
      {label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      <input
        type="text"
        onChange={val => {
          const newValue = get(val, 'currentTarget.value')
          handleChange(name, newValue)
        }}
        onBlur={handleBlur}
        {...inputProps}
      />
      <ErrorText show={hasError}>{error}</ErrorText>
    </InputContainer>
  )
}

SignatureInput.propTypes = {
  columns: PropTypes.number,
  error: PropTypes.string,
  handleBlur: PropTypes.func,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  touch: PropTypes.bool,
  validation: PropTypes.string,
  value: PropTypes.string,
}

export default SignatureInput
