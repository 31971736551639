import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import Section, { SliceSection } from '../common/Section'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import decodeBodyHtml from '../../utils/decodeBodyHtml'
import { transformLinks } from '../../utils/textTransforms'

const WideWysiwygModule = ({ primary: data, ...props }) => {
  const body = get(data, 'wide_body.html')
  const bgColor = get(data, 'background_color') || 'transparent'
  const decodedBody = useMemo(() => decodeBodyHtml(body), [body])

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container>
        {body && (
          <Text html wysiwyg htmlMargin>
            {ReactHtmlParser(decodedBody, { transform: transformLinks })}
          </Text>
        )}
      </Container>
    </SliceSection>
  )
}

WideWysiwygModule.propTypes = {
  primary: PropTypes.object,
}

export default WideWysiwygModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;

    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidthSm};

    margin: 0 ${theme.space(3)};

    > div {
      width: 100%;
    }

    ${theme.media.lg`
        flex-direction: row;
      `};
  `};
`
