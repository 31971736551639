import React, { useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'

import { get, isEqual, omit, functions, first } from 'lodash'
import styled, { css } from 'styled-components'
import { GATSBY_GOOGLE_MAPS_API_KEY } from '../../../lib/constants'

const isBrowser = () => typeof window !== 'undefined'
const URL = `https://maps.google.com/maps/api/js?key=${GATSBY_GOOGLE_MAPS_API_KEY}`

const mapStyle = [
  {
    featureType: 'all',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f3f5f7',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        gamma: 0.01,
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        saturation: -31,
      },
      {
        lightness: -33,
      },
      {
        weight: 2,
      },
      {
        gamma: 0.8,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#1d355b',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#1d355b',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#1d355b',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'simplified',
      },
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#1d355b',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 30,
      },
      {
        saturation: 30,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        saturation: 20,
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 20,
      },
      {
        saturation: -20,
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 10,
      },
      {
        saturation: -30,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        saturation: 25,
      },
      {
        lightness: 25,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ff0000',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
      {
        color: '#1d355b',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ff0000',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
      {
        color: '#ff0000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        lightness: -20,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#cad1ea',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

const scriptLoaded = url => {
  const scripts = document.getElementsByTagName('script')
  for (let i = scripts.length; i--; ) {
    if (scripts[i].src === url) return true
  }
  return false
}

function GoogleMap({ coordinates, onMount, ...props }) {
  const el = useRef()
  const onLoad = () => {
    const mapEl = get(el, 'current')

    const map = new window.google.maps.Map(mapEl, {
      center: { ...coordinates },
      zoom: 16,
      styles: mapStyle,
    })
    new window.google.maps.Marker({
      position: { ...coordinates },
      icon: require('../../assets/map-pin.png'),
      map,
    })

    onMount && onMount(map)
  }

  useEffect(() => {
    if (isBrowser() && !scriptLoaded(URL)) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = URL
      const headScript = first(document.getElementsByTagName('script'))
      headScript.parentNode.insertBefore(script, headScript)
      script.addEventListener('load', onLoad, false)
      return () => script.removeEventListener('load', onLoad)
    }
  })

  return <Container ref={el} />
}

GoogleMap.propTypes = {
  coordinates: PropTypes.object,
  onMount: PropTypes.func,
}

const shouldUpdate = (prevProps, nextProps) => {
  const [prevFuncs, nextFuncs] = [functions(prevProps), functions(nextProps)]
  return (
    isEqual(omit(prevProps, prevFuncs), omit(nextProps, nextFuncs)) &&
    prevFuncs.every(fn => prevProps[fn].toString() === nextProps[fn].toString())
  )
}

export default memo(GoogleMap, shouldUpdate)

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
  `};
`
