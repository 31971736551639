import React from 'react'
import PropTypes from 'prop-types'

import AlphaGradient from '../common/AlphaGradient'
import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { rem, transparentize } from 'polished'
import { get } from 'lodash'
import { WEIGHT } from '../../style/type'

const IntroModule = ({ index, primary: data, ...props }) => {
  const image = get(data, 'intro_image.url')
  const imageAlt = get(data, 'intro_image.alt')
  const title = get(data, 'intro_title.text')
  const body = get(data, 'intro_body.html')
  const bgImage = get(data, 'background_image.url')
  const bgColor = get(data, 'background_color') || 'transparent'

  return (
    <SliceSection
      fullWidthSmall
      noPaddingTop
      noPaddingBottom={index === 0}
      bgColor={bgColor}
      {...props}
    >
      {index === 0 && <Bg />}
      <Container>
        <Content>
          {image && <Image src={image} alt={imageAlt} />}
          {title && (
            <Text.h1 center white>
              {title}
            </Text.h1>
          )}
          {body && (
            <Text center html white medium>
              {ReactHtmlParser(body)}
            </Text>
          )}
        </Content>

        {bgImage && <BackgroundImage src={bgImage} alt={title} />}
        <AlphaGradient height={100} />
        <ColorOverlay />
      </Container>
    </SliceSection>
  )
}

IntroModule.propTypes = {
  index: PropTypes.number,
  primary: PropTypes.object,
}

export default IntroModule

const Bg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - ${rem(140)});

    ${theme.media.md`
      background-color: ${theme.color.softBlue};
    `};

    ${theme.media.lg`
      height: calc(100% - ${rem(180)});
    `};
  `};
`

const ColorOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => transparentize(0.25, theme.color.blue)};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: ${rem(130)};
    width: 100%;
    max-width: ${theme.layout.maxWidth};
    z-index: 2;

    ${theme.media.md`
      max-height: none;
      border-radius: ${theme.layout.borderRadius};
      overflow: hidden;
      display: block;
       min-height: ${rem(340)};
    `};

    ${theme.media.lg`
       min-height: ${rem(360)};
    `};

    ${theme.media.xl`
       min-height: ${rem(500)};
    `};

    ${ColorOverlay} {
      ${theme.media.md`
       display: none;
    `};
    }

    ${AlphaGradient} {
      display: none;

      ${theme.media.md`
       display: block;
    `};
    }
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    ${theme.media.md`
      justify-content: flex-end;
      padding: ${theme.space(6.5)} 0;
    `};

    > div {
      display: none;
      max-width: calc(${rem(600)} + ${theme.space(6)});
    }

    > div,
    > h1 {
      padding: 0 ${theme.space(3)};

      ${theme.media.md`
        display: block;

        &:not(:last-child) {
          margin-bottom: ${theme.space(1.8)};
        }
      `};
    }

    p {
      font-weight: ${WEIGHT.MEDIUM};
    }

    img {
      display: none;

      ${theme.media.md`
        display: block;
        margin-bottom: ${theme.space(2.5)};
      `};
    }
  `};
`

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: ${rem(210)};
  max-height: ${rem(50)};
  object-fit: scale-down;

  margin: 0 auto;
  z-index: 1;
`

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`
