import React from 'react'
import PropTypes from 'prop-types'

import Button from '../common/Button'
import ReactHtmlParser from 'react-html-parser'
import Section from '../common/Section'
import Text, { setFontSize } from '../common/Text'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import linkResolver from '../../utils/linkResolver'

const EndUpPageModule = ({ primary: data }) => {
  const title = get(data, 'end_title.text')
  const body = get(data, 'end_body.html')
  const label = get(data, 'end_button_label') || 'MORE INFO'
  const link = {
    href: linkResolver(data, 'end_link'),
    target: get(data, 'end_link.target'),
  }

  return (
    <Section>
      <Container>
        <Card>
          {title && (
            <Text.h2 center html white>
              {title}
            </Text.h2>
          )}
          {body && (
            <Text center html color="secondaryLightBlue">
              {ReactHtmlParser(body)}
            </Text>
          )}
          {link.href && (
            <Button {...link} inline>
              {label}
            </Button>
          )}
        </Card>
      </Container>
    </Section>
  )
}

EndUpPageModule.propTypes = {
  primary: PropTypes.object,
}

export default EndUpPageModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;
    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidthArticle};

    ${theme.media.lg`
        flex-direction: row;
      `};
  `};
`

const Card = styled.div`
  ${({ theme }) => css`
    ${theme.layout.bgGradient};

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: ${theme.space(4)} ${theme.space(2)} ${theme.space(4.5)};

    border-radius: ${theme.layout.borderRadius};

    p {
      margin: ${theme.space(1)} 0 ${theme.space(2)};
    }

    ${theme.media.sm`
      padding: ${theme.space(4)} ${theme.space(4)} ${theme.space(4.5)};
    `};

    ${theme.media.lg`
      padding: ${theme.space(6)};
    `};
  `};
`
