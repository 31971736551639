import React from 'react'
import PropTypes from 'prop-types'

import { Star } from '../../common/Icons'
import Text from '../../common/Text'
import { rem } from 'polished'

function Label({ children, required, ...props }) {
  return (
    <Text.h5
      as="label"
      css={{ display: 'block', marginBottom: rem(15), zIndex: 0 }}
      {...props}
    >
      {children}
      {required && (
        <Star
          width={10}
          height={10}
          style={{ marginLeft: rem(4), marginBottom: rem(2) }}
        />
      )}
    </Text.h5>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  required: PropTypes.bool,
}

export default Label
