import { useState, useEffect } from 'react'
import { get, throttle } from 'lodash'

const isClient = () => typeof window !== 'undefined'

const defaultState = {
  width: 0,
  height: 0,
}

function getSize(target) {
  if (!isClient() || !target) return defaultState

  return {
    width: get(target, 'clientWidth') || 0,
    height: get(target, 'clientHeight') || 0,
  }
}

export default function(ref, update, testProp = false) {
  const [test, setTest] = useState(testProp)
  const el = get(ref, 'current')
  const [windowSize, setWindowSize] = useState(defaultState)

  useEffect(() => {
    if (!isClient()) return false

    function resize() {
      setWindowSize(getSize(el))
    }

    if (update || test !== testProp) {
      setTest(testProp)
      resize()
    }

    const handleResize = throttle(resize, 200)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [el, ref, test, testProp, update]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}
