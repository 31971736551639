import React from 'react'
import PropTypes from 'prop-types'

import ErrorText from './ErrorText'
import InputContainer from './InputContainer'
import Label from './Label'

import { get } from 'lodash'

const MessageInput = ({
  columns,
  error,
  label,
  name,
  placeholder,
  handleChange,
  handleBlur,
  touch,
  value,
  ...props
}) => {
  const hasError = touch && error
  const required = get(props, 'required') === 'yes'

  const inputProps = {
    name,
    placeholder,
    required,
  }

  const gridColumn = columns && columns !== 2 ? {} : { gridColumn: '1 / 3' }

  return (
    <InputContainer
      error={hasError}
      css={gridColumn}
      valid={!hasError && touch && value !== ''}
    >
      {label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      <textarea
        {...inputProps}
        onChange={val => {
          const newValue = get(val, 'currentTarget.value')
          handleChange(name, newValue)
        }}
        onBlur={handleBlur}
      />
      <ErrorText show={hasError}>{error}</ErrorText>
    </InputContainer>
  )
}

MessageInput.propTypes = {
  columns: PropTypes.number,
  error: PropTypes.string,
  handleBlur: PropTypes.func,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  touch: PropTypes.bool,
  value: PropTypes.string,
}

export default MessageInput
