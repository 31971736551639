import React from 'react'
import PropTypes from 'prop-types'

import AspectRatio from 'react-aspect-ratio'

import styled, { css } from 'styled-components'

// Use with images

const AspectRatioBox = ({ children, height, width, ...props }) => {
  return (
    <Container className="aspect-img" {...props}>
      <AspectRatio ratio={width / height}>{children}</AspectRatio>
    </Container>
  )
}

AspectRatioBox.propTypes = {
  border: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  fit: PropTypes.string,
  height: PropTypes.number,
  test: PropTypes.bool,
  width: PropTypes.number,
}

AspectRatioBox.defaultProps = {
  contain: false,
  height: 9,
  width: 16,
}

export default AspectRatioBox

const Container = styled.div`
  ${({ border, contain, test, theme }) => css`
    position: relative;
    overflow: hidden;

    img {
      object-fit: ${contain ? 'contain' : 'cover'};
      width: 100%;
      height: 100%;
      transition: transform 0.3s, opacity 0.3s;
    }

    ${border &&
      css`
        border-radius: ${theme.layout.borderRadius};
        overflow: hidden;
      `}

    ${test &&
      css`
        border: 1px solid red;
      `};
  `};
`
