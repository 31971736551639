import React from 'react'
import PropTypes from 'prop-types'

import { WEIGHT } from '../../../style/type'

import AlphaGradient from '../../common/AlphaGradient'
import Text from '../../common/Text'
import Button from '../../common/Button'
import AspectRatioBox from '../../common/AspectRatioBox'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useAppContext } from '../../../context/AppContext'

const ThankYou = ({ children, label, image, link }) => {
  const { isLarge } = useAppContext()
  const img = <img alt="" {...image} />

  const text = (
    <TextContainer>
      {isLarge && (
        <Gradient viewBox="0 0 597 503">
          <defs>
            <radialGradient
              cx="0%"
              cy="-7.007%"
              fx="0%"
              fy="-7.007%"
              r="119.087%"
              gradientTransform="matrix(.35612 .05884 -.02099 .99827 -.001 0)"
              id="prefix__b"
            >
              <stop stopColor="#3E6594" offset="0%" />
              <stop stopColor="#3E6594" offset="0%" />
              <stop stopColor="#1D355B" stopOpacity={0} offset="100%" />
            </radialGradient>
            <rect id="prefix__a" x={0} y={0} width={1410} height={503} rx={5} />
          </defs>
          <use
            fill="url(#prefix__b)"
            fillRule="nonzero"
            xlinkHref="#prefix__a"
          />
        </Gradient>
      )}

      <Text center={!isLarge} html>
        {children}
      </Text>
      <Button {...link}>{label}</Button>
    </TextContainer>
  )

  return (
    <Content>
      <Container>
        {!isLarge && (
          <>
            <AspectRatioBox>
              {img}
              <AlphaGradient
                deg={isLarge ? 90 : 0}
                height={100}
                width={150}
                padGradient={isLarge ? 50 : 15}
              />
            </AspectRatioBox>
            {text}
          </>
        )}

        {isLarge && (
          <>
            {text}
            <ImageContainer>
              {img}
              <AlphaGradient deg={90} padGradient={50} />
            </ImageContainer>
          </>
        )}
      </Container>
    </Content>
  )
}

ThankYou.propTypes = {
  children: PropTypes.node,
  image: PropTypes.object,
  label: PropTypes.string,
  link: PropTypes.object,
}

export default ThankYou

const Gradient = styled.svg`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  `};
`

const Content = styled.div`
  ${({ theme }) => css`
    position: relative;
    max-width: ${theme.layout.maxWidth};
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};

       max-height: none;
    `};
  `};
`

const TextContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;
    background-color: ${theme.color.blue};

    padding: ${theme.space(2)} ${theme.space(3)};
    margin-bottom: ${theme.space(1)};
    grid-column: 1 / 5;

    margin-top: -${theme.space(1)};

    z-index: 9;

    > svg {
      height: auto;

      ${theme.media.lg`
        max-width: 50vw;
      `};
    }

    h1,
    h2,
    h3,
    h4 {
      color: #fff;
      z-index: 1;
    }

    h1 {
      font-size: ${rem(30)};
      line-height: ${rem(36)};

      ${theme.media.lg`
      font-size: ${rem(46)};
      line-height: ${rem(54)};
    `};
    }

    h4 {
      font-size: ${rem(13)};
      line-height: ${rem(19)};
      letter-spacing: 1px;
      margin-top: ${theme.space(0.5)};
      font-weight: ${WEIGHT.MEDIUM};

      ${theme.media.lg`
        font-size: ${rem(20)};
        line-height: ${rem(28)};
      `};
    }

    p {
      color: ${theme.color.secondaryLightBlue};
      margin-top: ${theme.space(2)};
    }

    a,
    button {
      z-index: 1;
      margin: ${theme.space(3)} auto 0 auto;
      width: 100%;

      ${theme.media.lg`
        max-width: ${rem(200)};
        margin: ${theme.space(3)} 0 0 0;
      `};
    }

    ${theme.media.lg`
      padding: ${theme.space(8)};
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 0;
      grid-column: 1 / 6;
    `};
  `};
`

const ImageContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    z-index: 1;

    ${theme.media.lg`
      min-height: 503px;
       grid-column: 6 / 13;
    `};

    ${theme.media.xxl`
       grid-column: 6 / 13;
    `}

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `};
`
