import React from 'react'
import PropTypes from 'prop-types'

import useMousePosition from '../../hooks/useMousePosition'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const ToolTip = ({ children, image, ...props }) => {
  const { x, y } = useMousePosition()

  return (
    <Container css={{ transform: `translate(${x}px, ${y}px)` }} {...props}>
      {image && image.src && <img alt="" {...image} />}
      {children}
    </Container>
  )
}

ToolTip.propTypes = {
  children: PropTypes.node,
  image: PropTypes.object,
}

export default ToolTip

const Container = styled(motion.div)`
  ${({ theme }) => css`
    position: absolute;
    top: -215px;
    left: -70px;
    width: 300px;
    height: 200px;
    z-index: 11;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ${theme.layout.borderRadius};
    }
  `};
`
