import { useEffect, useState } from 'react'

const loadImage = src =>
  new Promise((r, f) => {
    const image = new Image()
    image.onload = () => r(image)
    image.onerror = e => f(e)
    image.src = src
  })

export const useLoadImage = src => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    loadImage(src).then(setLoaded(true))
  }, [src])

  return { loaded, src }
}

export default loadImage
