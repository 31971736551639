import React from 'react'
import PropTypes from 'prop-types'

import Button from '../common/Button'
import ReactHtmlParser from 'react-html-parser'
import Section from '../common/Section'
import Text from '../common/Text'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import linkResolver from '../../utils/linkResolver'

const ListingModule = ({ items, primary }) => {
  const columns = get(primary, 'columns') || 'auto-fit'
  const sectionTitle = get(primary, 'listing_title.text')

  return (
    <Section bgColor="softBlue">
      <Content>
        {sectionTitle && <Text.h2>{sectionTitle}</Text.h2>}
        <Grid columns={columns}>
          {items &&
            items.map((item, i) => {
              const title = get(item, 'listing_item_title.text')
              const body = get(item, 'listing_body.html')
              const label = get(item, 'listing_button_label')
              const link = {
                href: linkResolver(item, 'listing_button_link'),
                target: get(item, 'listing_button_link.target'),
              }

              return (
                <Item key={`${i}`}>
                  <TextContainer>
                    {title && <Text.h3>{title}</Text.h3>}
                    {body && <Text html>{ReactHtmlParser(body)}</Text>}
                  </TextContainer>
                  {link.href && (
                    <ButtonContainer>
                      <Button {...link} underline>
                        {label || 'Read more'}
                      </Button>
                    </ButtonContainer>
                  )}
                </Item>
              )
            })}
        </Grid>
      </Content>
    </Section>
  )
}

ListingModule.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
}

export default ListingModule

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};
    padding: 0 ${theme.space(2)};

    margin-top: -${rem(5)};

    ${theme.media.lg`
      h2 {
        margin: -${rem(8)} 0 ${theme.space(2)};
      }
    `};

    ${theme.media.lg`
      padding: 0;
    `};
  `};
`

const Grid = styled.div`
  ${({ columns, theme }) => css`
    width: 100%;

    ${theme.media.lg`
     display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: minmax(${rem(120)}, 1fr);
      grid-gap: ${theme.space(1)} ${theme.space(1)};
    `};

    ${theme.media.sm`
      grid-template-columns: repeat(${columns}, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(1.5)} ${theme.space(3)};
    `};
  `};
`

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    margin: ${theme.space(0.5)} 0 0;
  `};
`

const TextContainer = styled.div`
  flex: 1;

  p {
    margin-bottom: ${({ theme }) => theme.space(2)};
  }

  h3 {
    margin-bottom: ${({ theme }) => theme.space(1.5)};
  }

  z-index: 2;
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: ${theme.layout.borderRadius};

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    .img-spacer {
      display: none;
    }

    ${TextContainer} {
      margin-top: ${theme.space(2.5)};
    }
  `};
`
