import React, { useRef, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import AspectRatioBox from '../../common/AspectRatioBox'
import Button from '../../common/Button'
import { CloseButton } from './DiningModal'
import Flickity from '../../common/Flickity'
import ReactHtmlParser from 'react-html-parser'
import Modal from '../../common/Modal'
import Section from '../../common/Section'
import Text from '../../common/Text'
import { Close } from '../../common/Icons'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useAppContext } from '../../../context/AppContext'
import { useLoadImage } from '../../../hooks/useLoadImage'
import gradient from '../../../assets/gallery-gradient.png'
import { transformLinks } from '../../../utils/textTransforms'

// Aspect Ratio
const WIDTH = 381
const HEIGHT = 237

const DiningGallery = ({ items, ...props }) => {
  const {
    focusStyleOn,
    isMedium,
    isLarge,
    isXLarge,
    setScrollingLocked,
  } = useAppContext()
  const [index, setIndex] = useState(0)
  const modal = useRef()
  const flickityContainer = useRef()
  const flickityRef = useRef()
  const [showModal, setModal] = useState(false)
  const toggleModal = useCallback(() => setModal(!showModal), [showModal])
  const setModalClosed = () => setModal(false)
  const firstImage = get(items, '[0].gallery_image.url')
  const { loaded } = useLoadImage(firstImage)

  const columns = get(props, 'columns') || 'auto-fit'
  const logoImage = {
    src: get(props, 'logo.url'),
    alt: get(props, 'logo.alt') || '',
  }
  const label = get(props, 'label')
  let len = 1
  if (isXLarge) len = 4
  else if (isLarge) len = 3
  else if (isMedium) len = 2
  const images = items && items.slice(0, len)
  const bodyText = get(items[index], 'gallery_body.html') || ' ' // @Note: Keep this to retain layout

  const closeModal = useCallback(() => {
    const close = get(modal, 'current.close')
    if (close) close()
  }, [modal])

  const handleOpenGalleryAtIndex = useCallback(
    index => {
      setIndex(index)
      const select = get(flickityRef, 'current.select')
      if (select) select(index)
      toggleModal()
    },
    [toggleModal],
  )

  useEffect(() => {
    setScrollingLocked(showModal)
    return () => setScrollingLocked(false)
  }, [setScrollingLocked, showModal])

  return (
    <>
      <Section
        bgColor="#fff"
        fullWidth
        noPaddingTop
        noPaddingBottom
        css={{ zIndex: 1 }}
      >
        <Bg />
        <Grid columns={columns} css={{ opacity: loaded ? 1 : 0 }}>
          {images &&
            images.map((item, i) => {
              const src = get(item, 'gallery_image.url')
              const alt = get(item, 'gallery_image.alt') || ''
              return (
                <Item
                  key={`${src}-${i}`}
                  onClick={() => handleOpenGalleryAtIndex(i)}
                >
                  <AspectRatioBox absolute width={WIDTH} height={HEIGHT}>
                    {src && <Image src={src} alt={alt} />}
                  </AspectRatioBox>
                </Item>
              )
            })}
          {logoImage && logoImage.src && (
            <Logo>
              <div>
                <Circle>
                  <img alt="" {...logoImage} />
                </Circle>
              </div>
            </Logo>
          )}
          {label && (
            <ButtonPosition>
              <Button
                size="xxs"
                onClick={() => handleOpenGalleryAtIndex(0)}
                css={{
                  maxWidth: rem(120),
                  maxHeight: rem(40),
                  height: rem(40),
                  minHeight: rem(40),
                }}
              >
                {label}
              </Button>
            </ButtonPosition>
          )}
        </Grid>
      </Section>
      <Modal
        targetId="fixed-portal"
        ref={modal}
        show={showModal}
        hideModal={setModalClosed}
        onClose={setModalClosed}
        transitionType="fadeIn"
        zIndex={99999999999999}
      >
        <ModalContainer>
          <ModalBg />
          <Gradient src={gradient} />
          <FlickityContainer ref={flickityContainer}>
            <NumText center medium color="secondaryLightBlue" size="xs" lg="sm">
              {`${index + 1} of ${items.length}`}
            </NumText>
            <Flickity
              ref={flickityRef}
              index={index}
              className="flickity"
              onChange={i => setIndex(i)}
              type="diningGallery"
              key={focusStyleOn}
              options={{
                accessibility: focusStyleOn,
                pageDots: false,
              }}
              updateOnIndexChage
            >
              {items &&
                items.map((item, i) => {
                  const src = get(item, 'gallery_image.url')
                  const alt = get(item, 'gallery_image.alt') || ''
                  //const body = get(item, 'image_body.html')

                  return (
                    <GalleryItem key={`g-${src}-${i}`}>
                      {src && <img src={src} alt={alt} />}
                    </GalleryItem>
                  )
                })}
            </Flickity>
            {bodyText && (
              <SlideText
                center
                color="secondaryLightBlue"
                size="sm"
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                {ReactHtmlParser(bodyText, { transform: transformLinks })}
              </SlideText>
            )}
          </FlickityContainer>

          <CloseButton onClick={closeModal} showFocus={focusStyleOn}>
            <Close />
          </CloseButton>
        </ModalContainer>
      </Modal>
    </>
  )
}

DiningGallery.propTypes = {
  items: PropTypes.array,
}

export default DiningGallery

const Gradient = styled.img`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    object-fit: cover;
  `};
`

const Bg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: ${theme.color.softBlue};
  `};
`

const ModalBg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.95;
    background: radial-gradient(circle, #3e6594 0%, #1d355b 100%);
  `};
`

const ModalContainer = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;
  `};
`

const Grid = styled.div`
  ${({ columns, theme }) => css`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(${rem(120)}, 1fr);
    grid-gap: ${theme.space(1)} ${theme.space(1)};
    padding: 0 ${theme.space(1)};

    width: 100%;
    max-width: ${rem(1570)};

    ${theme.media.sm`
      grid-template-columns: repeat(${columns}, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(1.5)} ${theme.space(1.5)};
    `};
  `};
`

const Image = styled.img`
  ${({ type, theme }) => css`
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: 1;
    background: ${theme.color.blue};
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    cursor: pointer;

    img {
      transition: opacity 0.3s, transform 0.3s;
    }

    &:hover {
      .aspect-img img {
        transform: scale(1.03);
        opacity: 0.75;
      }
    }
  `};
`

const GalleryItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    padding: 0 ${theme.space(1)};

    img {
      max-width: ${rem(990)};
      max-height: 70vh;
      width: 100%;
      height: auto;
      object-fit: contain;
      border-radius: ${theme.layout.borderRadius};

      ${theme.media.lg`
        width: 70vw;
      `}
    }
  `};
`

const ButtonPosition = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${theme.space(2)};
    right: ${theme.space(3)};
    z-index: 2;
  `};
`

const Circle = styled.div`
  ${({ theme }) => css`
    height: ${rem(80)};
    width: ${rem(80)};
    transform: translateY(25%);

    border-radius: 100%;
    border: 1px solid rgba(29, 53, 91, 0.15);
    background-color: #ffffff;

    ${theme.media.lg`
      height: ${rem(100)};
      width: ${rem(100)};
      transform: translateY(50%);
    `}

    img {
      width: auto;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  `};
`

const Logo = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${theme.space(0)} ${theme.space(2)};

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 ${theme.space(4)};

    z-index: 3;
    pointer-events: none;

    @media (min-width: 1520px) {
      padding: 0 ${theme.space(6)};
    }

    > div {
      width: 100%;
      max-width: ${theme.layout.maxWidth};

      grid-column: 1 / 8;

      ${theme.media.xl`
        grid-column: 2 / 8;
      `};
    }
  `};
`

const FlickityContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 100vw;
    height: 100vh;
  `};
`

const NumText = styled(Text)`
  ${({ showFocus, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-end;

    width: 100%;
    min-height: 15vh;
    padding: ${theme.space(2)};
  `};
`

const SlideText = styled(Text.motion)`
  ${({ showFocus, theme }) => css`
    width: 100%;
    min-height: 15vh;
    padding: ${theme.space(2)};
    margin-top: ${theme.space(2)};

    ${theme.media.lg`
      margin-top: 0
    `}
  `};
`
