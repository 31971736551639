import {
  CHECKBOX_MODULE,
  DATE_MODULE,
  DOB_MODULE,
  MESSAGE_MODULE,
  TEXT_MODULE,
  TIME_MODULE,
  TYPE_EMAIL,
  TYPE_PHONE,
  TYPE_REWARDS,
  // TODO: add new DOB type
} from '../constants/formTypes'

import * as yup from 'yup'
import { get } from 'lodash'

const getTextType = (type, currentValidation) => {
  let validation = currentValidation
  switch (type) {
    case TYPE_EMAIL:
      validation = yup
        .string()
        .email('Invalid email')
        .max(255, 'Must be less than 255 characters')
      break
    case TYPE_PHONE:
      validation = yup
        .string()
        .matches(
          /^[2-9]\d{2}-\d{3}-\d{4}$/,
          'Invalid phone number. Format: 555-555-5555.',
        )
      break
    case TYPE_REWARDS:
      validation = yup
        .string()
        .matches(
          /^\d{3}-\d{3}-\d{3}$/,
          'Invalid account number. Format: ###-###-###.',
        )
      break
  }
  return validation
}

const validateForm = items => {
  const validationShape = items
    .map(({ slice_type, ...item }) => {
      let validation = yup.string().max(255, 'Must be less than 255 characters')

      switch (slice_type) {
        case CHECKBOX_MODULE:
          validation = yup.boolean()
          break
        case MESSAGE_MODULE:
          validation = yup
            .string()
            .min(1)
            .max(5000, 'Must be less than 5000 characters')
          break
        case TEXT_MODULE:
          const type = get(item, 'primary.validation')
          validation = getTextType(type, validation)
          break
        case TIME_MODULE:
          validation = yup
            .string()
            .matches(
              /^((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))$/,
              'Invalid time',
            )
          break
        case DATE_MODULE:
          validation = yup.date('Invalid date')
          break
        case DOB_MODULE:
          let date = new Date()
          date.setFullYear(date.getFullYear() - item.primary.age_limit)
          validation = yup.date().max(date, 'Invalid date')
          break
        default:
      }

      const required = get(item, 'primary.required') === 'yes'
      if (!!required)
        validation = validation.required(
          `${get(item, 'primary.label')} required`,
        )

      return { id: item.id, validation }
    })
    .reduce((obj, item) => {
      obj[item.id] = item.validation
      return obj
    }, {})

  return yup.object().shape(validationShape)
}

export default validateForm

/*

 validationSchema={yup.object().shape({
              arrive: yup.string(),
              depart: yup.string(),
              rates: yup.string(),
              adult: yup.number().required('Required'),
            })}

const test = {
  documentId: 'prismicDocId',
  fields: JSON.stringify([
    { type: 'text', name: 'firstName', value: 'Some Dudes Name' },
    { type: 'text', name: 'lastName', value: 'Last Name' },
  ]),
}

const schema = Joi.object().keys({
  documentId: Joi.string()
    .max(255, 'Must be less than 255 characters')
    .required(),
  fields: Joi.array()
    .items(
      Joi.object().keys({
        type: Joi.string()
          .valid(
            TYPE_BOOLEAN,
            TYPE_DATE,
            TYPE_EMAIL,
            TYPE_MESSAGE,
            TYPE_PHONE,
            TYPE_REWARDS,
            TYPE_TEXT,
            TYPE_TIME,
          )
          .required(),
        name: Joi.string()
          .max(255, 'Must be less than 255 characters')
          .required(),
        value: Joi.any()
          .required()
          .when('type', { is: TYPE_BOOLEAN, then: Joi.boolean() })
          .when('type', { is: TYPE_DATE, then: Joi.date() })
          .when('type', {
            is: TYPE_EMAIL,
            then: Joi.string()
              .email()
              .max(255, 'Must be less than 255 characters'),
          })
          .when('type', { is: TYPE_MESSAGE, then: Joi.string().max(5000) })
          .when('type', {
            is: TYPE_PHONE,
            then: Joi.string().regex(/^[2-9]\d{2}-\d{3}-\d{4}$/),
          })
          .when('type', {
            is: TYPE_REWARDS,
            then: Joi.string().regex(/^\d{3}-\d{3}-\d{3}$/),
          })
          .when('type', { is: TYPE_TEXT, then: Joi.string().max(255, 'Must be less than 255 characters') })
          .when('type', {
            is: TYPE_TIME,
            then: Joi.string().regex(
              /^((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))$/,
            ),
          }),
      }),
    )
    .min(1)
    .required(),
})
*/
