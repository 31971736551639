import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import Flickity from '../common/Flickity'
import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text from '../common/Text'
import LinkComponent from '../common/Link'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'
import linkResolver from '../../utils/linkResolver'
import WaveLogo from '../../assets/logo-mark.inline.svg'
import { ICON_ATLAS, iconCss } from '../common/IconAtlas'

const TickerItem = ({ item, index }) => {
  const titleRef = useRef()
  const icon = get(item, 'icon')
  const title = get(item, 'ticker_title.text')
  const body = get(item, 'ticker_body.text')
  const label = get(item, 'label', 'MORE INFO')

  const link = {
    href: linkResolver(item, 'link'),
    target: get(item, 'link.target'),
  }
  const Icon = icon && get(ICON_ATLAS, icon)

  return (
    <Item key={`t${index}`}>
      <IconContainer>{icon && Icon && <Icon />}</IconContainer>
      <div>
        <TextGroup>
          {title && (
            <Text
              as="h4"
              size="xl"
              bold
              spacing={1}
              lineHeight={rem(28)}
              ref={titleRef}
              uppercase
            >
              {title}
            </Text>
          )}
          {body && (
            <Text.h5Alt medium bold={false}>
              {ReactHtmlParser(body)}
            </Text.h5Alt>
          )}
        </TextGroup>
        {link.href && (
          <StyledLink {...link}>
            <Text size="xs" medium lineHeight="23px" color="secondaryBlue">
              {label}
            </Text>
          </StyledLink>
        )}
      </div>
    </Item>
  )
}

TickerItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
}

const TickerModule = ({ primary, items, slice_type, ...props }) => {
  const { focusStyleOn } = useAppContext()
  const autoPlay = (get(primary, 'time') || 6) * 1000
  const showLogo = get(primary, 'show_logo') === 'Yes'

  return (
    <SliceSection fullWidth noPaddingTop noPaddingBottom {...props}>
      <Container showLogo={showLogo}>
        <Flickity
          key={focusStyleOn}
          className="flickity"
          type={slice_type}
          options={{
            accessibility: focusStyleOn,
            autoPlay,
            contain: true,
            cellAlign: 'center',
            pauseAutoPlayOnHover: true,
            prevNextButtons: false,
          }}
        >
          {items &&
            items.map((item, i) => (
              <TickerItem key={`t${i}`} item={item} index={i} />
            ))}
          {items &&
            items.length < 6 &&
            items.map((item, i) => (
              <TickerItem key={`t2${i}`} item={item} index={i + items.length} />
            ))}
        </Flickity>
      </Container>
      {showLogo && (
        <Wave>
          <WaveLogo />
        </Wave>
      )}
    </SliceSection>
  )
}

TickerModule.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
  slice_type: PropTypes.string,
}

export default TickerModule

const Container = styled.div`
  ${({ showLogo, theme }) => css`
    position: relative;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;

    > div {
      width: 100%;
    }
  `}
`

const Wave = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
  pointer-events: none;
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;

    height: ${rem(165)};
    max-height: ${rem(165)};
    width: calc(100vw - ${rem(40)});

    ${theme.media.md`
      width: ${rem(400)};
    `};

    margin-right: ${theme.space(2)};
    padding: ${theme.space(1.5)} ${theme.space(2)};

    background-color: ${theme.color.softBlue};
    border-radius: ${theme.layout.borderRadius};
    cursor: grab;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  `};
`

const TextGroup = styled.div`
  > h4 {
    margin-bottom: ${rem(8)};
  }
`

const IconContainer = styled.div`
  ${({ theme }) => css`
    height: ${rem(25)};
    margin-top: ${rem(2)};
    margin-right: ${theme.space(2)};

    ${iconCss(theme.color.accentLightBlue)};
  `};
`

const StyledLink = styled(LinkComponent)`
  ${({ theme }) => css`
    > div {
      ${theme.mixin.secondaryBlueHover};
      margin-top: 10px;
    }
  `};
`
