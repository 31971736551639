import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text, { setFontSize, sizes } from '../common/Text'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import decodeBodyHtml from '../../utils/decodeBodyHtml'
import { transformLinks } from '../../utils/textTransforms'

const ArticleModule = ({ primary: data, ...props }) => {
  const body = get(data, 'article_body.html')
  const bgColor = get(data, 'background_color') || 'transparent'

  const decodedBody = useMemo(() => decodeBodyHtml(body), [body])

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container>
        {decodedBody && (
          <Text.article html htmlMargin wysiwyg>
            {ReactHtmlParser(decodedBody, { transform: transformLinks })}
          </Text.article>
        )}
      </Container>
    </SliceSection>
  )
}

ArticleModule.propTypes = {
  primary: PropTypes.object,
}

export default ArticleModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;

    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidthArticle};

    margin: 0 ${theme.space(3)};

    h3 {
      margin-top: ${rem(30)};
      margin-bottom: ${rem(20)};
    }

    > div {
      width: 100%;
    }

    article {
      p:not(:last-child) {
        margin-bottom: ${theme.space(2)};
      }

      ul,
      ol {
        margin: ${theme.space(2)} 0;
      }
      p,
      li {
        ${setFontSize(theme.fontSizesSmall.lg)};

        ${theme.media.lg`
          ${setFontSize(sizes.lg)};
        `};
      }
    }

    ${theme.media.lg`
        flex-direction: row;
      `};
  `};
`
