import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactHtmlParser from 'react-html-parser'
import { StyledButton } from '../../common/Button'
import { get } from 'lodash'
import getPath from '../../../../lib/getPath'
import { CARD_STYLES } from '../../../../lib/constants'
import { rem } from 'polished'
import { motion } from 'framer-motion'
import {
  ButtonContainer,
  Image,
  TextBody,
  TextContainer,
  transform,
  TextTitle,
} from './GridItemCommon'
import linkResolver from '../../../utils/linkResolver'

const StripGridItem = ({ item, style, tags }) => {
  const data = get(item, 'grid_content.document.data')

  const image = get(data, 'image')
  const title = get(data, 'card_title.html')
  const body = get(data, 'card_body.html')
  const label = get(data, 'button_label')

  const showButton = ![CARD_STYLES.LANDING_CARD].includes(style)
  const showBody = ![CARD_STYLES.IMAGE_HEADLINE_CARD].includes(style)
  const showImage = ![CARD_STYLES.HEADLINE_COPY_CARD].includes(style)

  //temp
  const buttonLink = {
    href: linkResolver(data, 'button_link') || getPath({ data }),
    target: get(data, 'button_link.target'),
  }

  const buttonContent = (
    <ButtonContainer>
      {buttonLink.href && showButton && (
        <StyledButton {...buttonLink}>{label || 'Read more'}</StyledButton>
      )}
    </ButtonContainer>
  )

  const imageContent = (
    <>
      {image && (
        <ImageContainer>
          <Image src={image.url} alt={title || ''} />
        </ImageContainer>
      )}
    </>
  )
  //Transition Stuff

  return (
    <Item>
      <Content styleType={style}>
        {showImage && imageContent}
        <TextContainer>
          {title && (
            <TextTitle medium html>
              {ReactHtmlParser(title, { transform })}
            </TextTitle>
          )}
          {body && showBody && (
            <TextBody
              medium
              size="xs"
              lineHeightMultiplier={19 / 13}
              color="#4A5D7C"
            >
              {ReactHtmlParser(body)}
            </TextBody>
          )}
        </TextContainer>
        {buttonContent}
      </Content>
    </Item>
  )
}

StripGridItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.node),
}

export default StripGridItem

const ImageContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    border-radius: ${theme.layout.borderRadius} 0 0 ${theme.layout.borderRadius};
    height: auto;
    overflow: hidden;
    background-color: ${theme.color.blue};

    img {
      border-radius: ${theme.layout.borderRadius} 0 0
        ${theme.layout.borderRadius};
      height: 100%;
      max-width: ${rem(210)};
    }
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: ${theme.layout.borderRadius};
    box-shadow: 0 5px 10px 0 rgba(29, 53, 91, 0.05);

    > div {
      height: 100%;
    }

    &:hover {
      ${Image} {
        // transform: scale(1.03);
        // opacity: 0.75;
      }
    }
  `};
`

const Content = styled(motion.div)`
  ${({ theme, styleType }) => css`
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    min-height: ${rem(130)};

    background-color: #fff;

    ${TextContainer} {
      padding: ${rem(28)} 0px ${theme.space(3)} ${theme.space(3)};
      display: flex;
      flex-direction: column;
      justify-content: center;

      ${theme.media.xl`
        > * {
          max-width: 65%;
        }
      `};
    }

    ${ButtonContainer} {
      padding: 0 ${theme.space(2.5)};
      margin: 0;
      align-items: center;
    }

    ${StyledButton} {
      min-width: ${rem(160)};
    }

    ${TextTitle} {
      color: ${theme.color.blue};
      margin-bottom: 4px;
    }

    p {
      font-size: ${rem(14)};
      line-height: ${rem(20)};

      ${theme.media.lg`
          font-size: ${rem(13)};
          line-height: ${rem(19)};
        `};

      &:last-child {
        margin-bottom: 0;
      }
    }

    .img-spacer {
      display: block;
    }
  `};
`
