export const TYPE_BOOLEAN = 'boolean'
export const TYPE_DATE = 'date'
export const TYPE_EMAIL = 'email'
export const TYPE_MESSAGE = 'message'
export const TYPE_PHONE = 'phone'
export const TYPE_REWARDS = 'rewards'
export const TYPE_TEXT = 'text'
export const TYPE_TIME = 'time'

export const CHECKBOX_MODULE = 'checkbox_module'
export const DATE_MODULE = 'date_module'
export const DOB_MODULE = 'dob_module'
export const DISCLAIMER_MODULE = 'disclaimer_module'
export const MESSAGE_MODULE = 'message_module'
export const SIGNATURE_MODULE = 'signature_module'
export const STATE_MODULE = 'state_module'
export const TEXT_MODULE = 'text_module'
export const TIME_MODULE = 'time_module'
export const UPLOAD_MODULE = 'upload_module'

export const formTypeByModuleType = {
  [CHECKBOX_MODULE]: TYPE_BOOLEAN,
  [DATE_MODULE]: TYPE_DATE,
  [DOB_MODULE]: TYPE_DATE,
  [MESSAGE_MODULE]: TYPE_MESSAGE,
  [SIGNATURE_MODULE]: TYPE_TEXT,
  [STATE_MODULE]: TYPE_TEXT,
  [TEXT_MODULE]: TYPE_TEXT,
  [TIME_MODULE]: TYPE_TIME,
}
