import React from 'react'
import PropTypes from 'prop-types'

import {
  TYPE_EMAIL,
  TYPE_PHONE,
  TYPE_REWARDS,
} from '../../../constants/formTypes'

import ErrorText from './ErrorText'
import InputContainer from './InputContainer'
import Label from './Label'
import MaskedInput from 'react-maskedinput'

import { get } from 'lodash'

function formatPhoneNumber(value) {
  const cleaned = ('' + value).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) return `${match[1]}-${match[2]}-${match[3]}`
  return value
}

function formatRewards(value) {
  const cleaned = ('' + value).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/)
  if (match) return `${match[1]}-${match[2]}-${match[3]}`
  return value
}

const TextInput = ({
  columns,
  error,
  label,
  name,
  placeholder,
  handleChange,
  handleBlur,
  touch,
  validation,
  value,
  ...props
}) => {
  const hasError = touch && error
  const required = get(props, 'required') === 'yes'

  let mask
  let type = 'text'
  if (validation === TYPE_EMAIL) {
    type = TYPE_EMAIL
  } else if (validation === TYPE_PHONE) {
    type = 'tel'
    mask = '111-111-1111'
  } else if (validation === TYPE_REWARDS) {
    mask = '111-111-111'
  }

  const inputProps = {
    name,
    placeholder,
    required,
    type,
    onBlur: handleBlur,
  }

  const gridColumn = columns && columns !== 1 ? { gridColumn: '1 / 3' } : {}

  return (
    <InputContainer
      valid={!hasError && touch && value !== ''}
      error={hasError}
      css={gridColumn}
    >
      {label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      {mask ? (
        <MaskedInput
          {...inputProps}
          mask={mask}
          maxLength={mask.length}
          onChange={val => {
            const newValue = get(val, 'currentTarget.value')
            if (validation === TYPE_PHONE)
              handleChange(name, formatPhoneNumber(newValue))
            if (validation === TYPE_REWARDS)
              handleChange(name, formatRewards(newValue))
            else handleChange(name, newValue)
          }}
        />
      ) : (
        <input
          {...inputProps}
          onChange={val => {
            const newValue = get(val, 'currentTarget.value')

            handleChange(name, newValue)
          }}
        />
      )}
      <ErrorText show={hasError}>{error}</ErrorText>
    </InputContainer>
  )
}

TextInput.propTypes = {
  columns: PropTypes.number,
  error: PropTypes.string,
  handleBlur: PropTypes.func,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  touch: PropTypes.bool,
  validation: PropTypes.string,
  value: PropTypes.string,
}

export default TextInput
