import React from 'react'
import PropTypes from 'prop-types'

import AspectRatioBox from '../../common/AspectRatioBox'
import Text from '../../common/Text'
import { StyledButton } from '../../common/Button'
import {
  Image,
  Item,
  TextBody,
  TextContainer,
  TextTitle,
} from './GridItemCommon'

import { get, first } from 'lodash'
import getPath from '../../../../lib/getPath'
import { rem } from 'polished'
import { format } from 'date-fns'
import styled, { css } from 'styled-components'
import linkResolver from '../../../utils/linkResolver'
import truncate from '../../../utils/truncateText'

export const BLACKLIST_TAGS = ['featured', 'promo']

const PostGridItem = ({ item, page }) => {
  const data = get(item, 'grid_content.document.data')
  const tag = first(
    get(item, 'grid_content.document.tags', []).filter(
      v => !BLACKLIST_TAGS.includes(v.toLowerCase()),
    ),
  )

  const image = get(data, 'image')
  const title = get(data, 'card_title.text')
  const body = get(data, 'card_body.text')
  const label = get(data, 'button_label')

  const dateRaw = get(data, 'date')
  const date = dateRaw && format(new Date(dateRaw), "MMM d',' y").toUpperCase()

  const showImage = image && image.url

  const buttonLink = {
    href: linkResolver(data, 'button_link') || getPath({ data }),
    target: get(data, 'button_link.target'),
    state: { page },
  }

  return (
    <Item className="post-grid-item">
      <Content showImage={showImage}>
        <Top>
          {date && (
            <Text size="xs" uppercase medium>
              {date}
            </Text>
          )}
          {tag && (
            <PostTag>
              <Text bold lineHeightMultiplier={1.3} nowrap>
                {tag.toUpperCase()}
              </Text>
            </PostTag>
          )}
        </Top>

        <TextContainer>
          {showImage && (
            <AspectRatioBox border>
              <Image src={image.url} alt={title || ''} />
            </AspectRatioBox>
          )}

          {title && (
            <TextTitle.h5
              lineHeight={1.52}
              lineHeightLg={22 / 14}
              spacing={1.2}
            >
              {truncate(title, showImage ? 50 : 70)}
            </TextTitle.h5>
          )}
          {body && (
            <TextBody
              size="sm"
              lg="md"
              lineHeight={1.48}
              lineHeightLg={1.7}
              book
              html
            >
              {truncate(body, showImage ? 120 : 200)}
            </TextBody>
          )}
        </TextContainer>
        <StyledButton {...buttonLink} outline inline>
          {label || 'Read more'}
        </StyledButton>
      </Content>
    </Item>
  )
}

PostGridItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.string,
}

export default PostGridItem

const Content = styled.div`
  ${({ theme, showImage }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.space(2.5)} ${theme.space(3)} ${theme.space(3)};
    min-height: ${rem(470)};

    border-top: 3px solid ${theme.color.yellow};
    background-color: ${theme.color.softBlue};

    @media (min-width: 500px) {
      min-height: ${rem(520)};
    }

    @media (min-width: 650px) {
      min-height: ${rem(550)};
    }

    ${theme.media.lg`
      height: 100%;
    `};

    && {
      p {
        color: ${theme.color.neutralBlue};
        font-weight: inherit;
      }
    }

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    .img-spacer {
      display: block;
    }

    ${TextContainer} {
      display: flex;
      flex-direction: column;

      justify-content: ${showImage ? 'flex-start' : 'center'};
      align-content: space-between;

      height: 100%;
    }

    ${TextTitle.h5} {
      text-transform: uppercase;
      padding: ${showImage ? theme.space(2.5) : 0} 0 0;
    }

    ${TextBody} {
      && {
        letter-spacing: normal;
      }
    }

    ${StyledButton} {
      margin: ${theme.space(2)} 0 0;
      max-width: ${rem(132)};
    }
  `};
`

export const Top = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.space(2)};
  `}
`

export const PostTag = styled.div`
  ${({ theme }) => css`
    height: ${rem(20)};
    border-radius: ${rem(10)};
    background-color: ${theme.color.accentBlue};

    padding: ${theme.space(0.3)} ${theme.space(0.8)};
    display: flex;
    align-items: center;

    ${Text} {
      color: #fff;
      font-size: ${rem(11)};
    }
  `}
`
