import React, { useState } from 'react'
import PropTypes from 'prop-types'

import AspectRatioBox from '../common/AspectRatioBox'
import Flickity from '../common/Flickity'
import { SliceSection } from '../common/Section'
import ReactHtmlParser from 'react-html-parser'
import Text from '../common/Text'
import { Instagram } from '../common/Icons'

import { useAppContext } from '../../context/AppContext'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import { formatTweet } from '../../utils/formatText'
import { formatDistance } from 'date-fns'
import { WEIGHT } from '../../style/type'
import truncate from '../../utils/truncateText'

const InstagramItem = ({ bgColor, item }) => {
  const [hover, setHover] = useState(false)
  const toggle = () => setHover(!hover)
  const src = get(item, 'image')
  const text = get(item, 'text')
  const lastModified = get(item, 'last_modified').replace(' ', 'T')

  return (
    <Item
      hover={hover}
      onClick={toggle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <AspectRatioBox width={1} height={1}>
        <img src={src} alt="" />
        <Instagram className="icon" fill="#fff" width={20} height={20} />
        <Overlay>
          <OverlayText>
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ pointerEvents: hover ? 'all' : 'none' }}
            >
              <Instagram fill="#fff" width={20} height={20} />
            </a>
            {text && (
              <Text.p center white>
                {formatTweet(truncate(text))}
              </Text.p>
            )}
          </OverlayText>
          {lastModified && (
            <WhiteBox
              center
              color="neutralBlue"
              size="xs"
              lineHeight="20px"
              bg={bgColor === '#FFFFFF' ? '#f3f5f7' : '#FFFFFF'}
            >
              {`${formatDistance(new Date(lastModified), new Date())} ago`}
            </WhiteBox>
          )}
        </Overlay>
      </AspectRatioBox>
    </Item>
  )
}

InstagramItem.propTypes = {
  bgColor: PropTypes.string,
  item: PropTypes.object,
}

const InstagramModule = ({
  slice_type,
  primary: data,
  instagram,
  ...props
}) => {
  const { isMedium, focusStyleOn } = useAppContext()
  const title = get(data, 'instagram_title.text')
  const body = get(data, 'instagram_body.html')
  const bgColor = get(data, 'background_color') || 'transparent'

  const Wrapper = isMedium ? Grid : Flickity

  const flickityProps = isMedium
    ? {}
    : {
        key: focusStyleOn,
        options: {
          accessibility: focusStyleOn,
        },
      }

  if (instagram.length > 4) instagram.length = 4

  return (
    <SliceSection fullWidthSmall bgColor={bgColor} {...props}>
      <Container>
        <TextGroup>
          {title && <Text.h2 center>{title}</Text.h2>}
          {body && (
            <Text html center>
              {ReactHtmlParser(body)}
            </Text>
          )}
        </TextGroup>
        <Wrapper type={slice_type} {...flickityProps}>
          {instagram &&
            instagram.map(({ node: item }, i) => {
              return (
                <InstagramItem key={item.id} bgColor={bgColor} item={item} />
              )
            })}
        </Wrapper>
      </Container>
    </SliceSection>
  )
}

InstagramModule.propTypes = {
  instagram: PropTypes.array,
  primary: PropTypes.object,
  slice_type: PropTypes.string,
}

export default InstagramModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;

    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidth};

    > div {
      width: 100%;
    }
  `};
`

const Overlay = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    opacity: 0;
    transition: 0.5s opacity ease-in-out;

    background: radial-gradient(circle, #3e6594 0%, #1d355b 100%);
    border: 1px solid white;
    outline: 1px solid white;
  `};
`

const OverlayText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: calc(100% - ${rem(35)});

    padding: ${theme.space(2)};

    > a > svg {
      margin-bottom: ${theme.space(2)};
    }

    a {
      ${theme.mixin.opacityHover};
      color: ${theme.color.accentBlue};
      font-weight: ${WEIGHT.MEDIUM};
    }
  `};
`

const WhiteBox = styled(Text)`
  ${({ bg, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    height: ${rem(35)};
    width: 100%;
    background-color: ${bg};
    border: 1px solid white;
  `};
`

const TextGroup = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.space(2)};
  `};
`

const Grid = styled.div`
  ${({ columns, theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(${rem(120)}, 1fr);
    grid-gap: ${theme.space(1)} ${theme.space(1)};

    width: 100%;

    ${theme.media.sm`
      grid-template-columns: repeat(2, minmax(${rem(160)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};

    ${theme.media.xxl`
      grid-template-columns: repeat(4, minmax(${rem(160)}, 1fr));
    `};
  `};
`

const Item = styled.div`
  ${({ hover, theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    > div {
      position: relative;
      padding: 0 ${theme.space(1)};

      ${theme.media.md`
        padding: 0;
      `};
    }

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    ${hover &&
      css`
        img {
          opacity: 0;
        }

        ${Overlay} {
          opacity: 1;
          transition: 0.3s opacity ease-out;
        }
      `};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.9;
      transition: opacity 0.3s ease-out;
      border-radius: ${theme.layout.borderRadius};
    }

    .icon {
      position: absolute;
      right: ${theme.space(2)};
      bottom: ${theme.space(2)};
    }
  `};
`
