import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'

import { WEIGHT } from '../../style/type'
import { CALENDAR_MODULE_ID } from '../slices/CalendarModule'
import { TOURNAMENT_CALENDAR_MODULE_ID } from '../slices/TournamentCalendarModule'

import Page from '../container/Page'
import Section from '../common/Section'
import styled, { css } from 'styled-components'
import { AlphaGradientCorner } from '../common/AlphaGradient'
import Text from '../common/Text'
import ReactHtmlParser from 'react-html-parser'
import Button, { StyledButton } from '../common/Button'
import SplitGallery from '../common/SplitGallery'

import { get } from 'lodash'
import componentRenderer from '../componentRenderer'
import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'
import linkResolver from '../../utils/linkResolver'
import AspectRatioBox from '../common/AspectRatioBox'

const PokerRoomLandingPage = ({ pageContext: { staticData } }) => {
  const { isXLarge } = useAppContext()
  const pageData = usePreviewData(staticData)
  const { components, sections, social } = useParseSiteData(pageData)
  const { data } = pageData

  const title = get(data, 'header_title')
  const titleLabel = get(data, 'header_title_label')
  const description = get(data, 'header_description.html')

  const menusTitle = get(data, 'menus_title.text')
  const menusDescription = get(data, 'menus_description.html')
  const { url: menusImageUrl, alt: menusImageAlt } =
    get(data, 'menus_image') || {}
  const menusLinks = get(data, 'menus_links', [])

  const showFoodMenus = menusTitle && menusLinks && menusLinks.length

  let featured = get(data, 'featured')
  const hasFeatures = featured && !!featured.length
  if (hasFeatures) {
    featured = featured.map(item => ({
      ...item,
      ...get(item, 'event.document.data'),
    }))
  }

  const scrollTo = id => () => {
    const el = document.getElementById(id)
    if (el && el.scrollIntoView) {
      el.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  return (
    <Page {...sections}>
      <Section noPaddingBottom noPaddingTop>
        <TopBg />
        <Content>
          <PokerRoomHeader>
            <TextContainer>
              <AlphaGradientCorner />
              <TextContent>
                {title && isXLarge && (
                  <>
                    <Text.h2
                      white
                      spacing="normal"
                      lineHeight="28px"
                      css={{
                        marginBottom: rem(isXLarge ? 3 : 0),
                      }}
                    >
                      {titleLabel}
                    </Text.h2>
                    <Text.h1 white bold spacing="normal" xs={46}>
                      {title}
                    </Text.h1>
                  </>
                )}
                {title && !isXLarge && (
                  <Text.h2 white>
                    {titleLabel} {title}
                  </Text.h2>
                )}

                {description && (
                  <Text white html spacing="normal" medium>
                    {ReactHtmlParser(description)}
                  </Text>
                )}
              </TextContent>
              <ButtonContainer>
                <StyledButton
                  outline="white"
                  onClick={scrollTo(CALENDAR_MODULE_ID)}
                >
                  Poker Promotions & Tournaments
                </StyledButton>
                {/* <StyledButton
                  outline="white"
                  onClick={scrollTo(TOURNAMENT_CALENDAR_MODULE_ID)}
                >
                  VIEW TOURNAMENT CALENDAR
                </StyledButton> */}
              </ButtonContainer>
            </TextContainer>
            {hasFeatures && (
              <SplitGallery hasFeatures={hasFeatures} features={featured} />
            )}
          </PokerRoomHeader>
        </Content>
      </Section>
      {!!components && componentRenderer(components, { social })}
      {showFoodMenus && (
        <Section bgColor="softBlue">
          <MenusContainer>
            <MenusGrid>
              <MenusImageContainer>
                <AspectRatioBox width={450} height={280}>
                  <img src={menusImageUrl} alt={menusImageAlt} />
                </AspectRatioBox>
              </MenusImageContainer>
              <MenusInfoContainer>
                <Text.h4>{menusTitle}</Text.h4>
                <Text size="md" lineHeightMultiplier={25 / 15} book>
                  {ReactHtmlParser(menusDescription)}
                </Text>
                <MenusButtonsContainer>
                  {menusLinks.map((linkData, i) => {
                    const label = get(linkData, 'link_label')
                    const link = {
                      href: linkResolver(linkData, 'link'),
                      target: get(linkData, 'link.target'),
                    }
                    return (
                      <Button key={`link_${i}`} outline {...link}>
                        {label}
                      </Button>
                    )
                  })}
                </MenusButtonsContainer>
              </MenusInfoContainer>
            </MenusGrid>
          </MenusContainer>
        </Section>
      )}
    </Page>
  )
}
PokerRoomLandingPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default PokerRoomLandingPage

const MenusContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: ${theme.layout.maxWidth};
    width: 100%;
    margin-top: ${theme.space(1)};

    ${theme.media.lg`
      margin-top: 0;
    `};
  `}
`

const MenusGrid = styled.div`
  ${({ theme }) => css`
    display: grid;

    width: 100%;

    ${theme.media.lg`
      grid-template-columns: repeat(12, minmax(${theme.breakpointsPx.lg /
        12}px, 1fr));
      grid-auto-rows: minmax(${rem(120)}, 1fr);
      grid-gap: 0;
      ${MenusImageContainer} {
        grid-column: 1 / 5;
      }

      ${MenusInfoContainer} {
        grid-column: 6 / 13;
      }
    `}

    ${theme.media.xl`
      grid-template-columns: repeat(12, minmax(${theme.breakpointsPx.xl /
        12}px, 1fr));
      ${MenusImageContainer} {
        grid-column: 1 / 5;
      }

      ${MenusInfoContainer} {
        grid-column: 6 / 13;
      }
    `}

    ${theme.media.xxl`
      grid-template-columns: repeat(12, minmax(${theme.breakpointsPx.xxl /
        12}px, 1fr));
      ${MenusImageContainer} {
        grid-column: 2 / 6;
      }

      ${MenusInfoContainer} {
        grid-column: 7 / 12;
      }
    `}
  `}
`
const MenusInfoContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h4 {
      text-align: center;
      margin-top: -${theme.space(0.5)};
      margin-bottom: ${theme.space(2)};
    }

    > ${Text} {
      text-align: center;
      margin-bottom: ${theme.space(1.5)};
    }

    ${theme.media.lg`
      h4 {
        text-align: left;
        margin-top: 0;
        margin-bottom: ${theme.space(1)};
      }

      > ${Text} {
        text-align: left;
        margin-bottom: ${theme.space(1.5)};
      }
    `}
    ${theme.media.xl`
      justify-content: center;
    `}
  `}
`

const MenusImageContainer = styled.div`
  ${({ theme }) => css`
    flex-direction: column;
    justify-content: flex-start;
    display: none;

    ${theme.media.lg`
      display: block;
    `}

    ${theme.media.xl`
      justify-content: center;
    `}

    img {
      border-radius: ${theme.layout.borderRadius};
    }
  `}
`

const MenusButtonsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      width: auto;
      margin-bottom: ${theme.space(2)};
    }

    ${theme.media.lg`
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;

      > * {
        margin-right: ${theme.space(1.5)};
        margin-bottom: ${theme.space(1.5)};
        &:last-child {
          margin-right: 0;
        }
      }

    `}
  `}
`

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.space(3)};
    width: 100%;

    ${StyledButton} {
      width: 100%;
      margin-bottom: ${rem(10)};
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.media.xl`
      padding-top: ${theme.space(5)};
      max-width: ${rem(290)};
      align-items: flex-start;
    `}
  `}
`

const PokerRoomHeader = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    ${theme.media.xl`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};
      background-color: ${theme.color.blue};
      border-radius: ${theme.layout.borderRadius};
      overflow: hidden;
    `};
  `};
`

const TopBg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 33%;
    background-color: ${theme.color.softBlue};

    ${theme.media.lg`
      height: calc(100% - ${rem(100)});
    `};
  `};
`

const TextContent = styled.div`
  ${({ theme }) => css`
    > h2 {
      line-height: 32px;
    }

    > h1 {
      line-height: 1;

      > span {
        display: block;
        &:first-child {
          white-space: nowrap;
          line-height: 1;
        }
      }
    }

    > * {
      display: inline-block;
      text-align: center;
      width: 100%;

      ${theme.media.xl`
        width: auto;
        display: block;
        text-align: left;
      `}
    }

    p {
      font-weight: ${WEIGHT.MEDIUM};
      margin-top: ${theme.space(2)};
    }
  `};
`

const TextContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: ${theme.color.blue};
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    padding: ${theme.space(2)} ${theme.space(3)};
    margin-bottom: ${theme.space(1)};

    > svg {
      height: auto;

      ${theme.media.xl`
        max-width: 50vw;
      `};
    }

    ${theme.media.lg`
      padding: ${theme.space(5)} ${theme.space(4)} ${theme.space(5.5)}
      grid-column: 1 / 7;
    `};

    ${theme.media.xl`
      grid-column: 1 / 6;
      padding-right: 0;
    `};
  `};
`

const GridContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;

    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(3)};
    `};
  `};
`

const Pagination = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  `};
`

const Next = styled.button`
  ${({ theme }) => css`
    align-self: flex-end;
    margin-bottom: ${theme.space(8)};
    grid-column: 7/8;
    pointer-events: all;
    padding-right: ${theme.space(2)};
    cursor: pointer;

    ${theme.media.xxl`
      grid-column: -2;
    `};

    svg > * {
      transition: 0.3s fill;
    }

    &:hover {
      svg > * {
        fill: ${theme.color.yellow};
      }
    }
  `};
`

const Prev = styled(Next)`
  ${({ theme }) => css`
    grid-column: 1/2;
    padding-right: 0;
    padding-left: ${theme.space(2)};

    ${theme.media.xxl`
       grid-column: 2/3;
    `};
  `};
`

const FeaturedTextContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    padding: ${theme.space(3)};
    padding-bottom: ${theme.space(5)};
    margin: 0 auto;

    ${theme.media.lg`
        padding: 0;
        padding-bottom: ${theme.space(6)};
        grid-column: 2/7;
      `};

    ${theme.media.xxl`
      grid-column: 3/7;
    `};

    button,
    a {
      flex: 0;
      z-index: 1;
      margin: 0 auto;
      margin-top: ${theme.space(2)};
      width: 100%;

      ${theme.media.lg`
           width: auto;
        `}
    }

    h3 {
      line-height: 1.3;
      padding-top: ${theme.space(1)};
    }

    h5 {
      padding-top: ${theme.space(1)};
    }
  `}
`

const FeaturedItem = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    min-height: ${rem(370)};

    ${theme.media.lg`
      min-height: 0;
    `}

    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `};
`

const ImageContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;

      ${theme.media.lg`
         max-height: none;
      `};
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .flickity-prev-next-button {
      &.previous {
        left: ${theme.space(4)};
      }
      &.next {
        right: ${theme.space(4)};
      }
    }

    ${theme.media.xl`
      grid-column: 7 / 13;
    `};

    ${theme.media.xxl`
      grid-column: 6 / 13;
    `};
  `};
`
