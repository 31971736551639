import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  DINING_ITEM_MODULE,
  MENU_SECTION_TITLE_MODULE,
  TAG_GRID_MODULE,
  GRID_MODULE,
} from '../../constants/sliceTypes'

import Awards from '../components/dining/Awards'
import Button from '../common/Button'
import ErrorBoundary from '../common/ErrorBoundary'
import Link from '../common/Link'
import Section, { SliceSection } from '../common/Section'
import ReactHtmlParser from 'react-html-parser'
import Page from '../container/Page'
import PrevNextLinks from '../common/PrevNextLinks'
import Text from '../common/Text'
import TextExpander from '../common/TextExpander'
import {
  Facebook,
  Food,
  Hours,
  Location,
  Info,
  InstagramInColor,
  SmallLinkArrow,
  Twitter,
} from '../common/Icons'
import DiningGallery from '../components/dining/DiningGallery'
import SpecialDiscounts from '../components/dining/SpecialDiscounts'
import SocialMedia from '../components/dining/SocialMedia'

import componentRenderer from '../componentRenderer'
import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import { get, isNull } from 'lodash'
import styled, { css } from 'styled-components'
import { rem, transparentize } from 'polished'
import { useAppContext } from '../../context/AppContext'
import linkResolver from '../../utils/linkResolver'
import { formatGoogleMapsLink } from '../../utils/formatText'

//import opentable from '../../assets/open-table.png'

const DiningDetailPage = ({ pageContext: { staticData } }) => {
  const { isLarge } = useAppContext()
  const pageData = usePreviewData(staticData)
  const { components, sections, social } = useParseSiteData(pageData)
  const { data } = pageData

  const diningMenus = get(data, 'menu_links', [])
  const title = get(data, 'restaurant_name.text')
  const subTitle = get(data, 'sub_title.text')
  const bodyLen = get(data, 'dining_body.text.length')
  const body = get(data, 'dining_body.html')
  const addressLink = formatGoogleMapsLink(get(data, 'location.text'))
  const address = get(data, 'location')
  const phone = get(data, 'phone')
  const hoursBody = get(data, 'hours_body')
  const ageBody = get(data, 'age_body')
  const reservationLabel =
    get(data, 'reservation_label') || 'Make a reservation'

  const allLink = get(data, 'all_dining_link')

  const paginationLinks = {
    allLabel: get(data, 'all_dining_label'),
    allLink,
  }

  const gallery = {
    items: get(data, 'gallery'),
    label: get(data, 'gallery_label'),
    logo: get(data, 'logo_image'),
  }

  const specialDiscounts = {
    title: get(data, 'discount_title') || 'Special Discounts',
    items: get(data, 'discounts'),
  }

  const awards = {
    title: get(data, 'awards_title') || 'Awards',
    items: get(data, 'awards'),
  }

  const socialMedia = {
    title: get(data, 'social_title') || "Let's Connect",
    items: [
      { link: get(data, 'facebook_link'), icon: <Facebook /> },
      { link: get(data, 'twitter_link'), icon: <Twitter fill="#1DA1F2" /> },
      { link: get(data, 'instagram_link'), icon: <InstagramInColor /> },
    ],
  }

  const hasSocialMedia = !!socialMedia.items.filter(
    item => !isNull(get(item, 'link.url')),
  ).length

  const reservationLink = {
    href: linkResolver(data, 'reservation_link'),
    target: get(data, 'reservation_link.target'),
  }
  // const reservationIcon = {
  //   src: opentable,
  //   alt: get(data, 'reservation_link.alt') || '',
  // }

  function renderTitle(title, icon, staticTitle) {
    const titleText = get(data, `${title}.text`) || staticTitle

    return (
      <ItemHeader>
        {icon && <IconContainer>{icon}</IconContainer>}
        {titleText && (
          <Text
            as="h3"
            size={18}
            lg={20}
            lineHeight={rem(20)}
            lineHeightLg={rem(28)}
            spacing={1}
            uppercase
            bold
          >
            {ReactHtmlParser(titleText)}
          </Text>
        )}
      </ItemHeader>
    )
  }

  const slices = get(components, 'body') || []
  const diningItems = useMemo(
    () =>
      slices.reduce((acc, entry, i) => {
        console.log(entry)
        if (entry.slice_type === MENU_SECTION_TITLE_MODULE) {
          entry.list = []
          acc.push(entry)
        } else if (entry.slice_type === DINING_ITEM_MODULE) {
          const current = get(acc[acc.length - 1], 'list')
          if (current) current.push(entry)
        }
        return acc
      }, []),
    [slices],
  )
  console.log(diningItems)

  const checkForTagGrid = useMemo(
    () =>
      components.body.find(item => {
        return (
          item.slice_type === TAG_GRID_MODULE || item.slice_type === GRID_MODULE
        )
      }),
    [components.body],
  )

  const useVenueInfo = !!(
    address.text ||
    phone.text ||
    hoursBody.text ||
    !!diningMenus.length ||
    reservationLink.href
  )
  const checkOnlyHours =
    address.text.length + phone.text.length + diningMenus.length === 0
  const venueInfo = (
    <>
      {(address.text || phone.text) && (
        <SideContainer noPaddingTop>
          {renderTitle('location_title', <Location />, 'Location')}
          {address.text && (
            <Text
              center={!isLarge}
              size="sm"
              md="md"
              lineHeight={rem(20)}
              lineHeightLg={rem(25)}
            >
              <a href={addressLink} target="_blank" rel="noopener noreferrer">
                {ReactHtmlParser(address.html)}
              </a>
            </Text>
          )}
          {phone.text && (
            <Phone
              center={!isLarge}
              lineHeight={rem(23)}
              letter-spacing={rem(1.2)}
              size="xs"
            >
              {ReactHtmlParser(phone.html)}
            </Phone>
          )}
        </SideContainer>
      )}
      {hoursBody.text && (
        <SideContainer onlyHours={checkOnlyHours}>
          {renderTitle('hours_title', <Hours />, 'Hours')}
          <Text center={!isLarge} lineHeight={rem(20)} lineHeightLg={rem(25)}>
            {ReactHtmlParser(hoursBody.html)}
          </Text>
        </SideContainer>
      )}

      {diningMenus && !!diningMenus.length && (
        <>
          <SideContainer noPaddingBottom>
            {renderTitle('menus_title', <Food />, 'Menus')}
          </SideContainer>
          <MenuContainer hasMarginBottom={reservationLink.href}>
            {diningMenus.map((item, i) => {
              const link = {
                href: linkResolver(item, 'link'),
                target: get(item, 'link.target'),
              }
              return (
                <Link key={i} {...link}>
                  <Text size="md" medium>
                    {item.menu_label}
                  </Text>
                  <SmallLinkArrow width={7} height={12} />
                </Link>
              )
            })}
          </MenuContainer>
        </>
      )}
      {reservationLink && reservationLink.href && (
        <>
          <Reservation>
            {reservationLink && reservationLink.href && (
              <Button {...reservationLink}>{reservationLabel}</Button>
            )}
          </Reservation>
          {ageBody.text && ageBody.text.length > 0 && (
            <SideContainer paddingTopOnly>
              {renderTitle('age_title', <Info />, 'Age')}
              <Text size="sm" lg="md" center={!isLarge}>
                {ReactHtmlParser(ageBody.html)}
              </Text>
            </SideContainer>
          )}
        </>
      )}
      {!reservationLink &&
        !reservationLink.href &&
        ageBody.text &&
        ageBody.text.length > 0 && (
          <SideContainer noPaddingBottom>
            {renderTitle('age_title', <Info />, 'Age')}
            <Text size="sm" lg="md" center={!isLarge}>
              {ReactHtmlParser(ageBody.html)}
            </Text>
          </SideContainer>
        )}
    </>
  )

  return (
    <Page {...sections}>
      {!!get(gallery, 'items.length') && (
        <ErrorBoundary label="DiningGallery">
          <DiningGallery {...gallery} />
        </ErrorBoundary>
      )}
      <Section bgColor="#fff" noPaddingBottom>
        <Columns useAside={useVenueInfo}>
          <div>
            <TopText>
              {title && <Text.h1>{title}</Text.h1>}
              {subTitle && <Text.h4>{subTitle}</Text.h4>}
              <TextExpander len={bodyLen}>
                {body && <Text html>{ReactHtmlParser(body)}</Text>}
              </TextExpander>
            </TopText>
            {!isLarge && useVenueInfo && (
              <VenueInfo hasReservComponent={!!reservationLink.href}>
                {venueInfo}
              </VenueInfo>
            )}
            {diningItems &&
              !!diningItems.length &&
              diningItems.map(({ primary, list }, i) => {
                const sectionTitle = get(primary, 'menu_section_title.text')
                const columns = get(primary, 'columns')
                return (
                  <DiningSection key={`ds${i}`} index={i}>
                    <Text.h4 center={!isLarge}>{sectionTitle}</Text.h4>
                    <Grid columns={columns}>
                      {componentRenderer(
                        { body: list },
                        {
                          social,
                        },
                      )}
                    </Grid>
                  </DiningSection>
                )
              })}

            {!!get(specialDiscounts, 'items.length') && (
              <ErrorBoundary label="SpecialDiscounts">
                <SpecialDiscounts {...specialDiscounts} />
              </ErrorBoundary>
            )}
            {!!get(awards, 'items.length') && (
              <ErrorBoundary label="Awards">
                <Awards {...awards} />
              </ErrorBoundary>
            )}

            {hasSocialMedia && (
              <ErrorBoundary label="socialMedia">
                <SocialMedia {...socialMedia} />
              </ErrorBoundary>
            )}
          </div>
          {isLarge && useVenueInfo && <aside>{venueInfo}</aside>}
        </Columns>
      </Section>

      {components &&
        components.body &&
        components.body.length !== 0 &&
        checkForTagGrid && (
          <SliceSection
            fullWidth
            bgColor="softBlue"
            as="div"
            paddingProps={{
              top: 1,
              bottom: 1,
            }}
          >
            <Container>
              {componentRenderer(components, {
                filter: [DINING_ITEM_MODULE, MENU_SECTION_TITLE_MODULE],
                social,
                noPaddingBottom: true,
                noPaddingTop: true,
              })}
            </Container>
          </SliceSection>
        )}

      <PrevNextLinks hideBorder fullWidth {...paginationLinks} />
    </Page>
  )
}

DiningDetailPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default DiningDetailPage

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `}
`

const DiningSection = styled.div`
  ${({ theme }) => css`
    ${theme.layout.verticalPaddingTopMenu};

    h3 {
      margin-bottom: ${theme.space(2.5)};
    }
  `};
`

const Grid = styled.div`
  ${({ columns, theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${theme.space(1)} ${theme.space(1)};

    width: 100%;

    ${theme.media.xxl`
        grid-template-columns: repeat(${columns}, minmax(${rem(120)}, 1fr));
        grid-gap: ${theme.space(3)} ${theme.space(3)};
      `};
  `};
`

const VenueInfo = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${transparentize(0.5, theme.color.secondaryLightBlue)};
    border-bottom: 1px solid
      ${transparentize(0.5, theme.color.secondaryLightBlue)};
    margin: ${theme.space(4)} 0 0;
    padding: ${theme.space(4)} 0;
  `};
`

const TopText = styled.div`
  ${({ theme }) => css`
    padding: 0 calc(${theme.space(2)} - ${rem(10)});

    a {
      color: ${theme.color.secondaryBlue};
    }
    ${theme.media.md`
      padding: 0;
    `};
  `};
`

const Columns = styled.div`
  ${({ theme, useAside }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};
    `};

    > div,
    > aside {
      width: 100%;

      h1 {
        margin-bottom: ${theme.space(1.5)};
      }

      h4 {
        margin-bottom: ${theme.space(2)};
      }
    }

    > div {
      grid-column: 1 / 8;

      ${theme.media.lg`
        padding-right: ${theme.space(2)};

        ${!useAside &&
          css`
            grid-column: 2/12;
          `};
      `};

      ${theme.media.xl`
        padding-right: 0;
        grid-column: 2 / ${useAside ? 7 : 12};
      `};

      ${theme.media.xxl`
        grid-column: 2 / ${useAside ? 8 : 12};
      `};
    }

    > aside {
      grid-column: 9 / 13;

      ${theme.media.lg`
        grid-column: 8 / 13;
      `};

      ${theme.media.xl`
        grid-column: 8 / 12;
      `};

      ${theme.media.xxl`
        grid-column: 9 / 12;
      `};
    }
  `};
`

const IconContainer = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    width: ${theme.space(2.5)};
    transform: translateY(${rem(2.12)});

    ${theme.media.lg`
      transform: translateY(${rem(3.5)});
    `};
  `};
`

const ItemHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: ${rem(11)};

    ${theme.media.lg`
      justify-content: flex-start;
    `};

    ${Text} {
      transform: translateY(2px);

      ${theme.media.lg`
        transform: translateY(-1px);
      `};
    }

    img,
    svg {
      height: ${rem(20)};
      width: auto;
      object-fit: contain;
      object-position: left;
    }
  `};
`

const SideContainer = styled.div`
  ${({
    onlyHours,
    noPaddingBottom,
    noPaddingTop,
    paddingTopOnly,
    theme,
  }) => css`
    padding-bottom: ${onlyHours ? 0 : theme.space(3)};

    > div:not(:first-child) {
      ${theme.media.lg`
        padding-left: ${rem(26)};
      `};
    }

    ${paddingTopOnly &&
      css`
        padding-top: ${theme.space(3)};
        padding-bottom: 0;

        ${theme.media.lg`
          padding-bottom: 0;
        `};
      `};

    ${noPaddingBottom &&
      css`
        padding-bottom: 0;

        ${theme.media.lg`
          padding-bottom: 0;
        `};
      `};

    ${noPaddingTop &&
      css`
        padding-top: 0;

        ${theme.media.lg`
          padding-top: 0;
        `};
      `};
  `};
`

const MenuContainer = styled.div`
  ${({ theme, hasMarginBottom }) => css`
    border: ${theme.layout.border} solid
      ${transparentize(0.5, theme.color.secondaryLightBlue)};
    border-radius: ${theme.layout.borderRadius};
    margin-bottom: ${hasMarginBottom ? theme.space(3) : 0};
    margin-top: ${theme.space(3)};

    ${theme.media.lg`
      margin-top: 0;
      margin-bottom: ${hasMarginBottom ? theme.space(4) : 0};
    `};

    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: ${rem(55)};
      padding: 0 ${theme.space(1.5)};

      transition: background-color 0.4s ease-in;
      cursor: pointer;

      svg {
        transition: transform 0.4s ease-out;
      }

      ${theme.media.lg`
        padding: 0 ${theme.space(3)};
      `};

      &:hover {
        transition: background-color 0.3s ease-out;
        background-color: ${theme.color.softBlue};

        svg {
          transition: transform 0.3s ease-out;
          transform: translateX(${rem(5)});
        }
      }

      &:not(:last-child) {
        border-bottom: ${theme.layout.border} solid
          ${transparentize(0.5, theme.color.secondaryLightBlue)};
      }
    }
  `};
`

const Phone = styled(Text)`
  ${({ theme }) => css`
    margin-top: ${theme.space(1)};
    a,
    a:active,
    a:visited {
      ${theme.mixin.blueLink};

      &:after {
        bottom: -4px;
      }
    }
  `};
`

const Reservation = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.layout.borderRadius};

    ${theme.media.lg`
      padding: ${theme.space(2)};
      border: ${theme.layout.border} solid ${transparentize(
      0.5,
      theme.color.secondaryLightBlue,
    )};
    `};

    button,
    a {
      width: 100%;
      /* margin-bottom: ${theme.space(1)}; */
    }

    img {
      ${theme.mixin.opacityHover};
      width: ${rem(118)};
      height: auto;
      object-fit: scale-down;
      margin: 0 auto;
    }
  `};
`
