import React from 'react'
import PropTypes from 'prop-types'

import { CalendarWithDays } from '../../common/Icons'
import ErrorText from './ErrorText'
import InputContainer from './InputContainer'
import Label from './Label'
import DatePicker from 'react-datepicker'
import { datePickerStyle } from '../../common/DatePicker'

import { format } from 'date-fns'
import { get } from 'lodash'
import styled from 'styled-components'
import { rem } from 'polished'

const DateInput = ({
  columns,
  error,
  label,
  name,
  placeholder,
  handleChange,
  handleBlur,
  touch,
  validation,
  value,
  ...props
}) => {
  const hasError = touch && error
  const required = get(props, 'required') === 'yes'

  const inputProps = {
    name,
    required,
  }

  const zIndex = 9999
  const gridColumn =
    columns && columns !== 1 ? { gridColumn: '1 / 3', zIndex } : { zIndex }

  return (
    <StyledInputContainer
      error={hasError}
      css={gridColumn}
      valid={!hasError && touch && value !== ''}
    >
      {label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      <DateContainer>
        <DatePicker
          {...inputProps}
          placeholderText={placeholder || 'MM / DD / YYYY'}
          selected={(value && new Date(value)) || null}
          onChange={val => {
            handleChange(name, val ? format(new Date(val), 'MM/dd/yyyy') : '')
          }}
          onBlur={handleBlur}
        />
        <CalendarWithDays width={20} height={20} />
      </DateContainer>
      <ErrorText show={hasError}>{error}</ErrorText>
    </StyledInputContainer>
  )
}

DateInput.propTypes = {
  columns: PropTypes.number,
  error: PropTypes.string,
  handleBlur: PropTypes.func,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  touch: PropTypes.bool,
  validation: PropTypes.string,
  value: PropTypes.string,
}

export default DateInput

const StyledInputContainer = styled(InputContainer)`
  ${datePickerStyle};

  .react-datepicker {
    transform: translateY(${rem(-8)});

    &-wrapper {
      width: 100%;
    }
  }
`

const DateContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
  }
`
