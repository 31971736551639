import React from 'react'
import PropTypes from 'prop-types'

import ErrorText from './ErrorText'
import InputContainer from './InputContainer'
import Label from './Label'

import { get } from 'lodash'
import usStates from '../../../constants/usStates'

const StateInput = ({
  columns,
  error,
  label,
  name,
  placeholder,
  handleChange,
  handleBlur,
  touch,
  validation,
  value,
  ...props
}) => {
  const hasError = touch && error
  const required = get(props, 'required') === 'yes'

  const inputProps = {
    name,
    placeholder,
    required,
  }

  const gridColumn = columns && columns !== 1 ? { gridColumn: '1 / 3' } : {}

  return (
    <InputContainer
      error={hasError}
      css={gridColumn}
      valid={!hasError && touch && value !== ''}
    >
      {label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      <select
        {...inputProps}
        onChange={val => {
          const newValue = get(val, 'currentTarget.value')
          handleChange(name, newValue)
        }}
        onBlur={handleBlur}
      >
        <option value="" disabled>
          Select
        </option>
        {usStates.map((state, i) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
      <ErrorText show={hasError}>{error}</ErrorText>
    </InputContainer>
  )
}

StateInput.propTypes = {
  columns: PropTypes.number,
  error: PropTypes.string,
  handleBlur: PropTypes.func,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  touch: PropTypes.bool,
  validation: PropTypes.string,
  value: PropTypes.string,
}

export default StateInput
