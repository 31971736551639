import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import Text from './Text'
import { transparentize } from 'polished'
import { useAppContext } from '../../context/AppContext'

const TextExpander = ({ children, len }) => {
  const { isLarge } = useAppContext()
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  useEffect(() => {
    if (isLarge && open) setOpen(false)
  }, [isLarge, open])

  return (
    <Container>
      {isLarge || len < 400 ? (
        children
      ) : (
        <>
          <motion.div
            initial="collapsed"
            animate={open ? 'open' : 'collapsed'}
            exit="collapsed"
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 100 },
            }}
            transition={{
              duration: 0.4,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            {children}
          </motion.div>
          <Gradient open={open} />
          <button onClick={toggle} tabIndex={0}>
            <Text color="#465172" center medium size="xs">
              {open ? 'CLOSE' : 'READ MORE'}
            </Text>
          </button>
        </>
      )}
    </Container>
  )
}

TextExpander.propTypes = {
  len: PropTypes.number,
  children: PropTypes.node,
}

export default TextExpander

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    button {
      ${theme.mixin.opacityHover};
      ${theme.mixin.blueLink};
      position: relative;
      margin: ${theme.space(2)} auto 0;
      padding: 0;
      z-index: 2;

      transform: translateY(-5px);
    }
  `};
`

const Gradient = styled.div`
  ${({ open, theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      #fff 5%,
      ${transparentize(1, '#fff')} 100%
    );
    opacity: ${open ? 0 : 1};
    transition: opacity 0.3s ease-out;

    z-index: 1;
  `};
`
