import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'

const SectionWithoutVerticalPadding = styled.section`
  ${({ bgColor, center, fullWidthSmall, theme }) => css`
    position: relative;
    width: 100%;
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
    background-color: ${get(theme.color, bgColor) || bgColor};

    ${fullWidthSmall &&
      css`
        padding-left: 0;
        padding-right: 0;
      `};

    ${center &&
      css`
        display: flex;
        justify-content: center;
      `};

    ${theme.media.md`
      padding-left: ${rem(45)};
      padding-right: ${rem(45)};
    `};
  `};
`

SectionWithoutVerticalPadding.defaultProps = {
  bgColor: 'transparent',
  center: true,
}

SectionWithoutVerticalPadding.propTypes = {
  bgColor: PropTypes.string,
  center: PropTypes.bool,
  fullWidthSmall: PropTypes.bool,
}

export default SectionWithoutVerticalPadding
