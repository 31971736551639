import styled, { css } from 'styled-components'
import Text from './Text'
import { rem } from 'polished'

export const TagGroup = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
  `};
`

export const Tag = styled(Text.h6)`
  ${({ theme, fill }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    padding: 0 ${theme.space(1)};
    height: ${rem(22)};
    ${!fill
      ? css`
          border: 1px solid ${theme.color.accentBlue};
        `
      : css`
          background-color: ${theme.color.accentBlue};
        `};
    ${fill &&
      css`
        color: #fff;
      `};
    border-radius: 50px;
    margin-top: ${theme.space(1)};
    margin-right: ${theme.space(1)};
  `};
`
