import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import AspectRatioBox from './AspectRatioBox'
import ReactPlayer from 'react-player'
import useToggle from '../../hooks/useToggle'

import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const variants = {
  hover: { scale: 1.25 },
  transition: {
    duration: 0.3,
    ease: [0.04, 0.62, 0.23, 0.98],
  },
}

const Play = ({ ...props }) => (
  <PlayContainer {...props}>
    <Circle variants={variants} />
    <svg viewBox="0 0 100 100">
      <path
        d="M69.43 50.76L36.69 69a.93.93 0 01-1 .08 1 1 0 01-.4-.89V31.85a1 1 0 01.4-.89.93.93 0 011 .08l32.74 18.2a.79.79 0 010 1.52z"
        fill="#1d355b"
      />
    </svg>
  </PlayContainer>
)

const ResponsivePlayer = ({ image, url, ytProps }) => {
  const video = useRef()
  //const { on, toggle } = useToggle()

  return (
    <Container whileHover="hover">
      <AspectRatioBox width={16} height={9} border>
        <Player
          ref={video}
          //light={image}
          url={url}
          width="100%"
          height="100%"
          //onReady={toggle}
          config={{
            youtube: ytProps,
            vimeo: {
              playerOptions: {
                controls: 1,
              },
            },
          }}
        />
      </AspectRatioBox>
    </Container>
  )
}

ResponsivePlayer.propTypes = {
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  url: PropTypes.string,
  ytProps: PropTypes.object,
}

ResponsivePlayer.defaultProps = {
  ytProps: {
    playerVars: {
      //autoplay: 1,
      controls: 1,
      modestbranding: 1,
      rel: 0,
    },
  },
}

export default ResponsivePlayer

const Container = styled(motion.div)`
  position: relative;
`

const Player = styled(ReactPlayer)`
  z-index: 1;
  background-color: #000;

  .react-player {
    &__shadow {
      background: transparent;
    }

    &__play-icon {
      display: none;
    }
  }
`

const Circle = styled(motion.div)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: ${theme.color.yellow};
    border-radius: 100px;
    box-shadow: 0 2px 13px 0 rgba(29, 53, 91, 0.3);
    z-index: 1;
    transform: 0.3s transform;
  `};
`

const PlayContainer = styled(motion.div)`
  ${({ hover, theme }) => css`
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 100px;

    ${hover &&
      css`
        > div {
          width: 125px;
          height: 125px;
          transform: translate(${-25 / 2}px, ${-25 / 2}px);
        }
      `};

    svg {
      position: relative;
      width: 100px;
      height: 100px;
      z-index: 3;
    }
  `};
`

const Overlay = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
`
