import React, { useCallback, useEffect, useRef, useState } from 'react'
import Text from '../../common/Text'
import {
  getDate,
  getMonth,
  isFirstDayOfMonth,
  isToday,
  isSameDay,
  format,
  getDay,
  getWeekOfMonth,
} from 'date-fns'
import { get, first } from 'lodash'
import linkResolver from '../../../utils/linkResolver'
import LinkComponent from '../../common/Link'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { rem, transparentize } from 'polished'
import theme from '../../../style/theme'
import { DAYS } from '../../../constants/site'
import { SmallArrow, Plus } from '../../common/Icons'
import { localize } from 'date-fns/locale/en-US/index'
import { useAppContext } from '../../../context/AppContext'
import useGetCalendarDatesList from '../../../hooks/dates/useGetCalendarDatesList'

import ItemDetail, { ItemDetailContainer } from './CalendarItemDetail'
const CalendarMonthView = ({ events, month, year }) => {
  const datesOfMonth = useGetCalendarDatesList(events, month, year)
  return (
    <>
      <CalendarHeader>
        {DAYS.map((day, i) => (
          <CalendarHeaderDay key={`${day}_${i}`}>
            <Text size="xxs" bold selectNone>
              {day.label}
            </Text>
          </CalendarHeaderDay>
        ))}
      </CalendarHeader>
      <CalendarGrid>
        {datesOfMonth.map(({ date, events }, i) => (
          <CalendarDay
            key={`cal_day_${i}`}
            date={date}
            events={events}
            index={i}
          />
        ))}
      </CalendarGrid>
    </>
  )
}

CalendarMonthView.propTypes = {
  events: PropTypes.array,
  month: PropTypes.number,
  year: PropTypes.number,
}

function parseEventTime(timeStr) {
  // Normalize the time into a 24-hour format and return as minutes since midnight.
  let time = timeStr.match(/(\d+)(?::(\d\d))?\s*(p?)/i)
  if (!time) return null

  let hours = parseInt(time[1], 10)
  if (time[3]) {
    // PM
    if (hours !== 12) {
      hours += 12
    }
  } else {
    // AM
    if (hours === 12) {
      hours = 0
    }
  }
  let minutes = hours * 60 + (parseInt(time[2]) || 0)
  return minutes
}

export default CalendarMonthView

const CalendarDay = ({ events, date, index }) => {
  const { isXLarge } = useAppContext()
  const month =
    isFirstDayOfMonth(date) &&
    `${localize.month(getMonth(date), { width: 'abbreviated' })}`.toUpperCase()
  const label = getDate(date)
  const isDateToday = isToday(date)
  return (
    <CalendarDayContainer>
      <CalendarDayText isToday={isDateToday}>
        <Text.h5 color="secondaryBlue">
          {month && `${month} `}
          <span>{label}</span>
        </Text.h5>
      </CalendarDayText>
      <CalendarItemsContainer>
        {events
          .sort((a, b) => {
            let aTime = parseEventTime(
              get(a, 'grid_content.document.data.time.text'),
            )
            let bTime = parseEventTime(
              get(b, 'grid_content.document.data.time.text'),
            )

            if (aTime === null || bTime === null) {
              return 0
            }

            return aTime - bTime
          })
          .map((event, j) => (
            <CalendarEventItem
              index={index}
              short={event.short || events.length > 2 || !isXLarge}
              key={`cal_event_${date.getTime()}_${j}`}
              event={event}
              date={date}
            />
          ))}
      </CalendarItemsContainer>
    </CalendarDayContainer>
  )
}

CalendarDay.propTypes = {
  events: PropTypes.array,
  date: PropTypes.instanceOf(Date),
  index: PropTypes.number,
}

const CalendarEventItem = ({ event, short, date, index }) => {
  const { isXLarge } = useAppContext()
  const title = get(event, 'grid_content.document.data.card_title.text')
  const time = get(event, 'grid_content.document.data.time.text')
  const dates = get(event, 'grid_content.document.data.dates', []).map(
    ({ date }) => new Date(date),
  )

  const thisDate = first(dates.filter(d => isSameDay(d, date)))
  const link = {
    href: linkResolver(event, 'grid_content', false),
  }

  const [showPlus, setShowPlus] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [toggleDetail, setToggleDetail] = useState(false)
  const textRef = useRef()
  const containerRef = useRef()

  const dayOfWeek = getDay(date)
  const day = dayOfWeek > 1 ? dayOfWeek - 1 : 6
  const week = getWeekOfMonth(date)
  useEffect(() => {
    const Bowser = require('bowser')
    const browser = Bowser.getParser(window.navigator.userAgent)

    setIsTablet(browser.getPlatformType() === 'tablet')
  }, [])

  useEffect(() => {
    if (short && textRef.current && containerRef.current) {
      setShowPlus(
        textRef.current.offsetWidth > containerRef.current.offsetWidth - 50,
      )
    }
    if (!isXLarge) setShowPlus(true)
  }, [day, isXLarge, short, week])

  const align = css`
    ${index % 7 > 3
      ? css`
          right: ${theme.space(0.5)};
        `
      : css`
          left: ${theme.space(0.5)};
        `}
    ${2 - index / 5 < 0
      ? css`
          top: 0;
          transform: translateY(-100%);
        `
      : css`
          bottom: ${theme.space(0.5)};
          transform: translateY(100%);
        `}
  `

  const onToggleDetail = useCallback(() => setToggleDetail(!toggleDetail), [
    toggleDetail,
  ])

  const wrapperProps = {}
  if (isTablet) {
    wrapperProps.onClick = onToggleDetail
  }

  let Wrapper = isTablet ? LinkTouchWrapper : LinkComponent
  if (!link.href) {
    Wrapper = LinkTouchWrapper
  }

  return (
    <CalendarEventItemContainer
      short={short}
      ref={containerRef}
      align={align}
      isTablet={isTablet}
      toggleDetail={toggleDetail}
      {...wrapperProps}
    >
      <Wrapper
        {...link}
        aria-label={`${title} on ${format(
          date,
          "EEEE',' MMMM do',' yyyy",
        )} at ${time}`}
      >
        <Text
          ref={textRef}
          size="xxs"
          bold
          lineHeightMultiplier={1.1}
          uppercase
        >
          {title}
        </Text>
        {time && !short && (
          <Text size="xxs" bold lineHeightMultiplier={1.1} uppercase>
            {time}
          </Text>
        )}
        {link.href && !short && (
          <StyledSmallArrow fill="#92AAC5" width={20} height={20} />
        )}
        {short && showPlus && <StyledPlus fill={theme.color.blue} />}
      </Wrapper>
      <ItemDetail title={title} date={thisDate} time={time} link={link} />
    </CalendarEventItemContainer>
  )
}

CalendarEventItem.propTypes = {
  event: PropTypes.object,
  short: PropTypes.bool,
  index: PropTypes.number,
  date: PropTypes.instanceOf(Date),
}

const LinkTouchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const positionIconCss = ({ theme }) => css`
  position: absolute;
  right: ${theme.space(0.5)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
`

const StyledSmallArrow = styled(SmallArrow)`
  ${({ theme }) => css`
    ${positionIconCss};
  `}
`

const StyledPlus = styled(Plus)`
  ${({ theme }) => css`
    ${positionIconCss};
  `}
`

const hoverProps = ({ isTablet }) => css`
  ${ItemDetailContainer} {
    opacity: 1;
    pointer-events: auto;
    transition-delay: ${isTablet ? '0s' : '.7s'};
  }
`

const CalendarEventItemContainer = styled.div`
  ${({ theme, short, align, isTablet, toggleDetail }) => css`
    width: 100%;
    position: relative;

    &:hover {
      > a {
        border: 1px solid ${theme.color.secondaryLightBlue};
      }
    }

    > a,
    > ${LinkTouchWrapper} {
      position: relative;
      min-height: ${rem(45)};
      overflow: hidden;
      border-radius: ${theme.layout.borderRadius};
      background: ${theme.color.softBlue};
      padding: ${theme.space(0.5)} ${theme.space(3)} ${theme.space(0.5)}
        ${theme.space(1)};
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      border: 1px solid ${theme.color.softBlue};
      transition: 0.3s border;

      ${Text} {
        user-select: none;
        color: ${theme.color.blue} !important;
      }
    }
    padding: 0 ${theme.space(0.5)} ${theme.space(0.5)} ${theme.space(0.5)};

    ${ItemDetailContainer} {
      transition: opacity 0.4s;
      transition-delay: 0s;
      opacity: 0;
      pointer-events: none;
      ${align};
    }

    ${!isTablet &&
      css`
        &:hover {
          ${hoverProps};
        }
      `};

    ${toggleDetail &&
      css`
        ${hoverProps}
      `}

    ${short &&
      css`
        > * > ${Text} {
          position: relative;
          white-space: nowrap;
        }
        > a {
          min-height: 0;

          &:after {
            content: '';
            width: ${theme.space(7)};
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(
              90deg,
              ${transparentize(1, theme.color.softBlue)} 0%,
              ${transparentize(0, theme.color.softBlue)} 75%,
              ${transparentize(0, theme.color.softBlue)} 100%
            );
            z-index: 2;
          }
        }
      `}
  `}
`

const CalendarGrid = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: repeat(
      7,
      minmax(${rem(theme.breakpointsPx.md / 7)}, 1fr)
    );

    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    border-radius: 0 0 ${theme.layout.borderRadius} ${theme.layout.borderRadius};
    border-bottom: 1px solid ${theme.color.secondaryLightBlue};
    border-right: 1px solid ${theme.color.secondaryLightBlue};

    border-bottom-left-radius: ${theme.layout.borderRadius};
    overflow: hidden;
  `}
`

const CalendarHeader = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(
      7,
      minmax(${rem(theme.breakpointsPx.md / 7)}, 1fr)
    );
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    background-color: white;
    border-radius: ${theme.layout.borderRadius} ${theme.layout.borderRadius} 0 0;
    border-top: 1px solid ${theme.color.secondaryLightBlue};
    border-left: 1px solid ${theme.color.secondaryLightBlue};
    border-right: 1px solid ${theme.color.secondaryLightBlue};
  `}
`

const CalendarHeaderDay = styled.div`
  ${({ theme }) => css`
    grid-column: span 1;
    display: flex;
    justify-content: flex-end;

    ${Text} {
      padding: ${theme.space(1)} ${theme.space(1.5)} ${theme.space(1)} 0;
    }
  `}
`

const CalendarItemsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
  `}
`

const CalendarDayContainer = styled.div`
  ${({ theme }) => css`
    grid-column: span 1;
    min-height: 150px;
    border-top: 1px solid ${theme.color.secondaryLightBlue};
    border-left: 1px solid ${theme.color.secondaryLightBlue};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  `}
`

const CalendarDayText = styled.div`
  ${({ theme, isToday }) => css`
    display: flex;
    justify-content: flex-end;

    h5 {
      margin: ${theme.space(1)} ${theme.space(1.5)} ${theme.space(1)} 0;
    }

    ${isToday &&
      css`
        > *:last-child {
          position: relative;
          span {
            position: relative;
          }
          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            width: ${rem(30)};
            height: ${rem(30)};
            background-color: ${theme.color.yellow};
            border-radius: 20px;
          }
        }
      `}
  `}
`
