import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../common/Button'
import Text from '../../common/Text'
import { Tag } from '../../common/Tags'

import { convertNodeToElement } from 'react-html-parser'
import getPath from '../../../../lib/getPath'
import { rem, transparentize } from 'polished'
import styled, { css } from 'styled-components'
import { iconCss } from '../../common/IconAtlas'

const LINK_HEIGHT = 40

export function transform(node, index) {
  const { type, name } = node
  if (type === 'tag' && name !== 'h4') {
    return convertNodeToElement({ ...node, name: 'h4' }, index, transform)
  }
}

export function parseLink({
  link_label,
  link: { link_type, url, document, target },
}) {
  if (link_type === 'Web') {
    return {
      href: url,
      label: link_label,
      target,
    }
  } else if (link_type === 'Document') {
    return {
      href: getPath(document),
      label: link_label,
    }
  }
}

export function parseExtraLinks(extraLinks) {
  const actualLinks = extraLinks.filter(
    ({ link: { link_type, ...link } }) =>
      (link.document || link.url) && link_type !== 'Media',
  )
  const links = actualLinks.map(parseLink)

  return links
}

export const GridItemTag = ({ onClick, label, ...props }) => {
  return (
    <StyledGridItemTag as="div" onClick={onClick} {...props}>
      {label}
    </StyledGridItemTag>
  )
}

GridItemTag.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
}

const StyledGridItemTag = styled(Tag)`
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s, opacity 0.3s;
`

export const ToggleButton = styled(Button)`
  ${({ theme }) => css`
    min-width: ${rem(44)};
    max-width: ${rem(50)};
    flex-shrink: 0;
  `}
`

const sharedBadge = ({ theme }) => css`
  ${iconCss(theme.color.blue)};

  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: ${rem(30)};
  height: ${rem(30)};

  svg {
    height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:first-child {
      * {
        stroke: none;
      }
    }
  }
`

const sharedBadgeShape = ({ theme }) => css`
  width: ${rem(70)};
  height: ${rem(70)};
  background-color: transparent;

  &:before {
    content: '';
    border-top: ${rem(80)} solid ${theme.color.yellow};
    border-left: ${rem(80)} solid transparent;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
  }

  svg {
    top: 35%;
    left: 66%;
    height: ${rem(28)};
  }
`

export const BadgeContainerTriangle = styled.div`
  ${sharedBadgeShape};
  ${sharedBadge};

  svg {
    top: 50%;
    left: 50%;
    transform: translate(-80%, -30%);
  }
`

export const BadgeContainer = styled.div`
  ${({ theme, badge }) => css`
    ${sharedBadge};

    ${theme.media.lg`
      ${sharedBadgeShape};
    `}
  `}
`

export const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: ${theme.layout.borderRadius};

    margin-left: ${rem(10)};
    margin-right: ${rem(10)};
    max-width: calc(100% - ${rem(20)});

    &:hover {
      ${theme.mixin.imgHover};
    }

    ${theme.media.md`
      margin-left: 0;
      margin-right: 0;
      max-width: none;
    `};
  `};
`

export const ImagePositioner = styled.div`
  width: 100%;
  z-index: 0;
`

export const WideImagePositioner = styled.div`
  ${({ image }) => css`
    z-index: 0;

    ${image &&
      image.url &&
      css`
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(${image.url});
      `}
  `}
`

export const ButtonContainer = styled.div`
  ${({ toggleOn, theme, fullWidth }) => css`
    display: flex;
    justify-content: center;
    padding: 0 ${theme.space(2.5)};
    margin: ${theme.space(0.5)} 0 ${theme.space(2.5)};

    ${ToggleButton} {
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:last-child {
        margin-left: ${theme.space(1)};
        border: ${theme.layout.border} solid
          ${toggleOn ? '#fff' : theme.color.yellow};
        background-color: ${toggleOn ? '#fff' : 'transparent'};

        svg {
          width: ${rem(toggleOn ? 17 : 25)};
          height: ${rem(toggleOn ? 17 : 5)};
        }
      }

      transition: background 0.3s, border 0.3s;

      &:hover {
        background-color: ${theme.color.yellow};
        border: 1px solid ${theme.color.yellow};

        svg path {
          fill: ${theme.color.blue};
        }
      }
    }
  `};
`

export const TextBody = styled(Text)`
  ${({ theme }) => css``}
`

export const TextContainer = styled.div`
  flex: 1;

  ${TextBody} {
    margin-bottom: ${({ theme }) => theme.space(1)};
  }

  h4,
  h5 {
    margin-bottom: ${({ theme }) => theme.space(1)};
  }

  z-index: 2;
`

export const TextDate = styled(Text.h5)`
  ${({ theme }) => css`
    && {
      color: #fff;
      margin-bottom: ${theme.space(1)};
    }
  `}
`

export const TextTitle = styled(Text)`
  ${({ theme }) => css``}
`

export const LinksList = styled(Text.motion)`
  ${({ theme }) => css`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;

    background-color: #fff;

    ul {
      list-style-image: none;
    }

    li,
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0;
      margin: 0;
      height: ${rem(LINK_HEIGHT)};

      &:not(:last-child) {
        border-bottom: 1px solid
          ${transparentize(0.5, theme.color.secondaryLightBlue)};
      }
    }

    &:hover {
      color: ${theme.color.blue};
    }
  `};
`
