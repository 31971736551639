import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Text from './Text'

import styled from 'styled-components'

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any,
    fill: PropTypes.bool,
    label: PropTypes.string,
    test: PropTypes.bool,
  }

  state = {
    hasError: false,
    error: null,
    toggle: false,
  }

  componentDidCatch(error, info) {
    const { label } = this.props
    this.setState({ hasError: true, error })

    console.warn('Error:', label, error, info)
  }

  render() {
    const { children, fill, label, test } = this.props
    const { error, toggle } = this.state

    return test || this.state.hasError ? (
      <ErrorDisplay
        directionColumn
        fill={fill}
        onClick={() => this.setState({ toggle: true })}
      >
        <Text semiBold center uppercase>
          Something went wrong.
        </Text>

        {!!label && (
          <Text.p center color="red" sm="sm" size="md">
            {label}
          </Text.p>
        )}

        {toggle && !!error && (
          <Text.p center sm="sm" size="md">
            {error}
          </Text.p>
        )}
      </ErrorDisplay>
    ) : (
      children
    )
  }
}

export default ErrorBoundary

const ErrorDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ fill }) => (fill ? '100vw' : '100%')};
  height: 100%;
  min-height: 300px;
  text-align: center;

  h2 {
    margin: 0.5em 0;
  }
`
