import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import Button from '../common/Button'
import { PrevNextArrow } from '../common/Icons'
import Section from '../common/Section'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { color } from '../../style/theme'
import { useAppContext } from '../../context/AppContext'
import { rem } from 'polished'
import linkResolver from '../../utils/linkResolver'
import { isNull } from 'lodash'

const getLink = link => ({
  href: linkResolver({ link }, 'link'),
})

const iconSize = {
  width: 24,
  height: 24,
}

const PrevNextLinks = ({
  bgColor,
  hideBorder,
  fullWidth,
  prevUrl,
  prevTitle,
  allLink,
  allLabel,
  nextUrl,
  nextTitle,
}) => {
  const { isLarge } = useAppContext()

  const spacer = !isLarge && !prevUrl && !nextUrl ? null : <Spacer />

  return (
    <Section bgColor={bgColor} noPaddingTop noPaddingBottom fullWidth>
      <Content fullWidth={fullWidth} hideBorder={hideBorder}>
        <NextSection center={isNull(spacer)}>
          {prevUrl ? (
            <PrevLink to={prevUrl}>
              <Row>
                <PrevNextArrow {...iconSize} fill={color.yellow} left />
                {isLarge && <Text.h5>{prevTitle}</Text.h5>}
              </Row>
            </PrevLink>
          ) : (
            spacer
          )}
          {allLink && (
            <Button size="xxs" outline {...getLink(allLink)}>
              {allLabel}
            </Button>
          )}
          {nextUrl ? (
            <NextLink to={nextUrl}>
              <Row>
                {isLarge && <Text.h5>{nextTitle}</Text.h5>}
                <PrevNextArrow {...iconSize} fill={color.yellow} />
              </Row>
            </NextLink>
          ) : (
            spacer
          )}
        </NextSection>
      </Content>
    </Section>
  )
}

PrevNextLinks.propTypes = {
  allLink: PropTypes.object,
  allLabel: PropTypes.string,
  bgColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideBorder: PropTypes.bool,
  nextUrl: PropTypes.string,
  nextTitle: PropTypes.string,
  prevUrl: PropTypes.string,
  prevTitle: PropTypes.string,
}

export default PrevNextLinks

const Spacer = styled.div`
  ${({ theme }) => css`
    min-width: ${rem(26)};
    margin: ${theme.space(1)};
  `};
`

const Content = styled.div`
  ${({ fullWidth, hideBorder, theme }) => css`
    width: 100%;

    ${!fullWidth &&
      css`
        max-width: ${theme.layout.maxWidth};
      `};

    ${!hideBorder &&
      css`
        border-top: 1px solid ${theme.color.secondaryLightBlue};
      `};

    padding: ${theme.space(1)} ${rem(10)};

    ${theme.media.md`
        padding: ${theme.space(2)} ${rem(20)};
    `};

    ${theme.media.lg`
        padding: ${theme.space(2)} ${rem(30)};
    `};

    ${theme.media.xxl`
        padding: ${theme.space(2)} 0;
    `};
  `};
`

const NextSection = styled.div`
  ${({ center, theme }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${theme.space(1)} 0;

    a,
    button {
      margin: 0 ${center ? 'auto' : theme.space(1)};
      flex-shrink: 0;

      @media (min-width: 1450px) {
        margin: 0;
      }
    }

    ${theme.media.lg`
       button {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
        }
      `};
  `};
`

const sharedLink = ({ theme }) => css`
  cursor: pointer;

  * {
    transition: 0.3s fill;
  }

  &:hover {
    * {
      fill: ${theme.color.blue};
      transition: 0.3s fill;
    }
  }

  ${theme.media.lg`
    max-width: 35%;
  `};
`

const PrevLink = styled(Link)`
  ${({ theme }) => css`
    ${sharedLink};
    /* visibility: hidden;
    pointer-events: none; */

    margin-right: ${theme.space(2)};

    svg {
      margin-right: ${theme.space(2)};
    }

    ${theme.media.lg`
        display: flex;
        pointer-events: all;
      `};
  `};
`

const NextLink = styled(Link)`
  ${({ theme }) => css`
    ${sharedLink};

    margin-left: ${theme.space(2)};

    svg {
      margin-left: ${theme.space(2)};
    }

    ${theme.media.lg`
      margin-bottom: 0;

      h5 {
        text-align: right;
      }
    `};
  `};
`

const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
  `};
`
