import React from 'react'
import PropTypes from 'prop-types'

import { SliceSection } from '../common/Section'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import theme, { color } from '../../style/theme'
import linkResolver from '../../utils/linkResolver'
import LinkComponent from '../common/Link'
import { useAppContext } from '../../context/AppContext'

const SectionTitleModule = ({ as, primary: data, ...props }) => {
  const { isMedium } = useAppContext()
  const title = get(data, 'section_title.text')
  const leftAligned = get(data, 'left_aligned') === 'on'
  const subTitle = get(data, 'section_sub_title.text')
  const linkLabel = get(data, 'misc_link_label') || 'See All'
  const bgColor = get(data, 'background_color') || color.softBlue

  const link = {
    href: linkResolver(data, 'misc_link'),
    target: get(data, 'misc_link.target'),
  }

  const align = {}
  if (!leftAligned || !isMedium) align.center = true

  return (
    <SliceSection as={as} bgColor={bgColor} {...props}>
      <Container>
        {title && <Text.h3 {...align}>{title}</Text.h3>}
        {subTitle && <Text.h5 {...align}>{subTitle}</Text.h5>}
        {link.href && (
          <ButtonContainer>
            <LinkComponent {...link}>
              <Text
                uppercase
                size="sm"
                medium
                color={theme.color.secondaryBlue}
              >
                {linkLabel}
              </Text>
            </LinkComponent>
          </ButtonContainer>
        )}
      </Container>
    </SliceSection>
  )
}

SectionTitleModule.propTypes = {
  primary: PropTypes.object,
  as: PropTypes.string,
  paddingProps: PropTypes.object,
}

SectionTitleModule.defaultProps = {
  as: 'section',
}

export default SectionTitleModule

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: ${theme.space(1)};

    ${theme.media.md`
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 10;
      margin-top: 0;
      a {
        display: block;
      }
    `}

    a {
      transition: opacity 0.3s ease-out;
      display: inline-block;
      > ${Text} {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: ${theme.color.secondaryLightBlue};
        }
      }
    }

    a:hover {
      opacity: 0.7;
    }
  `}
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    max-width: ${theme.layout.maxWidth};
  `};
`
