import { useState, useCallback } from 'react'
import { localize } from 'date-fns/locale/en-US/index'

const today = new Date()

export const initMonthState = {
  month: today.getMonth(),
  year: today.getFullYear(),
}

const useChangeMonthYear = (startDate = today) => {
  const [month, setMonth] = useState(startDate.getMonth())
  const [year, setYear] = useState(startDate.getFullYear())

  const onNextMonth = useCallback(() => {
    if (month < 11) {
      setMonth(month + 1)
    } else {
      setMonth(0)
      setYear(year + 1)
    }
  }, [month, year])

  const onPrevMonth = useCallback(() => {
    if (month < 1) {
      setMonth(11)
      setYear(year - 1)
    } else {
      setMonth(month - 1)
    }
  }, [month, year])

  return {
    onNextMonth,
    onPrevMonth,
    month,
    year,
    text: `${localize.month(month)} ${year}`,
  }
}

export default useChangeMonthYear
