import React from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import Section, { SliceSection } from '../common/Section'
import RewardsAccordian from '../components/rush-rewards-matrix/RewardsAccordian'
import RewardsTable from '../components/rush-rewards-matrix/RewardsTable'
import Text, { setFontSize, sizes } from '../common/Text'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'
import { useAppContext } from '../../context/AppContext'

const RushRewardsMatrixModule = ({ primary, ...props }) => {
  const { isLarge } = useAppContext()
  const microCopy = get(primary, 'micro_copy.html')
  const bgColor = get(primary, 'background_color') || 'transparent'

  const items = get(props, 'items')

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container>
        {isLarge ? (
          <RewardsTable items={items} {...primary} />
        ) : (
          <RewardsAccordian items={items} {...primary} />
        )}
        {microCopy && (
          <MicroText center={!isLarge} html>
            {ReactHtmlParser(microCopy)}
          </MicroText>
        )}
      </Container>
    </SliceSection>
  )
}

RushRewardsMatrixModule.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
}

export default RushRewardsMatrixModule

const MicroText = styled(Text)`
  ${({ theme }) => css`
    margin: ${theme.space(2)} 0;

    p {
      ${setFontSize(sizes.xxs)};
      margin-bottom: 0 !important;
    }
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;

    min-height: ${rem(140)};
    max-width: ${theme.layout.maxWidthSm};

    > div {
      width: 100%;
    }

    ${theme.media.lg`
    margin: 0 ${theme.space(3)};
        flex-direction: row;
      `};
  `};
`
