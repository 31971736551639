import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { SANS_SERIF, WEIGHT } from '../../style/type'

import Text, { setFontSize, sizes } from './Text'

import styled, { css } from 'styled-components'
import { first, get, upperFirst } from 'lodash'
import { useAppContext } from '../../context/AppContext'
import { rem } from 'polished'
import { motion } from 'framer-motion'

import arrow from '../../assets/select-arrow.svg'

const variants = {
  hover: { opacity: 1, pointerEvents: 'auto' },
  hidden: { opacity: 0, pointerEvents: 'none' },
}

const Dropdown = ({
  activeLabel,
  defaultLabel,
  defaultValue,
  defaultItemLabel,
  icon,
  items,
  value,
  onChange,
  minWidth,
  style,
}) => {
  const { isLarge, focusStyleOn } = useAppContext()
  const [hover, setHover] = useState(false)

  let activeItems = items.filter(item => item.value === value)
  if (activeItems && !activeItems.length) first(items)

  const label =
    activeLabel || upperFirst(get(activeItems, '[0].label')) || defaultLabel

  return isLarge ? (
    <Container
      onMouseDown={() => setHover(!hover)}
      animate={hover ? 'hover' : 'hidden'}
      transition={{
        duration: 0.4,
        ease: [0.04, 0.62, 0.23, 0.98],
      }}
      css={style}
    >
      <Select minWidth={minWidth}>
        <Text size="xs" nowrap medium color="neutralBlue">
          {label}
        </Text>
        {icon ? icon : <img src={arrow} alt="arrow" />}
      </Select>
      <Options initial="hidden" variants={variants}>
        <motion.div
          variants={{
            hover: { y: 0, opacity: 1 },
            hidden: { y: '-25%', opacity: 0 },
          }}
          transition={{
            duration: 0.3,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
        >
          {defaultLabel && defaultItemLabel && (
            <Item
              size="xs"
              lineHeight="20px"
              medium
              color={label === defaultLabel ? 'accentBlue' : 'neutralBlue'}
              onClick={() => {
                setHover(false)
                if (onChange) onChange(defaultValue)
              }}
            >
              {defaultItemLabel}
            </Item>
          )}
          {items &&
            !!items.length &&
            items.map((item, i) => {
              const active = label === item.value
              return (
                <Item
                  key={value + i}
                  value={item.value}
                  size="xs"
                  medium
                  color={active ? 'accentBlue' : 'neutralBlue'}
                  onClick={() => {
                    setHover(false)
                    if (onChange) onChange(item.value)
                  }}
                >
                  {upperFirst(item.label)}
                </Item>
              )
            })}
        </motion.div>
      </Options>
    </Container>
  ) : (
    <SelectContainer showFocus={focusStyleOn}>
      <select
        onChange={e => {
          if (onChange) onChange(e.target.value)
        }}
        value={value}
      >
        {defaultLabel && <option value={defaultValue}>{defaultLabel}</option>}
        {items &&
          !!items.length &&
          items.map((item, i) => {
            const value = `/${item}`
            return (
              <option key={value + i} value={item.value}>
                {upperFirst(item.label)}
              </option>
            )
          })}
      </select>
    </SelectContainer>
  )
}

Dropdown.propTypes = {
  activeLabel: PropTypes.string,
  defaultLabel: PropTypes.string,
  defaultItemLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  icon: PropTypes.node,
  items: PropTypes.array,
  minWidth: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  value: PropTypes.string,
}

export default Dropdown

const Container = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;
    height: ${rem(36)};
    z-index: 3;
  `};
`

const Select = styled.button`
  ${({ minWidth, theme }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
    min-width: ${minWidth ? rem(minWidth) : 'none'};
    padding: 0 ${theme.space(2)};

    border: 1px solid ${theme.color.secondaryLightBlue};
    border-radius: ${theme.layout.borderRadius};
    background-color: #fff;

    cursor: pointer;

    ${Text} {
      min-width: ${rem(140)};
    }

    img {
      width: ${rem(12)};
      max-width: ${rem(12)};
      height: auto;
    }

    svg {
      width: ${rem(15)};
      height: auto;
    }

    svg,
    img {
      margin-left: ${theme.space(3)};
      flex-shrink: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -${rem(8)};
      height: ${rem(8)};
      width: 100%;
      background-color: transparent;
      z-index: 3;
    }
  `};
`

const Options = styled(motion.div)`
  ${({ theme }) => css`
    position: absolute;
    left: 0;
    top: calc(100% + ${rem(8)});
    width: 100%;

    overflow: hidden;
    border-radius: ${theme.layout.borderRadius};
    filter: drop-shadow(0px 8px 20px rgba(29, 53, 91, 0.05));

    > div {
      background-color: #fff;
    }

    z-index: 2;
  `};
`

const Item = styled(Text)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    ${theme.mixin.opacityHover};
    height: ${rem(53)};
    padding: 0 ${theme.space(1.5)};

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.color.rule};
    }
  `};
`

const SelectContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;

    color: ${theme.color.neutralBlue};

    ${theme.media.lg`
      width: auto;

      > div {
        margin-right: ${theme.space(1)};
      }
    `};

    select {
      color: currentColor;
      font-family: ${SANS_SERIF};
      ${setFontSize(13)};
      font-weight: ${WEIGHT.MEDIUM};
      min-height: ${rem(44)};

      &:focus {
        outline: none;
        box-shadow: none;
      }

      ${theme.mixin.afterFocus};

      option {
        color: currentColor;
      }

      width: 100%;
      padding: ${theme.space(0.5)} ${theme.space(4)} ${theme.space(0.5)}
        ${theme.space(1.5)};

      appearance: none;
      background: url(${arrow}) calc(100% - ${rem(17)}) / 15px no-repeat #fff;

      border: 1px solid ${theme.color.secondaryLightBlue};
      border-radius: ${theme.layout.borderRadius};

      cursor: pointer;
      transition: background-color 0.3s ease-out;

      ${theme.media.lg`
        width: auto;
      `};
    }
  `};
`
