import React from 'react'
import PropTypes from 'prop-types'

import Page from '../container/Page'

import componentRenderer from '../componentRenderer'
import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'

const LandingPage = ({ pageContext: { staticData } }) => {
  const data = usePreviewData(staticData)
  const { components, sections, social } = useParseSiteData(data)

  return (
    <Page noPaddingTop {...sections}>
      {!!components && componentRenderer(components, { social })}
    </Page>
  )
}

LandingPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default LandingPage
