import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'

import AlphaGradient from '../common/AlphaGradient'
import AspectRatioBox from '../common/AspectRatioBox'
import CornerGradient from '../common/CornerGradient'
import Button from '../common/Button'
import Section, { SliceSection } from '../common/Section'
import Text from '../common/Text'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'

// Aspect Ratio
const WIDTH = 66
const HEIGHT = 41

const CasinoHostModule = ({ items, primary, ...props }) => {
  const { isMedium } = useAppContext()
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)
  const columns = get(primary, 'columns') || 4
  const title = get(primary, 'host_title.text')
  const bgColor = get(primary, 'background_color') || 'transparent'

  useEffect(() => {
    if (isMedium && open) setOpen(false)
  }, [isMedium, open])

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container>
        {title && <Text.h2 center>{title}</Text.h2>}
        <Grid columns={columns}>
          {items &&
            items.map((item, i) => {
              const image = get(item, 'host_image.url')
              const fullName = get(item, 'full_name')
              const position = get(item, 'position')
              const email = get(item, 'email')
              const phone = get(item, 'phone')
              const hide = i !== 0 && !isMedium && !open

              return (
                <Fragment key={`host${i}`}>
                  {!hide && (
                    <Item>
                      <ImagePositioner>
                        <AspectRatioBox absolute width={WIDTH} height={HEIGHT}>
                          {image && <Image src={image} alt={fullName || ''} />}
                        </AspectRatioBox>
                      </ImagePositioner>
                      <AspectRatioBox
                        className="img-spacer"
                        absolute
                        width={WIDTH}
                        height={HEIGHT}
                      >
                        <AlphaGradient className="gradient" />
                      </AspectRatioBox>

                      <CornerGradient />

                      <TextContainer>
                        {fullName && (
                          <Text.h4 center white>
                            {fullName}
                          </Text.h4>
                        )}
                        {position && (
                          <Text.h5 center white>
                            {position}
                          </Text.h5>
                        )}
                        {(email || phone) && (
                          <Text as="p" center html size="xs" lineHeight="23px">
                            {phone && <a href={`tel:${phone}`}>{phone}</a>}
                            {email && <a href={`mailto:${email}`}>EMAIL ME</a>}
                          </Text>
                        )}
                      </TextContainer>
                    </Item>
                  )}
                </Fragment>
              )
            })}
        </Grid>
        {!open && !isMedium && (
          <Button underline onClick={toggle}>
            Show More
          </Button>
        )}
      </Container>
    </SliceSection>
  )
}

CasinoHostModule.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
}

export default CasinoHostModule

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    .gradient {
      top: 50% !important;
      height: 50% !important;
    }

    > h2 {
      margin: 0 0 ${theme.space(3)};
      padding: 0 ${theme.space(2)};
    }

    > button {
      margin: ${theme.space(3)} auto ${theme.space(1)} auto;
      left: 50%;
      transform: translateX(-50%);

      ${theme.media.lg`
        margin: ${theme.space(3)} auto 0 auto;
      `};
    }
  `};
`

const Grid = styled.div`
  ${({ columns, theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(${rem(120)}, 1fr);
    grid-gap: ${theme.space(1)} ${theme.space(1)};

    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.md`
      grid-template-columns: repeat(${columns / 2}, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(3)} ${theme.space(3)};
    `};

    ${theme.media.xl`
      grid-template-columns: repeat(${columns}, minmax(${rem(160)}, 1fr));
    `};
  `};
`

const ImagePositioner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const TextContainer = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.space(2.5)};

    p {
      margin: ${theme.space(2)} 0 0;
      margin-bottom: ${theme.space(2.5)};
    }

    h4 {
      margin-bottom: ${theme.space(0.5)};
    }

    a,
    a:active,
    a:visited {
      display: inline-block;
      margin: 0 ${({ theme }) => theme.space(1)};
      margin-bottom: ${theme.space(1)};

      &:after {
        bottom: 1px;
      }
    }

    z-index: 2;
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: ${theme.color.blue};

    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    &:hover {
      ${theme.mixin.imgHover};
    }

    h4,
    p {
      color: #fff;
    }

    .img-spacer {
      display: block;
    }
  `};
`
