import React from 'react'
import PropTypes from 'prop-types'

import GridModule from './GridModule'
import {
  FEATURE_MODULE_3_UP,
  FEATURE_MODULE_4_UP,
} from '../../constants/sliceTypes'
import { get } from 'lodash'

const transformSchemaToGridItem = n => item => {
  return {
    grid_content: {
      document: {
        data: {
          button_label: get(item, `up_${n}_card_button_label`),
          button_link: get(item, `up_${n}_card_link`),
          card_body: get(item, `up_${n}_card_body`),
          card_title: get(item, `up_${n}_card_title`),
          image: get(item, `up_${n}_card_image`),
        },
      },
    },
  }
}

const NUpFeatureModule = ({
  primary: { ...primaryProps },
  items,
  slice_type,
  ...props
}) => {
  let columns = 2
  if (slice_type === FEATURE_MODULE_3_UP) columns = 3
  if (slice_type === FEATURE_MODULE_4_UP) columns = 4

  const transformedItems = items.map(transformSchemaToGridItem(columns))
  const bgColor = get(primaryProps, 'background_color', 'transparent')

  return (
    <GridModule
      bgColor={bgColor}
      primary={primaryProps}
      items={transformedItems}
      {...props}
      colums={columns}
    />
  )
}

NUpFeatureModule.propTypes = {
  slice_type: PropTypes.string,
  items: PropTypes.array,
  primary: PropTypes.shape({
    style: PropTypes.string,
  }),
}

export default NUpFeatureModule
