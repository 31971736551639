import React from 'react'
import PropTypes from 'prop-types'

import Text from '../../common/Text'
import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { rem, transparentize } from 'polished'
import linkResolver from '../../../utils/linkResolver'

const SocialMedia = ({ items, ...props }) => {
  const title = get(props, 'title.text')
  return (
    <Container>
      {title && <Text.h4>{title}</Text.h4>}
      <div>
        {items.map((item, i) => {
          const icon = get(item, 'icon')
          const link = {
            href: linkResolver(item, 'link'),
            target: get(item, 'link.target'),
          }
          return link && link.href ? (
            <a key={i} {...link}>
              {icon}
            </a>
          ) : null
        })}
      </div>
    </Container>
  )
}

SocialMedia.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
}

export default SocialMedia

const Container = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space(4)};

    display: flex;
    justify-content: space-between;
    align-content: center;

    padding-right: ${theme.space(2)};
    padding-left: ${theme.space(2)};

    ${theme.media.lg`
      padding-right: ${theme.space(4)};
      padding-left: ${theme.space(4)};
    `};

    ${theme.media.lg`
      padding-right: 0;
      padding-left: 0;

      padding-top: ${theme.space(4)};
      border-top: 1px solid ${transparentize(
        0.5,
        theme.color.secondaryLightBlue,
      )};
    `};

    > h4 {
      ${theme.media.sm`
       transform: translateY(2px);
      `};
    }

    > div {
      display: flex;

      a {
        ${theme.mixin.opacityHover};
        margin-left: ${theme.space(2)};
      }

      img,
      svg {
        height: ${rem(24)};
        width: auto;
        object-fit: contain;
      }
    }
  `};
`
