import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import AlphaGradient from '../common/AlphaGradient'
import AspectRatioBox from '../common/AspectRatioBox'
import Link from '../common/Link'
import Flickity from '../common/Flickity'
import { SliceSection } from '../common/Section'
import Text, { setFontSize, sizes } from '../common/Text'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import useElementSize from '../../hooks/useElementSize'
import { useLoadImage } from '../../hooks/useLoadImage'
import { useAppContext } from '../../context/AppContext'
import linkResolver from '../../utils/linkResolver'

const PADDING_H = 120

const FeaturedHeroSliderModule = ({ primary, items, slice_type, ...props }) => {
  const elRef = useRef(null)
  const firstImage = get(items, '[0].hero_image.url')
  const { loaded } = useLoadImage(firstImage)
  const { width } = useElementSize(elRef, loaded)
  const { focusStyleOn, isMedium } = useAppContext()

  const offsetH = isMedium ? PADDING_H : 0
  const height = ((width - offsetH) * 19) / 39
  const bgColor = get(primary, 'background_color') || 'softBlue'

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container ref={elRef}>
        <Flickity
          className="flickity"
          minHeight={height}
          type={slice_type}
          key={focusStyleOn}
          options={{
            accessibility: focusStyleOn,
          }}
        >
          {items &&
            items.map((item, i) => {
              const image = {
                src: get(item, 'hero_image.url'),
                alt: get(item, 'hero_image.alt') || '',
              }
              const title = get(item, 'hero_title.text')
              const label = get(item, 'hero_button_label')
              const link = {
                href: linkResolver(item, 'hero_button_link'),
                target: get(item, 'hero_button_link.target'),
              }

              return (
                <Item key={`img${i}`}>
                  <AspectRatioBox width={39} height={19} border>
                    <Image alt="" {...image} />
                    <AlphaGradient />
                    <ImageText center html white>
                      {title && <h4>{title}</h4>}
                      {link && link.href && (
                        <span>
                          <Link {...link}>{label || 'Read more'}</Link>
                        </span>
                      )}
                    </ImageText>
                  </AspectRatioBox>
                </Item>
              )
            })}
        </Flickity>
      </Container>
    </SliceSection>
  )
}

FeaturedHeroSliderModule.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
  slice_type: PropTypes.string,
}

export default FeaturedHeroSliderModule

const ImageText = styled(Text)`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: ${theme.space(1)} 0;
    align-self: flex-end;
    padding: ${theme.space(2)};

    h4 {
      color: #fff;
    }

    a,
    a:active,
    a:visited {
      ${setFontSize(sizes.xs)};
    }

    span {
      position: relative;
      display: block;
      margin-top: ${theme.space(1)};
    }

    ${theme.media.md`
      transform: translateY(-${theme.space(4)});

      span {
        margin-top: ${theme.space(2)};
      }
    `};

    ${theme.media.xxl`
      transform: translateY(-${theme.space(5)});

      span {
        margin-top: ${theme.space(3)};
      }
    `};
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(3)};

      > div {
        grid-column: 2 / 12;
      }
    `};

    ${Text} {
      margin-top: ${theme.space(2)};
    }
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: ${theme.layout.maxWidth};

    > div {
      width: 100%;
    }
  `};
`

const Image = styled.img`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `};
`
