const { pick, reduce, get, first, set, last } = require('lodash')
const {
  format,
  compareAsc,
  getMonth,
  eachDayOfInterval,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  isToday,
  isAfter,
  endOfDay,
} = require('date-fns')
const {
  EVENT_DETAIL_PAGE,
  PROMO_DETAIL_PAGE,
  EVENT_VENUE_DETAIL_PAGE,
  POKER_ROOM_LANDING_PAGE,
} = require('./constants/index')

const DAYS_OF_WEEK = {
  sun: 'Sunday',
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
}

const dayCheckFns = [
  isSunday,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
]

const today = new Date()

const reduceFormatDays = (useAbbr, defaultSuff = ' IN', plural = 's') => (
  acc,
  val,
  i,
  l,
) => {
  let suff = i < l.length - 1 ? ', ' : defaultSuff
  if (i === l.length - 2) suff = ' & '
  acc += `${useAbbr ? val : DAYS_OF_WEEK[val]}${plural}${suff}`

  return acc
}

const filterRangeWithDaysOfWeek = onlyOnDays => date => {
  const daysIndex = Object.keys(DAYS_OF_WEEK)
  return onlyOnDays.reduce((acc, day) => {
    if (acc) return acc
    const dayFnIndex = daysIndex.indexOf(day)
    const dayFn = dayCheckFns[dayFnIndex]
    acc = dayFn(date)
    return acc
  }, false)
}

const mapToDateStructure = date => ({ date: format(date, 'yyyy/MM/dd') })

const parseRecurringRange = (range, isOnCertainDays, onlyOnDays, type) => {
  let allDaysInRange = eachDayOfInterval({
    start: range[0],
    end: range[1],
  })

  let fullDateRange = allDaysInRange || []
  if (isOnCertainDays) {
    fullDateRange = allDaysInRange.filter(filterRangeWithDaysOfWeek(onlyOnDays))
  }

  if (type === EVENT_DETAIL_PAGE) {
    fullDateRange = fullDateRange.filter(date => {
      return isToday(date) || isAfter(date, new Date())
    })
  }

  return fullDateRange.map(mapToDateStructure)
}

const injectRecurring = item => {
  const data = get(item, 'data')
  const dates = get(data, 'dates')
  set(data, 'display_dates', dates)
  if (!dates) return

  const { onlyOnDays, isOnCertainDays } = parseDayBasedData(data)
  const { range, isRange } = extractRange(dates)
  if (isRange && isAfter(range[1], range[0])) {
    const fullDateRange = parseRecurringRange(
      range,
      isOnCertainDays,
      onlyOnDays,
      item.type,
    )
    set(item, 'data.dates', fullDateRange)
  } else {
    if (item.type === EVENT_DETAIL_PAGE) {
      const onlyFutureDates = dates.filter(({ date }) => {
        const d = new Date(date)
        return isToday(d) || isAfter(d, today)
      })

      set(item, 'data.dates', onlyFutureDates)
    }
  }
}

const cleanEvents = items => {
  items = items.filter(item => {
    if (item.type === EVENT_DETAIL_PAGE || item.type === PROMO_DETAIL_PAGE) {
      const dates = get(item, 'data.dates')

      return !!dates.length
    } else return true
  })
}

const injectRecurringIntoPages = items => {
  try {
    items.forEach(item => {
      if (item.type === EVENT_DETAIL_PAGE || item.type === PROMO_DETAIL_PAGE) {
        injectRecurring(item)
      } else if (
        item.type === EVENT_VENUE_DETAIL_PAGE ||
        item.type === POKER_ROOM_LANDING_PAGE
      ) {
        if (item.data.featured && item.data.featured.length) {
          item.data.featured.forEach(({ event }) => {
            if (event.document) {
              injectRecurring(event.document)
            }
          })
        }
      }
    })
    cleanEvents(items)
    return [...items]
  } catch (e) {
    console.error(e)
  }

  return [...items]
}

const consecutiveDays = days => {
  //TODO
  // Make this work if we have crazy split week ranges
  const keys = Object.keys(DAYS_OF_WEEK)
  const startIndex = keys.indexOf(days[0])
  return reduce(
    days,
    (acc, day, i) => {
      const match = day === keys[startIndex + i]
      const prevMatch = i === 0 || days[i - 1] === keys[startIndex + i - 1]

      if (match && prevMatch) {
        const array = acc.pop() || []

        array.push(day)

        acc.push(array)
      } else if (match) {
        const array = [day]
        acc.push(array)
      }

      return acc
    },
    [],
  )
}

const parseDayBasedData = data => {
  const days = pick(data, Object.keys(DAYS_OF_WEEK))
  const onlyOnDays = reduce(
    days,
    (acc, val, key) => {
      if (val === 'Yes') acc.push(key)
      return acc
    },
    [],
  )
  const isOnCertainDays = onlyOnDays.length !== 7

  return {
    onlyOnDays,
    isOnCertainDays,
    consecutiveRanges: consecutiveDays(onlyOnDays),
  }
}

const extractRange = dates => {
  const range =
    first(
      reduce(
        dates,
        (acc, val) => {
          const date = get(val, 'date')
          const endDate = get(val, 'end_date')
          const monthData = []
          if (date) monthData.push(new Date(date))
          if (endDate) monthData.push(new Date(endDate))
          acc.push(monthData)
          return acc
        },
        [],
      ),
    ) || []

  const isRange = range.length === 2
  const isRangeInsideMonth =
    isRange &&
    !!(range[0] && range[1] && getMonth(range[1]) === getMonth(range[0]))

  return { range, isRange, isRangeInsideMonth }
}

const transformDisplayDates = data => {
  const dates = get(data, 'display_dates') || []
  if (!dates.length) return

  dates.sort((a, b) => compareAsc(new Date(a.date), new Date(b.date))) //Should start at earliest date

  const { range, isRange, isRangeInsideMonth } = extractRange(dates)

  const { onlyOnDays, isOnCertainDays, consecutiveRanges } = parseDayBasedData(
    data,
  )

  console.log(consecutiveRanges)

  const isSingleDate = dates.length === 1 && !dates[0].end_date
  const isTwoArbitraryDates = dates.length === 2

  const content = {}
  if (isOnCertainDays && isRange) {
    const useDayRange = onlyOnDays.length > 4

    const usableDays = onlyOnDays.slice(0, 4)
    const useAbbr = usableDays.length > 2
    const suff = isRangeInsideMonth ? ' IN' : ' THROUGH'
    const monthFormat = isRangeInsideMonth ? 'MMMM' : 'MMMM do'
    content.top = [
      useDayRange
        ? `${onlyOnDays[0]}-${onlyOnDays[onlyOnDays.length - 1]} through`
        : usableDays.reduce(reduceFormatDays(useAbbr, suff), '').toUpperCase(),
    ]
    content.bottom = [format(new Date(range[1]), monthFormat).toUpperCase()]
    return content
  }

  if (isTwoArbitraryDates && !isRange) {
    content.top = [
      format(new Date(dates[0].date), 'MMM').toUpperCase(),
      '',
      format(new Date(dates[1].date), 'MMM').toUpperCase(),
    ]
    content.bottom = [
      format(new Date(dates[0].date), 'd').toUpperCase(),
      '&',
      format(new Date(dates[1].date), 'd').toUpperCase(),
    ]
    return content
  }

  if (isRangeInsideMonth) {
    content.top = [format(new Date(range[0]), 'MMM').toUpperCase()]
    content.bottom = [
      `${format(new Date(range[0]), 'd').toUpperCase()}-${format(
        new Date(range[1]),
        'd',
      ).toUpperCase()}`,
    ]
    return content
  }

  if (isRange) {
    content.top = [
      format(new Date(range[0]), 'MMM').toUpperCase(),
      '',
      format(new Date(range[1]), 'MMM').toUpperCase(),
    ]
    content.bottom = [
      format(new Date(range[0]), 'd').toUpperCase(),
      'UNTIL',
      format(new Date(range[1]), 'd').toUpperCase(),
    ]
    return content
  }

  if (isSingleDate) {
    content.top = [
      `${format(new Date(dates[0].date), 'E').toUpperCase()}, ${format(
        new Date(dates[0].date),
        'MMM',
      ).toUpperCase()}`,
    ]
    content.bottom = [format(new Date(dates[0].date), 'd').toUpperCase()]
    return content
  }
}

module.exports = transformDisplayDates
module.exports.reduceFormatDays = reduceFormatDays
module.exports.injectRecurringIntoPages = injectRecurringIntoPages
module.exports.parseDayBasedData = parseDayBasedData
module.exports.injectRecurring = injectRecurring
module.exports.parseRecurringRange = parseRecurringRange
module.exports.extractRange = extractRange
