import React from 'react'
import PropTypes from 'prop-types'

import { HOTEL_BOOKING_MODULE } from '../../constants/sliceTypes'

import Page from '../container/Page'

import componentRenderer from '../componentRenderer'
import usePreviewData from '../../hooks/usePreviewData'
import useParseSiteData from '../../hooks/useParseSiteData'
import HomeSlideshow from '../components/home/HomeSlideshow'
import FullbleedIntroModule from '../slices/FullbleedIntroModule'
import { get } from 'lodash'

const HomePage = ({ location, pageContext: { staticData }, skipPreview }) => {
  const data = usePreviewData(staticData)
  const { components, sections, social } = useParseSiteData(data)
  const slideshow = {
    items: get(data, 'data.gallery'),
    links: get(data, 'data.right_buttons'),
    time: get(data, 'data.time'),
  }

  const fullbleedHero = {
    items: get(data, 'data.fullbleed_intro_module'),
    time: get(data, 'data.fullbleed_time'),
  }

  const hotelBookingIsFirst =
    get(components, 'body[0].slice_type') === HOTEL_BOOKING_MODULE

  return (
    <Page noPaddingTop {...sections}>
      {fullbleedHero &&
      fullbleedHero.items &&
      fullbleedHero.items.length > 0 ? (
        <FullbleedIntroModule
          items={fullbleedHero.items}
          primary={{ time: fullbleedHero.time }}
        />
      ) : (
        <HomeSlideshow fadeBottom={hotelBookingIsFirst} {...slideshow} />
      )}
      {!!components &&
        componentRenderer(components, { social, pageType: staticData.type })}
    </Page>
  )
}

HomePage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  skipPreview: PropTypes.bool,
}

export default HomePage
