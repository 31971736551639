import React from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import Text from '../../common/Text'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { rem, transparentize } from 'polished'
import { useAppContext } from '../../../context/AppContext'
import { transformLinks } from '../../../utils/textTransforms'

const SpecialDiscounts = ({ items, ...props }) => {
  const { isLarge } = useAppContext()
  const title = get(props, 'title.text')

  return (
    <Container>
      {title && <Text.h4 center={!isLarge}>{title}</Text.h4>}
      {items && !!items.length && (
        <Grid>
          {items.map((item, i) => {
            const itemTitle = get(item, 'item_title.text')
            const body = get(item, 'item_body.html')
            const discount = get(item, 'discount.html')

            return (
              <Item key={i}>
                <Row>
                  <div>
                    {itemTitle && (
                      <Text
                        as="h5"
                        size="xs"
                        lg="sm"
                        lineHeight={rem(19)}
                        lineHeightLg={rem(22)}
                        bold
                        uppercase
                        spacing={isLarge ? 1.2 : 1}
                      >
                        {itemTitle}
                      </Text>
                    )}
                    {body && (
                      <Text size="xs">
                        {ReactHtmlParser(body, { transform: transformLinks })}
                      </Text>
                    )}
                  </div>
                  {discount && (
                    <Text
                      right
                      uppercase
                      color="secondaryBlue"
                      size={14}
                      lineHeight={1.4}
                    >
                      <b>{ReactHtmlParser(discount)}</b>
                    </Text>
                  )}
                </Row>
              </Item>
            )
          })}
        </Grid>
      )}
    </Container>
  )
}

SpecialDiscounts.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
}

export default SpecialDiscounts

const Container = styled.div`
  ${({ theme }) => css`
    ${theme.layout.verticalPaddingTopMenu};

    h4 {
      margin-bottom: ${theme.space(2)};
    }
  `};
`

const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${theme.space(1)} ${theme.space(1)};
    width: 100%;

    ${theme.media.xxl`
        grid-template-columns: repeat(2, minmax(${rem(120)}, 1fr));
        grid-gap: ${theme.space(1.5)} ${theme.space(3)};
      `};
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: ${theme.layout.border} solid
      ${transparentize(0.5, theme.color.secondaryLightBlue)};
    border-radius: ${theme.layout.borderRadius};
    padding: ${theme.space(2)};

    img {
      height: 100%;
      width: auto;
      max-height: ${rem(120)};
      object-fit: contain;
    }
  `};
`

const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;

    > div {
      &:first-child {
        width: 100%;

        padding-right: ${theme.space(2)};
      }
    }

    h5 {
      margin-bottom: ${theme.space(1)};
    }
  `};
`
