const { isArray, isObject, forEach } = require('lodash')
const { formatISO, startOfDay, addHours, toDate } = require('date-fns')
const pattern = /\d{4}-\d{1,2}-\d{1,2}$/i
const breakSafariPattern = /\d{4}-\d{1,2}-\d{1,2}T\d{2}:\d{2}:\d{2}\+\d{4}/i

const normalizeRecursive = node => {
  forEach(node, (value, key) => {
    if (typeof value === 'string' && pattern.test(value)) {
      const dateStr = value.replace(/-/g, '/')
      //const start = addHours(startOfDay(date), 12)
      node[key] = dateStr
    } else if (
      key !== 'first_publication_date' &&
      key !== 'last_publication_date' &&
      typeof value === 'string' &&
      breakSafariPattern.test(value)
    ) {
      const dateStr = value.replace(/\+\d{4}/gi, '')
      node[key] = dateStr
    } else if (isArray(value) || isObject(value)) normalizeRecursive(value)
  })
}

module.exports = (allPages, timeZone) => {
  allPages.forEach(normalizeRecursive)
}
