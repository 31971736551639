import React from 'react'
import PropTypes from 'prop-types'

import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { transparentize } from 'polished'

const DiningItemModule = ({ primary, items }) => {
  const itemTitle = get(primary, 'label')
  const description = get(primary, 'right_label')
  return (
    <Item>
      <Header>
        {itemTitle && (
          <HeaderText medium uppercase color="blue" size={13}>
            {itemTitle}
          </HeaderText>
        )}
        {description && (
          <Text color="neutralBlue" size="sm" right size={13}>
            {description}
          </Text>
        )}
      </Header>
      {items && !!items.length && (
        <MenuTextGroup>
          {items.map(({ label, price }, i) => {
            const isLast = i === items.length - 1
            const borderBottom = isLast ? 'none' : '1px solid #e8ecf3'
            return (
              <MenuText
                size="xs"
                color="neutralBlue"
                key={`menu_item-${i}`}
                spacing={0.5}
                css={{ borderBottom }}
              >
                <div>{label}</div>
                <Text medium size="xs" color="neutralBlue">
                  {price}
                </Text>
              </MenuText>
            )
          })}
        </MenuTextGroup>
      )}
    </Item>
  )
}

DiningItemModule.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
}

export default DiningItemModule

const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: ${theme.layout.border} solid
      ${transparentize(0.5, theme.color.secondaryLightBlue)};
    border-radius: ${theme.layout.borderRadius};
  `};
`

const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${theme.space(1)} ${theme.space(2)};
    background-color: ${theme.color.softBlue};
  `};
`

const HeaderText = styled(Text)`
  ${({ theme }) => css`
    h3 {
      margin: 0 !important;
      margin-right: ${theme.space(2)};
      padding: 0;
    }
  `};
`

const MenuTextGroup = styled(Text)`
  ${({ theme }) => css`
    width: calc(100% - ${theme.space(4)});
    padding: ${theme.space(0.5)} 0;
  `};
`

const MenuText = styled(Text)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.space(1)} 0;

    width: 100%;

    > div:last-child {
      padding-left: ${theme.space(4)};
    }
  `};
`
