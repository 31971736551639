import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import PlusMinus from '../common/PlusMinus'
import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import Text from '../common/Text'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { transformLinks } from '../../utils/textTransforms'

const FaqItem = ({ activeIndex, item, setIndex, i }) => {
  const itemRef = useRef(null)
  const title = get(item, 'qa_title.text')
  const body = get(item, 'qa_body.html')
  const itemOpen = activeIndex === i
  const handleClick = () => {
    setIndex(itemOpen ? null : i)
    if (!itemOpen)
      itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
  }

  console.log(body)
  return (
    <Item
      ref={itemRef}
      initial={false}
      animate={{
        backgroundColor: itemOpen
          ? 'rgba(255, 255, 255, 1)'
          : 'rgba(255, 255, 255, 0.5)',
      }}
    >
      <Hit onClick={handleClick} />
      <Row onClick={handleClick}>
        <Text
          size="xs"
          lg={20}
          bold
          color="secondaryBlue"
          uppercase
          lineHeight={1.5}
          lineHeightLg={28 / 20}
          spacing={1}
          css={{ pointerEvents: 'none' }}
        >
          {title}
        </Text>
        <PlusMinus itemOpen={itemOpen} />
      </Row>
      <AnimatePresence initial={false}>
        {itemOpen && (
          <Body
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.4,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            <Text.p spacing={0} lineHeight="18px" lineHeightLg={25 / 15}>
              {ReactHtmlParser(body, { transform: transformLinks })}
            </Text.p>
          </Body>
        )}
      </AnimatePresence>
    </Item>
  )
}

FaqItem.propTypes = {
  activeIndex: PropTypes.number,
  i: PropTypes.number,
  item: PropTypes.object,
  setIndex: PropTypes.func,
}

const FaqModule = ({ primary, items, ...props }) => {
  const [activeIndex, setIndex] = useState(null)
  const bgColor = get(primary, 'background_color') || 'softBlue'

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container>
        <Grid>
          {items.map((item, i) => (
            <FaqItem
              key={`item${i}`}
              i={i}
              activeIndex={activeIndex}
              setIndex={setIndex}
              item={item}
            />
          ))}
        </Grid>
      </Container>
    </SliceSection>
  )
}

FaqModule.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
}

export default FaqModule

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.xl`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};
    `};
  `};
`

const Grid = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    grid-column: 2 / 12;
  `};
`

const Hit = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: 0.3s background-color;

  &:hover {
    background-color: #fff;
  }
`

const Item = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;

    padding: ${theme.space(3)} ${theme.space(4)} ${theme.space(3)};
    border-radius: ${theme.layout.borderRadius};

    &:not(:last-child) {
      margin-bottom: ${theme.space(1)};
    }
  `};
`

const Body = styled(motion.div)`
  ${({ theme }) => css`
    p {
      padding-top: ${theme.space(0.5)};
    }

    ${theme.media.md`
      max-width: calc(100% - ${theme.space(8)});
    `};

    a,
    a:active,
    a:visited {
      ${theme.mixin.blueLink};
    }
  `};
`

const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    h4 {
      margin-bottom: ${theme.space(1)};
    }
  `};
`
