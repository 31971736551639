import React from 'react'
import PropTypes from 'prop-types'

import ReactHtmlParser from 'react-html-parser'
import Section, { SliceSection } from '../common/Section'
import Text, { setFontSize, sizes } from '../common/Text'

import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { rem } from 'polished'

const InlineImageModule = ({ primary: data, ...props }) => {
  const image = get(data, 'inline_image.url')
  const caption = get(data, 'image_caption.text')
  const body = get(data, 'body_inline.html')
  const bgColor = get(data, 'background_color') || 'transparent'

  return (
    <SliceSection bgColor={bgColor} {...props}>
      <Container>
        <article>
          {image && (
            <ImageContainer>
              <Image src={image} />
              {caption && (
                <Text
                  size="xxs"
                  lg="xs"
                  medium
                  lineHeightMultiplier={18 / 11}
                  lineHeightLg={20 / 13}
                >
                  {ReactHtmlParser(caption)}
                </Text>
              )}
            </ImageContainer>
          )}
          {body && (
            <BodyText color="neutralBlue" html>
              {ReactHtmlParser(body)}
            </BodyText>
          )}
        </article>
      </Container>
    </SliceSection>
  )
}

InlineImageModule.propTypes = { primary: PropTypes.object }

export default InlineImageModule

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    p {
      font-size: ${rem(15)} !important;
      line-height ${rem(24)} !important;

       ${theme.media.md`
        font-size: ${rem(18)} !important;
        line-height ${rem(32)} !important;
      `};
    }

    p:not(:last-child) {
      margin-bottom: ${theme.space(2)};
    }

    article {
      max-width: ${theme.layout.maxWidthArticle};
      margin: 0 auto;
    }

    cite {
      ${setFontSize(sizes.xs)};
      font-weight: 700;
      color: ${theme.color.secondaryBlue};
    }
  `};
`

const ImageContainer = styled.div`
  ${({ theme }) => css`
    display: block;
    float: left;
    width: 100%;
    margin-right: ${theme.space(3)};
    margin-bottom: ${theme.space(3)};

    ${theme.media.md`
      width: calc(50% - ${theme.space(2)});
      margin-bottom: ${theme.space(0.5)};
    `};
  `};
`

const Image = styled.img`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${theme.layout.borderRadius};
    margin-bottom: ${theme.space(1)};

    ${theme.media.md`
      margin-bottom: ${theme.space(1.5)};
     `};
  `};
`

const BodyText = styled(Text)`
  ${({ theme }) => css`
    padding: ${theme.space(3)} ${theme.space(4)} 0;

    ${theme.media.md`
      padding: 0;
     `}
  `};
`
