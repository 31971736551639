const { reduce, uniq, get } = require('lodash')
const { sortEventDate } = require('./events')
const { transformToGridItems } = require('./tranforms')
const {
  TAGGED_SLICES,
  EVENT_DETAIL_PAGE,
  EVENT_CALENDAR_PAGE,
} = require('./constants')
const reduceTags = (acc, value) => {
  acc.push(...value.tags)
  return acc
}

const computeTags = pages => {
  const allTags = uniq(reduce(pages, reduceTags, [])).map(t => t.toLowerCase())
  const pagesByTags = reduce(
    allTags,
    (acc, tag) => {
      const taggedPages = pages.filter(({ tags = [] }) =>
        tags.map(t => t.toLowerCase()).includes(tag),
      )
      acc[tag] = taggedPages
      return acc
    },
    {},
  )
  return { tags: allTags, pagesByTags }
}

const injectPage = (allPages, pagesByTags, dataKey = 'data') => page => {
  // Get components from each page
  const components = get(page, `${dataKey}.body`, [])
  if (components.length) {
    //If components exist loop through them
    components.forEach(component => {
      //Check if component.slice_type is in the TAGGED_SLICES list and has a field named tag_name
      if (
        TAGGED_SLICES.includes(component.slice_type) &&
        component.primary.tag_name &&
        component.primary.tag_name.length
      ) {
        //get tagged pages in question

        const taggedPages =
          pagesByTags[component.primary.tag_name.toLowerCase()]
        if (taggedPages && taggedPages.length) {
          //Get page type if exists
          const pageType = get(component, 'primary.page_type', false)
          //Filter by page types or not
          const filteredByPageType = !pageType
            ? taggedPages
            : taggedPages.filter(({ type }) => type === pageType)
          //remove self
          const filteredNotSelf = filteredByPageType.filter(
            ({ id }) => id !== page.id,
          )
          //Sort by first pub date
          /*TODO
           * make concessions for event dates if applicable.
           * */
          let sortFn = (a, b) => {
            const aDate = new Date(a.first_publication_date).valueOf()
            const bDate = new Date(b.first_publication_date).valueOf()
            return bDate - aDate
          }
          if (pageType) sortFn = sortEventDate
          filteredNotSelf.sort(sortFn)

          const items = component.items || []

          //Format same as what graphql queries would return
          component.items = [
            ...items.map(({ override_grid_content: grid_content }) => ({
              grid_content,
            })),
            ...filteredNotSelf.map(transformToGridItems),
          ]
        }
      }
    })
  }
  if (page.type === EVENT_CALENDAR_PAGE) {
    const tagName = (get(page, `${dataKey}.tag_name`) || '').toLowerCase()
    const taggedPages = get(pagesByTags, tagName) || allPages

    const filteredByPageType = taggedPages.filter(
      ({ type }) => type === EVENT_DETAIL_PAGE,
    )
    const filteredNotSelf = filteredByPageType.filter(
      ({ id }) => id !== page.id,
    )

    filteredNotSelf.sort(sortEventDate)
    page.items = filteredNotSelf.map(transformToGridItems)
  }
  return page
}

const injectTags = (pages = [], pagesByTags) => {
  //Loop through pages

  const injectWithTags = injectPage(pages, pagesByTags)
  pages.forEach(injectWithTags)

  return [...pages]
}

module.exports = {
  injectPage,
  injectTags,
  computeTags,
}
