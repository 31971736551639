import React from 'react'
import PropTypes from 'prop-types'

import InputContainer from './InputContainer'
import ReactHtmlParser from 'react-html-parser'
import Text from '../../common/Text'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { transformLinks } from '../../../utils/textTransforms'

const Disclaimer = ({ columns, disclaimer_body, ...props }) => {
  const body = get(disclaimer_body, 'html')
  const gridColumn = columns && columns !== 2 ? {} : { gridColumn: '1 / 3' }

  return (
    <InputContainer css={gridColumn}>
      <StyledText html>
        {ReactHtmlParser(body, { transform: transformLinks })}
      </StyledText>
    </InputContainer>
  )
}

Disclaimer.propTypes = {
  columns: PropTypes.number,
  disclaimer_body: PropTypes.object,
}

export default Disclaimer

const StyledText = styled(Text)`
  ${({ theme }) => css`
    h5 {
      margin-bottom: ${theme.space(1.5)};
    }
  `};
`
