import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import AlphaGradient from '../common/AlphaGradient'
import AspectRatioBox from '../common/AspectRatioBox'
import Link from '../common/Link'
import Section, { SliceSection } from '../common/Section'
import Text from '../common/Text'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'
import linkResolver from '../../utils/linkResolver'
import truncate from '../../utils/truncateText'

const ImageItem = ({ item }) => {
  const image = get(item, 'image_cta_card_module_image.url')
  const title = get(item, 'image_cta_card_module_image_title.text')
  const body = get(item, 'image_cta_card_module_image_body.text')
  const href = linkResolver(item, 'image_cta_card_link')
  const target = get(item, 'image_cta_card_link.target')
  const link = { href, target }
  const { isXXLarge } = useAppContext()

  const content = (
    <TextContainer>
      {title && (
        <Text.h3 center white>
          {title}
        </Text.h3>
      )}
      {body && (
        <Text medium center color="secondaryLightBlue" size="xs">
          {truncate(body, 165)}
        </Text>
      )}
    </TextContainer>
  )

  const Wrapper = isXXLarge ? Fragment : AspectRatioBox
  const aspectRatio = isXXLarge ? {} : { width: 71, height: 55 }

  return (
    <Item>
      <Link {...link}>
        <Wrapper {...aspectRatio}>
          <ImagePositioner>
            <AspectRatioBox width={23} height={14}>
              {image && <img src={image} alt={title} />}
              <AlphaGradient />
              {isXXLarge && content}
            </AspectRatioBox>
          </ImagePositioner>
          {!isXXLarge && content}
        </Wrapper>
      </Link>
    </Item>
  )
}

ImageItem.propTypes = {
  item: PropTypes.object,
}

const ImageCtaCardModule = ({ items, primary, ...props }) => {
  const columns = get(primary, 'columns') || 'auto-fit'
  const bgColor = get(primary, 'background_color') || 'transparent'

  return (
    <SliceSection bgColog={bgColor} {...props}>
      <Content>
        <Grid columns={columns}>
          {items &&
            items.map((item, i) => <ImageItem item={item} key={`${i}`} />)}
        </Grid>
      </Content>
    </SliceSection>
  )
}

ImageCtaCardModule.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
}

export default ImageCtaCardModule

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    h2 {
      margin: ${theme.space(2)} 0 0;
    }

    ${theme.media.md`

      h2 {
        margin: ${theme.space(5)} 0 ${theme.space(2)};
      }
    `};
  `};
`

const Grid = styled.div`
  ${({ columns, theme }) => css`
    width: 100%;

    ${theme.media.lg`
     display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: minmax(${rem(120)}, 1fr);
      grid-gap: ${theme.space(1)} ${theme.space(1)};
    `};

    ${theme.media.sm`
      grid-template-columns: repeat(${columns}, minmax(${rem(120)}, 1fr));
      grid-gap: ${theme.space(1.5)} ${theme.space(3)};
    `};
  `};
`

const ImagePositioner = styled.div`
  width: 100%;
  z-index: 0;
`

const TextContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    padding: ${theme.space(2.5)} ${theme.space(2.5)};

    ${theme.media.xxl`
        padding: ${theme.space(4)} ${theme.space(6)};
    `};

    h3 {
      margin-bottom: ${theme.space(1.5)};
    }

    z-index: 2;
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    border-radius: ${theme.layout.borderRadius};
    background-color: ${theme.color.blue};

    &:not(:last-child) {
      margin-bottom: ${theme.space(1)};
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: ${theme.layout.borderRadius};
    }

    &:hover {
      .aspect-img img {
        transform: scale(1.03);
        opacity: 0.75;
      }
    }

    ${theme.media.lg`
        margin-bottom: 0;
    `};
  `};
`
