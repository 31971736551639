import React from 'react'
import PropTypes from 'prop-types'

import { motion } from 'framer-motion'

const Checkbox = ({ checked }) => {
  return (
    <svg width={18} height={16}>
      <g fillRule="nonzero" fill="none">
        <motion.path
          initial="hide"
          animate={checked ? 'show' : 'hide'}
          variants={{
            show: { fillOpacity: 0 },
            hide: { fillOpacity: 1 },
          }}
          transition={{
            duration: 0.3,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
          d="M1.5 1.5v13h13v-13h-13zM1 0h14a1 1 0 011 1v14a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1z"
          fill="#455073"
        />
        <motion.path
          initial="hide"
          animate={checked ? 'show' : 'hide'}
          variants={{
            show: { fillOpacity: 1 },
            hide: { fillOpacity: 0 },
          }}
          transition={{
            duration: 0.3,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
          d="M12 0v1.5H1.5v13h13V9.357H16V15a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1h11zm4.828.648L18 1.82l-10 10-4.648-4.648L4.523 6 8 9.477 16.828.648z"
          fill="#FDB61B"
        />
      </g>
    </svg>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
}

export default Checkbox
