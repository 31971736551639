import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import AlphaGradient, { AlphaGradientCorner } from '../../common/AlphaGradient'
import AspectRatioBox from '../../common/AspectRatioBox'
import AspectRatioContainer from '../../common/AspectRatioContainer'
import Text from '../../common/Text'
import { get } from 'lodash'
import getPath from '../../../../lib/getPath'
import { CARD_STYLES } from '../../../../lib/constants'
import { rem, transparentize } from 'polished'
import LinkComponent from '../../common/Link'
import {
  Image,
  ImagePositioner,
  TextContainer,
  TextTitle,
} from './GridItemCommon'
import linkResolver from '../../../utils/linkResolver'
// Aspect Ratio
const WIDTH = 33
const HEIGHT = 20

const LandingGridItem = ({ item, style, tags }) => {
  const data = get(item, 'grid_content.document.data')
  const image = get(data, 'image')
  const logoImage = get(data, 'card_logo_image')
  const title = get(data, 'card_title.text')
  const body = get(data, 'card_body.text')
  const showLogo =
    logoImage && logoImage.url && style === CARD_STYLES.LANDING_CARD

  const width = WIDTH
  const height = HEIGHT
  //temp

  const buttonLink = {
    href: linkResolver(data, 'button_link') || getPath({ data }),
    target: get(data, 'button_link.target'),
  }

  const imageContent = (
    <>
      <ImagePositioner>
        <AspectRatioBox absolute width={width} height={height}>
          {image && <Image src={image.url} alt={title || ''} />}
        </AspectRatioBox>
        <AlphaGradient style={{ transform: 'translateY(1px)' }} />

        {showLogo && (
          <Logo>
            <Circle>
              <img alt="logo" src={logoImage.url} />
            </Circle>
          </Logo>
        )}
      </ImagePositioner>
      <AspectRatioContainer
        className="img-spacer"
        absolute
        width={width}
        height={height}
      />
    </>
  )

  return (
    <Item>
      <Content styleType={style} {...buttonLink}>
        {imageContent}
        <AlphaGradientCorner transparent />
        <TextContainer>
          {title && (
            <TextTitle.h4 bold spacing={1.58} uppercase center>
              {title}
            </TextTitle.h4>
          )}
          {body && (
            <Text.p
              medium
              center
              line-height={rem(19)}
              lineHeightMultiplier={19 / 13}
              spacing={0}
            >
              {body}
            </Text.p>
          )}
        </TextContainer>
      </Content>
    </Item>
  )
}

LandingGridItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.node),
}

export default LandingGridItem

const Circle = styled.div`
  ${({ theme }) => css`
    background-color: #fff;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    max-width: ${rem(95)};
    max-height: ${rem(95)};

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  `};
`

const Logo = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    max-width: ${rem(95)};
    max-height: ${rem(95)};

    background-color: rgba(255, 255, 255, 0.2);
    padding: ${theme.space(0.5)};
    border-radius: 50%;
    box-shadow: 0 3px 20px 0 #1d355b;
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: ${theme.layout.borderRadius};

    margin-left: ${rem(10)};
    margin-right: ${rem(10)};
    max-width: calc(100% - ${rem(20)});

    &:hover {
      ${theme.mixin.imgHover};

      .aspect-img img {
        opacity: 0.75;
        transform: scale(1.03);
      }
    }

    ${theme.media.md`
      margin-left: 0;
      margin-right: 0;
      max-width: none;
    `};
  `};
`

const Content = styled(LinkComponent)`
  ${({ theme, styleType }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    min-height: ${rem(330)};

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    background: ${theme.color.blue};

    ${ImagePositioner} {
      position: absolute;
      top: 0;
      left: 0;
    }

    h4 {
      color: #fff;
    }

    && {
      p {
        color: ${theme.color.secondaryLightBlue};
        font-size: ${rem(14)};
        line-height: ${rem(20)};
        ${theme.media.lg`
          font-size: ${rem(13)};
          line-height: ${rem(19)};

        `};
      }
    }

    .img-spacer {
      display: block;
    }

    ${TextContainer} {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 ${theme.space(3)} ${theme.space(3)};
      position: absolute;
      bottom: 0;
      width: 100%;
      ${Text} {
        text-align: center;
      }

      ${Text.p} {
        margin-bottom: 0;
      }
    }
  `};
`
