import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useReducer,
} from 'react'
import PropTypes from 'prop-types'

import Button from '../../common/Button'
import ErrorText from './ErrorText'
import InputContainer from './InputContainer'
import { Upload } from '../../common/Icons'
import Text from '../../common/Text'
import Label from './Label'

import { get, first } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { motion } from 'framer-motion'
import { color } from '../../../style/theme'
import { useAppContext } from '../../../context/AppContext'

function preventDefaults(e) {
  e.preventDefault()
  e.stopPropagation()
}

function truncate(n, len = 30) {
  if (n.length <= len) return n

  const ext = n
    .split('.')
    .pop()
    .toLowerCase()
  let filename = n.replace('.' + ext, '')
  filename = filename.substr(0, len) + (n.length > len ? '..' : '')
  return `${filename}.${ext}`
}

function humanFileSize(size) {
  if (size < 1024) return size + ' B'
  let i = Math.floor(Math.log(size) / Math.log(1024))
  let num = size / Math.pow(1024, i)
  let round = Math.round(num)
  num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
  return `${num}${'KMGTPEZY'[i - 1]}B`
}

const ADD_FILE = 'addFile'
const REMOVE_FILE = 'removeFile'

const uploadReducer = (state, { type, files, index }) => {
  switch (type) {
    case ADD_FILE:
      return [...state, ...files]
    case REMOVE_FILE:
      return [...state.slice(0, index), ...state.slice(index + 1, state.length)]
    default:
      return [...state]
  }
}

const isBrowser = () => typeof document !== 'undefined'

const UploadInput = ({
  browse_label,
  columns,
  error,
  instruction_label,
  label,
  name,
  placeholder,
  handleChange,
  handleBlur,
  touch,
  validation,
  value,
  ...props
}) => {
  const { isLarge } = useAppContext()
  const [dragOver, setDragOver] = useState(false)
  const [uploaded, dispatchUploaded] = useReducer(uploadReducer, [])
  const dropAreaRef = useRef()
  const inputRef = useRef()
  const hasError = touch && error
  const required = get(props, 'required') === 'yes'

  const handleBrowse = () => {
    const inputEl = get(inputRef, 'current')
    if (inputEl) inputEl.click()
  }

  const inputProps = {
    name,
    placeholder,
    required,
  }

  const gridColumn = {
    gridColumn: '1 / 3',
    zIndex: 1,
  }

  useEffect(() => {
    handleChange(name, uploaded)
  }, [handleChange, name, uploaded])

  const handleRemove = useCallback(index => {
    dispatchUploaded({ type: REMOVE_FILE, index })
  }, [])

  const handleDrop = useCallback(e => {
    const files = get(e, 'dataTransfer.files') || get(e, 'target.files')
    const len = get(files, 'length') || null
    if (len) {
      const arrFiles = Array.from(files)
      const val = arrFiles.map(file => ({
        file,
        src: window.URL.createObjectURL(file),
      }))
      //const uploadedFile = first(val)
      dispatchUploaded({ type: ADD_FILE, files: val })
    }
  }, [])

  const el = get(dropAreaRef, 'current')
  useEffect(() => {
    if (el) {
      const handleDragOver = () => setDragOver(true)
      const handleDragLeave = () => setDragOver(false)

      const eventList = ['dragenter', 'dragover', 'dragleave', 'drop']
      eventList.forEach(eventName => {
        el.addEventListener(eventName, preventDefaults, false)
        if (isBrowser)
          document.body.addEventListener(eventName, preventDefaults, false)
      })

      el.addEventListener('drop', handleDrop, false)
      el.addEventListener('dragover', handleDragOver, false)
      el.addEventListener('dragleave', handleDragLeave, false)

      return () => {
        eventList.forEach(eventName => {
          el.removeEventListener(eventName, preventDefaults)
          if (isBrowser)
            document.body.addEventListener(eventName, preventDefaults)
        })

        el.removeEventListener('drop', handleDrop)
        el.removeEventListener('dragover', handleDragOver)
        el.removeEventListener('dragleave', handleDragLeave)
      }
    }
  }, [el, handleDrop])

  return (
    <InputContainer
      error={hasError}
      css={gridColumn}
      valid={!hasError && touch && value !== ''}
    >
      {label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      <Row isLarge={isLarge}>
        <DropZone
          ref={dropAreaRef}
          onClick={handleBrowse}
          initial="leave"
          animate={dragOver ? 'over' : 'leave'}
          exit="collapsed"
          variants={{
            over: {
              color: color.blue,
            },
            leave: {
              color: color.secondaryLightBlue,
            },
          }}
          transition={{
            duration: 0.4,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
        >
          <Border>
            <input
              ref={inputRef}
              type="file"
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
              onChange={handleDrop}
              onBlur={handleBlur}
              multiple
              {...inputProps}
            />

            <div>
              <Row>
                <Upload width={24} height={24} />
                <Text size="sm" medium ellipsis>
                  {instruction_label}
                </Text>
              </Row>
            </div>
          </Border>
        </DropZone>

        {browse_label && (
          <label htmlFor={name} onClick={handleBrowse} tabIndex={0}>
            <Button outline onClick={e => e.preventDefault()} as="div">
              {browse_label}
            </Button>
          </label>
        )}
      </Row>

      {uploaded && !!uploaded.length && (
        <Files>
          {uploaded.map((file, i) => {
            const filename = get(file, 'file.name')
            const size = get(file, 'file.size')
            return (
              <File key={filename}>
                <Text size="xxs" lg="md">
                  {filename}
                </Text>
                <TextRow>
                  {size && (
                    <Text size="xxs" lg="xs">
                      {humanFileSize(size)}
                    </Text>
                  )}
                  <Text
                    bold
                    uppercase
                    as="button"
                    size="xxs"
                    lg="xs"
                    onClick={() => handleRemove(i)}
                  >
                    Remove
                  </Text>
                </TextRow>
              </File>
            )
          })}
        </Files>
      )}

      <ErrorText show={hasError}>{error}</ErrorText>
    </InputContainer>
  )
}

UploadInput.propTypes = {
  browse_label: PropTypes.string,
  instruction_label: PropTypes.string,
  columns: PropTypes.number,
  error: PropTypes.string,
  handleBlur: PropTypes.func,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  touch: PropTypes.bool,
  validation: PropTypes.string,
  value: PropTypes.array,
}

export default UploadInput

const Files = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.space(1)};
  `};
`

const File = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: ${theme.space(2)} 0;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.color.rule};
    }
  `};
`

const TextRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > * {
      line-height: 1.3;
    }

    button {
      ${theme.mixin.opacityHover};
      margin-left: ${theme.space(1)};
    }
  `};
`

const DropZone = styled(motion.div)`
  ${({ theme }) => css`
    display: none;
    width: 100%;
    height: ${rem(41)};
    min-height: ${rem(41)};

    margin-right: ${theme.space(2)};

    input {
      display: none;
    }

    ${theme.media.lg`
      display: block;
    `};
  `};
`

const Border = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    padding: 0 ${theme.space(2)};

    border: ${theme.layout.border} dashed currentColor;
    border-radius: ${theme.layout.borderRadius};
  `};
`

const Row = styled.div`
  ${({ isLarge, theme }) => css`
    display: flex;
    align-items: center;

    > svg {
      margin-right: ${theme.space(1)};
    }

    ${!isLarge &&
      css`
        button,
        label {
          width: 100%;
          max-width: none;

          > * {
            max-width: none !important;
          }
        }
      `};
  `};
`
