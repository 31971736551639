import React from 'react'
import PropTypes from 'prop-types'

import AspectRatioBox from '../common/AspectRatioBox'
import GoogleMap from '../common/GoogleMap'
import ReactHtmlParser from 'react-html-parser'
import Section from '../common/Section'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'

// Aspect Ratio
const aspectRatio = {
  width: 81,
  height: 71,
}

const GoolgeMapEmbedModule = ({ primary: data }) => {
  const address = get(data, 'address')
  const body = get(data, 'map_body.html')
  const coordinates = {
    lat: get(data, 'coordinates.latitude'),
    lng: get(data, 'coordinates.longitude'),
  }
  const bgColor = get(data, 'background_color') || 'transparent'

  return (
    <Section bgColor={bgColor}>
      <Container>
        <TextContainer>
          {body && (
            <Text html htmlMargin>
              {address && <h3>{address}</h3>}
              {ReactHtmlParser(body)}
            </Text>
          )}
        </TextContainer>
        {address && (
          <AspectRatioBox border {...aspectRatio}>
            <GoogleMap coordinates={coordinates} address={address} />
          </AspectRatioBox>
        )}
      </Container>
    </Section>
  )
}

GoolgeMapEmbedModule.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
  slice_type: PropTypes.string,
}

export default GoolgeMapEmbedModule

const TextContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: ${theme.space(3.5)};

    ${theme.media.lg`
      margin-right: ${theme.space(4)};
      margin-bottom: 0;
      max-width: ${rem(450)};
      height: 100%;
    `};
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    min-height: ${rem(140)};
    width: 100%;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.lg`
        margin: 0 ${theme.space(3)};
      `};

    > div {
      width: 100%;

      ${theme.media.lg`

        &:first-child {
          width: 45%;
        }

        width: 55%;
      `};
    }

    ${theme.media.lg`
        flex-direction: row;
      `};
  `};
`
