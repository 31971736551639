import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { SliceSection } from '../common/Section'

import CalendarMonthView from '../components/calendar/CalendarMonthView'
import CalendarIcon from '../../assets/calendar.inline.svg'
import ListIcon from '../../assets/list.inline.svg'
import PrintIcon from '../../assets/printer.inline.svg'
import Text from '../common/Text'
import ReactHtmlParser from 'react-html-parser'
import { SmallLinkArrow } from '../common/Icons'
import CardsIcon from '../../assets/cards-icon.inline.svg'
import CalendarMobileView from '../components/calendar/CalendarMobileView'
import CalendarListView from '../components/calendar/CalendarListView'

import { useAppContext } from '../../context/AppContext'
import useChangeWeekMonthYear, {
  initWeekState,
} from '../../hooks/dates/useChangeWeekMonthYear'
import useChangeMonthYear, {
  initMonthState,
} from '../../hooks/dates/useChangeMonthYear'
import theme, { fontSizesSmall } from '../../style/theme'
import { get } from 'lodash'
import { rem } from 'polished'
import styled, { css } from 'styled-components'

const VIEW_MONTH = 'Month'
const VIEW_LIST = 'List'

export const CALENDAR_MODULE_ID = 'calendarModule'

const ICONS = {
  calendar: CalendarIcon,
  list: ListIcon,
  printer: PrintIcon,
}

const CalendarModule = ({
  primary,
  slice_type,
  items,
  moduleId: id,
  ...props
}) => {
  const { isLarge } = useAppContext()
  const moduleId = id || CALENDAR_MODULE_ID
  const title = get(primary, 'calendar_title.html')
  const allowToggle = get(primary, 'allow_toggle') === 'Yes'
  const defaultView = get(primary, 'default_view')
  const legalText = get(primary, 'calendar_legal_text')
  const bgColor = get(primary, 'background_color') || 'softBlue'
  const pdfDownloadUrl = get(primary, 'pdf_download')
  const [view, setView] = useState(defaultView || VIEW_MONTH)
  const [{ month, year }, setMonth] = useState(initMonthState)
  const [{ weekStart, weekEnd }, setWeek] = useState(initWeekState)

  const handlePrint = e => {
    e.preventDefault()
    if (window && window.print) {
      window.print()
    }
  }

  const handlePdfDownload = e => {
    e.preventDefault()
    if (window && window.open) {
      window.open(pdfDownloadUrl.url)
    }
  }

  const selectMonthView = useCallback(() => {
    setView(VIEW_MONTH)
  }, [])

  const selectListView = useCallback(() => {
    setView(VIEW_LIST)
  }, [])

  const useWeeks = !isLarge

  return (
    <>
      <SliceSection bgColor={bgColor} id={moduleId} {...props}>
        <Container>
          <HeaderContainer>
            {isLarge && (
              <>
                <Buttons justify="flex-start">
                  {pdfDownloadUrl && pdfDownloadUrl.url ? (
                    <CalendarRoundButton
                      icon="printer"
                      label="print"
                      showLabel
                      onClick={handlePdfDownload}
                    />
                  ) : (
                    <CalendarRoundButton
                      icon="printer"
                      label="print"
                      showLabel
                      onClick={handlePrint}
                    />
                  )}
                </Buttons>
              </>
            )}
            <TitleBar
              useWeeks={useWeeks}
              onDateChange={useWeeks ? setWeek : setMonth}
              title={title}
            />
            {isLarge && (
              <Buttons justify="flex-end">
                {allowToggle && (
                  <>
                    <CalendarRoundButton
                      icon="calendar"
                      label="calendar view"
                      selected={view === VIEW_MONTH}
                      onClick={selectMonthView}
                    />
                    <CalendarRoundButton
                      icon="list"
                      label="list view"
                      selected={view === VIEW_LIST}
                      onClick={selectListView}
                    />
                  </>
                )}
              </Buttons>
            )}
          </HeaderContainer>
          {isLarge && (
            <>
              {view === VIEW_MONTH && (
                <CalendarMonthView month={month} year={year} events={items} />
              )}
              {view === VIEW_LIST && (
                <CalendarListView month={month} year={year} events={items} />
              )}
            </>
          )}
          {!isLarge && (
            <CalendarMobileView
              dateStart={weekStart}
              dateEnd={weekEnd}
              events={items}
            />
          )}

          <LegalContainer>
            <Text.p size="xxs" lg="xs" lineHeight={20 / 13} color="neutralBlue">
              {legalText}
            </Text.p>
          </LegalContainer>
        </Container>
      </SliceSection>
    </>
  )
}

CalendarModule.propTypes = {
  primary: PropTypes.object,
  slice_type: PropTypes.string,
  items: PropTypes.array,
  moduleId: PropTypes.string,
}

CalendarModule.defaultProps = {
  items: [],
}

export default CalendarModule

const CalendarRoundButton = ({ onClick, icon, label, showLabel, ...props }) => {
  const Icon = ICONS[icon]

  return (
    <ButtonContainer onClick={onClick} aria-label={label} {...props}>
      <RoundButton>
        <Icon />
      </RoundButton>
      {showLabel && <Text.h6>{label}</Text.h6>}
    </ButtonContainer>
  )
}

CalendarRoundButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  icon: PropTypes.oneOf(['printer', 'list', 'calendar']),
}

const TitleBar = ({ title, onDateChange, useWeeks }) => {
  const DateSelect = useWeeks ? WeekSelect : MonthSelect
  return (
    <Title>
      <TitleIcon>
        <CardsIcon width={30} height={30} />
      </TitleIcon>
      <Text html uppercase center selectNone>
        {ReactHtmlParser(title)}
      </Text>
      <DateSelect onDateChange={onDateChange} />
    </Title>
  )
}

TitleBar.propTypes = {
  onDateChange: PropTypes.func,
  title: PropTypes.string,
  useWeeks: PropTypes.bool,
}

const WeekSelect = ({ onDateChange }) => {
  const {
    weekStart,
    weekEnd,
    onNextWeek,
    onPrevWeek,
    text,
  } = useChangeWeekMonthYear()

  useEffect(() => {
    onDateChange({ weekStart, weekEnd })
  }, [onDateChange, weekEnd, weekStart])

  return (
    <DateSelect>
      <PrevMonth
        fill={theme.color.accentBlue}
        height={12}
        onClick={onPrevWeek}
      />
      <Text center medium size={fontSizesSmall.md} uppercase selectNone>
        {text}
      </Text>
      <NextMonth
        fill={theme.color.accentBlue}
        height={12}
        onClick={onNextWeek}
      />
    </DateSelect>
  )
}

WeekSelect.propTypes = {
  onDateChange: PropTypes.func,
}

const MonthSelect = ({ onDateChange }) => {
  const { onNextMonth, onPrevMonth, month, year, text } = useChangeMonthYear()

  useEffect(() => {
    onDateChange({ month, year })
  }, [onDateChange, month, year])

  return (
    <DateSelect>
      <PrevMonth
        fill={theme.color.accentBlue}
        height={12}
        onClick={onPrevMonth}
      />
      <Text medium size={fontSizesSmall.md} uppercase selectNone>
        {text}
      </Text>
      <NextMonth
        fill={theme.color.accentBlue}
        height={12}
        onClick={onNextMonth}
      />
    </DateSelect>
  )
}

MonthSelect.propTypes = {
  onDateChange: PropTypes.func,
}

const LegalContainer = styled.div`
  ${({ theme }) => css`
    width: 90%;
    margin: ${theme.space(2.5)} auto 0;

    > p {
      text-align: justify;

      ${theme.media.lg`
         text-align: center;
      `};
    }
  `}
`

const Buttons = styled.div`
  ${({ theme, justify }) => css`
    grid-column: span 2;
    display: flex;
    align-items: flex-end;
    justify-content: ${justify};

    > div:first-child {
      margin-right: ${theme.space(1)};
    }
  `}
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    max-width: ${theme.layout.maxWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
  `}
`

const Relative = styled.div`
  ${({ theme }) => css`
    position: relative;
  `}
`

const Box = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 300px;
    height: 200px;
    background-color: red;
  `}
`

const ButtonContainer = styled.div`
  ${({ theme, align, selected }) => css`
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    ${RoundButton} {
      position: relative;

      transform: translateY(0px) scale(1);
      transition-property: transform, box-shadow;
      transition-duration: 1s, 0.7s;
      transition-delay: 0.1s, 0s;
      transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    ${selected
      ? css`
          cursor: auto;

          ${RoundButton} {
            background-color: ${theme.color.yellow};
          }
        `
      : css`
          &:hover {
            ${RoundButton} {
              box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1px 0px;
              transform: translateY(1px) scale(0.99);
            }
          }
        `};

    h6 {
      margin-left: ${theme.space(1)};
      margin-right: ${theme.space(1)};
    }
  `}
`

const RoundButton = styled.div`
  ${({ theme }) => css`
    width: ${rem(50)};
    height: ${rem(50)};
    background: white;
    border-radius: ${rem(50)};
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  `}
`

const DateSelect = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.space(1)};
  `}
`

const NextMonth = styled(SmallLinkArrow)`
  ${({ theme }) => css`
    position: relative;
    margin-left: ${theme.space(2)};
    cursor: pointer;

    * {
      transition: 0.3s fill;
    }

    &:hover {
      * {
        fill: ${theme.color.blue};
        transition: 0.3s fill;
      }
    }
  `}
`

const PrevMonth = styled(NextMonth)`
  ${({ theme }) => css`
    transform: scaleX(-1);
    margin-right: ${theme.space(2)};
    margin-left: 0;
  `}
`

const HeaderContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    padding-bottom: ${theme.space(1)};

    ${theme.media.lg`
      padding-bottom: ${theme.space(3)};
    `};

    ${theme.media.xl`

      grid-template-columns: repeat(
        8,
        minmax(${rem(theme.breakpointsPx.lg / 8)}, 1fr)
      );
    `};
  `}
`

const TitleIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.space(1)};

    svg {
      height: 30px;
      path {
        fill: ${theme.color.accentBlue};
      }
    }
  `}
`

const Title = styled.div`
  ${({ theme }) => css`
    grid-column: span 8;
    h1,
    h2,
    h3,
    h4 {
      line-height: ${32 / 26};
    }

    ${theme.media.lg`
      grid-column: span 4;
    `}
  `}
`
