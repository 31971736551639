import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import AlphaGradient from '../common/AlphaGradient'
import ReactHtmlParser from 'react-html-parser'
import { SliceSection } from '../common/Section'
import FullBleedPlayer from '../common/FullBleedPlayer'
import Flickity from '../common/Flickity'
import Button from '../common/Button'
import Text, { setFontSize, sizes } from '../common/Text'

import styled, { css } from 'styled-components'
import useElementSize from '../../hooks/useElementSize'
import { useLoadImage } from '../../hooks/useLoadImage'
import { useAppContext } from '../../context/AppContext'
import Link from '../common/Link'
import linkResolver from '../../utils/linkResolver'
import { PrevNextSimpleArrow } from '../common/Icons'
import { motion } from 'framer-motion'
import { rem, transparentize } from 'polished'
import { get } from 'lodash'
import { SANS_SERIF, WEIGHT } from '../../style/type'

import { ICON_ATLAS } from '../common/IconAtlas'

const PADDING_H = 120

const FullbleedIntroModule = ({ slice_type, items, primary, ...props }) => {
  const [init, setInit] = useState(false)
  const [index, setIndex] = useState(0)
  const [count, setCount] = useState(0)
  const [isPaused, setIsPaused] = useState(false)
  const requestRef = React.useRef()
  const startTimeRef = React.useRef(null)
  const pausedRef = React.useRef(false)
  const isRunningRef = React.useRef(true)
  const pausedTimeRef = React.useRef(0)
  const timeElapsedSinceStart = React.useRef(0)
  const item = get(items, `[${index}]`)
  const headline = get(item, 'intro_title.text')
  const body = get(item, 'intro_body.html')
  const buttonLabel = get(item, 'button_label') || 'Read More'
  const link = {
    href: linkResolver(item, 'button_link'),
    target: get(item, 'button_link.target'),
  }

  const { isLarge, isMedium, focusStyleOn } = useAppContext()
  const elRef = useRef(null)
  const flickityRef = useRef(null)
  const firstImage = get(items, '[0].hero_image.url')
  const { loaded } = useLoadImage(firstImage)
  const { width } = useElementSize(elRef, loaded)

  const offsetH = isMedium ? PADDING_H : 0
  const height = ((width - offsetH) * 19) / 39

  function getCookie(cname) {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  function getCookieValues(cookieString) {
    const cookiePairs = cookieString.split(',')
    const cookieValues = {}

    for (const pair of cookiePairs) {
      const [key, value] = pair.split(':')
      cookieValues[key] = value
    }

    return cookieValues
  }

  const [cookieConsent, setCookieConsent] = useState(false)

  useEffect(() => {
    const consentCookie = getCookie('cookieyes-consent')
    console.info('cc' + consentCookie)

    if (consentCookie.length > 0) {
      const cookieValues = getCookieValues(consentCookie)
      console.info(cookieValues.advertisement)
      if (cookieValues.advertisement === 'yes') {
        setCookieConsent(true)
      } else {
        setCookieConsent(false)
      }
    }
  }, [])

  const PlayIcon = ICON_ATLAS.PlayIcon
  const PauseIcon = ICON_ATLAS.PauseIcon

  const autoPlay = (get(primary, 'time') || 3) * 1000
  let flickityEl = get(flickityRef, 'current')
  const handlePrev = () => {
    flickityEl.prev()
  }
  const handleNext = () => {
    if (!flickityEl) flickityEl = get(flickityRef, 'current')
    flickityEl.next()
  }
  const handleEnter = () => {
    pausedRef.current = true
    isRunningRef.current = false
    setIsPaused(true)
  }
  const handleLeave = () => {
    isRunningRef.current = true
    pausedRef.current = false
    requestRef.current = requestAnimationFrame(animate)
    setIsPaused(false)
  }

  const toggleAutoPlayHandlers = {
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave,
  }

  const showPagination = items && !!items.length

  const animate = timeStamp => {
    if (!startTimeRef.current) startTimeRef.current = timeStamp

    if (isRunningRef.current && !pausedRef.current) {
      timeElapsedSinceStart.current =
        timeStamp - startTimeRef.current + pausedTimeRef.current
      const progress = timeElapsedSinceStart.current / autoPlay
      const safeProgress = Math.min(progress.toFixed(2), 1)
      setCount(57 - 57 * safeProgress)
      requestRef.current = requestAnimationFrame(animate)
    } else if (!isRunningRef.current && pausedRef.current) {
      pausedTimeRef.current = timeElapsedSinceStart.current
      startTimeRef.current = null
      pausedRef.current = false
    }

    if (timeElapsedSinceStart.current >= autoPlay) {
      // Timer ends
      startTimeRef.current = null
      pausedTimeRef.current = 0
      handleNext()
    }
  }

  useEffect(() => {
    if (items && items.length > 1) {
      requestRef.current = requestAnimationFrame(animate)
      return () => cancelAnimationFrame(requestRef.current)
    }
  }, [])

  return (
    <SliceSection
      fullWidth
      noPaddingTop
      noPaddingBottom={index === 0}
      paddingProps={{ top: 0, bottom: 0 }}
      {...props}
    >
      {index === 0 && <Bg />}

      <Container>
        {/* // only one item and no link */}
        {items && items.length === 1 && !(link && link.href) && (
          <SingleImageContent>
            {headline && (
              <Text.h1 center white>
                {headline}
              </Text.h1>
            )}
            {body && <SubHeadlines>{ReactHtmlParser(body)}</SubHeadlines>}
            {items[0].video_url && items[0].video_url.url && cookieConsent ? (
              <FullBleedPlayerEl url={items[0].video_url.url} visible />
            ) : (
              items[0].background_image && (
                <BackgroundImage
                  src={items[0].background_image.url}
                  alt={items[0].background_image.alt}
                />
              )
            )}
            {/* <AlphaGradient height={100} /> */}
            <ColorOverlay />
          </SingleImageContent>
        )}

        {items && items.length === 1 && link && link.href && (
          <SingleImageCTAContent>
            {items[0].video_url && items[0].video_url.url && cookieConsent ? (
              <FullBleedPlayerEl url={items[0].video_url.url} visible />
            ) : (
              items[0].background_image && (
                <BackgroundImage
                  src={items[0].background_image.url}
                  alt={items[0].background_image.alt}
                />
              )
            )}

            {/* <AlphaGradient height={100} /> */}
            <LockupContainer>
              <Lockup>
                <LockupHeadlines>
                  {headline && (
                    <Text.h1 center white>
                      {headline}
                    </Text.h1>
                  )}
                  {body && <SubHeadlines>{ReactHtmlParser(body)}</SubHeadlines>}
                </LockupHeadlines>

                <Button inline {...link}>
                  {buttonLabel}
                </Button>
              </Lockup>
            </LockupContainer>
          </SingleImageCTAContent>
        )}

        {items && items.length > 1 && (
          <FlickityContainer>
            <Flickity
              ref={flickityRef}
              key={loaded && focusStyleOn}
              className="flickity"
              onChange={i => {
                setIndex(i)
                startTimeRef.current = null
                pausedTimeRef.current = 0
                setCount(57)
              }}
              onInit={() => {
                setInit(true)
              }}
              type="homeGallery"
              options={{
                autoPlay: false,
                pauseAutoPlayOnHover: false,
                prevNextButtons: false,
                accessibility: focusStyleOn,
              }}
            >
              {items &&
                items.map((item, i) => {
                  const image = {
                    src: get(item, 'background_image.url'),
                    alt: get(item, 'background_image.alt') || '',
                  }

                  const videoData = {
                    videoUrl: get(item, 'video_url.url'),
                    videoImage: get(item, 'video_image'),
                  }

                  const link = {
                    href: linkResolver(item, 'button_link'),
                    target: get(item, 'button_link.target'),
                  }

                  const Component = link.href ? ItemLink : Item

                  return (
                    <Component key={`img${i}`} {...link}>
                      {videoData.videoUrl && cookieConsent ? (
                        <FullBleedPlayerEl
                          url={videoData.videoUrl}
                          visible={index === i}
                        />
                      ) : (
                        image.src && (
                          <BackgroundImage src={image.src} alt={image.src} />
                        )
                      )}
                      {/* <AlphaGradient height={100} /> */}
                    </Component>
                  )
                })}
            </Flickity>

            {/*
            Removed the autoPlay handlers from the LockupContainer
            and moved them to the pause/play buttons instead
            <LockupContainer {...toggleAutoPlayHandlers}>
            */}
            <LockupContainer>
              <Lockup>
                <TextContainer
                  initial="hide"
                  animate={init ? 'show' : 'hide'}
                  variants={{
                    show: { opacity: 1 },
                    hide: { opacity: 0 },
                  }}
                  transition={{
                    duration: 0.7,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  <LockupHeadlinesFlickity
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <LockupHeadlineButton>
                      {headline && (
                        <Text.h1 center white>
                          {headline}
                        </Text.h1>
                      )}
                      {isLarge && link.href && (
                        <Button small {...link}>
                          {buttonLabel}
                        </Button>
                      )}
                    </LockupHeadlineButton>

                    {body && (
                      <SubHeadlines>{ReactHtmlParser(body)}</SubHeadlines>
                    )}

                    {!isLarge && link.href && (
                      <Button inline {...link}>
                        {buttonLabel}
                      </Button>
                    )}
                  </LockupHeadlinesFlickity>
                </TextContainer>

                {showPagination && (
                  <ArrowsAndPagination>
                    <PrevNext
                      onClick={handlePrev}
                      tabIndex={0}
                      aria-label="Navigation Button"
                    >
                      <PrevNextSimpleArrow left fill="#fff" />
                    </PrevNext>

                    <Pagination>
                      {items.map((item, i) => {
                        const handleSelect = () => flickityRef.current.select(i)
                        return (
                          <button
                            key={`p${i}`}
                            onClick={handleSelect}
                            aria-label={`Carousel Button Slide ${i + 1}`}
                          >
                            <Timer width="20" height="20">
                              <circle
                                r="9"
                                cx="10"
                                cy="10"
                                fill="transparent"
                                stroke="green"
                                strokeWidth="2"
                                strokeDasharray="57"
                                strokeDashoffset={i === index ? count : '57'}
                              ></circle>
                            </Timer>
                            <Dot active={i === index} />
                          </button>
                        )
                      })}
                    </Pagination>

                    <PrevNext
                      onClick={handleNext}
                      tabIndex={0}
                      aria-label="Navigation button"
                    >
                      <PrevNextSimpleArrow fill="#fff" />
                    </PrevNext>

                    {/* Accessibility / ADA play/pause button */}
                    {isPaused ? (
                      <button
                        onClick={() => handleLeave()}
                        style={{ color: '#fff' }}
                      >
                        <PlayIcon />
                      </button>
                    ) : (
                      <button
                        onClick={() => handleEnter()}
                        style={{ color: '#fff' }}
                      >
                        <PauseIcon />
                      </button>
                    )}
                  </ArrowsAndPagination>
                )}
              </Lockup>
            </LockupContainer>
          </FlickityContainer>
        )}
      </Container>
    </SliceSection>
  )
}

FullbleedIntroModule.propTypes = {
  slice_type: PropTypes.string,
  primary: PropTypes.object,
  items: PropTypes.array,
}

export default FullbleedIntroModule

const Bg = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - ${rem(140)});

    ${theme.media.md`
      background-color: ${theme.color.softBlue};
    `};

    ${theme.media.lg`
      height: calc(100% - ${rem(180)});
    `};
  `};
`

const ColorOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => transparentize(0.25, theme.color.blue)};
`

const Container = styled.div`
  ${({ theme }) => css`
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

            // height: ${rem(130)};
        width: 100%;
            // max-width: ${theme.layout.maxWidth};
        z-index: 2;

        ${theme.media.md`
      max-height: none;
      // border-radius: ${theme.layout.borderRadius};
      // overflow: hidden;
      display: block;
      //  min-height: ${rem(340)};
    `};

        ${theme.media.lg`
      //  min-height: ${rem(360)};
    `};

        ${theme.media.xl`
      //  min-height: ${rem(500)};
    `};

        ${ColorOverlay} {
            ${theme.media.md`
       display: none;
    `};
        }
    }
    `};

  h1 {
    ${({ theme }) => css`
      font-size: ${rem(32)};
      line-height: ${rem(32)};
      margin-bottom: ${rem(24)};

      ${theme.media.lg`
        margin-bottom: 0;
      `};
    `};
  }
`

const SubHeadlines = styled.div`
  ${({ theme }) => css`
    font-size: ${rem(12)};
    line-height: ${rem(12)};
    color: #ffffff;
    margin-bottom: ${rem(24)};
    margin-top: ${rem(24)};
    z-index: 1;

    ${theme.media.md`
      margin-bottom:${rem(16)};
    `};

    ${theme.media.lg`
      margin-bottom: 0;
      margin-top: ${rem(8)}
    `};

    p {
      font-family: ${SANS_SERIF};
      text-transform: uppercase;
      font-weight: ${WEIGHT.BOLD};

      span span {
        opacity: 0.16;
        margin: 0 16px;
      }
    }
  `};
`

const SingleImageContent = styled.div`
  ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 66.667vw;
        // min-height: 56.25vw;

        ${theme.media.md`
      // justify-content: flex-end;
      // padding: ${theme.space(6.5)} 0;
      // min-height: auto;
      // height: 38vw;
      // max-height: 620px;
    `};

        ${theme.media.lg`
      height: 37.991vw;
      overflow: hidden;
    `};

        ${BackgroundImage} {
            position: absolute;
        }

        ${FullBleedPlayerEl} {
            position: absolute;
            height: 100%;
        }


        // > div {
        //   display: none;
            //   max-width: calc(${rem(600)} + ${theme.space(6)});
        // }

        > h1 {
            padding: 0 ${theme.space(3)};

            ${theme.media.md`
        display: block;

        &:not(:last-child) {
          margin-bottom: ${theme.space(1.8)};
        }
      `};
        }

        ${SubHeadlines} {
            display: none;
            ${theme.media.md`
        display: block;
      `};
        }

        ${ColorOverlay} {
            display: none;

        }

        p {
                // font-weight: ${WEIGHT.MEDIUM};
        }

        img {
            // display: none;

            ${theme.media.md`
        // display: block;
        // margin-bottom: ${theme.space(2.5)};
      `};
        }
    `};
`

const SingleImageCTAContent = styled.div`
  ${({ theme }) => css`
        height: 100%;
        position: relative;

        ${theme.media.lg`
      height: 37.991vw;
      overflow: hidden;
    `};

        ${AlphaGradient} {
            display: none;

            ${theme.media.lg`
        display: block;
      `};
        }

        ${BackgroundImage} {
            position: relative;
        }

        > h1 {
            padding: 0 ${theme.space(3)};

            ${theme.media.md`
        display: block;

        &:not(:last-child) {
          margin-bottom: ${theme.space(1.8)};
        }
      `};
        }

        p {
                // font-weight: ${WEIGHT.MEDIUM};
        }
    `};
`

const LockupContainer = styled.div`
  ${({ theme }) => css`
    padding-top: ${rem(24)};
    padding-right: ${rem(10)};
    padding-bottom: ${rem(24)};
    padding-left: ${rem(10)};
    z-index: 2;
    background: radial-gradient(
      95.2% 129.92% at 2.13% 8.72%,
      #2e4d79 0%,
      #1c355c 100%
    );

    ${theme.media.lg`
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-top: 0;
      padding-right: ${rem(45)};
      padding-bottom: 0;
      padding-left: ${rem(45)};
      display: flex;
      justify-content: center;
      background: none;
      min-height: 84px;
  }
    `};
  `};
`

const Lockup = styled.div`
  ${({ theme }) => css`
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.lg`
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: ${rem(16)};
      border-radius: 8px 8px 0px 0px;
      background: rgba(31, 54, 88, 0.1);
      backdrop-filter: blur(64px);
    `};

    a {
      width: 100%;

      ${theme.media.lg`
        width: auto;
        flex: none;
    `};
    }
  `};
`

const LockupHeadlines = styled.div`
  ${({ theme }) => css`
    ${theme.media.md`
      
    `};

    > h1 {
      text-align: center;

      ${theme.media.lg`
        text-align: left;
    `};
    }
  `};
`

const LockupHeadlinesFlickity = styled(motion.div)`
  ${({ theme }) => css`
            // padding: ${rem(24)} 0;

            // ${theme.media.lg`
    //   padding: 0;
    // `};
    `};
`

const LockupHeadlineButton = styled(motion.div)`
  ${({ theme }) => css`
            // margin-bottom: ${rem(24)};

        ${theme.media.lg`
    // margin-bottom: 8px;

    h1 {
      text-align: left;
      display: inline;
      margin-right: ${rem(24)};
    }
    
    a {
      display: inline-block;
    }
  `};
    `};
`

const BackgroundImage = styled.img`
  ${({ theme }) => css`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 66.667vw;
    z-index: -1;

    ${theme.media.lg`
      // height: 100%;
    `};
  `};
`

const FullBleedPlayerEl = styled(FullBleedPlayer)`
  ${({ theme }) => css`
    height: 66.667vw;

    ${theme.media.lg`
      position: absolute;
      height: 100%;
    `};
  `};
`

const FlickityContainer = styled.div`
  ${({ theme }) => css`
        position: relative;
        width: 100%;


        ${theme.media.lg`
      height: 37.991vw;
    `};


        .flickity {
            ${theme.media.lg`
        height: 100%;
      `};
        }

        > div:first-child {
                // border-top-right-radius: ${theme.layout.borderRadius};
                // border-top-left-radius: ${theme.layout.borderRadius};
            overflow: hidden;

            ${theme.media.lg`
      // border-radius: ${theme.layout.borderRadius};

      .flickity-viewport{
        // border-radius: ${theme.layout.borderRadius};
        margin-bottom: 0;
      }
    `};
        }

        ${AlphaGradient} {
            display: none;

            ${theme.media.lg`
        display: block;
      `};
        }

        ${BackgroundImage} {
            position: relative;
        }

        ${FullBleedPlayerEl} {
            ${theme.media.lg`
        position: relative;
        height: 100%;
      `};
        }

        .flickity-viewport {
            margin-bottom: 0;
            border-radius: 0;
        }

        button {
            z-index: 3;
        }

        > div:first-child {
            z-index: 1;
        }
    `};
`

const sharedItem = ({ theme }) => css`
  position: relative;

  ${Text} {
    margin-top: ${theme.space(2)};
  }
`

const Item = styled.div`
  ${sharedItem};
`

const ItemLink = styled(Link)`
  ${sharedItem};
`

const TextContainer = styled(motion.div)`
  ${({ theme }) => css`
        // display: flex;
        // justify-content: space-around;
        // width: 100%;
            // margin: ${rem(-20)} 0 ${theme.space(2)};

        // z-index: 2;

        ${theme.media.md`
      // margin: 0 0 ${theme.space(2)};
    `};

        ${theme.media.lg`
      // display: flex;
      // background-color: transparent;

      // position: absolute;
      // bottom: ${theme.space(3)};
      // left: 0;

      // padding: 0 ${theme.space(2)};
      // margin: 0;
    `};
    `};
`

const ArrowsAndPagination = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${rem(24)};

    ${theme.media.lg`
      margin-top: 0;
      align-items: center;
    `};
  `};
`
const Pagination = styled.div`
  ${({ theme }) => css`
        // position: absolute;
            // bottom: ${rem(14)};
        display: flex;
        justify-content: center;

        button {
            padding-left: 7.5px;
            padding-right: 7.5px;
            position: relative;
        }
    `};
`

const Timer = styled.svg`
  ${({ active, theme }) => css`
    transform: rotate(-90deg);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;

    circle {
      stroke: ${theme.color.yellow};
    }
  `};
`

const Dot = styled.div`
  ${({ active, theme }) => css`
        width: ${rem(8)};
        height: ${rem(8)};
        border-radius: 100%;
        opacity: 1;
        background: #ffffff;
            // border: 1px solid ${theme.color.secondaryLightBlue};
        cursor: pointer;
        transition: background-color 0.4s ease-in, border 0.4s ease-in, transform 0.4s ease-out;

        ${theme.media.lg`
      width: ${rem(9)};
      height: ${rem(9)};
    `};

        &:hover {
            background: ${transparentize(0.5, theme.color.secondaryLightBlue)};
            transition: background-color 0.3s ease-out, border 0.3s ease-out, transform 0.3s ease-out;
        }

        ${active &&
          css`
            background: ${theme.color.yellow};
            border: none;
            cursor: auto;
            transform: scale(1.35);
          `};
    `};
`

const PrevNext = styled.button`
  ${({ theme }) => css`
        display: none;
        // position: absolute;
            // bottom: ${rem(92)};
        // left: 0;
        cursor: pointer;
        width: ${rem(40)};
        height: ${rem(40)};

        &:last-child {
            left: auto;
            right: 0;
        }

        justify-content: center;
        align-items: center;
        // width: 10vw;
        max-width: ${rem(160)};

        z-index: 2;

        ${theme.media.lg`
      display: flex;
    `};

        ${theme.media.xl`
        // width: 10vw;
    `};

        svg {
            width: ${rem(10)};
            height: ${rem(18)};

            * {
                transition: 0.3s stroke;
            }
        }

        &:hover {
            * {
                stroke ${theme.color.yellow};
            }
        }
    `};
`
