import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import GridModule from './GridModule'
import { get } from 'lodash'
import { DEFAULT_MAX_FEATURED_CARDS } from '../../constants/site'

const TagGridModule = ({
  primary: { heading, module_sub_heading, max_featured_cards, ...primaryProps },
  items,
  ...props
}) => {
  const cleanedItems = items.filter(
    ({ grid_content }) => grid_content && grid_content.document,
  )

  const maxItems = cleanedItems.slice(0, DEFAULT_MAX_FEATURED_CARDS)

  const bgColor = get(primaryProps, 'background_color', 'transparent')

  return (
    <GridModule
      bgColor={bgColor}
      primary={primaryProps}
      items={maxItems}
      {...props}
    />
  )
}

TagGridModule.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.shape({
    heading: PropTypes.object,
    module_sub_heading: PropTypes.object,
    max_featured_cards: PropTypes.number,
  }),
}

export default TagGridModule
