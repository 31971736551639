import React from 'react'
import PropTypes from 'prop-types'

import { CARD_STYLES } from '../../../../lib/constants'

import AlphaGradient, { AlphaGradientCorner } from '../../common/AlphaGradient'
import AspectRatioBox from '../../common/AspectRatioBox'
import Text from '../../common/Text'
import ReactHtmlParser from 'react-html-parser'
import { StyledButton } from '../../common/Button'
import {
  Item,
  ButtonContainer,
  Image,
  ImagePositioner,
  TextBody,
  TextContainer,
  TextTitle,
} from './GridItemCommon'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { motion } from 'framer-motion'
import { get } from 'lodash'
import getPath from '../../../../lib/getPath'
import linkResolver from '../../../utils/linkResolver'

// Aspect Ratio
const WIDTH = 35
const HEIGHT = 20

const ImageHeadlineCopyGridItem = ({ item, style, tags }) => {
  const data = get(item, 'grid_content.document.data')

  const image = get(data, 'image')
  const title = get(data, 'card_title.text')
  const body = get(data, 'card_body.html')
  const label = get(data, 'button_label')

  const showButton = ![CARD_STYLES.LANDING_CARD].includes(style)
  const showBody = ![CARD_STYLES.IMAGE_HEADLINE_CARD].includes(style)
  const showImage = ![CARD_STYLES.HEADLINE_COPY_CARD].includes(style)

  const buttonLink = {
    href: linkResolver(data, 'button_link') || getPath({ data }),
    target: get(data, 'button_link.target'),
  }

  const buttonContent = (
    <ButtonContainer>
      {buttonLink.href && showButton && (
        <StyledButton fullWidth {...buttonLink}>
          {label || 'Read More'}
        </StyledButton>
      )}
    </ButtonContainer>
  )

  const imageContent = (
    <>
      <ImagePositioner>
        <AspectRatioBox absolute width={WIDTH} height={HEIGHT}>
          {image && <Image src={image.url} alt={title || ''} />}
        </AspectRatioBox>
      </ImagePositioner>
      <AspectRatioBox
        className="img-spacer"
        absolute
        width={WIDTH}
        height={HEIGHT}
      >
        <AlphaGradient />
      </AspectRatioBox>
    </>
  )

  return (
    <Item>
      <Content styleType={style}>
        {showImage && imageContent}
        <AlphaGradientCorner transparent />

        <TextContainer>
          {title && (
            <TextTitle
              size="xl"
              bold
              html
              lineHeightMultiplier={28 / 20}
              spacing={1.6}
              as="h4"
              uppercase
            >
              {title}
            </TextTitle>
          )}
          {body && showBody && (
            <TextBody medium size="xs" lineHeightMultiplier={19 / 13}>
              {ReactHtmlParser(body)}
            </TextBody>
          )}
          {buttonContent}
        </TextContainer>
      </Content>
    </Item>
  )
}

ImageHeadlineCopyGridItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.node),
}

export default ImageHeadlineCopyGridItem

const Content = styled(motion.div)`
  ${({ theme, styleType }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    background: ${theme.color.blue};

    ${[CARD_STYLES.HEADLINE_COPY_CARD].includes(styleType) &&
      css`
        background: radial-gradient(
          at 0% 100%,
          ${theme.color.secondaryBlue} 0%,
          ${theme.color.blue} 33%
        );
      `}

    ${TextContainer} {
      padding: 0 ${theme.space(3)};
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    ${ImagePositioner} {
      position: absolute;
      top: 0;
      left: 0;
    }

    h4 {
      color: #fff;
    }

    && {
      p {
        color: ${theme.color.secondaryLightBlue};
        font-size: ${rem(14)};
        line-height: ${rem(20)};
        ${theme.media.lg`
          font-size: ${rem(13)};
          line-height: ${rem(19)};

        `};
      }
    }
    ${ButtonContainer} {
      padding: 0;
      margin: ${theme.space(0.5)} 0 ${theme.space(3)};
      > * {
        width: 100%;
      }
    }
    .img-spacer {
      display: block;
    }

    min-height: ${rem(330)};
    ${[CARD_STYLES.IMAGE_HEADLINE_COPY_CARD].includes(styleType) &&
      css`
        min-height: ${rem(370)};
        max-height: ${rem(370)};
      `};

    ${TextContainer} {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      ${[CARD_STYLES.IMAGE_HEADLINE_COPY_CARD].includes(styleType) &&
        css`
          justify-content: flex-end;
        `};

      ${[CARD_STYLES.HEADLINE_COPY_CARD].includes(styleType) &&
        css`
          margin-top: ${theme.space(2.5)};
          top: 0;

          ${ButtonContainer} {
            margin-top: auto;
          }
        `};
      ${[CARD_STYLES.IMAGE_HEADLINE_CARD].includes(styleType) &&
        css`
          ${Text} {
            text-align: center;
          }
        `};
    }

    h3,
    h5 {
      color: #fff;
    }
  `};
`
