import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../common/Checkbox'
import InputContainer from './InputContainer'
import Text from '../../common/Text'
import ReactHtmlParser from 'react-html-parser'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { transformLinks } from '../../../utils/textTransforms'

const CheckboxInput = ({
  columns,
  error,
  label,
  name,
  placeholder,
  handleChange,
  handleBlur,
  touch,
  validation,
  ...props
}) => {
  const [checked, setChecked] = useState(false)
  const toggle = () => setChecked(!checked)
  const hasError = touch && error
  const required = get(props, 'required') === 'yes'
  const body = get(props, 'checkbox_body.html')

  const inputProps = {
    name,
    placeholder,
    required,
  }
  const gridColumn = columns && columns !== 2 ? {} : { gridColumn: '1 / 3' }

  useEffect(() => {
    //@NOTE: Make sure it's checked if required
    const isChecked = required && !checked ? '' : checked
    handleChange(name, isChecked)
  }, [checked, handleChange, name, required])

  return (
    <InputContainer error={hasError} css={gridColumn} disableFocus>
      <Row>
        <CheckboxContainer>
          <input
            type="checkbox"
            checked={checked}
            onChange={toggle}
            onBlur={handleBlur}
            {...inputProps}
          />
          <Checkbox checked={checked} />
        </CheckboxContainer>
        {body && (
          <Text html onClick={toggle}>
            {ReactHtmlParser(body, { transform: transformLinks })}
          </Text>
        )}
      </Row>
    </InputContainer>
  )
}

CheckboxInput.propTypes = {
  columns: PropTypes.number,
  error: PropTypes.string,
  handleBlur: PropTypes.func,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  touch: PropTypes.bool,
  validation: PropTypes.string,
}

export default CheckboxInput

const CheckboxContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-top: ${theme.space(0.5)};

    svg {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    input {
      width: 18px;
      height: 16px;
      padding: ${theme.space(1.5)};
      border: none;
      cursor: pointer;
    }
  `};
`

const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
  `};
`
