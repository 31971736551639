import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import CalendarModule from './CalendarModule'

import { get } from 'lodash'
import { format, getMinutes, compareAsc } from 'date-fns'
import linkResolver from '../../utils/linkResolver'

export const TOURNAMENT_CALENDAR_MODULE_ID = 'tournamentCalendarModule'

const TournamentCalendarModule = ({ items, ...props }) => {
  const transformedItems = useMemo(() => {
    items.sort((a, b) => {
      const dateA = new Date(get(a, 'date'))
      const dateB = new Date(get(b, 'date'))

      return compareAsc(dateA, dateB)
    })
    return items.map(item => {
      const date = get(item, 'date')
      const time = get(item, 'time')
      const title = get(item, 'tournament_title')
      const titleShort = get(item, 'tournament_title_short')
      const buyIn = get(item, 'buy_in')
      const guarantee = get(item, 'guarantee')
      const dates = [{ date }]

      const cardTitle = `${time} • ${titleShort || title} ${
        guarantee ? `• ${guarantee} Guarantee` : ''
      } • ${buyIn} Buy-In`

      return {
        ...item,
        grid_content: {
          url: item.details.url,
          document: {
            data: {
              card_title: {
                text: cardTitle,
              },
              dates,
            },
          },
        },
      }
    })
  }, [items])

  return (
    <CalendarModule
      items={transformedItems}
      {...props}
      moduleId={TOURNAMENT_CALENDAR_MODULE_ID}
    />
  )
}

TournamentCalendarModule.propTypes = {
  items: PropTypes.array,
}

export default TournamentCalendarModule
