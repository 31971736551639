import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { rem } from 'polished'
import { get } from 'lodash'
import { usePrismicPreview } from 'gatsby-source-prismic'
import { navigate } from 'gatsby'
import { PRISMIC_REPO_NAME } from '../../../lib/constants'
import getPath from '../../../lib/getPath'
import Page from '../container/Page'
import Text from '../common/Text'
import { Row, Col } from 'react-flexa'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import styled from 'styled-components'
import theme from '../../style/theme'
import useParseSiteData from '../../hooks/useParseSiteData'
import {
  injectPostDetailsWithPagination,
  injectPostListingWithPostDetailPages,
  injectBlogGridModulesWithPostDetailPages,
} from '../../../lib/posts'

import { computeTags, injectPage } from '../../../lib/tags'
import normalizeDates from '../../../lib/normalizeDates'

const injectInferredRelationships = pages => {
  const withPostDetailPages = injectPostListingWithPostDetailPages(pages)
  const withPostPagination = injectPostDetailsWithPagination(
    withPostDetailPages,
  )

  const withBlogPostModules = injectBlogGridModulesWithPostDetailPages(
    withPostPagination,
  )
  return withBlogPostModules
}

const injectBlogGridModules = (hydratedPages, pageData) => {
  const hydratedPage = hydratedPages.find(({ uid }) => pageData.uid === uid)
  const bodyRaw = get(pageData, 'dataRaw.body')
  const body = get(pageData, 'data.body', [])
  if (bodyRaw && bodyRaw.length) {
    bodyRaw.forEach(({ slice_type, ...slice }, i) => {
      if (slice_type === 'blog_grid_module') {
        const hydratedSlice = get(hydratedPage, `data.body[${i}]`)
        body[i] = hydratedSlice
      }
    })
  }
}

const injectTagGridModules = (hydratedPages, pageData) => {
  //const tagName = page
  const { pagesByTags } = computeTags(hydratedPages)

  const injectPagesWithTags = injectPage(hydratedPages, pagesByTags, 'dataRaw')

  injectPagesWithTags(pageData)
  if (pageData.dataRaw.body && !!pageData.dataRaw.body.length) {
    pageData.dataRaw.body.forEach((slice, i) => {
      if (
        slice.slice_type === 'tag_grid_module' ||
        slice.slice_type === 'calendar_module'
      ) {
        slice.items.forEach(item => {
          if (
            item.grid_content &&
            !item.grid_content.document &&
            item.grid_content.uid
          ) {
            //get the actual document ffs
            const document = hydratedPages.find(
              ({ uid }) => uid === item.grid_content.uid,
            )
            item.grid_content.document = document
          }
        })
        pageData.data.body[i] = slice
      }
    })
  }
}

const PreviewPage = ({
  location,
  pageContext: { pages: pagesJson, staticData },
}) => {
  const pages = useMemo(() => JSON.parse(pagesJson), [pagesJson])
  const data = useMemo(() => JSON.parse(staticData), [staticData])

  const pageUIDs = pages.map(p => getPath(p, '/'))

  const { sections } = useParseSiteData(data)

  const pathResolver = () => doc => {
    const previewSlug = getPath(doc)
    if (pageUIDs.includes(previewSlug)) {
      return previewSlug
    } else {
      return '/draft'
    }
  }
  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: PRISMIC_REPO_NAME,
    pathResolver,
  })

  useEffect(() => {
    if (previewData && path) {
      const keys = Object.keys(previewData)
      const key = keys.length ? keys[0] : false
      const pData = key ? previewData[key] : previewData

      if (path === '/draft') {
        pages.push(pData)
      }
      const hydratedPages = injectInferredRelationships(pages)

      const injectedPageData = hydratedPages.find(
        ({ uid }) => uid === pData.uid,
      )
      console.log(path)
      if (path !== '/draft') normalizeDates(hydratedPages)

      injectTagGridModules(hydratedPages, pData)
      injectBlogGridModules(hydratedPages, pData)

      window.__PRISMIC_PREVIEW_DATA__ = {
        ...pData,
        pagination: injectedPageData ? injectedPageData.pagination : [],
        blogs: injectedPageData ? injectedPageData.blogs : [],
        venueEvents: injectedPageData ? injectedPageData.venueEvents : [],
      }
      navigate(path)
    }
  }, [previewData, path, data, pages])

  return (
    <Page {...sections}>
      <PreviewRow
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        direction="column"
      >
        <StyledLoader
          type="Oval"
          color={theme.color.blue}
          height={200}
          width={200}
        />
        <Col xs={12}>
          <LoadPreviewText center xs={22} md={32} lg={32} xxl={32}>
            Loading Preview...
          </LoadPreviewText>
        </Col>
      </PreviewRow>
    </Page>
  )
}

PreviewPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default PreviewPage

const LoadPreviewText = styled(Text.h1)``

const PreviewRow = styled(Row)`
  height: 100%;
  flex: 1;
`

const StyledLoader = styled(Loader)`
  margin-bottom: ${rem(50)};
`
