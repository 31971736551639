import React, { useState } from 'react'
import PropTypes from 'prop-types'

import PlusMinus from '../../common/PlusMinus'
import ReactHtmlParser from 'react-html-parser'
import Text from '../../common/Text'
import { motion, AnimatePresence } from 'framer-motion'

import styled, { css } from 'styled-components'
import { rem, transparentize } from 'polished'
import { get } from 'lodash'

const RewardsAccordian = ({ items, ...data }) => {
  const [activeIndex, setIndex] = useState(null)
  const title = get(data, 'matrix_title.text')
  const description = get(data, 'label_description')
  const cards = [
    {
      image: get(data, 'column_1_image.url'),
      label: get(data, 'column_1_label'),
    },
    {
      image: get(data, 'column_2_image.url'),
      label: get(data, 'column_2_label'),
    },
    {
      image: get(data, 'column_3_image.url'),
      label: get(data, 'column_3_label'),
    },
  ]

  return (
    <>
      {title && <StyledHeader center>{title}</StyledHeader>}
      {cards &&
        cards.map(({ label, image }, col) => {
          let column = col
          const itemOpen = activeIndex === col
          const handleClick = () => {
            setIndex(itemOpen ? null : col)
          }
          return (
            <Card key={`c${col}`} first={col === 0} onClick={handleClick}>
              <CardHeader>
                <CardText>
                  {image && <img src={image} alt={label || ''} />}
                  <div>
                    {label && <Text.h4>{label}</Text.h4>}
                    {description && (
                      <Text.h6 css={{ opacity: 0.75 }}>{description}</Text.h6>
                    )}
                  </div>
                </CardText>
                <PlusMinus itemOpen={itemOpen} />
              </CardHeader>

              <AnimatePresence initial={false}>
                {itemOpen && (
                  <Body
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: 'auto' },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 0.4,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {items &&
                      items.map((item, row) => {
                        const body = get(item, 'row_body.html')
                        const col = get(item, `column_${column + 1}`) === 'on'
                        return col ? (
                          <Text key={`r${row}`} size="xxs" lineHeight={1.7}>
                            {ReactHtmlParser(body)}
                          </Text>
                        ) : null
                      })}
                  </Body>
                )}
              </AnimatePresence>
            </Card>
          )
        })}
    </>
  )
}

RewardsAccordian.propTypes = {
  primary: PropTypes.object,
  items: PropTypes.array,
}

export default RewardsAccordian

const StyledHeader = styled(Text.h2)`
  ${({ first, theme }) => css`
    margin-bottom: ${theme.space(3)};
  `};
`

const Card = styled.div`
  ${({ first, theme }) => css`
    padding-top: ${theme.space(2)};
    padding-bottom: ${theme.space(1)};
    cursor: pointer;

    ${first &&
      css`
        border-top: 1px solid
          ${transparentize(0.5, theme.color.secondaryLightBlue)};
      `};

    border-bottom: 1px solid
      ${transparentize(0.5, theme.color.secondaryLightBlue)};

    img {
      width: 100%;
      height: auto;
      object-fit: scale-down;
      max-width: ${rem(75)};
    }
  `};
`

const CardHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${theme.space(1)};
  `};
`

const CardText = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > div {
      padding: 0 ${theme.space(1.5)};
    }
  `};
`

const Body = styled(motion.div)`
  ${({ theme }) => css`
    > div {
      padding: ${rem(13.5)} ${theme.space(1)};

        &:nth-child(odd) {
          background-color ${theme.color.softBlue};
        }

    }
  `};
`
