import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { SANS_SERIF, WEIGHT } from '../../style/type'

import Text from './Text'
import CalendarIcon from '../../assets/calendar.inline.svg'
import CloseIcon from '../../assets/close.inline.svg'
import ReactDatePicker from 'react-datepicker'

import { format } from 'date-fns'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { rem } from 'polished'

import arrowPrev from '../../assets/arrow-prev.svg'
import arrowNext from '../../assets/arrow-next.svg'

import '../../style/dateStyle.css'

const DatePicker = ({ defaultLabel, onChange, includeDates, ...props }) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const [label, setLabel] = useState(defaultLabel)
  const [dateSelected, setDateSelected] = useState(false)

  return (
    <Container>
      <Select onClick={() => setShowCalendar(!showCalendar)}>
        <Text size={13} nowrap medium>
          {label}
        </Text>
        {dateSelected ? (
          <CloseIcon
            onClick={e => {
              e.stopPropagation()
              setDateSelected(false)
              setLabel(defaultLabel)
              onChange()
            }}
          />
        ) : (
          <CalendarIcon />
        )}
      </Select>
      <CalendarContainer>
        {showCalendar && (
          <ReactDatePicker
            popperModifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                //boundariesElement: 'viewport',
              },
            }}
            startOpen
            isClearable
            includeDates={includeDates}
            onChange={v => {
              if (v instanceof Date) {
                setLabel(format(v, "E',' MMM d',' yyyy"))
                onChange(v)
                setShowCalendar(false)
                setDateSelected(true)
              }
            }}
          />
        )}
      </CalendarContainer>
    </Container>
  )
}

DatePicker.propTypes = {
  defaultLabel: PropTypes.string,
  onChange: PropTypes.func,
  includeDates: PropTypes.array,
}
DatePicker.defaultProps = {
  defaultLabel: 'Jump To Date',
  onChange: () => {},
}

export default DatePicker

const DAY_WIDTH = 38

export const datePickerStyle = ({ theme }) => css`
  input {
    cursor: pointer;
  }

  .react-datepicker {
    font-family: ${SANS_SERIF};
    box-shadow: 0 8px 20px 0 rgba(29, 53, 91, 0.05);
    transform: translateY(-25px);

    &__triangle {
      display: none;
    }

    &__header {
      background-color: #fff;
      border: none;
    }

    &__month-container {
      padding: ${theme.space(1)};
    }

    &__current-month {
      margin: ${theme.space(1.5)} 0;
      transform: translateY(-${rem(5)});
      text-transform: capitalize !important;
    }

    &__navigation--next,
    &__navigation--previous {
      width: ${rem(15)};
      height: ${rem(20)};
      transform: translateY(${theme.space(1.5)});
      border: none;
    }

    &__navigation--next {
      right: ${rem(15)};
      background: url(${arrowNext}) left center no-repeat;
      background-size: ${rem(15)} ${rem(15)};
    }

    &__navigation--previous {
      left: ${rem(15)};
      background: url(${arrowPrev}) right center no-repeat;
      background-size: ${rem(15)} ${rem(15)};
    }

    &__month {
      width: ${rem(DAY_WIDTH * 7)};
      padding: 0;
      border-left: 1px solid ${theme.color.secondaryLightBlue};
      border-top: 1px solid ${theme.color.secondaryLightBlue};
    }

    &__week,
    &__day-names {
      padding: 0;
      margin: 0;
      width: ${rem(DAY_WIDTH * 7)};
    }

    &__day-names {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &__day-name {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: ${rem(DAY_WIDTH)};
    }

    &__day {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      border-right: 1px solid ${theme.color.secondaryLightBlue};
      border-bottom: 1px solid ${theme.color.secondaryLightBlue};
      border-radius: 0;
      width: ${rem(DAY_WIDTH)};
      height: ${rem(33)};
      margin: 0;

      &--keyboard-selected {
        border-radius: 0;
        font-weight: ${WEIGHT.MEDIUM};
        color: ${theme.color.secondaryLightBlue};
      }

      &:hover {
        border-right: 1px solid ${theme.color.secondaryLightBlue};
        border-bottom: 1px solid ${theme.color.secondaryLightBlue};
        border-radius: 0;
      }
    }

    &__day--today {
      background-color: #fff;
      border-right: 1px solid ${theme.color.secondaryLightBlue};
      border-bottom: 1px solid ${theme.color.secondaryLightBlue};

      border-radius: 0;

      &:hover {
        border-right: 1px solid ${theme.color.accentBlue};
        border-bottom: 1px solid ${theme.color.accentBlue};
        border-radius: 0;
      }
    }

    &__current-month {
      text-transform: uppercase;
      font-weight: ${WEIGHT.MEDIUM};
    }

    &__day--disabled {
      color: ${theme.color.secondaryLightBlue};
    }
  }
`

const CalendarContainer = styled.div`
  ${({ theme }) => css`
    ${datePickerStyle};
    position: absolute;
  `};
`

const Container = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;
    height: ${rem(36)};
    z-index: 99;
  `};
`

const Select = styled.button`
  ${({ minWidth, theme }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
    min-width: ${minWidth ? rem(minWidth) : 'none'};
    padding: 0 ${theme.space(1.5)};

    border: 1px solid ${theme.color.secondaryLightBlue};
    border-radius: ${theme.layout.borderRadius};
    background-color: #fff;

    cursor: pointer;

    img,
    svg {
      width: ${rem(20)};
      height: auto;
      margin-left: ${theme.space(2)};
      flex-shrink: 0;

      * {
        fill: ${theme.color.secondaryBlue};
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -${rem(8)};
      height: ${rem(8)};
      width: 100%;
      background-color: transparent;
      z-index: 99;
    }
  `};
`
