const { get } = require('lodash')

const transformToGridItems = page => ({
  grid_content: {
    document: {
      tags: get(page, 'tags', []),
      type: get(page, 'type'),
      data: {
        time: get(page, 'data.time'),
        path: get(page, 'data.path'),
        card_body: get(page, 'data.card_body'),
        card_title: get(page, 'data.card_title'),
        image: get(page, 'data.image'),
        button_label: get(page, 'data.button_label'),
        button_link: get(page, 'data.button_link'),
        extra_links: get(page, 'data.extra_links'),
        tickets_link: get(page, 'data.tickets_link'),
        location: {
          title: get(page, 'data.location.document.data.header_title.text'),
          card_title: get(page, 'data.location.document.data.card_title.text'),
          path: get(page, 'data.location.document.data.path'),
        },
        badge: get(page, 'data.badge'),
        dates: get(page, 'data.dates'),
        displayDates: get(page, 'data.display_dates'),
        mon: get(page, 'data.mon'),
        tue: get(page, 'data.tue'),
        wed: get(page, 'data.wed'),
        thu: get(page, 'data.thu'),
        fri: get(page, 'data.fri'),
        sat: get(page, 'data.sat'),
        sun: get(page, 'data.sun'),
      },
    },
  },
})

module.exports = {
  transformToGridItems,
}
