import React, { useMemo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ItemContent } from '../calendar/CalendarItemDetail'
import Text from '../../common/Text'
import { format, getMinutes, getMonth, getYear } from 'date-fns'
import { get, first } from 'lodash'
import linkResolver from '../../../utils/linkResolver'
import LinkComponent from '../../common/Link'
import { motion, AnimatePresence } from 'framer-motion'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import theme from '../../../style/theme'

const CalendarListView = ({ events, month, year }) => {
  const eventsByMonth = useMemo(
    () =>
      events.filter(({ date }) => {
        return (
          getMonth(new Date(date)) === month && getYear(new Date(date)) === year
        )
      }),
    [events, month, year],
  )

  return (
    <>
      <ListViewHeader>
        <ListViewColumn>
          <Label selectNone>Date</Label>
        </ListViewColumn>
        <ListViewColumn>
          <Label selectNone>Time</Label>
        </ListViewColumn>
        <ListViewColumn>
          <Label selectNone>Tournament</Label>
        </ListViewColumn>
        <ListViewColumn>
          <Label selectNone>Guarantee</Label>
        </ListViewColumn>
        <ListViewColumn>
          <Label selectNone>Buy-In</Label>
        </ListViewColumn>
        <ListViewColumn>
          <Label selectNone>Details</Label>
        </ListViewColumn>
      </ListViewHeader>
      <div>
        {eventsByMonth.map((event, i) => (
          <ListViewItem key={`list_view_item_${i}`} data={event} index={i} />
        ))}
        {!!!eventsByMonth.length && (
          <ListViewItemGrid>
            <EmptyList>
              <Label selectNone center>
                No Events This Month
              </Label>
            </EmptyList>
          </ListViewItemGrid>
        )}
      </div>
    </>
  )
}

CalendarListView.propTypes = {
  events: PropTypes.array,
  month: PropTypes.number,
  year: PropTypes.number,
}

export default CalendarListView

const Label = styled(Text.h6)``
const BodyText = styled(Text.h5)`
  letter-spacing: 1.2px;
`

const ListViewItem = ({ index, data }) => {
  const hoverRef = useRef()
  const [height, setHeight] = useState(0)
  const [top, setTop] = useState(true)
  const [hover, setHover] = useState(null)
  const date = get(data, 'date')
  const time = get(data, 'time')
  const title = get(data, 'tournament_title') || '⁠—'
  const buyIn = get(data, 'buy_in') || '⁠—'
  const guarantee = get(data, 'guarantee') || '⁠—'
  const detailsLabel = get(data, 'details_label') || 'Learn More'

  const link = {
    href: linkResolver(data, 'details'),
    target: get(data, 'details.target'),
  }

  const dateFormatted = date ? format(new Date(date), "eee',' MMM d") : '⁠—'

  useEffect(() => {
    const el = get(hoverRef, 'current')
    const hoverEl = get(el.querySelectorAll('.item-content'), '[0]')
    if (hoverEl) {
      const offsetHeight = get(hoverEl, 'offsetHeight')
      const { top } = hoverEl.getBoundingClientRect()
      //const h = !typeof window !== 'undefined' ? window.innerHeight : 0
      const isTop = top > 100
      if (top !== isTop) setTop(isTop)
      setHeight(offsetHeight)
    }
  }, [hover, index])

  const details = {
    title,
    buyIn,
    guarantee,
    detailsLabel,
    link,
    time,
    date,
  }

  return (
    <ListViewItemGrid>
      <ListViewColumn>
        <BodyText>{dateFormatted}</BodyText>
      </ListViewColumn>
      <ListViewColumn>
        <BodyText>{time}</BodyText>
      </ListViewColumn>
      <ListViewColumn
        onMouseEnter={() => setHover(details)}
        onMouseLeave={() => setHover(null)}
      >
        <BodyText>{title}</BodyText>
      </ListViewColumn>
      <ListViewColumn>
        <BodyText>{guarantee}</BodyText>
      </ListViewColumn>
      <ListViewColumn>
        <BodyText>{buyIn}</BodyText>
      </ListViewColumn>
      {!link.href && (
        <ListViewColumn>
          <BodyText>—</BodyText>
        </ListViewColumn>
      )}
      {link.href && (
        <ListViewColumn>
          <LinkComponent {...link}>
            <BodyText color={theme.color.secondaryBlue}>
              {detailsLabel}
            </BodyText>
          </LinkComponent>
        </ListViewColumn>
      )}
      <div ref={hoverRef}>
        <AnimatePresence>
          {hover && (
            <Hover
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  type: 'tween',
                  duration: 0.3,
                  ease: 'easeOut',
                  delay: 0.75,
                },
              }}
              exit={{ opacity: 0 }}
              transition={{
                type: 'tween',
                duration: 0.3,
                ease: 'easeOut',
              }}
              css={{ zIndex: details ? 11 : 0 }}
              height={height}
              top={top}
            >
              <ItemContent {...details} />
            </Hover>
          )}
        </AnimatePresence>
      </div>
    </ListViewItemGrid>
  )
}

ListViewItem.propTypes = {
  data: PropTypes.object,
  setHover: PropTypes.func,
}

const Hover = styled(motion.div)`
  ${({ height, top, theme }) => css`
    position: absolute;
    top: ${top ? 0 : `calc(${height}px + 100%)`};
    left: 25%;
    transform: translate(-50%, -${height}px);
    pointer-events: none;
  `};
`

const ListViewItemGrid = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    background-color: white;
    display: grid;
    grid-template-columns: repeat(
      24,
      minmax(${rem(theme.breakpointsPx.md / 24)}, 1fr)
    );
    grid-template-rows: ${theme.space(5)};
    grid-column-gap: 0;
    grid-row-gap: 0;
    align-items: center;

    border-bottom: 1px solid ${theme.color.secondaryLightBlue};
    border-right: 1px solid ${theme.color.secondaryLightBlue};
    border-left: 1px solid ${theme.color.secondaryLightBlue};

    &:last-child {
      border-radius: 0 0 ${theme.layout.borderRadius}
        ${theme.layout.borderRadius};
    }
  `}
`

const ListViewHeader = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(
      24,
      minmax(${rem(theme.breakpointsPx.md / 24)}, 1fr)
    );
    grid-template-rows: ${theme.space(5)};
    grid-column-gap: 0;
    grid-row-gap: 0;
    align-items: center;

    background-color: white;
    border-radius: ${theme.layout.borderRadius} ${theme.layout.borderRadius} 0 0;
    border: 1px solid ${theme.color.secondaryLightBlue};
  `}
`
const ListViewColumn = styled.div`
  ${({ theme, span = 2 }) => css`
    &:nth-child(1) {
      grid-column: span 4;
    }

    &:nth-child(2) {
      grid-column: span 2;
    }

    &:nth-child(3) {
      grid-column: span 7;
      overflow: hidden;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        bottom: 0;
        width: ${rem(40)};
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        z-index: 10;
      }
    }
    &:nth-child(4) {
      grid-column: span 3;
    }
    &:nth-child(5) {
      grid-column: span 2;
    }
    &:nth-child(6) {
      grid-column: span 6;
    }

    ${theme.media.xl`
      &:nth-child(1) {  grid-column: span 4; }
      &:nth-child(2) {  grid-column: span 2; }
      &:nth-child(3) {  grid-column: span 9; }
      &:nth-child(4) {  grid-column: span 3; }
      &:nth-child(5) {  grid-column: span 2; }
      &:nth-child(6) {  grid-column: span 4; }

    `};

    ${theme.media.xxl`
      &:nth-child(1) {  grid-column: span 3; }
      &:nth-child(2) {  grid-column: span 2; }
      &:nth-child(3) {  grid-column: span 11; }
      &:nth-child(4) {  grid-column: span 3; }
      &:nth-child(5) {  grid-column: span 2; }
      &:nth-child(6) {  grid-column: span 3; }

    `};

    grid-column: span ${span};
    display: flex;
    justify-content: flex-start;
    ${BodyText}, ${Label} {
      margin: ${theme.space(1)} 0 ${theme.space(1)} ${theme.space(3)};
      white-space: nowrap;
    }

    a {
      transition: opacity 0.3s ease-out;

      > ${BodyText} {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: ${theme.color.secondaryLightBlue};
        }
      }
    }

    a:hover {
      opacity: 0.7;
    }
  `}
`

const EmptyList = styled.div`
  ${({ theme }) => css`
    margin: ${theme.space(1)} ${theme.space(1)};
    grid-column: span 24;
  `}
`
