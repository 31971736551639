import React from 'react'
import PropTypes from 'prop-types'

import Link from '../../common/Link'
import Text from '../../common/Text'

import { useAppContext } from '../../../context/AppContext'
import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { rem } from 'polished'

const Awards = ({ items, ...props }) => {
  const title = get(props, 'title.text')
  const { isLarge } = useAppContext()
  return (
    <Container>
      {title && <Text.h4 center={!isLarge}>{title}</Text.h4>}
      <Grid>
        {items &&
          !!items.length &&
          items.map((item, i) => {
            const awardTitle = get(item, 'award_title.text')
            const awardLabel = get(item, 'award_label')
            const src = get(item, 'award_image.url')
            const alt = get(item, 'award_image.alt') || ''
            const link = {
              href: get(item, 'award_link.url'),
              target: get(item, 'award_link.target'),
            }
            return (
              <Item key={i}>
                {src && <img src={src} alt={alt} />}
                <div>
                  {awardTitle && <Text.h5>{awardTitle}</Text.h5>}
                  {awardLabel && (
                    <Link {...link}>
                      <Text uppercase size="xxs" bold>
                        {awardLabel}
                      </Text>
                    </Link>
                  )}
                </div>
              </Item>
            )
          })}
      </Grid>
    </Container>
  )
}

Awards.propTypes = {
  items: PropTypes.array,
}

export default Awards

const Container = styled.div`
  ${({ theme }) => css`
    ${theme.layout.verticalPaddingTopMenu};

    h4 {
      margin-bottom: ${theme.space(2)};
    }
  `};
`

const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(${rem(120)}, 1fr);
    grid-gap: ${theme.space(1)} ${theme.space(1)};

    width: 100%;

    @media (min-width: ${rem(1300)}) {
      ${theme.media.xxl`
        grid-template-columns: repeat(2, minmax(${rem(120)}, 1fr));
        grid-gap: ${theme.space(1.5)} ${theme.space(3)};
      `};
    }
  `};
`

const Item = styled.div`
  ${({ theme }) => css`
    display: flex;
    background-color: ${theme.color.softBlue};
    border-radius: ${theme.layout.borderRadius};

    > div {
      padding: ${theme.space(2)} ${theme.space(1.5)};

      h5 {
        margin-bottom: ${theme.space(1)};
      }
    }

    img {
      height: 100%;
      width: auto;
      max-height: ${rem(120)};
      object-fit: contain;
    }
  `};
`
