import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import {
  CARD_STYLES,
  EVENT_DETAIL_PAGE,
  GATSBY_SITE_URL,
  PROMO_DETAIL_PAGE,
} from '../../../../lib/constants'

import AlphaGradient, { AlphaGradientCorner } from '../../common/AlphaGradient'
import AspectRatioBox from '../../common/AspectRatioBox'
import LinkComponent from '../../common/Link'
import { Close, MoreDots } from '../../common/Icons'
import { StyledButton } from '../../common/Button'

import { get } from 'lodash'
import getPath from '../../../../lib/getPath'
import {
  parseRecurringRange,
  parseDayBasedData,
  extractRange,
} from '../../../../lib/transformDisplayDates'
import { rem } from 'polished'
import { motion } from 'framer-motion'

import { compareAsc, format, isAfter, isDate, isToday } from 'date-fns'
import styled, { css } from 'styled-components'

import {
  BadgeContainerTriangle,
  ButtonContainer,
  Image,
  ImagePositioner,
  Item,
  LinksList,
  parseExtraLinks,
  TextBody,
  TextContainer,
  TextDate,
  TextTitle,
  ToggleButton,
} from './GridItemCommon'
import { ICON_ATLAS } from '../../common/IconAtlas'
import linkResolver from '../../../utils/linkResolver'
import truncate from '../../../utils/truncateText'
import { useAppContext } from '../../../context/AppContext'
import { buildUrl } from '../../../utils/calendarLinks'
import useCalendarLinks from '../../../hooks/useCalendarLinks'

// Aspect Ratio
const WIDTH = 69
const HEIGHT = 43

const LINK_HEIGHT = 40

const FeaturedGridItem = ({ item, style, tags }) => {
  const today = new Date()
  const { isMedium, isLarge } = useAppContext()
  const [toggleOn, setToggle] = useState(false)
  const toggle = useCallback(() => setToggle(!toggleOn), [toggleOn])
  const data = get(item, 'grid_content.document.data')
  const type = get(item, 'grid_content.document.type')
  const displayDates = get(data, 'displayDates')
  const datesRaw = get(data, 'dates', [])
  // console.log('datesRaw', datesRaw)
  const dates = datesRaw
    .filter(({ date }) => date && isDate(new Date(date)))
    .map(({ date }) => new Date(date))
  // date && is causing only the start date to show
  // if there is only one date the obvious happens
  // if there are two dates it means there is a start and end
  // only thing we can do at this point is have the add to calendar add it for that entire period which if it is recurring will be incorrect
  // console.log('data', data)
  const image = get(data, 'image')
  const title = get(data, 'card_title.text')
  const body = get(data, 'card_body.text')
  const badge = get(data, 'badge')
  const label = get(data, 'button_label')

  const time = get(data, 'time.text')
  const location =
    get(data, 'location.card_title') || get(data, 'location.title.text')
  const extraLinks = parseExtraLinks(get(data, 'extra_links', []))
  dates.sort(compareAsc)
  const dateRaw = !!dates.length && dates[0]
  const date = dateRaw && format(dateRaw, "E',' MMM d").toUpperCase()
  const buttonLink = {
    href: linkResolver(data, 'button_link') || getPath({ data }),
    target: get(data, 'button_link.target'),
  }
  const hasButtonPathOrLink = get(data, 'path') || get(data, 'button_link.url')

  const shouldDisplayDate = useMemo(() => {
    if (type !== PROMO_DETAIL_PAGE) return true
    if (displayDates && displayDates.length === 1 && !displayDates[0].end_date)
      return true
    if (!displayDates && datesRaw.length === 1 && !datesRaw[0].end_date)
      return true
    else if (!displayDates || (displayDates && !!displayDates.length))
      return false
    else return !!date
  }, [date, datesRaw, displayDates, type])

  const ticketsLink = {
    href: linkResolver(data, 'tickets_link'),
    target: get(data, 'tickets_link.target'),
    label: 'Buy Tickets',
  }

  const fullDateRange = useMemo(() => {
    if (displayDates) return dates
    const { onlyOnDays, isOnCertainDays } = parseDayBasedData(data)
    const { range, isRange } = extractRange(dates)
    if (isRange && isAfter(range[1], range[0])) {
      return parseRecurringRange(range, isOnCertainDays, onlyOnDays, item.type)
    } else {
      return dates
    }
  }, [data, dates, displayDates, item.type])

  // const onlyOnDays = parseDayBasedData(data).onlyOnDays
  // const recurring = dates.length > 1 && parseDayBasedData(data).isOnCertainDays

  const nextDateInFuture = fullDateRange.find(
    date => isAfter(date, today) || isToday(date),
  )

  const displayCalLinks = useMemo(() => {
    if (type === EVENT_DETAIL_PAGE && nextDateInFuture) {
      return true
    } else if (fullDateRange.length === 1) {
      return true
    } else {
      return false
    }
  }, [fullDateRange.length, nextDateInFuture, type])

  // const endDate = dates.length > 1 ? dates[dates.length - 1] : null

  const calendarLinks = useCalendarLinks(
    !!(displayCalLinks && nextDateInFuture),
    time,
    nextDateInFuture,
    get(data, 'location.title', ''),
    title,
    body,
    !isMedium,
    GATSBY_SITE_URL + buttonLink.href,
    // endDate,
    // dates,
    // recurring,
    // onlyOnDays,
  )

  if (ticketsLink.href) extraLinks.push(ticketsLink)
  if (calendarLinks.length) extraLinks.push(...calendarLinks)

  const len = extraLinks.length
  const showExtraLinks =
    !!len &&
    [CARD_STYLES.FEATURED_CARD, CARD_STYLES.EVENT_VENUE_CARD].includes(style)
  const showTags =
    [CARD_STYLES.EVENT_VENUE_CARD].includes(style) && tags && !!tags.length

  const useTimeAndLocation = style === CARD_STYLES.EVENT_VENUE_CARD
  const timeAndLocation = `${time} @ ${location}`

  const width = WIDTH
  const height = HEIGHT

  const textProps = {}
  if ([CARD_STYLES.FEATURED_CARD, CARD_STYLES.EVENT_VENUE_CARD].includes(style))
    textProps.center = true

  //temp
  const buttonContent = (
    <ButtonContainer toggleOn={toggleOn} fullWidth>
      {buttonLink.href && (
        <StyledButton {...buttonLink} fullWidth>
          {label || 'Read more'}
        </StyledButton>
      )}
      {showExtraLinks && (
        <ToggleButton inline outline onClick={toggle}>
          {toggleOn ? <Close /> : <MoreDots />}
        </ToggleButton>
      )}
    </ButtonContainer>
  )

  const imageContent = (
    <>
      <ImagePositioner>
        <AspectRatioBox absolute width={width} height={height}>
          {image && <Image src={image.url} alt={title || ''} />}
        </AspectRatioBox>
        <AlphaGradient padGradient={15} />
      </ImagePositioner>
    </>
  )

  const textContent = (
    <TextContent>
      <TextContainer>
        {shouldDisplayDate && (
          <TextDate {...textProps} medium>
            {date}
          </TextDate>
        )}
        {title && (
          <TextTitle.h4
            white
            bold
            size={18}
            lg={title.length <= 70 ? 24 : 18}
            spacing={1.58}
            spacingLg={1.9}
            lineHeight="24px"
            lineHeightLg={title.length <= 70 ? '30px' : '24px'}
            {...textProps}
          >
            {title}
          </TextTitle.h4>
        )}
        {body && !useTimeAndLocation && (
          <TextBody
            {...textProps}
            medium
            color="secondaryLightBlue"
            size="xxs"
            lg={13}
            lineHeight={16 / 11}
            lineHeightLg="23px"
          >
            {truncate(body, 190)}
          </TextBody>
        )}
        {timeAndLocation && useTimeAndLocation && (
          <TextBody
            {...textProps}
            medium
            color="secondaryLightBlue"
            xs="xs"
            lg="xs"
            lineHeightMultiplier={19 / 13}
          >
            {timeAndLocation}
          </TextBody>
        )}
        {showTags && <TagGroup>{tags}</TagGroup>}
      </TextContainer>
      {hasButtonPathOrLink && buttonContent}
    </TextContent>
  )

  //Transition Stuff
  const transition = {
    duration: 0.4,
    ease: [0.04, 0.62, 0.23, 0.98],
  }

  const variants = {
    open: { y: -LINK_HEIGHT * len },
    closed: { y: 0 },
  }

  const motionProps = {
    initial: 'closed',
    animate: toggleOn ? 'open' : 'closed',
    exit: 'closed',
    variants,
    transition,
  }

  const Badge = get(ICON_ATLAS, badge)
  const showBadge = [CARD_STYLES.FEATURED_CARD].includes(style) && Badge
  const limitButtonWidth = style === CARD_STYLES.GRADIENT_CARD

  return (
    <Item>
      <Content
        styleType={style}
        limitButtonWidth={limitButtonWidth}
        {...motionProps}
      >
        {imageContent}
        <AlphaGradientCorner transparent />

        {textContent}
        {showBadge && (
          <BadgeContainerTriangle>
            <svg width={80} height={80}>
              <path fill="#FDB61B" d="M0 0h75a5 5 0 015 5v75L0 0z" />
            </svg>
            <Badge />
          </BadgeContainerTriangle>
        )}
      </Content>
      <LinksList
        medium
        center
        size="sm"
        css={{ pointerEvents: showExtraLinks ? 'all' : 'none' }}
        {...motionProps}
      >
        {showExtraLinks && (
          <ul>
            {extraLinks.map(({ href, target, label }, i) => (
              <li key={label + i}>
                <LinkComponent {...{ href, target }}>{label}</LinkComponent>
              </li>
            ))}
          </ul>
        )}
      </LinksList>
    </Item>
  )
}

FeaturedGridItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.node),
}

export default FeaturedGridItem

const Square = styled.div`
  ${({ theme }) => css`
    width: ${rem(30)};
    height: ${rem(30)};
    background-color: ${theme.color.yellow};
  `};
`

const TextContent = styled.div`
  ${({ theme, styleType }) => css`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ${TextContainer} {
      padding: 0 ${theme.space(3)};
    }
  `}
`

const Content = styled(motion.div)`
  ${({ limitButtonWidth, theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: ${rem(370)};
    height: 100%;

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    background: ${theme.color.blue};

    ${ImagePositioner} {
      position: absolute;
      top: 0;
      left: 0;
    }

    ${BadgeContainerTriangle} {
      svg:not(:first-child) {
        max-width: ${rem(36)};
        max-height: ${rem(36)};
      }
    }

    ${ButtonContainer} {
      justify-content: flex-start;
      padding: 0 ${theme.space(3)};
      margin: ${theme.space(0.5)} 0 ${theme.space(3)};

      > ${StyledButton} {
        flex: 1;
        max-width: ${limitButtonWidth ? rem(200) : 'none'};
      }
    }
    .img-spacer {
      display: block;
    }
  `};
`

const TagGroup = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin-bottom: ${theme.space(1)};

    > div {
      margin: ${theme.space(0.5)} ${theme.space(0.5)};
    }
  `}
`
