import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

// Don't use with images

const AspectRatioContainer = ({
  border,
  className,
  children,
  height,
  test,
  width,
}) => {
  return (
    <Container border={border} className={className} test={test}>
      <svg viewBox={`0 0 ${width} ${height}`} />
      {children}
    </Container>
  )
}

AspectRatioContainer.propTypes = {
  border: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.number,
  test: PropTypes.bool,
  width: PropTypes.number,
}

AspectRatioContainer.defaultProps = {
  height: 9,
  width: 16,
}

export default AspectRatioContainer

const Container = styled.div`
  ${({ border, theme, test }) => css`
    position: relative;
    display: grid;

    ${border &&
      css`
        border-radius: ${theme.layout.borderRadius};
        overflow: hidden;
      `}

    ${test &&
      css`
        border: 1px solid red;
      `};

    > * {
      grid-area: 1 / 1 / 2 / 2;
    }
  `};
`
