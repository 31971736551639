import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Button from '../../common/Button'
import CornerGradient from '../../common/CornerGradient'
import LinkComponent from '../../common/Link'
import ReactHtmlParser from 'react-html-parser'
import Text from '../../common/Text'
import Sticky from 'react-stickynode'
import { PrevNextArrow, Recurring } from '../../common/Icons'

import { format, startOfDay, formatISO } from 'date-fns'
import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useAppContext } from '../../../context/AppContext'
import { ICON_ATLAS, iconCss } from '../../common/IconAtlas'
import { FEATURED_TAGS } from '../../templates/EventCalendarPage'
import {
  parseDayBasedData,
  reduceFormatDays,
} from '../../../../lib/transformDisplayDates'

const Event = props => {
  const { isLarge } = useAppContext()
  const image = {
    src: get(props, 'image.url'),
    alt: get(props, 'image.alt') || '',
  }
  const title = get(props, 'card_title.text')
  const body = get(props, 'card_body.html')
  const time = get(props, 'time.text') || ''
  const label = get(props, 'button_label') || 'View Details'
  const tags = get(props, 'tags')

  const venueTitle = get(props, 'location.card_title')

  const displayTime = useMemo(() => {
    const { onlyOnDays, isOnCertainDays } = parseDayBasedData(props)
    let dayStr = ''
    if (isOnCertainDays) {
      dayStr = (
        <>
          <RecurringStyled />
          {'Every ' +
            onlyOnDays.slice(0, 4).reduce(reduceFormatDays(false, '', ''), '') +
            ' @ '}
        </>
      )
    }

    return (
      <p>
        {dayStr}
        {time}
      </p>
    )
  }, [props, time])

  const isFeatured = tags.reduce((acc, tag) => {
    if (acc) return acc
    return FEATURED_TAGS.includes(tag.toLowerCase())
  }, false)

  const badgeId = get(props, 'badge')
  const Badge = get(ICON_ATLAS, badgeId)
  const path = get(props, 'path')
  const maxWidth = { maxWidth: badgeId ? '85%' : 'calc(100% - 30px)' }

  return (
    <Item featured={isFeatured} href={path}>
      <ImageContainer>
        <Image alt="" {...image} />
      </ImageContainer>

      <TextGroup>
        {isFeatured && <CornerGradient />}

        <div style={maxWidth}>
          {isLarge && title && <Text.h4 white={isFeatured}>{title}</Text.h4>}
          {!isLarge && title && (
            <Text.h5 size={13} spacing={1} lineHeight={1.4} white={isFeatured}>
              {title}
            </Text.h5>
          )}
          {(body || time) && (
            <Text
              white={isFeatured}
              size="xxs"
              lg="xs"
              medium={isLarge}
              lineHeight={isLarge ? 2.1 : '18px'}
            >
              {isLarge && venueTitle && ReactHtmlParser(venueTitle)}
              {displayTime}
            </Text>
          )}
        </div>
        {path && (
          <Button
            className="static-btn"
            as="div"
            tabIndex={-1}
            outline={isFeatured ? 'white' : true}
          >
            {label}
          </Button>
        )}
      </TextGroup>

      {isLarge ? (
        <>
          {badgeId && Badge && (
            <BadgeContainer>
              <svg width={80} height={80}>
                <path fill="#FDB61B" d="M0 0h75a5 5 0 015 5v75L0 0z" />
              </svg>
              <Badge />
            </BadgeContainer>
          )}
        </>
      ) : (
        <RightIconContainer>
          {badgeId && Badge ? (
            <BadgeContainerSmall>
              <Square />
              <Badge />
            </BadgeContainerSmall>
          ) : (
            <span />
          )}

          <PrevNextArrow width={18} height={18} fill="#e6e6e6" />

          <span />
        </RightIconContainer>
      )}
    </Item>
  )
}

const EventCalendarDayItem = ({ date, filter, height, index, items }) => {
  const { isLarge } = useAppContext()
  const ISOStamp = new Date(formatISO(new Date(date)))
  const dateId = `date${startOfDay(ISOStamp).getTime()}`
  const dateFormated = format(new Date(date), isLarge ? 'MMMM d' : 'E, MMM d')

  return (
    <Container id={dateId}>
      <Left>
        <Sticky top={isLarge ? 174 : 0} bottomBoundary={height} innerZ={3}>
          {index !== 0 && <Border />}
          <WhiteBg>
            {isLarge && <Text.h5>{format(new Date(date), 'E')}</Text.h5>}
            {isLarge ? (
              <Text.h3>{dateFormated}</Text.h3>
            ) : (
              <Text.h4 center>{dateFormated}</Text.h4>
            )}
          </WhiteBg>
        </Sticky>
      </Left>

      <Right>
        {items.map((item, i) => {
          const itemsProps = get(item, 'grid_content.document.data')
          const tags = get(item, 'grid_content.document.tags')
          const show = filter ? tags.includes(filter) : true
          return show ? (
            <Event key={`evt${i}`} {...itemsProps} tags={tags} />
          ) : null
        })}
      </Right>
    </Container>
  )
}

EventCalendarDayItem.propTypes = {
  date: PropTypes.string,
  filter: PropTypes.string,
  height: PropTypes.number,
  index: PropTypes.number,
  items: PropTypes.array,
}

export default EventCalendarDayItem

const ImageContainer = styled.div`
  ${({ theme }) => css`
    height: 100%;
    overflow: hidden;
    background-color: ${theme.color.blue};
  `};
`

const Image = styled.img`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    transition: 0.3s transform, 0.3s opacity;
  `};
`

const Border = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: -${rem(1)};
    left: 0;
    width: 100%;

    border: none;
    padding: 0;
    height: ${rem(31)};
    background: #fff;
    border-top: 1px solid ${theme.color.rule};

    ${theme.media.lg`
     top: -${rem(31)};
    `};
  `};
`

const RightIconContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    padding-bottom: ${theme.space(2)};
    background-color: #fff;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.color.rule};
    }

    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(2)};

      padding: ${theme.space(3)} ${theme.space(0)} ${theme.space(2)};
    `};
  `};
`

const Left = styled.div`
  ${({ theme }) => css`
    grid-column: span 3;
    background-color: #fff;
    z-index: 2;

    width: 100%;

    > * {
      background-color: #fff;
    }
  `};
`

const Right = styled.div`
  ${({ theme }) => css`
    position: relative;
    grid-column: span 9;
    z-index: 1;
  `};
`

const Item = styled(LinkComponent)`
  ${({ featured, theme }) => css`
    position: relative;
    display: grid;
    grid-template-columns: ${rem(100)} 1fr;

    width: 100%;
    min-height: ${rem(100)};
    margin-bottom: ${theme.space(1)};

    background-color: ${featured ? theme.color.blue : theme.color.softBlue};
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;
    cursor: pointer;
    transition: background-color 0.4s ease-in;

    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    * {
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
    }

    ${CornerGradient} {
      transition: opacity 0.4s ease-in;
    }

    .static-btn {
      opacity: 1;
      max-width: ${rem(170)};
    }

    &:hover {
      .static-btn {
        opacity: 1;
      }

      transition: background-color 0.4s ease-in;
      background-color: ${featured ? theme.color.blue : '#F9FAFB'};

      ${CornerGradient} {
        transition: opacity 0.3s ease-out;
        opacity: 0;
      }
    }

    ${theme.media.lg`
      grid-template-columns: ${rem(210)} 1fr;
      min-height: ${rem(130)};
    `};
  `};
`

const RecurringStyled = styled(Recurring)`
  ${({ theme }) => css``}
`

const TextGroup = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    flex-direction: column;

    padding: ${theme.space(1.5)} ${theme.space(2)};

    h4,
    h5 {
      margin-bottom: ${theme.space(1)};
    }

    .static-btn {
      flex: none;
      margin-top: ${theme.space(3)};
    }

    ${RecurringStyled} {
      height: ${rem(12)};
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: ${theme.space(0.5)};
    }

    ${theme.media.lg`
     padding: ${theme.space(3)};
    `};

    ${theme.media.xxl`
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .static-btn {
            margin-top: 0;
            margin-right: ${theme.space(3)};
        }
    `};
  `};
`

const BadgeContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: -0.5px;

    ${iconCss(theme.color.blue)}
    svg:first-child {
      * {
        stroke: none;
      }
    }

    svg:last-child {
      position: absolute;
      top: ${rem(8)};
      right: ${rem(8)};
      width: ${rem(30)};
      height: ${rem(30)};
    }
  `}
`

const BadgeContainerSmall = styled.div`
  ${({ theme }) => css`
    ${iconCss(theme.color.blue)}

    svg:first-child {
      * {
        stroke: none;
      }
    }

    svg:last-child {
      position: absolute;
      top: ${rem(5)};
      right: ${rem(5)};
      width: ${rem(20)};
      height: ${rem(20)};
    }
  `}
`

const Square = styled.div`
  ${({ theme }) => css`
    width: ${rem(30)};
    height: ${rem(30)};
    background-color: ${theme.color.yellow};
  `};
`

const WhiteBg = styled.div`
  ${({ theme }) => css`
    background-color: #fff;
    padding: ${theme.space(2)} 0 ${theme.space(1)} 0;

    ${theme.media.lg`
        padding: 0;
    `};
  `};
`

Item.propTypes = {
  featured: PropTypes.bool,
  theme: PropTypes.object,
}
