import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import AspectRatioBox from '../../common/AspectRatioBox'
import ReactHtmlParser from 'react-html-parser'
import Modal from '../../common/Modal'
import Text from '../../common/Text'
import { Close } from '../../common/Icons'

import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem, transparentize } from 'polished'
import { useAppContext } from '../../../context/AppContext'

// Aspect Ratio
const WIDTH = 381
const HEIGHT = 237

const DiningModal = ({
  alt,
  src,
  price,
  showModal,
  setModal,
  title,
  description,
}) => {
  const { focusStyleOn } = useAppContext()
  const modal = useRef()
  const flickityContainer = useRef()
  const setModalClosed = () => setModal(null)

  const target = get(flickityContainer, 'current')
  if (target) {
    const el = target.querySelector('.next')
    if (el) el.focus()
  }

  const closeModal = useCallback(() => {
    const close = get(modal, 'current.close')
    if (close) close()
  }, [modal])

  return (
    <Modal
      targetId="fixed-portal"
      ref={modal}
      show={showModal}
      hideModal={setModalClosed}
      onClose={setModalClosed}
      transitionType="fadeIn"
      zIndex={99999999999999}
    >
      <ModalContainer>
        <Container>
          {src && (
            <AspectRatioBox width={WIDTH} height={HEIGHT}>
              <img src={src} alt={alt} />
            </AspectRatioBox>
          )}

          {(title || price) && (
            <MenuItemHeader>
              {title && <Text.h5 white>{ReactHtmlParser(title)}</Text.h5>}
              {price && <Text.h5 white>{ReactHtmlParser(price)}</Text.h5>}
            </MenuItemHeader>
          )}

          {description && (
            <Text color="secondaryLightBlue" size="sm">
              {ReactHtmlParser(description)}
            </Text>
          )}
        </Container>

        <CloseButton onClick={closeModal} showFocus={focusStyleOn}>
          <Close />
        </CloseButton>
      </ModalContainer>
    </Modal>
  )
}

DiningModal.propTypes = {
  alt: PropTypes.string,
  price: PropTypes.string,
  src: PropTypes.string,
  showModal: PropTypes.bool,
  setModal: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default DiningModal

const ModalContainer = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;

    padding: 0 ${theme.space(1)};

    background-color: ${transparentize(0.05, theme.color.blue)};
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100vw;

    img {
      border-radius: ${theme.layout.borderRadius};
    }

    p {
      margin-top: ${theme.space(1)};
    }

    .mask {
      height: auto;
    }
  `};
`

export const CloseButton = styled.button`
  ${({ showFocus, theme }) => css`
    ${theme.mixin.fadeIn()};

    position: absolute;
    top: ${theme.space(2)};
    right: ${theme.space(2)};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    padding: 0;

    width: ${rem(41)};
    height: ${rem(41)};

    background-color: rgba(255, 255, 255, 0.2);
    border-radius: ${rem(41)};

    cursor: pointer;
    z-index: 99999999;

    transition: background-color 0.3s ease-out;

    svg {
      width: ${rem(15)};
      height: ${rem(15)};
      object-fit: scale-down;

      path {
        transition: fill 0.3s ease-out;
        fill: #fff;
      }
    }

    ${showFocus &&
      css`
        &:focus {
          border: 1px solid ${theme.color.accentLightBlue};
          box-shadow: 0 0 5px ${theme.color.accentLightBlue};
        }
      `};

    &:hover {
      background-color: rgb(255, 255, 255, 0.3);
      transition: background-color 0.3s ease-in;

      svg path {
        transition: fill 0.3s ease-in;
      }
    }

    ${theme.media.lg`
     top: ${theme.space(4)};
      right: ${theme.space(4)};
    `}
  `};
`

const MenuItemHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: ${theme.space(3)};
  `};
`
