import { ICON_ATLAS, iconCss } from '../../common/IconAtlas'
import { ALL_ICON_TYPES } from '../../../../lib/constants'
import CornerGradient from '../../common/CornerGradient'
import Text from '../../common/Text'
import { format } from 'date-fns'
import theme from '../../../style/theme'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import LinkComponent from '../../common/Link'
import { rem } from 'polished'
import img from '../../../assets/hover-bg.png'

// Hover item for calendar

export const ItemContent = ({ date, title, time, link, ...props }) => {
  const PokerBadge = ICON_ATLAS[ALL_ICON_TYPES.ICON_POKER]
  return (
    <ItemDetailContainer className="item-content" {...props}>
      <Content>
        <CornerGradient image={img} />

        <div>
          <ItemDetailHeader>
            {date && (
              <div>
                <Text size="xxs" bold white uppercase>
                  {date}
                </Text>
                {time && <Text.h5 white>{time}</Text.h5>}
              </div>
            )}
            <PokerBadge />
          </ItemDetailHeader>
          <Text
            size="xl"
            white
            bold
            uppercase
            lineHeightMultiplier={28 / 20}
            lineHeight={rem(28)}
            spacing={rem(1)}
          >
            {title}
          </Text>
        </div>

        {link && link.href && (
          <ItemDetailLink {...link}>
            <Text size="xs" color={theme.color.secondaryLightBlue}>
              Learn More
            </Text>
          </ItemDetailLink>
        )}
      </Content>
    </ItemDetailContainer>
  )
}

ItemContent.propTypes = {
  open: PropTypes.bool,
  date: PropTypes.string,
  title: PropTypes.string,
  time: PropTypes.string,
  link: PropTypes.object,
}

const ItemDetail = ({ date, ...props }) => {
  return (
    <ItemContent
      {...props}
      date={date ? format(date, "EEEE',' MMMM do',' yyyy") : date}
    />
  )
}

ItemDetail.propTypes = {
  open: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  title: PropTypes.string,
  time: PropTypes.string,
  link: PropTypes.object,
}

export default ItemDetail

const ItemDetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  min-height: inherit;
  padding: ${theme.space(2.5)};

  > div {
    ${Text}:last-child {
      margin-top: ${theme.space(2.5)};
    }

    ${Text}:first-child {
      margin-top: 0;
    }
  }
`

const ItemDetailLink = styled(LinkComponent)`
  ${({ theme }) => css`
    justify-self: flex-end;
    margin-top: ${theme.space(1.5)};

    * {
      transition: 0.3s color;
    }

    &:hover {
      * {
        color: #fff;
        transition: 0.3s color;
      }
    }
  `}
`

export const ItemDetailContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: ${rem(330)};
    min-height: ${rem(200)};
    background-color: ${theme.color.blue};
    border-radius: ${theme.layout.borderRadius};
    z-index: 40;
    overflow: hidden;

    ${iconCss(theme.color.yellow)};

    svg:last-child {
      width: ${rem(30)};
      height: ${rem(30)};
      margin-left: ${rem(30)};
    }
  `}
`
