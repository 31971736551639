import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { PROMO_DETAIL_PAGE } from '../../../lib/constants'
import AlphaGradient from '../common/AlphaGradient'
import Button from '../common/Button'
import Flickity from '../common/Flickity'
import { PrevNextArrow } from '../common/Icons'
import Text from '../common/Text'
import { motion } from 'framer-motion'

import { get, first } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'
import { format, getMinutes } from 'date-fns'
import linkResolver from '../../utils/linkResolver'

const SlideText = ({
  dates,
  index,
  event,
  event_button_label,
  time,
  card_title,
  card_body,
  featured_label,
  ...props
}) => {
  const date = get(dates, '[0].date')
  const timeText = get(time, 'text')
  const type = get(event, 'document.type')
  const link = {
    href: linkResolver({ event }, 'event'),
    target: get(event, 'target'),
  }
  const title = get(card_title, 'text')
  const body = get(card_body, 'text')

  return (
    <motion.div
      key={index}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        type: 'tween',
        duration: 0.4,
        ease: 'easeOut',
      }}
    >
      {featured_label && (
        <Text
          center
          color="secondaryLightBlue"
          size="xs"
          medium
          lineHeight={rem(23)}
          css={{ marginBottom: rem(7) }}
        >
          {featured_label}
        </Text>
      )}
      {title && (
        <Text.h3 center white>
          {title}
        </Text.h3>
      )}
      {date && type !== PROMO_DETAIL_PAGE && (
        <Text.h5 center white>
          {format(new Date(date), "iii',' MMM dd") +
            `${timeText ? ` @ ${timeText}` : ''}`}
        </Text.h5>
      )}

      {type === PROMO_DETAIL_PAGE && (
        <Text.h5 center white>
          {body}
        </Text.h5>
      )}

      <Button {...link} css={{ pointerEvents: 'all' }}>
        {event_button_label ? event_button_label : 'View Details'}
      </Button>
    </motion.div>
  )
}

SlideText.propTypes = {
  dates: PropTypes.array,
  event: PropTypes.object,
  event_button_label: PropTypes.string,
  index: PropTypes.number,
  card_title: PropTypes.string,
  featured_label: PropTypes.string,
  card_body: PropTypes.string,
  time: PropTypes.object,
}

const SplitGallery = ({ images, features, hasFeatures }) => {
  const flickityRef = useRef()
  const [flickity, setFlickity] = useState(null)
  const [index, setIndex] = useState(0)
  const { focusStyleOn, isLarge } = useAppContext()

  const firstImage = get(first(images), 'image.url')

  const onInit = el => setFlickity(el)

  const onPrev = () => {
    if (flickity) flickity.prev()
  }

  const onNext = () => {
    if (flickity) flickity.next()
  }

  const goTo = index => {
    if (flickity) flickity.select(index)
  }

  return (
    <Container>
      {hasFeatures ? (
        <Flickity
          ref={flickityRef}
          className="flickity"
          key={focusStyleOn + isLarge}
          minHeight={330}
          onInit={onInit}
          onChange={setIndex}
          onRemove={() => onInit(null)}
          options={{
            contain: true,
            accessibility: focusStyleOn,
            pageDots: false,
          }}
          type="eventsVenue"
        >
          {features.map(({ image, ...rest }, i) => {
            const title = get(rest, 'card_title.text')
            const img = {
              src: get(image, 'url'),
              alt: get(image, 'alt') || '',
            }

            return (
              <FeaturedItem key={'card_title' + i}>
                <img alt={title} {...img} />
              </FeaturedItem>
            )
          })}
        </Flickity>
      ) : (
        <>
          {firstImage && <img alt="" src={firstImage} />}
          <AlphaGradient deg={90} height={100} />
          <AlphaGradient />
        </>
      )}

      {!isLarge && features && (
        <PaginationContainer border>
          <GridContent>
            <AlphaGradient deg={360} height={100} />
            <AlphaGradient />

            {hasFeatures && <SlideText index={index} {...features[index]} />}
          </GridContent>
          {features.length > 1 && (
            <DotContainer>
              {features.map((item, i) => {
                const active = i === index
                const current = active ? { 'aria-current': 'step' } : {}
                return (
                  <Dot
                    {...current}
                    key={`step-${i}`}
                    active={active}
                    aria-label={`Page dot ${i}`}
                    onClick={() => goTo(i)}
                  />
                )
              })}
            </DotContainer>
          )}
        </PaginationContainer>
      )}

      {isLarge && features && (
        <PaginationContainer>
          <AlphaGradient deg={90} height={100} />
          <AlphaGradient />
          <GridContent>
            {features.length > 1 && (
              <Prev onClick={onPrev}>
                <PrevNextArrow left fill="#fff" width={40} height={40} />
              </Prev>
            )}
            {hasFeatures && <SlideText index={index} {...features[index]} />}
            {features.length > 1 && (
              <Next onClick={onNext}>
                <PrevNextArrow fill="#fff" width={40} height={40} />
              </Next>
            )}
          </GridContent>
        </PaginationContainer>
      )}
    </Container>
  )
}

SplitGallery.propTypes = {
  images: PropTypes.array,
  features: PropTypes.array,
  hasFeatures: PropTypes.bool,
}

export default SplitGallery

const DotContainer = styled.ol`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    position: absolute;
    left: 50%;
    transform: translate(-50%, -${rem(30)});
    pointer-events: none;

    list-style: none;
    margin: 0;
    padding: 0;
  `};
`

const Dot = styled.li`
  ${({ active, theme }) => css`
    width: ${rem(7)};
    height: ${rem(7)};

    border-radius: 100%;
    opacity: 1;
    background: transparent;
    border: 1px solid ${theme.color.secondaryLightBlue};
    margin: 0 ${rem(7)};
    cursor: pointer;
    transition: background-color 0.4s ease-in, border 0.4s ease-in;
    pointer-events: all;

    ${theme.media.lg`
      width: ${rem(9)};
      height: ${rem(9)};
    `};

    &:only-child {
      display: none;
    }

    ${theme.media.lg`
      margin: 0 ${theme.space(1.5)};
    `};

    &:hover {
      ${!active &&
        css`
          background: ${theme.color.yellow};
          border: 1px solid ${theme.color.yellow};
          transition: background-color 0.3s, border 0.3s;
        `};
    }

    ${active &&
      css`
        background: ${theme.color.secondaryLightBlue};
        border: none;
        cursor: auto;
      `};
  `};
`

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: ${rem(370)};
    border-radius: ${theme.layout.borderRadius};
    overflow: hidden;

    .flickity-viewport {
      min-height: ${rem(370)};
    }

    > div {
      width: 100%;
      height: 100%;

      ${theme.media.lg`
         max-height: none;
      `};
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    ${theme.media.lg`
      grid-column: 7 / 13;
      overflow: hidden;
    `};

    ${theme.media.xl`
      grid-column: 6 / 13;

      .flickity-viewport {
          min-height: ${rem(478)};
      }
    `};
  `};
`

const PaginationContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  `};
`

const Next = styled.button`
  ${({ theme }) => css`
    align-self: flex-end;
    margin-bottom: ${theme.space(8)};
    grid-column: 7/8;
    pointer-events: all;
    padding-right: ${theme.space(2)};

    cursor: pointer;

    > svg * {
      transition: 0.3s fill;
    }

    ${theme.media.xxl`
      grid-column: -2;
    `};

    &:hover {
      > svg * {
        fill: ${theme.color.yellow};
      }
    }
  `};
`

const Prev = styled(Next)`
  ${({ theme }) => css`
    grid-column: 1/2;
    padding-right: 0;
    padding-left: ${theme.space(2)};

    ${theme.media.xxl`
       grid-column: 2/3;
    `};
  `};
`

const GridContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: ${theme.space(4)} ${theme.space(3)} ${theme.space(5)};

      ${theme.media.lg`
        padding: ${theme.space(8)} 0 0 0;
        padding-bottom: ${theme.space(4)};
        grid-column: 2 / 7;
      `};

      ${theme.media.xxl`
        grid-column: 3 / 7;
      `};

      button,
      a {
        flex: 0;
        z-index: 1;
        margin: 0 auto;
        margin-top: ${theme.space(2)};
      }
    }

    ${theme.media.lg`
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(3)};
    `};
  `};
`

const FeaturedItem = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `};
`
