import { chunk } from 'lodash'
import { useMemo, useState, useCallback } from 'react'

const usePagination = (all, initialPage = 0) => {
  const [current, setCurrent] = useState(initialPage)
  const pages = useMemo(() => chunk(all, 12), [all])

  const onNext = useCallback(() => {
    if (current < pages.length - 1) {
      setCurrent(current + 1)
    }
  }, [current, pages.length])

  const onPrev = useCallback(() => {
    if (current > 0) {
      setCurrent(current - 1)
    }
  }, [current])

  return {
    pages,
    onNext,
    onPrev,
    current,
    total: pages.length,
  }
}

export default usePagination
